import AddCircleIcon from "@mui/icons-material/AddCircle";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiImport } from "react-icons/bi";
import { BsFileBarGraph, BsReceipt } from "react-icons/bs";
import { CgAdd } from "react-icons/cg";
import { LiaWpforms } from "react-icons/lia";
import { PiUsersThree } from "react-icons/pi";
import {
  RiAdminLine,
  RiEmpathizeLine,
  RiStethoscopeLine,
} from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import EyeLogo from "../../../assets/logo_eye.png";
import { getApi } from "../../../utils/axiosApiWrapper";
import "./Sidebar.scss";
import PropTypes from "prop-types";
import { useUserContext } from "../../../contexts/UserContext";
import { getSidebarMenus } from "../../../api/sidebarApi";

const menuIcons = {
  MemberList: PiUsersThree,
  AddNewMember: CgAdd,
  ViewClinicians: RiStethoscopeLine,
  ViewAdministrators: RiAdminLine,
  ViewClients: RiEmpathizeLine,
  ImportFiles: BiImport,
  Reports: BsFileBarGraph,
  Scheduler: BsReceipt,
  CreateHRAForm: LiaWpforms,
};

const DefaultIcon = AddCircleIcon;
const Icon = (props) => {
  const { name } = props;
  const IconComponent = menuIcons[name] || DefaultIcon;
  return <IconComponent />;
};

Icon.propTypes = {
  name: PropTypes.string,
};

export default function Sidebar({ roleId }) {
  const [menuData, setMenuData] = useState([]);
  const [activeNav, setActiveNav] = useState(0);
  const [showSideNav, setShowSideNav] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const { setSidebarData } = useUserContext();

  const MenuItemData = async () => {
    await getSidebarMenus()
      .then((response) => {
        setMenuData(response?.data?.data);
        setSidebarData(response?.data?.data);
      })
      .catch((error) => {
        return null;
      });
  };

  useEffect(() => {
    MenuItemData();

    const storedActiveNav = localStorage.getItem("activeNav");
    setActiveNav(storedActiveNav ? Number(storedActiveNav) : null);
  }, []);

  // Function to handle icon click
  const handleIconClick = (item) => {
    setActiveNav(item._id);
    localStorage.setItem("activeNav", item._id);
  };

  useEffect(() => {
    const menuItem = menuData?.find((item) => pathname.includes(item?.menuURL));

    if (menuItem) {
      setActiveNav(menuItem._id);
      localStorage.setItem("activeNav", menuItem._id);
    }
  }, [pathname, menuData]);

  return (
    <div
      className="sideBarContainer"
      onMouseOver={() => setShowSideNav(true)}
      onMouseLeave={() => setShowSideNav(false)}
    >
      {showSideNav && <div className={`thinOverlay`}></div>}
      <div className="logoContainer">
        <div className="logoImageContainer">
          <img src={EyeLogo} className="logoBrandImage" alt="brand logo" />
        </div>
        <h3 style={{ color: `var(--colorWhite)` }}>Focus Care</h3>
      </div>
      <div className="menuContent">
        <ul className="menuIconsList">
          {menuData?.map((item, index) => (
            <li
              key={`i${index + 1}`}
              id={`isidebar${index + 1}`}
              className="menuIcon"
            >
              <div
                className={`menuIconLeftStripe ${
                  activeNav === item._id ? "active" : ""
                }`}
              ></div>
              <div style={{ width: "4px" }}></div>
              <div
                className={`menuIconItem ${
                  activeNav === item._id ? "active" : ""
                }`}
              >
                <Link
                  to={item?.menuURL}
                  className={`icon-wrapper ${
                    activeNav === item._id ? "active" : ""
                  }`}
                  onClick={() => handleIconClick(item)}
                >
                  <div
                    className={`iconArea ${
                      activeNav === item._id ? "active" : ""
                    }`}
                    style={{ width: showSideNav ? "15%" : "100%" }}
                  >
                    <Icon name={item?.menuURL} />
                  </div>
                  {showSideNav && (
                    <p
                      className={`iconText ${
                        activeNav === item._id ? "active" : ""
                      }`}
                    >
                      {item?.menuTitle}
                    </p>
                  )}
                  {/* <p className={`iconText ${
                        activeNav === item._id ? "active" : ""
                      }`}>Administrators</p> */}
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  roleId: PropTypes.string,
};
