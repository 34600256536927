import {
  Grid,
  Stack,
  InputLabel,
  TextField,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Fade,
  Select,
  MenuItem,
  FormControl,
  Button,
  Divider,
} from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import focusCaresLoader from "../../../../../loader/focuscares_loader.gif";
import LoadingOverlay from "react-loading-overlay";
import { useLocation, useNavigate } from "react-router-dom";
import AppointmentModal from "../../../../AppointmentModal/AppointmentModal";
import Alert from "../../../../Alert/Alert";
import { getApi, putApi } from "../../../../../utils/axiosApiWrapper";
import PatientSummary from "../Sections/PatientSummary/PatientSummary";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import toast from "react-hot-toast";
import { useUserContext } from "../../../../../contexts/UserContext";
//import ConfirmDigitalSignatureDialog from "../Confirm.digital.signature";
import ConfirmDigitalSignatureDialog from "../../Confirm.digital.signature";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
const PedsPatientSummaryComp = forwardRef((props, ref) => {
  const { assessmentId } = props.assessmentId;
  const [assessorComents, setAssessorComents] = useState("");
  const [memberAcknowledge, setMemberAcknowledge] = useState("");
  const [evaluationDate, setEvaluationDate] = useState("");
  const [examFinishDate, setExamFinishDate] = useState("");
  const [providerSignature, setProviderSignature] = useState("");
  const [signSubmitImage, setSignSubmitImage] = useState("");
  const [visitType, setVisitType] = useState("");
  const [selectedVisitType, setSelectedVisitType] = useState("");
  const [consentToVideo, setConsentToVideo] = useState("");
  const [disclosureStatement, setDisclosureStatement] = useState("");
  const [clinicianName, setClinicianName] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [isVisitTypeAlertOpen, setIsVisitTypeAlertOpen] = useState(false);
  const [isEvaluationDateAlertOpen, setIsEvaluationDateAlertOpen] =
    useState(false);
  const [emptyDateErrorPopup, setEmptyDateErrorPopup] = useState(false);
  const [emptyDateErrorMessg, setEmptyDateErrorMessg] = useState("");
  const [errorSignSubmitBtn, setErrorSignSubmitBtn] = useState(false);
  const [errorSignSubmitBtnMsg, setErrorSignSubmitBtnMsg] = useState("");
  const [noShowUpdateHraStatusPopup, setNoShowUpdateHraStatusPopup] =
    useState(false);
  const [previouslyEvaluationDate, setPreviouslyEvaluationDate] = useState();
  const [
    cancelAppointmentUpdateHraStatusPopup,
    setCancelAppointmentUpdateHraStatusPopup,
  ] = useState(false);
  const [openSignSubmitDialog, setSignSubmitDialog] = useState(false);
  const [statusComment, setStatusComment] = useState("");
  const [cancleStatus, setCancleStatus] = useState("");
  const [closeDatePicker, setCloseDatePicker] = useState(false);
  const [driverLicense, setDriverLicense] = useState(""); // for 2024
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { _memberIdForQueryForm, selectedTimeZoneUtc } = useUserContext();
  const storedYear = localStorage.getItem("clientYear");

  const getPatientSummaryData = async () => {
    setIsLoading(true);
    try {
      const response = await getApi(`/hra/get-assessment?id=${assessmentId}`);
      console.log("response", response.data.data.patient?.data);
      const data = response?.data?.data?.patient?.data;
      var _providerSignature = "";
      setSignSubmitImage(data?.providerSignature);
      var providerSignatureFromSessionStorage =
        sessionStorage.getItem("providerSignature");
      const _evaluationDate = data?.evaluationDate;
      const _examFinishDate = data?.examFinishDate;
      const finalDate = sessionStorage.getItem("finalDate");
      if (_evaluationDate?.toString().includes("T")) {
        setEvaluationDate(_evaluationDate);
        setPreviouslyEvaluationDate(_evaluationDate);
      } else if (finalDate?.toString()?.includes("T")) {
        setEvaluationDate(finalDate);
        setPreviouslyEvaluationDate(finalDate);
      } else {
        setEvaluationDate("");
      }
      if (_examFinishDate?.toString().includes("T")) {
        setExamFinishDate(_examFinishDate);
      } else {
        setExamFinishDate("");
      }
      console.log("data?.assessorsComments", data?.assessorsComments);
      setAssessorComents(data?.assessorsComments);

      if (storedYear == "2023") {
        if (data?.disclosure == "1") {
          setDisclosureStatement("Y");
        } else {
          setDisclosureStatement("N");
        }
      }
      if (data?.memberAcknowledgement == "1") {
        setMemberAcknowledge("Y");
      }

      setComment(data?.visitType?.comment);
      if (data?.visitType?.memberIdentified == "2") {
        setDriverLicense("N");
      }
      if (data?.visitType?.memberIdentified == "1") {
        setDriverLicense("Y");
      }
      if (
        Boolean(
          JSON.parse(
            JSON.stringify(sessionStorage.getItem("selectedMemberVisitType")),
          ),
        )
      ) {
        const visitType = sessionStorage.getItem("selectedMemberVisitType");
        if (visitType == "In Person") {
          setSelectedVisitType("In Person");
          setVisitType("1");
        } else if (visitType == "Virtual") {
          setSelectedVisitType("Virtual: Video & Audio Capability");
          setVisitType("2");
        }
      }
      if (data?.visitType?.isVisitType == "1") {
        setSelectedVisitType("In Person");
        setVisitType("1");
        if (storedYear == "2023") {
          if (data?.visitType?.consentedToVideo == "2") {
            setConsentToVideo("N");
          } else if (data?.visitType?.consentedToVideo == "1") {
            setConsentToVideo("Y");
          }
        }
      } else if (data?.visitType?.isVisitType == "2") {
        setSelectedVisitType("Virtual: Video & Audio Capability");
        setVisitType("2");

        if (storedYear == "2023") {
          if (data?.visitType?.consentedToVideo == "2") {
            setConsentToVideo("N");
          } else if (data?.visitType?.consentedToVideo == "1") {
            setConsentToVideo("Y");
          }
        } else if (storedYear == "2024") {
          if (data?.visitType?.memberConsented == "2") {
            setConsentToVideo("N");
          } else if (data?.visitType?.memberConsented == "1") {
            setConsentToVideo("Y");
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
      return;
    }
  };

  console.log("assessorComents", assessorComents);

  const location = useLocation();
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");
  const hraStatus = location?.state?.hraStatus
    ? location?.state?.hraStatus
    : sessionStorage.getItem("hraStatus");
  const roleId = sessionStorage.getItem("roleId");
  const navigate = useNavigate();
  dayjs.extend(utc);
  dayjs.extend(timezone);
  function getReroute() {
    let path;
    const roleId = sessionStorage.getItem("roleId");
    if (roleId === "5") {
      path = "/NurseDashboard/Nurselist";
    } else {
      path = "/AdminDashboard/MemberList";
    }
    return path;
  }
  useEffect(() => {
    getPatientSummaryData();
  }, []);

  useEffect(() => {
    props.setAutoSavePedsPatientSumm({
      ...props?.autoSavePedsPatientSumm,
      evaluationDate: evaluationDate,
      examFinishDate: examFinishDate,
      memberAcknowledge: memberAcknowledge,
      assessorComents: assessorComents,
      disclosureStatement: disclosureStatement,
      visitType: visitType,
      consentToVideo: consentToVideo,
      driverLicense: driverLicense,
      comment: comment,
      providerSignature: signSubmitImage,
    });
  }, [
    evaluationDate,
    examFinishDate,
    memberAcknowledge,
    assessorComents,
    disclosureStatement,
    visitType,
    consentToVideo,
    driverLicense,
    comment,
    signSubmitImage,
  ]);

  useEffect(() => {
    const roleId = sessionStorage.getItem("roleId");
    if (roleId == 5) {
      const fname = sessionStorage.getItem("firstName");
      const lname = sessionStorage.getItem("lastName");
      const name = `${fname} ${lname}`;
      setClinicianName(name);
      const _speciality = sessionStorage.getItem("speciality");
      setSpeciality(_speciality);
      const providerSignature = sessionStorage.getItem("providerSignature");
      setProviderSignature(providerSignature);
    }
  }, []);

  const handleChangeDisclosureStatement = (event) => {
    if (event.target.checked) {
      setDisclosureStatement("Y");
    } else {
      setDisclosureStatement("N");
    }
  };
  const handleChangeConsentToVideo = (event) => {
    if (event.target.checked) {
      setConsentToVideo("Y");
    } else {
      setConsentToVideo("N");
    }
  };
  const handleChangeMemberAcknowledgement = (event) => {
    if (event.target.checked) {
      setMemberAcknowledge("Y");
    } else {
      setMemberAcknowledge("N");
    }
  };

  const handleChangeDriverLicenseCheckbox = (event) => {
    if (event.target.checked) {
      setDriverLicense("Y");
    } else {
      setDriverLicense("N");
    }
  };

  const handleEvaluationDateChange = (date) => {
    setEvaluationDate(date);
  };
  const handleExamFinishDate = (date) => {
    setExamFinishDate(date);
  };
  const handleVisitTypeChange = (e) => {
    const newVisitType = e.target.value;
    //In Person', 'Virtual: Video & Audio Capability
    if (newVisitType == "Virtual: Video & Audio Capability") {
      //setConsentToVideo("Y");
      setVisitType("2");
    }
    if (newVisitType == "In Person") {
      setVisitType("1");
    }
    setIsVisitTypeAlertOpen(true);

    setSelectedVisitType(newVisitType);
  };

  const handleVisitTypeUpdate = async () => {
    let visitTypeId;

    if (selectedVisitType === "In Person") {
      visitTypeId = "1";
      setConsentToVideo("N");
      setDriverLicense("N");
    } else if (selectedVisitType === "Virtual: Video & Audio Capability") {
      visitTypeId = "2";
    }
    const _id = _memberIdForQueryForm; //_memberIdForQueryForm;
    if (visitTypeId && _id) {
      const requestBody = {
        data: {
          visitType: visitTypeId,
          _id,
        },
      };

      try {
        const response = await putApi(
          "/visitTypes/updateVisiteType",
          requestBody,
        );

        if (response.status === 200) {
          setIsVisitTypeAlertOpen(false);
          toast.success(response.data.message, {});
          setSelectedVisitType(selectedVisitType);
          //setSelectedVisitType(selectedVisitType);
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsVisitTypeAlertOpen(false);
        //setSelectedVisitType(null);
      }
    }
  };

  const handleVisitTypeCancel = () => {
    setIsVisitTypeAlertOpen(false);
  };

  const getMaxDate = (dateField) => {
    const roleId = sessionStorage.getItem("roleId");
    const maxDate = new Date();
    maxDate.setFullYear(new Date().getFullYear() + 1);
    if (roleId == 1 || roleId == 2) {
      return dayjs(maxDate);
    }
    if (roleId == 5 && !evaluationDate) {
      return dayjs(maxDate);
    }
    if (roleId == 5 && evaluationDate) {
      return dayjs(evaluationDate);
    }
    if (roleId == 5 && evaluationDate && dateField == "examFinishDate") {
      return dayjs(evaluationDate);
    }
  };
  const getMinDate = (dateField) => {
    const minDate = new Date();
    const roleId = sessionStorage.getItem("roleId");
    minDate.setFullYear(new Date().getFullYear() - 1);

    if (roleId == 1 || roleId == 2) {
      return dayjs(minDate);
    }
    if (roleId == 5 && !evaluationDate) {
      return dayjs(new Date());
    }
    if (roleId == 5 && evaluationDate) {
      return dayjs(evaluationDate);
    }
  };

  const buttonGroup = [
    "Sign & Submit",
    "View Full Report",
    "View Summary",
    "Member Summary",
    "Referral Form",
    "Appointment Cancellation",
    "No Show",
    "XML",
    "jSON",
  ];
  const handleButtonAction = (button) => {
    if (button == "View Summary") {
      fetchViewSummaryPdfData();
    } else if (button == "View Full Report") {
      fetchFullReportPdfData();
    } else if (button == "Member Summary") {
      fetchMemberSummaryReport();
    } else if (button == "Sign & Submit") {
      setSignSubmitDialog(true);
    }
  };
  const customUpdateHraStatus = async (hraStatusToBeUpdated) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: hraStatusToBeUpdated, //6,
          subStatus: "",
          cancelStatus: "",
          cancelText: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        toast.success("Hra Status Updated Successfully", {});
        navigate(getReroute());
      } else {
        setSignSubmitDialog(false);
        toast.error("Failed to update HRA Status", {});
      }
    } catch (error) {
      setSignSubmitDialog(false);
      toast.error("Failed to update HRA Status", {});
    }
  };

  const handleCancelAppointmentAlertYesClick = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 3,
          subStatus: "",
          cancelStatus: cancleStatus,
          cancelText: statusComment,
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        toast.success("Hra Status Updated Successfully", {});
        navigate(getReroute());
      } else {
        setSignSubmitDialog(false);
        toast.error("Failed to update HRA Status", {});
      }
    } catch (error) {
      setSignSubmitDialog(false);
      toast.error("Failed to update HRA Status", {});
    }

    setCancelAppointmentUpdateHraStatusPopup(false);
  };
  const handleAssignedTermDateTypeUpdate = async (evaluationDate) => {
    const memberObjIds = [_memberIdForQueryForm];

    if (evaluationDate) {
      const requestBody = {
        memberObjIds,
        evaluationDate: evaluationDate,
      };

      try {
        const response = await putApi(
          "/assignClinician/updateScheduleandTermDate",
          requestBody,
        );
        setIsEvaluationDateAlertOpen(false);
        if (response.status === 200) {
          // setToasterOpen(true);
          // setToasterSeverity(Labels.SUCCESS);
          // setToasterMessage(response.data.message);

          setEvaluationDate(evaluationDate);
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsEvaluationDateAlertOpen(false);
        //setSelectedVisitType(null);
      }
    }
  };

  const handleAssignedTermDateTypeCancel = () => {
    setIsEvaluationDateAlertOpen(false);
    // Revert the selected evaluation date and time back to the previous value
    // setEvaluationDateTime(previousEvaluationDateTime);
    setEvaluationDate(previouslyEvaluationDate);
  };
  const downloadPdf = async (url, fileName) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        toast.error("Error while generating pdf", {});
        return;
      } //throw new Error("Network response was not ok");
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (err) {
      toast.error("Error while generating pdf", {});
    }
  };
  const fetchFullReportPdfData = async (
    assessmentId,
    setToasterMessage,
    setToasterOpen,
    setToasterSeverity,
  ) => {
    const response = await getApi(
      `/pdf/download-full-report?id=${assessmentId}`,
    );
    if (response.status == 200) {
      const url =
        `${process.env.REACT_APP_API_BASE_URL}` +
        "/full-report/" +
        response?.data?.pdfPath?.split("/")?.[
          response?.data?.pdfPath?.split("/").length - 1
        ];
      setTimeout(() => {
        downloadPdf(
          url,
          "full_Report.pdf",
          setToasterMessage,
          setToasterOpen,
          setToasterSeverity,
        );
      }, 3000);
    } else {
      toast.error("Error while fetching data", {});
    }
  };

  const fetchViewSummaryPdfData = async (
    assessmentId,
    setToasterMessage,
    setToasterOpen,
    setToasterSeverity,
  ) => {
    const response = await getApi(`/pdf/summary?id=${assessmentId}`);
    if (response.status == 200) {
      const url =
        `${process.env.REACT_APP_API_BASE_URL}` +
        "/view-summary/" +
        response?.data?.pdfPath?.split("/")?.[
          response?.data?.pdfPath?.split("/").length - 1
        ];
      setTimeout(() => {
        downloadPdf(
          url,
          "summary.pdf",
          setToasterMessage,
          setToasterOpen,
          setToasterSeverity,
        );
      }, 3000);
    } else {
      toast.error("Error while fetching data", {});
    }
  };

  const fetchMemberSummaryReport = async (
    assessmentId,
    setToasterMessage,
    setToasterOpen,
    setToasterSeverity,
  ) => {
    const response = await getApi(`/pdf/member-sumary?id=${assessmentId}`);
    if (response.status == 200) {
      const url =
        `${process.env.REACT_APP_API_BASE_URL}` +
        "/member-summary/" +
        response?.data?.pdfPath?.split("/")?.[
          response?.data?.pdfPath?.split("/").length - 1
        ];
      setTimeout(() => {
        downloadPdf(
          url,
          "member_summary.pdf",
          setToasterMessage,
          setToasterOpen,
          setToasterSeverity,
        );
      }, 3000);
    } else {
      toast.error("Error while fetching data", {});
    }
  };
  const updateHraStatusToInProgress = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 2,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        toast.success("Hra Status Updated Successfully", {});
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage(response.data.message);
        // fetchMemberData(storedPlan, storedYear);
      } else {
        return;
      }
    } catch (error) {
      return;
    }

    // setIsCancelAppointmentAlertOpen(false);
    // } else {
    //   console.error("Selected HRA status not found in data array");
    // }
  };

  const updateHraStatus = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 6,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        setSignSubmitDialog(false);
        toast.success("Hra Status Updated Successfully", {});
      } else {
        return;
      }
    } catch (error) {
      return;
    }

    // setIsCancelAppointmentAlertOpen(false);
    // } else {
    //   console.error("Selected HRA status not found in data array");
    // }
  };

  const handlePatientSave = async (_providerSignature) => {
    try {
      const _payload = {
        assessorsComments: assessorComents ? assessorComents : "",
        memberAcknowledgement: memberAcknowledge == "Y" ? "1" : "2",
        disclosure: disclosureStatement == "Y" ? "1" : "2",
        evaluationDate: evaluationDate,
        examFinishDate: examFinishDate,
        providerSignature: _providerSignature
          ? _providerSignature
          : signSubmitImage,
        visitType: {
          isVisitType: visitType == 1 ? "1" : visitType == 2 ? "2" : "",
          consentedToVideo: consentToVideo == "Y" ? "1" : "2",
        },
      };
      let payload2024 = {};
      if (storedYear == "2024") {
        delete _payload.disclosure;
        delete _payload.visitType;
        payload2024 = {
          ..._payload,
          visitType: {
            //driverLicense
            isVisitType: visitType == 1 ? "1" : visitType == 2 ? "2" : "",
            memberConsented: consentToVideo == "Y" ? "1" : "2",
            memberIdentified: driverLicense == "Y" ? "1" : "2",
            comment: comment ? comment : "",
          },
          providerSignature: _providerSignature
            ? _providerSignature
            : signSubmitImage,
          addAddendum: "",
          addendumSignature: "",
        };
      }

      let payload = {
        id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,
        data: {
          name: "Patient Summary",
          // data: {
          // ..._payload,
          data: storedYear == "2024" ? { ...payload2024 } : { ..._payload },
          // },
          // providerSignature: providerSignature ? providerSignature : "",
          // addAddendum: "",
          // addendumSignature: "",
        },
        year: storedYear,
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response.status === 200) {
        setSignSubmitDialog(false);
        toast.success("Assessment Updated Successfully", {});
        // navigate(getReroute());
        const roleId = sessionStorage.getItem("roleId");

        if (roleId === "5") {
          if (_providerSignature) {
            await updateHraStatus();
            navigate("/NurseDashboard/Nurselist");
          }
          //navigate("/NurseDashboard/Nurselist");
        }
        // else {
        //   navigate("/AdminDashboard/MemberList");
        // }
      } else {
        return;
      }
    } catch (error) {
      console.log("error", error);
      return;
    }
  };
  useImperativeHandle(ref, () => ({
    onSaveData: () => {
      handlePatientSave();
    },
  }));
  useEffect(() => {
    const id = setInterval(async () => {
      try {
        const _payload = {
          assessorsComments: assessorComents ? assessorComents : "",
          memberAcknowledgement: memberAcknowledge == "Y" ? "1" : "2",
          disclosure: disclosureStatement == "Y" ? "1" : "2",
          evaluationDate: evaluationDate,
          examFinishDate: examFinishDate,
          providerSignature: signSubmitImage ? signSubmitImage : "",
          visitType: {
            isVisitType: visitType == 1 ? "1" : visitType == 2 ? "2" : "",
            consentedToVideo: consentToVideo == "Y" ? "1" : "2",
          },
        };
        let payload2024 = {};
        if (storedYear == "2024") {
          delete _payload.disclosure;
          delete _payload.visitType;
          payload2024 = {
            ..._payload,
            visitType: {
              //driverLicense
              isVisitType: visitType == 1 ? "1" : visitType == 2 ? "2" : "",
              memberConsented: consentToVideo == "Y" ? "1" : "2",
              memberIdentified: driverLicense == "Y" ? "1" : "2",
              comment: comment ? comment : "",
            },
            providerSignature: signSubmitImage ? signSubmitImage : "",
            addAddendum: "",
            addendumSignature: "",
          };
        }

        let payload = {
          id: assessmentId, //"658421885b5cf5633f5bbece", //assessmentId,
          data: {
            name: "Patient Summary",
            // data: {
            // ..._payload,
            data: storedYear == "2024" ? { ...payload2024 } : { ..._payload },
            // },
            // providerSignature: providerSignature ? providerSignature : "",
            // addAddendum: "",
            // addendumSignature: "",
          },
          year: storedYear,
        };
        // if (tabId == 1) {
        //   updateHraStatusToInProgress();
        // }
        const response = await putApi("/hra/assessment", payload);
        if (response.status === 200) {
          setSignSubmitDialog(false);
          toast.success("Assessment Updated Successfully", {});
          // navigate(getReroute());
          const roleId = sessionStorage.getItem("roleId");

          // if (roleId === "5") {
          //   if (providerSignature) {
          //     await updateHraStatus();
          //   }
          //   navigate("/NurseDashboard/Nurselist");
          // } else {
          //   navigate("/AdminDashboard/MemberList");
          // }
        } else {
          return;
        }
      } catch (error) {
        console.log("error", error);
        return;
      }
    }, 60000);
    return () => {
      clearInterval(id);
    };
  }, [memberAcknowledge, comment, assessorComents, consentToVideo]);
  const clearExamFinishDate = () => {
    // setExamFinishDateSaveBtn(null);
    setExamFinishDate(null);
    // setFormData((prevFormData) => {
    //   const updatedFormData = { ...prevFormData };

    //   updatedFormData.examFinishDate = null;

    //   return updatedFormData;
    // });
  };
  useEffect(() => {
    if (
      Boolean(evaluationDate) &&
      Boolean(examFinishDate) &&
      closeDatePicker &&
      new Date(dayjs(evaluationDate).tz(selectedTimeZoneUtc)).getTime() >=
        new Date(dayjs(examFinishDate).tz(selectedTimeZoneUtc)).getTime()
      // new Date(dayjs(evaluationDate).tz("IST")) >=
      //   new Date(dayjs(examFinishDate).tz("IST"))
      // ||
      //   dayjs(evaluationDateSaveBtn).tz("IST") >=
      //     dayjs(examFinishDateSaveBtn).tz("IST"))
    ) {
      setEmptyDateErrorPopup(true);
      setEmptyDateErrorMessg(
        "Exam Finished Time should be greater than Exam Start Time",
      );
      clearExamFinishDate();
    } else {
      setCloseDatePicker(false);
    }
  }, [closeDatePicker]);
  return (
    <>
      <LoadingOverlay
        styles={{
          content: { marginLeft: "40vw", marginTop: "50px" },
          spinner: (base) => ({
            ...base,
            marginLeft: "50px",
            marginTop: "50px",
          }),
        }}
        style={{ marginTop: "20px" }}
        active={isLoading}
        spinner={<img src={focusCaresLoader} alt="Loader" />}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="flex-start"
          style={{ fontFamily: "Inter !important" }}
        >
          <Grid
            item
            xs={12}
            style={{ padding: "10px", textAlign: "center !important" }}
          >
            <Box>
              <Typography
                style={{
                  color: "var(--mainTheme)",
                  fontWeight: 600,
                }}
                variant="body2"
              >
                Assessor's Comments:
              </Typography>
              <TextField
                disabled={roleId == 5 && tabId == 3}
                multiline
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100px",
                  overflowY: "scroll",
                }}
                variant="outlined"
                //label={inputProps?.label}
                sx={{
                  height: "48px",
                  border: "1.5px solid var(--mainTheme) !important",
                  "& .MuiFormLabel-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiInputLabel-root": {
                    backgroundColor: "white",
                  },
                }}
                value={assessorComents}
                onChange={(e) => {
                  setAssessorComents(e?.target?.value);
                }}
              />
            </Box>

            {/* FOR MEMBER ACKNOWLEDGE AND TOTAL SCORE  */}

            <Box paddingY={2} display="flex">
              <Box display="flex" justifyContent="left" width={0.5}>
                <Typography>Member Acknowledgement</Typography>
              </Box>
              <Box display="flex" justifyContent="right" width={0.5}>
                <TextField
                  // disabled={getDisableFlag()}
                  disabled={true}
                  variant="outlined"
                  label="Total Score"
                  sx={{
                    height: "48px",
                    border: "1.5px solid var(--mainTheme) !important",

                    "& .MuiFormLabel-root": {
                      backgroundColor: "white",
                    },
                    "& .MuiInputLabel-root": {
                      backgroundColor: "white",
                    },
                  }}
                  // value={totalScore}
                  // onChange={(e) => {
                  //   setTotalScore(e?.target?.value);
                  // }}
                />
              </Box>
            </Box>
            {/* disclaimer 1 */}
            <Box paddingY={2} display="flex">
              <Typography
                sx={{
                  color: "var(--mainTheme) !important",
                  //marginLeft: "30px",
                }}
              >
                I have been advised by the evaluator and understand that the
                services performed by the evaluator are limited to the
                evaluation performed today; the evaluator has no further duties
                to me once the evaluation performed today is completed ; the
                evaluator is not liable for abandonment by refusing to provide
                me treatment or continuing care to me beyond this evaluation;
                and I should contact my primary care or treating physician for
                all questions and concerns regarding medical care and treatment
                or, in the event of an emergency, call 911
              </Typography>
            </Box>

            {/* for checkbox */}

            <Box paddingY={2}>
              <Box>
                <Box paddingTop={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={memberAcknowledge == "Y" ? true : false}
                        onChange={handleChangeMemberAcknowledgement}
                        name="memberacknowledgement"
                        color="primary"
                        disabled={roleId == 5 && tabId == 3}
                      />
                    }
                    label="Member Informed of acknowledgement"
                  />
                </Box>
                <Fade in={storedYear == "2023"} unmountOnExit>
                  <Box paddingTop={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={roleId == 5 && tabId == 3}
                          checked={disclosureStatement == "Y" ? true : false}
                          onChange={handleChangeDisclosureStatement}
                          name="disclosurement"
                          color="primary"
                        />
                      }
                      label="I accept the disclosure statement"
                    />
                  </Box>
                </Fade>

                <Fade
                  in={visitType == "2" && storedYear == "2023"}
                  unmountOnExit
                >
                  <Box paddingTop={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={roleId == 5 && tabId == 3}
                          checked={consentToVideo == "Y" ? true : false}
                          onChange={handleChangeConsentToVideo}
                          name="consentToVideo"
                          color="primary"
                        />
                      }
                      label="Consented To Video"
                    />
                  </Box>
                </Fade>

                <Fade
                  in={visitType == "2" && storedYear == "2024"}
                  unmountOnExit
                >
                  <Box>
                    <Box paddingTop={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={roleId == 5 && tabId == 3}
                            checked={consentToVideo == "Y" ? true : false}
                            onChange={handleChangeConsentToVideo}
                            name="consentToVideo"
                            color="primary"
                          />
                        }
                        label="Member consented to complete the virtual visit using audio and video, understands the purpose of the visit and their participation"
                      />
                    </Box>

                    <Box paddingTop={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={roleId == 5 && tabId == 3}
                            checked={driverLicense == "Y" ? true : false}
                            onChange={handleChangeDriverLicenseCheckbox}
                            name="driverLicense"
                            color="primary"
                          />
                        }
                        label="Members identify confirmed with Driver's license or valid photo ID"
                      />
                    </Box>
                  </Box>
                </Fade>
              </Box>
            </Box>

            {/* for comment 2024 */}
            <Fade in={storedYear == 2024} unmountOnExit>
              <Box display="flex" width="100%">
                <Box display="flex" width="10%">
                  <Typography style={{ alignSelf: "center" }} variant="body1">
                    Comment:
                  </Typography>
                </Box>
                <Box display="flex" width="40%">
                  <TextField
                    onChange={(e) => {
                      setComment(e?.target?.value);
                    }}
                    disabled={roleId == 5 && tabId == 3}
                    value={comment}
                    fullWidth
                    multiline
                    sx={{
                      height: "80px",
                      overflowY: "scroll",
                      width: "100%",
                      border: "1.5px solid var(--mainTheme) !important",
                      "& .MuiFormLabel-root": {
                        backgroundColor: "white",
                      },
                      "& .MuiInputLabel-root": {
                        backgroundColor: "white",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Fade>

            {/* for date field  */}

            <Box paddingY={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4}>
                  <Box
                    sx={{ "& > :not(style)": { width: "100%" } }}
                    className="inputAddMembers"
                  >
                    <Typography
                      style={{
                        color: "var(--mainTheme)",
                        fontWeight: 600,
                      }}
                      variant="body2"
                    >
                      {""}
                      Date/Time of Service/Evaluation:
                    </Typography>
                    <LocalizationProvider
                      disabled={roleId == 5 && tabId == 3}
                      dateAdapter={AdapterDayjs}
                    >
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DateTimePicker
                          disabled={roleId == 5 && tabId == 3}
                          timeSteps={{ minutes: 1 }}
                          id="datetime-local"
                          format="MM/DD/YYYY hh:mm a"
                          views={["year", "month", "day", "hours", "minutes"]}
                          // disabled={getDisableFlag()}
                          variant="outlined"
                          minDate={getMinDate("evaluationDate")}
                          maxDate={getMaxDate("evaluationDate")}
                          ampm
                          autoOk={false}
                          // value={dayjs(evaluationDate)}
                          value={
                            evaluationDate
                              ? dayjs(
                                  dayjs(evaluationDate)
                                    ?.tz(selectedTimeZoneUtc)
                                    .format("MM-DD-YYYY hh:mm:ss A"),
                                )
                              : null
                          }
                          // onChange={(newValue) => {
                          //   handleEvaluationDateChange(new Date(newValue));
                          // }}
                          onChange={(newValue) => {
                            handleEvaluationDateChange(new Date(newValue));
                          }}
                          onClose={() => {
                            // if (
                            //   (inputProps.name ===
                            //     "examFinishDate" &&
                            //     Boolean(evaluationDate) &&
                            //     !closeDatePicker) ||
                            //   (inputProps.name ===
                            //     "evaluationDate" &&
                            //     Boolean(examFinishDate) &&
                            //     !closeDatePicker)
                            // ) {

                            setCloseDatePicker(true);
                            // }
                          }}
                          slotProps={{
                            textField: {
                              disabled: true,
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Box
                    sx={{ "& > :not(style)": { width: "100%" } }}
                    className="inputAddMembers"
                  >
                    <Typography
                      style={{
                        color: "var(--mainTheme)",
                        fontWeight: 600,
                      }}
                      variant="body2"
                    >
                      {""}
                      {/* Exam Finish Date */}
                      Time exam finished:
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        components={["DatePicker"]}
                        sx={{ width: "100%" }}
                      >
                        <DateTimePicker
                          disabled={roleId == 5 && tabId == 3}
                          timeSteps={{ minutes: 1 }}
                          id="datetime-local"
                          format="MM/DD/YYYY hh:mm a"
                          views={["year", "month", "day", "hours", "minutes"]}
                          // disabled={getDisableFlag()}
                          variant="outlined"
                          minDate={getMinDate("examFinishDate")}
                          maxDate={getMaxDate("examFinishDate")}
                          ampm
                          autoOk={false}
                          //value={dayjs(examFinishDate)}
                          value={
                            examFinishDate
                              ? dayjs(
                                  dayjs(examFinishDate)
                                    ?.tz(selectedTimeZoneUtc)
                                    .format("MM-DD-YYYY hh:mm:ss A"),
                                )
                              : null
                          }
                          // onChange={(newValue) => {
                          //   handleExamFinishDate(new Date(newValue));
                          // }}
                          onChange={(newValue) => {
                            handleExamFinishDate(new Date(newValue));
                          }}
                          onClose={() => {
                            // if (
                            //   (inputProps.name ===
                            //     "examFinishDate" &&
                            //     Boolean(evaluationDate) &&
                            //     !closeDatePicker) ||
                            //   (inputProps.name ===
                            //     "evaluationDate" &&
                            //     Boolean(examFinishDate) &&
                            //     !closeDatePicker)
                            // ) {

                            setCloseDatePicker(true);
                            //}
                          }}
                          slotProps={{
                            textField: {
                              disabled: true,
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Box>
                    <Typography
                      style={{
                        color: "var(--mainTheme)",
                        fontWeight: 600,
                      }}
                      variant="body2"
                    >
                      Visit Type
                    </Typography>
                    <Select
                      id="visit-type"
                      key="visit-type"
                      variant="outlined"
                      style={{
                        borderRadius: "5px",
                        marginTop: "6px",
                        width: "80%",
                      }}
                      sx={{
                        border: "1.5px solid var(--mainTheme) !important",

                        "& .MuiFormLabel-root": {
                          backgroundColor: "white",
                          color: "rgba(0,0,0,0.38)!important",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "white",
                          color: "rgba(0,0,0,0.38)!important",
                        },
                      }}
                      //value={demographicsFormState?.[question?.questionField]}
                      value={selectedVisitType}
                      onChange={(event) => {
                        handleVisitTypeChange(event);
                      }}
                      fullWidth
                    >
                      <MenuItem key="select" disabled value="">
                        Select
                      </MenuItem>
                      {["In Person", "Virtual: Video & Audio Capability"].map(
                        (item, index) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {signSubmitImage && ["3", "4"].includes(tabId) ? (
              <Box>
                <div
                  style={{
                    width: "fit-content",
                    marginTop: "2%",
                    marginBottom: "1%",
                    marginLeft: "1%",
                    border: "1px solid grey",
                  }}
                >
                  <img src={signSubmitImage} alt="Provider Signature" />
                </div>
              </Box>
            ) : null}
            {/* FOR BUTTON GROUP */}
            <Box paddingY={2}>
              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                  // justifyContent: "space-between",
                }}
              >
                {buttonGroup
                  ?.filter((btn) =>
                    btn == "Sign & Submit" && ["3", "4"].includes(tabId)
                      ? false
                      : true,
                  )
                  ?.map((button) => (
                    <Grid xs={0} item>
                      <FormControl
                        className="inputStyle"
                        component="fieldset"
                        {...{
                          value: button,
                        }}
                        variant="outlined"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                          height: "100%",
                          flexWrap: "wrap",
                          justifyContent: "center",
                        }}
                      >
                        {/* // "Sign & Submit",
    "View Summary",
    "Member Summary",
    "Referral Form",
    "Appointment Cancellation",
    "No Show",
    "XML",
    "jSON", */}
                        <Button
                          disabled={
                            (roleId == 5 &&
                              tabId == 3 &&
                              ![
                                "View Full Report",
                                "View Summary",
                                "Member Summary",
                              ].includes(button)) ||
                            ((button == "No Show" ||
                              button == "Appointment Cancellation") &&
                              !["In Progress", "Scheduled"].includes(hraStatus))
                              ? true
                              : false //getDisableFlag()
                          }
                          style={{
                            display:
                              button == "Sign & Submit" && roleId != 5
                                ? "none"
                                : true,
                            "&:disabled": {
                              BackgroundColor: "var(--mainTheme)",
                            },
                          }}
                          onClick={() => {
                            //  handleButtonAction("dialogBox")
                            if (
                              button == "Sign & Submit" &&
                              //formData.acceptDisclosure == 1 &&
                              //acceptDisclosureSaveBtn == 1 &&
                              // formData.visitType == "virtual"
                              (visitType == 2
                                ? consentToVideo == 1 //formData?.consentedToVideo == 1
                                : visitType == 1
                                ? true
                                : false) &&
                              // formData.memberAknowledgement == 1 &&
                              memberAcknowledge == 1 &&
                              Boolean(examFinishDate) &&
                              Boolean(evaluationDate) &&
                              new Date(evaluationDate)?.getTime() <
                                new Date(examFinishDate)?.getTime()
                            ) {
                              handleButtonAction(button);
                            } else if (!["1", "2"].includes(visitType)) {
                              setErrorSignSubmitBtn(true);
                              setErrorSignSubmitBtnMsg(
                                "Please Select Visit Type To Proceed",
                              );
                            } else if (
                              button == "Sign & Submit" &&
                              memberAcknowledge != "Y" &&
                              visitType == "2" &&
                              consentToVideo != "1"
                            ) {
                              setErrorSignSubmitBtn(true);
                              setErrorSignSubmitBtnMsg(
                                "Member informed of acknowledgement  and  Consented to Video chat required",
                              );
                            } else if (
                              button == "Sign & Submit" &&
                              memberAcknowledge != "Y"
                            ) {
                              setErrorSignSubmitBtn(true);
                              setErrorSignSubmitBtnMsg(
                                "Member informed of acknowledgement is required ",
                              );
                            } else if (
                              button == "Sign & Submit" &&
                              visitType == "2" &&
                              consentToVideo != "Y"
                            ) {
                              setErrorSignSubmitBtn(true);
                              if (storedYear == "2024") {
                                setErrorSignSubmitBtnMsg(
                                  " Member consented to complete the virtual visit using audio and video, understands the purpose of the visit and their participation required",
                                );
                              } else {
                                setErrorSignSubmitBtnMsg(
                                  "Consented to Video chat required",
                                );
                              }
                            } else if (
                              button == "Sign & Submit" &&
                              (!Boolean(examFinishDate) ||
                                examFinishDate == null) &&
                              Boolean(evaluationDate)
                            ) {
                              setEmptyDateErrorPopup(true);
                              setEmptyDateErrorMessg(
                                "Please select Exam time Finish date",
                              );
                            } else if (
                              button == "Sign & Submit" &&
                              (!Boolean(evaluationDate) ||
                                evaluationDate == null) &&
                              Boolean(examFinishDate)
                            ) {
                              setEmptyDateErrorPopup(true);
                              setEmptyDateErrorMessg(
                                "Please select Exam Start Time",
                              );
                            } else if (
                              button == "Sign & Submit" &&
                              Boolean(evaluationDate) &&
                              Boolean(examFinishDate) &&
                              new Date(evaluationDate)?.getTime() >=
                                new Date(examFinishDate)?.getTime()
                            ) {
                              setEmptyDateErrorPopup(true);
                              setEmptyDateErrorMessg(
                                "Exam Finished Time should be greater than Exam Start Time",
                              );
                            } else if (
                              button == "Sign & Submit" &&
                              (!Boolean(evaluationDate) ||
                                evaluationDate == null) &&
                              (!Boolean(examFinishDate) ||
                                examFinishDate == null)
                            ) {
                              setEmptyDateErrorPopup(true);
                              setEmptyDateErrorMessg(
                                "Please select Exam Start Time and Exam time Finish date",
                              );
                            } else if (button == "No Show") {
                              setNoShowUpdateHraStatusPopup(true);
                            } else if (button == "Appointment Cancellation") {
                              setCancelAppointmentUpdateHraStatusPopup(true);
                            } else {
                              handleButtonAction(
                                // inputProps.name,
                                // assessmentId,
                                // setToasterMessage,
                                // setToasterOpen,
                                // setToasterSeverity
                                button,
                              );
                            }
                          }}
                          variant="contained"
                          sx={{
                            width: "auto",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            BackgroundColor: "var(--mainTheme)",
                            textAlign: "center",
                            height: "100%",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            "&:disabled": {
                              BackgroundColor: "var(--mainTheme600)",
                              color: "black",
                            },
                          }}
                        >
                          {button}
                        </Button>
                      </FormControl>
                    </Grid>
                  ))}
              </Grid>
            </Box>

            {isVisitTypeAlertOpen && (
              <Alert
                open={isVisitTypeAlertOpen}
                handleClose={() => setIsVisitTypeAlertOpen(false)}
                title={`Do you want to change Visit Type ?`}
                okText="Yes"
                cancelText="No"
                onAddClick={handleVisitTypeUpdate}
                onNoClick={handleVisitTypeCancel}
              />
            )}
            {noShowUpdateHraStatusPopup && (
              <Alert
                open={noShowUpdateHraStatusPopup}
                dialogTitle={`No Show`}
                handleClose={() => setNoShowUpdateHraStatusPopup(false)}
                title={`Are you sure you would like to change the hra status to No Show ?`}
                okText="Yes"
                cancelText="No"
                onAddClick={() => {
                  customUpdateHraStatus(19);
                }}
                onNoClick={() => setNoShowUpdateHraStatusPopup(false)}
              />
            )}

            {cancelAppointmentUpdateHraStatusPopup && (
              <AppointmentModal
                open={cancelAppointmentUpdateHraStatusPopup}
                handleClose={() =>
                  setCancelAppointmentUpdateHraStatusPopup(false)
                }
                onSave={(cancelComment) =>
                  handleCancelAppointmentAlertYesClick(cancelComment)
                }
                setCancleStatus={setCancleStatus}
                setStatusComment={setStatusComment}
                onExit={() => setCancelAppointmentUpdateHraStatusPopup(false)}
                statusComment={statusComment}
                cancleStatus={cancleStatus}
              />
            )}

            {openSignSubmitDialog && (
              <ConfirmDigitalSignatureDialog
                openConfirmDigitalSign={openSignSubmitDialog}
                onCloseConfirmDigitalSignatureDialogBox={() => {
                  setSignSubmitDialog(false);
                }}
                onSubmitCommentForClinician={(providerSignature) => {
                  handlePatientSave(providerSignature);
                }}
                onBack={() => {
                  setSignSubmitDialog(false);
                }}
                clinicianName={clinicianName}
                signCanvasImg={providerSignature}
                speciality={speciality}
                fromFillHra={true}
              />
            )}
            {isEvaluationDateAlertOpen && (
              <Alert
                open={isEvaluationDateAlertOpen}
                handleClose={() => setIsEvaluationDateAlertOpen(false)}
                title={`Are you sure you want to change the evaluation date ?`}
                okText="Yes"
                cancelText="No"
                onAddClick={handleAssignedTermDateTypeUpdate}
                onNoClick={handleAssignedTermDateTypeCancel}
              />
            )}
            {errorSignSubmitBtn && (
              <Alert
                open={errorSignSubmitBtn}
                handleClose={() => setErrorSignSubmitBtn(false)}
                title={errorSignSubmitBtnMsg}
                okText="OK"
                onAddClick={() => {
                  setErrorSignSubmitBtn(false);
                  setErrorSignSubmitBtnMsg("");
                }}
                //onNoClick={handleAssignedTermDateTypeCancel}
              />
            )}

            {emptyDateErrorPopup && (
              <Alert
                open={emptyDateErrorPopup}
                handleClose={() => setEmptyDateErrorPopup(false)}
                title={emptyDateErrorMessg}
                okText="OK"
                // cancelText="No"
                onAddClick={() => {
                  setEmptyDateErrorPopup(false);
                  setEmptyDateErrorMessg("");
                  setCloseDatePicker(false);
                }}
                //onNoClick={handleAssignedTermDateTypeCancel}
              />
            )}
            {/* 
      
       */}
          </Grid>

          <Box display="flex" style={{ width: "90vw", alignItems: "center" }}>
            <Divider orientation="horizontal" style={{ width: "10%" }} />

            <Box
              display="flex"
              style={{ width: "90%", backgroundColor: "#e3dada" }}
            >
              <Typography variant="h5">Disclosure Statement</Typography>
            </Box>
          </Box>

          <Box paddingY={3} paddingX={2}>
            <Typography
              style={{
                color: "var(--mainTheme)",
              }}
              variant="body1"
            >
              <span>
                Protected Health Information (PHI) is information, such as name,
                age, address, sex, race, and marital status, that may be used to
                identify your physical and mental health conditions; healthcare
                that you have received; and, how your healthcare services have
                been paid for. <br /> <br />
                FOCUS CARE does not use or disclose your PHI unless required or
                permitted by National or State laws or with your written
                consent. FOCUS CARE is required to release PHI to the Department
                of Health and Human Services (DHHS), or its contractors, for
                audits or other enforcement actions and to you, if you request
                access to, or an accounting of disclosures of your PHI.
                <br /> <br />
                FOCUS CARE may release your PHI without your authorization for
                treatment, payment, and health care operations of covered
                entities, such as providers, health plans, billing
                clearinghouses, and contracted business associates.
                <br /> <br />
                FOCUS CARE may disclose your PHI to you, unless otherwise
                restricted by law. FOCUS CARE may also use and disclose PHI
                without an individual's authorization where required by law,
                including statute, regulation, or valid court order.
                <br /> <br />
                FOCUS CARE will not disclose your individual personal health
                information to your employer, union, or any other employee
                organization without your permission.
                <br /> <br />
              </span>
            </Typography>
          </Box>
        </Grid>
      </LoadingOverlay>
    </>
  );
});

export default PedsPatientSummaryComp;
