import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useUserContext } from "../../../../contexts/UserContext";
import PropTypes from "prop-types";

import {
  Card,
  CardActions,
  CardHeader,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DragNDropContext } from "../../../../contexts/DragNDropContext";
import { getApi, postApi } from "../../../../utils/axiosApiWrapper";

export default function VphpDialog({
  fetchMemberData,
  fetchReportsData,
  fetchSchedulerMemberData,
  routedFrom,
}) {
  const { userCategories } = useContext(DragNDropContext);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [selectedTabforScheduler, setSelectedTabScheduler] =
    useState("MyMemberSelected");
  const [selectedTab, setSelectedTab] = useState("Member List");

  const {
    clientData,
    handleSelectionDataChange,
    handleSelectionDataSubmit,
    setClientData,
    setApiResponseData,
    setSelectedClientIdContext,
  } = useUserContext();

  const handleOk = async () => {
    await handleSelectionDataSubmit();
    if (routedFrom === "memberList") {
      await fetchMemberData(clientData?.plan, clientData?.year);
    }
    if (routedFrom === "reports") {
      await fetchReportsData(clientData?.plan, clientData?.year);
      // onSelection(clientData?.year);
    }
    if (routedFrom === "schedulerList") {
      let defaultTabId = 5;

      await fetchSchedulerMemberData(
        clientData?.plan,
        clientData?.year,
        defaultTabId,
      );
    }

    if (routedFrom == "schedulerDashboard") {
      let defaultTabId;

      if (selectedTabforScheduler === "MyMemberSelected") {
        defaultTabId = 6;
      }
      await fetchSchedulerMemberData(
        clientData?.plan,
        clientData?.year,
        defaultTabId,
      );
    }
    if (routedFrom === "NurseList") {
      await fetchMemberData(clientData?.plan, clientData?.year);
    }
    try {
      //const planId =  localStorage.getItem("selectedClientId") || selectedClientId;
      const planId = selectedClientId;
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: planId,
      });
      if (response.status === 404) {
        return;
      }

      if (response.data && response.data.data) {
        setApiResponseData(response.data.data);
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");
  const storedScreeningTypes = localStorage.getItem("clientScreening");
  const storedYearOptions = localStorage.getItem("extractedYearOptions");

  useEffect(() => {
    if (storedPlan && storedYear && storedScreeningTypes) {
      const newValue = {
        plan: storedPlan,
        year: storedYear,
        screeningTypes: storedScreeningTypes,
        isAllSelected: true,
      };
      localStorage.setItem("isAllSelected", true);
      setClientData(newValue);
      // Retrieve the stored _id and set it in the state
      setSelectedClientId(localStorage.getItem("selectedClientId") || "");
    } else {
      setClientData((prevData) => ({ ...prevData, plan: "" }));
      // setSelectedClientId("");
      setSelectedClientId(localStorage.getItem("selectedClientId") || "");
    }
  }, []);
  useEffect(() => {
    if (clientData?.plan) {
      localStorage.setItem("clientPlan", clientData.plan);
    }

    if (clientData?.year) {
      localStorage.setItem("clientYear", clientData.year);
    }
    if (clientData?.screeningTypes) {
      localStorage.setItem("clientScreening", clientData.screeningTypes);
    }
    // Store the _id in local storage
    localStorage.setItem("selectedClientId", selectedClientId);
  }, [clientData, selectedClientId]);

  const customStyles = {
    menu: {},
    listItem: {
      width: "280px",
      height: "30px",
    },
  };

  const [planOptions, setPlanOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [data, setData] = useState([]);
  const [extractedYearOptions, setExtractedYearOptions] = useState([]);

  const fetchData = async () => {
    try {
      const response = await getApi("/clients/list");
      if (response.status === 200) {
        const responseData = response.data;
        const extractedPlanOptions = responseData.data
          .flatMap((item) => item.plan)
          .filter(Boolean);

        extractedPlanOptions.sort();

        setPlanOptions(extractedPlanOptions);

        if (extractedPlanOptions.length > 0) {
          const selectedPlan = clientData?.plan || storedPlan || "";
          setClientData((prevData) => ({ ...prevData, plan: selectedPlan }));

          setData(responseData.data);

          const selectedClient = responseData.data.find((item) =>
            item.plan.includes(selectedPlan),
          );
          localStorage.setItem("selectedClientId", selectedClient?._id);
          const defaultYearOptions = selectedClient?.yearList || [];
          setYearOptions(defaultYearOptions);

          const storedYearOptions = localStorage.getItem(
            "extractedYearOptions",
          );
          if (storedYearOptions) {
            setExtractedYearOptions(JSON.parse(storedYearOptions));
          } else {
            setExtractedYearOptions(defaultYearOptions);
            localStorage.setItem(
              "extractedYearOptions",
              JSON.stringify(defaultYearOptions),
            );
          }
        }
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePlanChange = (selectedPlan) => {
    setClientData((prevData) => ({ ...prevData, plan: selectedPlan }));

    const selectedClient = data.find((item) =>
      item.plan.includes(selectedPlan),
    );

    // Store the selected client's _id in local storage
    setSelectedClientId(selectedClient?._id || "");
    setSelectedClientIdContext(selectedClient?._id || "");

    const extractedYearOptions = selectedClient?.yearList || [];
    setYearOptions(extractedYearOptions);
    setExtractedYearOptions(extractedYearOptions);

    localStorage.setItem(
      "extractedYearOptions",
      JSON.stringify(extractedYearOptions),
    );
  };

  useEffect(() => {
    localStorage.setItem(
      "extractedYearOptions",
      JSON.stringify(extractedYearOptions),
    );
  }, [extractedYearOptions]);

  return (
    <div>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        // style={{ minHeight: "350vh" }}
      >
        <Grid item xs={3}>
          <Card
            elevation={10}
            sx={{
              minWidth: "280px",
              marginTop: "50px",
              minHeight: "285px",
              borderRadius: "5px",
            }}
          >
            <CardHeader
              title="Selection Criteria"
              sx={{
                backgroundColor: `var(--mainTheme)`,
                marginBottom: "15px",
                color: "white",
                padding: "10px",
                textAlign: "center !important",
                fontWeight: 500,
                fontSize: 15,
              }}
              titleTypographyProps={{ variant: "outline" }}
            />

            <Typography
              variant="subtitle1"
              style={{ marginRight: "80%", fontWeight: 600 }}
            >
              Plan
            </Typography>
            <FormControl
              sx={{
                m: 1,
                width: "95%",
                height: "40px",
                border: `1px solid var(--mainTheme)`,
                borderRadius: "5px",
              }}
            >
              <Select
                sx={{ textAlign: "left" }}
                size="small"
                label="Plan"
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                id="memberlist_plan"
                // id={`#isTimezone${index}`}
                value={
                  clientData?.plan !== undefined && clientData.plan !== ""
                    ? clientData.plan
                    : storedPlan || ""
                }
                onChange={(e) => handlePlanChange(e.target.value)}
                // value={clientData?.plan ? clientData.plan : "" || storedPlan}

                MenuProps={{
                  sx: {
                    "& .MuiMenuItem-root.Mui-selected": {
                      backgroundColor: `var(--mainTheme800) !important`,
                    },
                    "& .MuiMenuItem-root:hover": {
                      backgroundColor: `var(--mainTheme300) !important`,
                      color: "#000000",
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <span>Select</span>
                </MenuItem>
                {planOptions.map((option, index) => (
                  <MenuItem
                    style={customStyles.listItem}
                    key={index}
                    value={option}
                    id={`isPlanSelection${index}`}
                  >
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="subtitle1"
              style={{ marginRight: "80%", fontWeight: 600 }}
            >
              Year
            </Typography>
            <FormControl
              sx={{
                m: 1,
                width: "95%",
                height: "40px",
                border: `1px solid var(--mainTheme)`,
                borderRadius: "5px",
              }}
            >
              <Select
                sx={{ textAlign: "left" }}
                size="small"
                label="Year"
                value={
                  clientData?.year !== undefined
                    ? clientData.year
                    : storedYear || ""
                }
                id="memberlist_year"
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: `var(--mainTheme800) !important`,
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: `var(--mainTheme300) !important`,
                        color: "#000000",
                      },
                    },
                  },
                }}
                onChange={(e) =>
                  handleSelectionDataChange(e.target.value, [
                    "dropDownInput",
                    "year",
                  ])
                }
              >
                <MenuItem value="" disabled>
                  <span>Select</span>
                </MenuItem>

                {yearOptions.map((option, index) => (
                  <MenuItem
                    key={index}
                    style={customStyles.listItem}
                    value={option}
                    id={`isYearSelection${index}`}
                  >
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography
              variant="subtitle1"
              style={{ marginRight: "65%", fontWeight: 600 }}
            >
              Screening
            </Typography>
            <FormControl
              sx={{
                m: 1,
                width: "95%",
                height: "40px",
                border: `1px solid var(--mainTheme)`,
                borderRadius: "5px",
              }}
            >
              <Select
                sx={{ textAlign: "left" }}
                size="small"
                label="Screening Types"
                // value={
                //   clientData?.screeningTypes ? clientData.screeningTypes : "" || storedScreeningTypes
                // }
                value={
                  clientData?.screeningTypes !== undefined
                    ? clientData.screeningTypes
                    : storedScreeningTypes || ""
                }
                id="memberlist_screen_type"
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: `var(--mainTheme800) !important`,
                      },
                      "& .MuiMenuItem-root:hover": {
                        backgroundColor: `var(--mainTheme300) !important`,
                        color: "#000000",
                      },
                    },
                  },
                }}
                onChange={(e) =>
                  handleSelectionDataChange(e.target.value, [
                    "dropDownInput",
                    "screeningTypes",
                  ])
                }
              >
                <MenuItem value="" disabled>
                  <span>Select</span>
                </MenuItem>
                {userCategories?.screeningTypes?.map(
                  (screeningTypesMeta, index) => (
                    <MenuItem
                      key={index}
                      style={customStyles.listItem}
                      value={screeningTypesMeta[0]}
                      id={`isScreeningTypeSelection${index}`}
                    >
                      <ListItemText primary={screeningTypesMeta[0]} />
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>

            <CardActions>
              <Button
                style={{
                  margin: "0 auto",
                  marginTop: "5px",
                  backgroundColor: `var(--mainTheme)`,
                  borderRadius: "5px",
                  color: "white",
                  padding: "10px",
                  width: "100px",
                }}
                size="small"
                onClick={handleOk}
                id="memberlist_submit"
              >
                Ok
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

VphpDialog.propTypes = {
  fetchMemberData: PropTypes.func,
  fetchReportsData: PropTypes.func,
  fetchSchedulerMemberData: PropTypes.func,
  routedFrom: PropTypes.string,
};
