import "./Scheduler.css";

import DashboardComponent from "../../../components/Dashboard/Dashboard";
import DataTableComponent from "../../../components/Dashboard/DataTable/DataTable";
import TooltipEllipsisCell from "../../../components/TooltipEllipsisCell/TooltipEllipsisCell";

import { useState, useEffect, useContext, useMemo } from "react";
import SchedulerComponent from "../../../components/Dashboard/Scheduler/Scheduler";
import Button from "../../../components/button/button";
import MuiInput from "../../../components/muiinput/MuiInput";
import SelectDropdown from "../../../components/select/select";
import VphpDialog from "../../../components/Dashboard/MemberList/vphp/vphp";
import { useUserContext } from "../../../contexts/UserContext";
import CallLog from "../../../components/Modal/callLog";
import { getApi, postApi, putApi } from "../../../utils/axiosApiWrapper";
import Loader from "../../../components/Loader/Loader";
import Labels from "../../../resource_files/Labels.resx";
import Toaster from "../../../components/toaster/toaster";
import Alert from "../../../components/Alert/Alert";
import { Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import AlertContext from "../../../contexts/AlertContext";
import moment from "moment";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

// Extend Day.js with plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const Scheduler = ({
  ModalClose,
  onAddClick,
  selectedValue,
  setSelectedValue,
  handleClose,
}) => {
  const { clientData, isDialogOpen, setClientData, selectedTimeZoneUtc } =
    useUserContext();
  const [hraStatus, setHraStatus] = useState([]);
  const [scheduledHraStatusIds, setScheduledHraStatusIds] = useState([]);
  const [visitTypeValues, setVisitTypeValues] = useState([]);
  const [visitTypeOptions, setVisitTypeOptions] = useState([]);
  const [isVisitTypeAlertOpen, setIsVisitTypeAlertOpen] = useState(false);
  const [status, setStatus] = useState({});
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [visitTypeData, setVisitTypeData] = useState([]);
  const [scheduleDateValues, setScheduleDateValues] = useState({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [termDateValues, setTermDateValues] = useState({});
  const [selectedTab, setSelectedTab] = useState("Member List");
  const [selectAllHeaderCheckbox, setSelectAllHeaderCheckbox] = useState(false);
  const [selectedRowCheckboxes, setSelectedRowCheckboxes] = useState({});
  const [areCheckboxesSelected, setAreCheckboxesSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportStatus, setReportStatus] = useState([]);
  const [selectedVisitTypeChange, setSelectedVisitTypeChange] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [selectedMemberDataArray, setSelectedMemberDataArray] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const storedClientId = localStorage.getItem("selectedClientId");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("defaultColumnName");
  const [tabId, setTabId] = useState(null);
  const navigate = useNavigate();
  const storedPlan = localStorage.getItem("clientPlan") || clientData?.plan;
  const storedYear = localStorage.getItem("clientYear") || clientData?.year;
  const storedScreeningTypes = localStorage.getItem("storedScreeningTypes");
  const alertContext = useContext(AlertContext);
  const [selectedRowData, setSelectedRowData] = useState({
    memberId: "",
    assessmentId: "",
    Name: "",
  });
  const [selectedScheduleDateRowIndex, setSelectedScheduleDateRowIndex] =
    useState(null);
  const [assignedScheduleDateValues, setAssignedScheduleDateValues] = useState(
    [],
  );
  const [isAssignedScheduleDateAlertOpen, setIsAssignedScheduleDateAlertOpen] =
    useState(false);
  const [
    selectedAssignedScheduleDateChange,
    setSelectedAssignedScheduleDateChange,
  ] = useState(null);
  const [selectedTermDateRowIndex, setSelectedTermDateRowIndex] =
    useState(null);
  const [assignedTermDateValues, setAssignedTermDateValues] = useState([]);
  const [isAssignedTermDateAlertOpen, setIsAssignedTermDateAlertOpen] =
    useState(false);
  const [selectedAssignedTermDateChange, setSelectedAssignedTermDateChange] =
    useState(null);
  const handleVphpSelection = (responseData) => {
    setResponseData(responseData || []);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleHeaderCheckboxChange = (event) => {
    const checked = event.target.checked;
    setSelectAllHeaderCheckbox(checked);

    const updatedCheckboxes = {};
    const selectedCheckboxesData = {};

    responseData?.data.forEach((rowData, rowIndex) => {
      updatedCheckboxes[rowIndex] = checked;

      if (checked) {
        const memberId = rowData?.memberId || "";
        selectedCheckboxesData[rowIndex] = memberId;
      }
    });

    setSelectedRowCheckboxes(updatedCheckboxes);
    setSelectedCheckboxes(selectedCheckboxesData);
    setIsAnyCheckboxChecked(checked);

    const allSelectedMemberData = checked
      ? responseData?.data.map((rowData) => ({
          memberId: rowData?.memberId || "",
          assessmentId: rowData?.assessmentId || "",
          Name: rowData?.Name || "",
        }))
      : [];
    // setSelectedMemberDataArray(allSelectedMemberData);
    if (checked) {
      setSelectedMemberDataArray([...responseData?.data]);
    } else {
      setSelectedMemberDataArray([]);
    }
  };

  const handleCheckboxSelection = (memberId, index) => {
    setAreCheckboxesSelected(true);

    if (selectAllHeaderCheckbox) {
      const updatedCheckboxes = {};
      const selectedCheckboxesData = {};

      responseData?.data.forEach((rowData, rowIndex) => {
        updatedCheckboxes[rowIndex] = true;

        const memberId = rowData?.memberId || "";
        selectedCheckboxesData[rowIndex] = memberId;
      });

      setSelectedRowCheckboxes(updatedCheckboxes);
      setSelectedCheckboxes(selectedCheckboxesData);
      setIsAnyCheckboxChecked(true);

      const allSelectedMemberData = responseData?.data;

      setSelectedMemberDataArray(allSelectedMemberData);
    } else {
      setSelectedCheckboxes((prevValues) => {
        const updatedCheckboxes = {
          ...prevValues,
          [index]: !prevValues[index],
        };
        const anyCheckboxChecked = Object.values(updatedCheckboxes).some(
          (isChecked) => isChecked,
        );

        setIsAnyCheckboxChecked(anyCheckboxChecked);

        if (!updatedCheckboxes[index]) {
          setSelectedMemberDataArray((prevArray) =>
            prevArray.filter(
              (data) => data?.memberId !== responseData?.data[index]?.memberId,
            ),
          );
        }

        return updatedCheckboxes;
      });

      if (!selectedCheckboxes[index]) {
        if (memberId) {
          const selectedMemberData = responseData?.data[index];

          // Add the selected member data to the array
          setSelectedMemberDataArray((prevArray) => [
            ...prevArray,
            selectedMemberData,
          ]);

          setSelectedMemberData(selectedMemberData);
        } else {
          return;
        }
      } else {
        if (selectedMemberDataArray.length === 0 || []) {
          // Reset all checkboxes and related state to their default state
          setSelectedRowCheckboxes({});
          setIsAnyCheckboxChecked(false);
          setAreCheckboxesSelected(false);
          setSelectedCheckboxes({});
        }
      }
    }
  };

  useEffect(() => {
    const fetchReportStatusOptions = async () => {
      try {
        const response = await getApi("/reportstatus");
        const data = await response.data.data;

        const reportStatusOptions = data.map((reportStatus) => reportStatus);
        setReportStatus(reportStatusOptions);
      } catch (error) {
        return;
      }
    };

    fetchReportStatusOptions();
  }, []);

  const handleRowCheckboxChange = (checked, rowIndex) => {
    setSelectedRowCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: checked,
    }));

    const allRowCheckboxesChecked = Object.values({
      ...selectedRowCheckboxes,
      [rowIndex]: checked,
    }).every((isChecked) => isChecked);

    setSelectAllHeaderCheckbox(allRowCheckboxesChecked);
  };

  const handleSchedulerListOk = () => {
    return null;
  };
  const handleModalClose = () => {
    setIsCallLogOpen(false);

    if (typeof ModalClose === "function") {
      ModalClose();
    }
  };

  // const handleScheduleDateChange = (event, rowIndex) => {
  //   const { value } = event.target;
  //   setScheduleDateValues((prevValues) => ({
  //     ...prevValues,
  //     [rowIndex]: value,
  //   }));
  // };

  // const handleTermDateChange = (event, rowIndex) => {
  //   const { value } = event.target;
  //   setTermDateValues((prevValues) => ({
  //     ...prevValues,
  //     [rowIndex]: value,
  //   }));
  // };
  const [isCallLogOpen, setIsCallLogOpen] = useState(false);
  const [hraStatusOptions, setHraStatusOptions] = useState([]);
  const [selectedMemberData, setSelectedMemberData] = useState(null);
  const [hraStatusData, setHraStatusData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(false);
  const [schedulerData, setSchedulerData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState(storedClientId);

  const [schedulerOptions, setSchedulerOptions] = useState([]);
  const [defaultId, setDefaultId] = useState();
  const handleHraButtonClick = (memberId, index) => {
    setIsCallLogOpen(true);

    if (memberId) {
      const selectedMemberData = responseData?.data[index];

      setSelectedMemberData(selectedMemberData);
    } else {
      return;
    }
  };

  useEffect(() => {
    const fetchHraStatusData = async () => {
      try {
        const response = await getApi("/hra-status");
        const data = response.data.data;
        setHraStatusData(data);
      } catch (error) {
        return;
      }
    };

    fetchHraStatusData();
  }, []);

  useEffect(() => {
    setLoading(true);
    const storedPlan = localStorage.getItem("clientPlan") || clientData?.plan;
    const storedYear = localStorage.getItem("clientYear") || clientData?.year;
    let _defaultTabId;

    if (
      (selectedTab === "Member List" || selectedTab === "BothUnchecked") &&
      (roleId == 3 || roleId == 1 || roleId == 2)
    ) {
      _defaultTabId = 5;
      setDefaultId(5);
    } else if (
      selectedTab === "Disabled Members" &&
      (roleId == 3 || roleId == 1 || roleId == 2)
    ) {
      _defaultTabId = 4;
      setDefaultId(4);
    } else if (
      selectedTab === "AssignedUnUnAssCh" &&
      (roleId == 3 || roleId == 1 || roleId == 2)
    ) {
      _defaultTabId = 2;
      setDefaultId(2);
    } else if (
      selectedTab === "BothChecked" &&
      (roleId == 3 || roleId == 1 || roleId == 2)
    ) {
      _defaultTabId = 1;
      setDefaultId(1);
    } else if (
      selectedTab === "AssignedChUnAssignedUn" &&
      (roleId == 3 || roleId == 1 || roleId == 2)
    ) {
      _defaultTabId = 3;
      setDefaultId(3);
    } else if (selectedTab == "MyMemberSelected" && roleId == 4) {
      _defaultTabId = 6;
      setDefaultId(6);
    } else if (selectedTab == "MyMemberNotSelected" && roleId == 4) {
      _defaultTabId = 5; //uncomment these two line once server issue is fixed make this tabid to 5 , i am making it to 4 so it wont crash the server
      setDefaultId(5);
    }

    setTabId(_defaultTabId);
    fetchSchedulerMemberData(storedPlan, storedYear, _defaultTabId);

    const fetchHRAStatusOptions = async () => {
      try {
        const response = await getApi("/hra-status");
        const data = await response.data.data;
        const hraOptions = data.map((status) => status.statusName);
        setHraStatusOptions(hraOptions);
      } catch (error) {
        return;
      }
    };

    fetchHRAStatusOptions();
  }, [selectedTab, clientData, page, rowsPerPage, defaultId]);

  useEffect(() => {
    const fetchVisitTypeOptions = async () => {
      try {
        const response = await getApi("/visitTypes/get-visit-types");
        const data = await response.data.data;
        setVisitTypeData(data);
        const visitOptions = data.map((visit) => visit.visitType);

        setVisitTypeValues(visitOptions);

        setVisitTypeOptions(visitOptions);
      } catch (error) {
        return;
      }
    };

    fetchVisitTypeOptions();
  }, []);

  useEffect(() => {
    console.log("here", alertContext?.filterDialogData);
    console.log("here2", !_.isNull(alertContext?.filterDialogData));
    if (!_.isNull(alertContext?.filterDialogData)) {
      handleFilter();
    } else {
      if (storedPlan && storedYear && tabId) {
        fetchSchedulerMemberData(storedPlan, storedYear, tabId);
      }
    }
  }, [alertContext?.filterDialogData]);

  const tableHeaders = [
    { field: "Action", headerName: "Action", hidesortable: true },
    {
      field: "CheckBox",
      headerName: (
        <input
          type="checkbox"
          style={{ width: "20px", height: "18px" }}
          checked={selectAllHeaderCheckbox}
          onChange={handleHeaderCheckboxChange}
        />
      ),
    },
    { field: "Program", headerName: "Program" },
    { field: "LOB", headerName: "LOB" },
    {
      field: "ID",
      headerName: "ID#",
      type: "number",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value.toString()} maxWidth={100} />
      ),
    },
    {
      field: "Name",
      headerName: "Name",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value} maxWidth={200} />
      ),
    },
    {
      field: "Contact",
      headerName: "Contact",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value} maxWidth={200} />
      ),
    },
    { field: "City", headerName: "City" },
    { field: "Schedule_Date", headerName: "Schedule_Date" },
    { field: "HRA_Status", headerName: "HRA_Status" },
    { field: "Status", headerName: "Status" },
    { field: "Visit_Type", headerName: "Visit_Type" },
    { field: "Eye_Exam", headerName: "Eye_Exam" },
    { field: "Term_date", headerName: "Term_date" },
    { field: "No_Of_Attempts", headerName: "#_of_Attempts" },
    { field: "Last_Attempts", headerName: "Last_Attempt" },
    { field: "Scheduler_Name", headerName: "Scheduler" },
  ];
  const tableHeadersForScheduler = [
    { field: "Action", headerName: "Action", hidesortable: true },
    { field: "Program", headerName: "Program" },
    { field: "LOB", headerName: "LOB" },
    {
      field: "ID",
      headerName: "ID#",
      type: "number",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value.toString()} maxWidth={100} />
      ),
    },
    {
      field: "Name",
      headerName: "Name",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value} maxWidth={200} />
      ),
    },
    {
      field: "Contact",
      headerName: "Contact",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value} maxWidth={200} />
      ),
    },
    { field: "City", headerName: "City" },

    { field: "Schedule_Date", headerName: "Schedule_Date" },
    { field: "HRA_Status", headerName: "HRA_Status" },
    //  { field: "Scheduler_Name", headerName: "Scheduler_Name" },
    { field: "Status", headerName: "Status" },
    { field: "Visit_Type", headerName: "Visit_Type" },
    { field: "Eye_Exam", headerName: "Eye_Exam" },
    { field: "Term_date", headerName: "Term_date" },
    { field: "No_Of_Attempts", headerName: "#_of_Attempts" },
    { field: "Last_Attempts", headerName: "Last_Attempt" },
  ];
  const fetchSchedulerMemberData = async (
    plan,
    date,
    tabId,
    filter,
    searchTerm,
    defaultSortBy,
    defaultOrder,
  ) => {
    setLoading(true);
    try {
      const columnMappings = {
        ID: "assessmentData.memberId",
        Name: "assessmentData.demographics.data.firstName",
        Program: "program",
        LOB: "assessmentData.demographics.data.lob",
        City: "assessmentData.demographics.data.city",
        HRA_Status: "currentStatus.hrastatusName",
        Assigned_Clinician: "nurseId",
        Schedule_Date: "scheduleDate",
        Visit_Type: "visitType",
        Term_date: "termDate",
        Qa_Analyst: "qaId",
        Scheduler_Name: "schedulerIdUserData.firstName",
      };
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        year: date,
        planId: plan,
        tabId: tabId ?? defaultId,
        searchTerm,
        fieldName:
          filter === "First name"
            ? "assessmentData.demographics.data.firstName"
            : filter === "Identification#"
            ? "memberId"
            : filter === "Last name"
            ? "assessmentData.demographics.data.lastName"
            : null,
        sortValue: defaultOrder,
        sortBy: columnMappings[defaultSortBy],
      };
      if (searchTerm && searchTerm !== "null" && searchTerm !== "") {
        params.searchTerm = searchTerm;
      }
      const response = await getApi("/scheduler/getMembers", {
        params,
      });

      if (response?.data && response.status == 200) {
        const totalCount = response?.data?.totalCount ?? 0;
        setTotalRowCount(totalCount || 0);
        handleVphpSelection(response?.data ?? []);
        const hraStatusIds = response.data.data.map(
          (item) => item.hraStatus?._id,
        );

        setScheduledHraStatusIds(hraStatusIds ?? []);
        setLoading(false);
      } else {
        handleVphpSelection([]);
      }
    } catch (error) {
      handleVphpSelection([]);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDropdownChange = (value, rowIndex, stateSetter, dataKey) => {
    stateSetter((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value;

      const selectedRow = responseData?.data[rowIndex];
      const memberId = selectedRow?.memberId || "";
      const Name =
        `${selectedRow?.assessmentData?.demographics?.data?.firstName} ${selectedRow?.assessmentData?.demographics?.data?.middleName} ${selectedRow?.assessmentData?.demographics?.data?.lastName}` ||
        "";
      setSelectedRowData({
        ...selectedRowData,
        memberId,
        Name,
        [dataKey]: value,
      });

      return updatedValues;
    });
  };

  const handleDropdownChangeScheduler = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleHraStatus = (value, rowIndex) => {
    handleDropdownChange(value, rowIndex, setHraStatus, "hraStatus");
  };

  const handleStatus = (value, rowIndex) => {
    handleDropdownChange(value, rowIndex, setReportStatus, "status");
  };

  const handleSorting = (defaultSortBy, defaultOrder, filter) => {
    const isAsc = order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    fetchSchedulerMemberData(
      storedPlan,
      storedYear,
      tabId,
      filter,
      null,
      defaultSortBy,
      newOrder === "asc" ? 1 : -1,
    );
    setOrder(newOrder);
    setOrderBy(defaultSortBy);
  };
  const handleSearch = (filter, searchTerm) => {
    fetchSchedulerMemberData(storedPlan, storedYear, tabId, filter, searchTerm);
  };

  const handleFilter = async () => {
    setLoading(true);
    try {
      const filterData = alertContext?.filterDialogData;
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        year: storedYear,
        planId: _.toLower(storedPlan),
        tabId: tabId,
      };
      if (!_.isEmpty(filterData?.program)) {
        params.program = filterData?.program;
      }
      if (!_.isEmpty(filterData?.lob)) {
        params.lob = filterData?.lob;
      }
      if (!_.isEmpty(filterData?.scheduleDateFrom)) {
        params.scheduleDateFrom = moment(filterData?.scheduleDateFrom).format(
          "YYYY-MM-DD",
        );
      }
      if (!_.isEmpty(filterData?.scheduleDateTo)) {
        params.scheduleDateTo = moment(filterData?.scheduleDateTo).format(
          "YYYY-MM-DD",
        );
      }
      if (!_.isEmpty(filterData?.callbackFromDate)) {
        params.callbackFromDate = moment(filterData?.callbackFromDate).format(
          "YYYY-MM-DD",
        );
      }
      if (!_.isEmpty(filterData?.callbackToDate)) {
        params.callbackToDate = moment(filterData?.callbackToDate).format(
          "YYYY-MM-DD",
        );
      }
      if (!_.isEmpty(filterData?.nextOutreachFromDate)) {
        params.nextOutreachFromDate = moment(
          filterData?.nextOutreachFromDate,
        ).format("YYYY-MM-DD");
      }
      if (!_.isEmpty(filterData?.nextOutreachToDate)) {
        params.nextOutreachToDate = moment(
          filterData?.nextOutreachToDate,
        ).format("YYYY-MM-DD");
      }
      if (!_.isEmpty(filterData?.visitType)) {
        params.visitType = filterData?.visitType;
      }
      if (
        !_.isEmpty(filterData?.hrastatus) ||
        !_.isNull(filterData?.hrastatus)
      ) {
        params.hrastatus = filterData?.hrastatus;
      }
      if (!_.isEmpty(filterData?.nurseId)) {
        params.nurseId = filterData?.nurseId;
      }
      if (!_.isEmpty(filterData?.schedulerId)) {
        params.schedulerId = filterData?.schedulerId;
      }
      if (!_.isEmpty(filterData?.callResultStatus)) {
        params.callResultStatus = filterData?.callResultStatus;
      }
      if (!_.isEmpty(filterData?.callbackstatus)) {
        params.callBackStatus = filterData?.callbackstatus;
      }
      if (!_.isEmpty(filterData?.eyeExam)) {
        params.eyeExam = filterData?.eyeExam;
      }
      if (!_.isEmpty(filterData?.screening)) {
        params.screening = filterData?.screening;
      }
      if (!_.isEmpty(filterData?.status)) {
        params.status = filterData?.status;
      }
      if (!_.isEmpty(filterData?.screeningIha)) {
        params.screeningIha = filterData?.screeningIha;
      }
      const response = await getApi("/scheduler/getMembersByFilters", {
        params,
      });
      const totalCount = response?.data?.data?.[0]?.count;
      setTotalRowCount(totalCount || 0);
      handleVphpSelection(response?.data ?? []);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const isAllSelected = localStorage.getItem("isAllSelected");

    if (storedPlan && storedYear && storedScreeningTypes && isAllSelected) {
      const newValue = {
        plan: storedPlan,
        year: storedYear,
        screeningTypes: storedScreeningTypes,
        isAllSelected: JSON.parse(isAllSelected),
      };

      setClientData(newValue);
    }
  }, []);

  const fetchSchedulerData = async (clientId) => {
    try {
      const response = await postApi("/users/get-users-by-program-roleId", {
        // userClientsId: "client_001",
        // planId: "6577071ca26c60f43e9281e6",
        planId: clientId,
      });

      if (response.data) {
        let schedulerData;

        if (response.data.data[0] && response.data.data[0].scheduler) {
          schedulerData = response.data.data[0].scheduler;
        } else if (response.data.data.scheduler) {
          schedulerData = response.data.data.scheduler;
        } else {
          return;
        }

        if (Array.isArray(schedulerData) && schedulerData.length > 0) {
          const options = schedulerData.map(
            (scheduler) => `${scheduler.firstName} ${scheduler.lastName}`,
          );

          setSchedulerOptions(options);
          setSchedulerData(schedulerData);
        } else {
          return;
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  function getCurrentDateTime() {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${padZero(
      currentDate.getMonth() + 1,
    )}-${padZero(currentDate.getDate())} ${padZero(
      currentDate.getHours(),
    )}:${padZero(currentDate.getMinutes())}:${padZero(
      currentDate.getSeconds(),
    )}`;
    return formattedDate;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = String(hours).padStart(2, "0");

    return dayjs(dateString)
      ?.tz(selectedTimeZoneUtc)
      .format("MM-DD-YYYY hh:mm A"); //`${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;
  }

  function padZero(value) {
    return value < 10 ? `0${value}` : value;
  }
  function calculateAge(dob) {
    if (dob != "undefined" && Boolean(dob)) {
      //const dateOfBirth = excelSerialToDate(JSON.parse(JSON.stringify(dob)));
      // const dateOfBirth = dob;
      const dateOfBirth = JSON.parse(JSON?.stringify(dob));
      var birthDateObj = new Date(dateOfBirth);
      // dateOfBirth?.includes("-")
      //   ? new Date(dateOfBirth)
      //   : new Date(excelSerialToDate(dateOfBirth));
      var currentDate = new Date();
      var timeDifference = currentDate?.getTime() - birthDateObj?.getTime();
      var age = Math?.floor(timeDifference / (1000 * 60 * 60 * 24 * 365.25));
      return age;
    } else {
      return 1000;
    }
  }
  const roleId = sessionStorage.getItem("roleId");
  const dataMemberList =
    (Array.isArray(responseData.data) ? responseData.data : []).map(
      (item, index) => {
        console.log("item", item);
        const retinalExamData =
          item?.assessmentData?.screenings?.data?.retinalExam;
        const callLogs = item?.schedulerCallLogDetails?.callLog;
        const reportStatus = item?.reportStatus;

        const latestCallLog =
          callLogs && callLogs.length > 0
            ? callLogs.reduce((latest, current) =>
                new Date(current.date) > new Date(latest.date)
                  ? current
                  : latest,
              )
            : null;
        const statuS = latestCallLog?.result || "";
        const statusId = latestCallLog?.resultId || "";
        const lastAttemptDetails = latestCallLog
          ? `${
              latestCallLog.date
                ? formatDate(latestCallLog.date)
                : getCurrentDateTime() || getCurrentDateTime()
            },
           ${latestCallLog.result || ""},
            ${latestCallLog.comment || ""}`
          : "";

        const scheduler = latestCallLog
          ? ` ${latestCallLog.scheduler || ""}`
          : "";
        const eyeExamValue =
          retinalExamData?.isRetinalExam === "1"
            ? "Yes"
            : retinalExamData?.isRetinalExam === "2"
            ? "No"
            : "No";
        const demographics = item.assessmentData?.demographics?.data;
        const firstName = demographics?.firstName || "";
        const middleName = demographics?.middleName || "";
        const lastName = demographics?.lastName || "";
        const address = demographics?.address || "";
        const primary = demographics?.primary || "";
        const visitType = item.visitType;
        const hraStatus = item.hraStatus.hraStatus;
        const eyeExam = eyeExamValue;
        const lastAttempts = lastAttemptDetails;
        const status = reportStatus?.reportStatusId;
        const _id = item?._id || "";
        const dateOfBirth = demographics?.dob;
        const pedsFormPatientName = demographics?.name;
        const matchingVisitType = visitTypeData.find(
          (visit) => visit.id === visitType,
        );

        const defaultVisitType = matchingVisitType?.visitType || "";

        const matchingHraStatus = hraStatusData.find(
          (status) => status.id == hraStatus,
        );

        const defaultHraStatus = matchingHraStatus
          ? matchingHraStatus.statusName
          : "";
        const schedulerIdUserData = item.schedulerIdUserData || {};
        const schedulerFullName =
          Object.keys(schedulerIdUserData).length > 0
            ? `${schedulerIdUserData?.firstName} ${schedulerIdUserData?.lastName}`
            : "";
        const screenIha = demographics?.screenIha === "2" ? true : false || "";

        return {
          Program: item.program,
          LOB: item.assessmentData?.demographics?.data?.lob || "",
          ID: item.memberId,
          Name:
            pedsFormPatientName?.length > 0
              ? pedsFormPatientName
              : `${firstName} ${middleName} ${lastName}`, //`${firstName} ${middleName} ${lastName}`,
          Contact: `${address} ${primary}`,
          City: item.assessmentData?.demographics?.data?.city || "",
          Scheduler_Name: schedulerFullName,
          Schedule_Date: item.scheduleDate || "",
          Visit_Type: defaultVisitType,
          Term_date: item.termDate || "",
          HRA_Status: defaultHraStatus,
          EyE_Exam: eyeExam,
          No_Of_Attempts: item.callLogAttempts || 0,
          Last_Attempts: lastAttempts,
          Status: status,
          Scheduler: scheduler,
          _id: _id,
          CheckBox: (
            <input
              type="checkbox"
              style={{ width: "20px", height: "18px" }}
              // checked={isChecked}
              checked={selectedCheckboxes[index] || false}
              onChange={() => handleCheckboxSelection(item.memberId, index)}
            />
          ),

          Action: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              // background={
              //   screenIha ? "var(--colorDarkBlue)" : "var(--mainTheme)"
              // }
              background={
                screenIha
                  ? "var(--colorDarkBlue)"
                  : calculateAge(dateOfBirth) >= 18
                  ? "var(--mainTheme)"
                  : "#ed8e22"
              }
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              // onClick={() => handleHraButtonClick(item.hraStatus._id, index)}
              onClick={() => {
                handleHraButtonClick(item.memberId, index);
              }}
            >
              {screenIha
                ? "Screen Only"
                : calculateAge(dateOfBirth) >= 18
                ? " HRA LOG"
                : "PEDS LOG"}
            </Button>
          ),
        };
      },
    ) || [];
  console.log("reportStatus", reportStatus);
  const dataMemberListWithDropdown = useMemo(() => {
    return dataMemberList.map((data, index) => ({
      ...data,

      Visit_Type:
        // <SelectDropdown
        //   label="Visit Type"
        //   // value={visitTypeValues[index] || ""}
        //   // value={data.visitType}
        //   value={data.Visit_Type}
        //   onChange={(event) => handleVisitTypeChange(event, index)}
        //   options={visitTypeOptions}
        // />
        data.Visit_Type,
      checkboxHeader: (
        <input
          key={index}
          type="checkbox"
          checked={selectedRowCheckboxes[index] || false}
          onChange={(event) => handleRowCheckboxChange(data.ID, index)}
        />
      ),

      Status: (
        <Select
          sx={{
            // height: "48px",
            border: "1.5px solid var(--mainTheme) !important",
            width: "180px",
            "& .MuiFormLabel-root": {
              backgroundColor: "white",
              color: "rgba(0,0,0,0.38)!important",
            },
            "& .MuiInputLabel-root": {
              backgroundColor: "white",
              color: "rgba(0,0,0,0.38)!important",
            },
          }}
          label="Status"
          value={data.Status}
        >
          {reportStatus?.map((option) => {
            return (
              <MenuItem
                sx={{ backgroundColor: "#fc4242" }}
                key={option.id}
                value={option.id}
                disabled={true}
              >
                {option.statusName}
              </MenuItem>
            );
          })}
        </Select>
      ),
      Schedule_Date: (
        <p>
          {" "}
          {!_.isEmpty(data?.Schedule_Date)
            ? dayjs(data?.Schedule_Date || "")
                .tz(selectedTimeZoneUtc)
                .format("MM-DD-YYYY")
            : ""}
        </p>
      ),
      // ) : (
      //   <MuiInput
      //     type="date"
      //     // value={scheduleDateValues[index] || ""}
      //     value={assignedScheduleDateValues[index] || ""}
      //     onChange={(event) => handleScheduleDateChange(event, index)}
      //   />
      // ),
      Term_date: (
        <p>
          {" "}
          {!_.isEmpty(data?.Term_date)
            ? dayjs(data?.Term_date || "")
                .tz(selectedTimeZoneUtc)
                .format("MM-DD-YYYY")
            : ""}
        </p>
      ),

      // <MuiInput
      //   type="date"
      //   value={assignedTermDateValues[index] || ""}
      //   onChange={(event) => handleTermDateChange(event, index)}
      // />
      // ),

      HRA_Status: (
        <SelectDropdown
          label="HRA status"
          value={data.HRA_Status}
          onChange={(event) => handleHraStatus(event, index)}
          options={hraStatusData.map((status) => status.statusName)}
          disabledOptions
        />
      ),
      Eye_Exam: data.EyE_Exam,
      Last_Attempts: (
        <TooltipEllipsisCell value={data.Last_Attempts} maxWidth={200} />
      ),
      Contact: <TooltipEllipsisCell value={data.Contact} maxWidth={100} />,
      City: <TooltipEllipsisCell value={data.City} maxWidth={100} />,
      Scheduler_Name: data.Scheduler_Name,
      No_Of_Attempts: data.No_Of_Attempts,
    }));
  }, [selectedTimeZoneUtc, dataMemberList]);

  useEffect(() => {
    const fetchSchedulerData = async (clientId) => {
      try {
        const response = await postApi("/users/get-users-by-program-roleId", {
          planId: storedClientId || selectedClientId,
          // userClientsId: "client_001",
          // planId: "6577071ca26c60f43e9281e6",
          // planId: clientId,
        });

        if (response.data) {
          let schedulerData;

          if (response.data.data[0] && response.data.data[0].scheduler) {
            schedulerData = response.data.data[0].scheduler;
          } else if (response.data.data.scheduler) {
            schedulerData = response.data.data.scheduler;
          } else {
            return;
          }

          if (Array.isArray(schedulerData) && schedulerData.length > 0) {
            const options = schedulerData.map(
              (scheduler) => `${scheduler.firstName} ${scheduler.lastName}`,
            );

            setSchedulerOptions(options);
            setSchedulerData(schedulerData);
          } else {
            return;
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      }
    };

    fetchSchedulerData(storedClientId || selectedClientId);
  }, []);

  //Scheduled Date
  const handleAssignedScheduleDateTypeUpdate = async () => {
    const storedPlan = localStorage.getItem("clientPlan") || clientData?.plan;
    const storedYear = localStorage.getItem("clientYear") || clientData?.year;
    if (selectedScheduleDateRowIndex !== null) {
      const selectedRowData = responseData.data[selectedScheduleDateRowIndex];
      const memberObjIds = [selectedRowData._id];
      const scheduleDate =
        assignedScheduleDateValues[selectedScheduleDateRowIndex];

      try {
        setIsLoading(true);
        const requestBody = {
          memberObjIds,
          scheduleDate,
        };

        if (scheduleDate && scheduleDate.trim() !== "") {
          const formattedDate = new Date(scheduleDate)
            .toISOString()
            .split("T")[0];
          requestBody.scheduleDate = formattedDate;
        }

        const response = await putApi(
          "/assignClinician/updateScheduleandTermDate",
          requestBody,
        );

        setToasterMessage(response.data.message);
        setToasterSeverity(Labels.SUCCESS);
        setToasterOpen(true);
        fetchSchedulerMemberData(storedPlan, storedYear);
      } catch (error) {
        return;
      } finally {
        setIsLoading(false);
      }
      setIsAssignedScheduleDateAlertOpen(false);
    }
  };

  const scheduleDateUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedScheduleDateValues = responseData.data.map((item) => {
        const scheduleDate = item.scheduleDate || "";

        if (scheduleDate && scheduleDate.trim() !== "") {
          const formattedDate = new Date(scheduleDate)
            .toISOString()
            .split("T")[0];
          return formattedDate;
        } else {
          return "";
        }
      });

      setAssignedScheduleDateValues(updatedScheduleDateValues);
    }
  };

  useEffect(() => {
    scheduleDateUpdate(responseData);
  }, [responseData]);

  //schedule Date
  const handleScheduleDateChange = (event, rowIndex) => {
    const { value } = event.target;
    const enteredDate = new Date(value);
    const minAllowedDate = new Date("2023-01-01");
    const maxAllowedDate = new Date("2025-12-31");

    if (enteredDate < minAllowedDate || enteredDate > maxAllowedDate) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Invalid date");
      setToasterOpen(true);

      //setDateError(true);

      if (enteredDate < minAllowedDate) {
        event.target.value = minAllowedDate.toISOString().slice(0, 10);
      }

      if (enteredDate > maxAllowedDate) {
        event.target.value = maxAllowedDate.toISOString().slice(0, 10);
      }

      return;
    }
    // setDateError(false);

    setAssignedScheduleDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedScheduleDateRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessmentData?.demographics?.data?.firstName} ${selectedRowData?.assessmentData?.demographics?.data?.middleName} ${selectedRowData?.assessmentData?.demographics?.data?.lastName}` ||
          "";
        const scheduleDate = updatedValues[rowIndex] || "";

        setSelectedRowData({
          memberId,
          Name,
          scheduleDate,
        });

        setIsAssignedScheduleDateAlertOpen(true);

        setSelectedAssignedScheduleDateChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        if (scheduleDate && scheduleDate.trim() !== "") {
          updatedValues[rowIndex] = scheduleDate;
        } else {
          return;
        }

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };

  const handleAssignedScheduleDateTypeCancel = () => {
    setAssignedScheduleDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedScheduleDateChange.rowIndex] =
        selectedAssignedScheduleDateChange.originalValue;
      return updatedValues;
    });

    setIsAssignedScheduleDateAlertOpen(false);
    setSelectedAssignedScheduleDateChange(null);
  };

  // Term Date
  const handleAssignedTermDateTypeUpdate = async () => {
    if (selectedTermDateRowIndex !== null) {
      const selectedRowData = responseData.data[selectedTermDateRowIndex];
      const memberObjIds = [selectedRowData._id];
      const termDate = assignedTermDateValues[selectedTermDateRowIndex];
      const storedPlan = localStorage.getItem("clientPlan") || clientData?.plan;
      const storedYear = localStorage.getItem("clientYear") || clientData?.year;
      try {
        setIsLoading(true);
        const requestBody = {
          memberObjIds,
          termDate,
        };

        if (termDate && termDate.trim() !== "") {
          const formattedDate = new Date(termDate).toISOString().split("T")[0];
          requestBody.termDate = formattedDate;
        }

        const response = await putApi(
          "/assignClinician/updateScheduleandTermDate",
          requestBody,
        );

        setToasterMessage(response.data.message);
        setToasterSeverity(Labels.SUCCESS);
        setToasterOpen(true);
        fetchSchedulerMemberData(storedPlan, storedYear);
      } catch (error) {
        return;
      } finally {
        setIsLoading(false);
      }
      setIsAssignedTermDateAlertOpen(false);
    }
  };

  const termDateUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedTermDateValues = responseData.data.map((item) => {
        const termDate = item.termDate || "";

        if (termDate && termDate.trim() !== "") {
          const formattedDate = new Date(termDate).toISOString().split("T")[0];
          return formattedDate;
        } else {
          return "";
        }
      });

      setAssignedTermDateValues(updatedTermDateValues);
    }
  };

  useEffect(() => {
    termDateUpdate(responseData);
  }, [responseData]);

  //Term Date
  const handleTermDateChange = (event, rowIndex) => {
    const { value } = event.target;
    const enteredDate = new Date(value);
    const minAllowedDate = new Date("2023-01-01");
    const maxAllowedDate = new Date("2025-12-31");

    if (enteredDate < minAllowedDate || enteredDate > maxAllowedDate) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Invalid date");
      setToasterOpen(true);

      //setDateError(true);
      if (enteredDate < minAllowedDate) {
        event.target.value = minAllowedDate.toISOString().slice(0, 10);
      }

      if (enteredDate > maxAllowedDate) {
        event.target.value = maxAllowedDate.toISOString().slice(0, 10);
      }

      return;
    }

    //setDateError(false);

    setAssignedTermDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedTermDateRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessmentData?.demographics?.data?.firstName} ${selectedRowData?.assessmentData?.demographics?.data?.middleName} ${selectedRowData?.assessmentData?.demographics?.data?.lastName}` ||
          "";
        const termDate = updatedValues[rowIndex] || "";

        setSelectedRowData({
          memberId,
          Name,
          termDate,
        });

        setIsAssignedTermDateAlertOpen(true);

        setSelectedAssignedTermDateChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        if (termDate && termDate.trim() !== "") {
          updatedValues[rowIndex] = termDate;
        } else {
          return;
        }

        return updatedValues;
      } else {
        return;
        return prevValues;
      }
    });
  };

  const handleAssignedTermDateTypeCancel = () => {
    setAssignedTermDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedTermDateChange.rowIndex] =
        selectedAssignedTermDateChange.originalValue;
      return updatedValues;
    });

    setIsAssignedTermDateAlertOpen(false);
    setSelectedAssignedTermDateChange(null);
  };

  return (
    <div className="right-content">
      <Toaster
        open={toasterOpen}
        severity={toasterSeverity}
        message={toasterMessage}
        handleClose={() => setToasterOpen(false)}
      />
      <div className="content-dashboard">
        <div>
          <DashboardComponent>
            <SchedulerComponent
              areCheckboxesSelected={areCheckboxesSelected}
              selectedRowCheckboxes={selectedRowCheckboxes}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              selectedMemberData={selectedMemberData}
              selectedMemberDataArray={selectedMemberDataArray}
              setSelectedMemberDataArray={setSelectedMemberDataArray}
              onSearch={handleSearch}
              setSelectedCheckboxes={setSelectedCheckboxes}
              setSelectedRowCheckboxes={setSelectedRowCheckboxes}
              // onAddClick={handleAssignedScheduleUpdate}
              // selectedValue={selectedValue}
              // setSelectedValue={setSelectedValue}
            />
          </DashboardComponent>
        </div>
        {isDialogOpen === true ? (
          <VphpDialog
            onSelection={handleVphpSelection}
            fetchSchedulerMemberData={fetchSchedulerMemberData}
            setLoading={setLoading}
            routedFrom={
              roleId == 3 || roleId == 1 || roleId == 2
                ? "schedulerList"
                : "schedulerDashboard"
            }
            onOk={handleSchedulerListOk}
          />
        ) : (
          <div className="container">
            {clientData &&
            clientData.isAllSelected === true &&
            clientData.plan !== null &&
            clientData.screeningTypes !== null &&
            clientData.year !== null ? (
              <div className="datatable-scheduler">
                {loading ? (
                  <Loader />
                ) : (
                  <DataTableComponent
                    header={
                      roleId == 3 || roleId == 1 || roleId == 2
                        ? tableHeaders
                        : tableHeadersForScheduler
                    }
                    showCheckboxHeader={
                      roleId == 3 || roleId == 1 || roleId == 2 ? true : false
                    }
                    data={dataMemberListWithDropdown}
                    selectAllHeaderCheckboxState={selectAllHeaderCheckbox}
                    selectedRowCheckboxes={selectedRowCheckboxes}
                    handleHeaderCheckboxChange={handleHeaderCheckboxChange}
                    handleRowCheckboxChange={handleRowCheckboxChange}
                    handleCheckboxSelection={handleCheckboxSelection}
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={totalRowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    handleSorting={handleSorting}
                    selectedHeader={orderBy}
                    selectedSorting={order}
                  />
                )}
              </div>
            ) : (
              <VphpDialog
                onSelection={handleVphpSelection}
                fetchSchedulerMemberData={fetchSchedulerMemberData}
                setLoading={setLoading}
                routedFrom={
                  roleId == 3 || roleId == 1 || roleId == 2
                    ? "schedulerList"
                    : "schedulerDashboard"
                }
                onOk={handleSchedulerListOk}
              />
            )}
          </div>
        )}
        {assignedScheduleDateValues && isAssignedScheduleDateAlertOpen && (
          <Alert
            open={isAssignedScheduleDateAlertOpen}
            handleClose={() => setIsAssignedScheduleDateAlertOpen(false)}
            title={`Do you want to schedule the selected date?`}
            okText="Yes"
            cancelText="No"
            onAddClick={handleAssignedScheduleDateTypeUpdate}
            onNoClick={handleAssignedScheduleDateTypeCancel}
          />
        )}
        {assignedTermDateValues && isAssignedTermDateAlertOpen && (
          <Alert
            open={isAssignedTermDateAlertOpen}
            handleClose={() => setIsAssignedTermDateAlertOpen(false)}
            title={`Are you sure you want to change the term date for ${selectedRowData.Name}?`}
            okText="Yes"
            cancelText="No"
            onAddClick={handleAssignedTermDateTypeUpdate}
            onNoClick={handleAssignedTermDateTypeCancel}
          />
        )}
        {isCallLogOpen && (
          <CallLog
            ModalClose={handleModalClose}
            selectedMemberData={selectedMemberData}
            _fetchSchedulerMemberData={fetchSchedulerMemberData}
          />
        )}
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default Scheduler;
