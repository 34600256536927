import { Grid } from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import LoadingOverlay from "react-loading-overlay";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";
import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import DFRadio from "../../DFInputs/DFRadio";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  deleteSubTree,
  getActivitiesData,
  getSubTree,
  setActivitiesData,
} from "./activitiesMapping";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./activitiesFormData";
// import { ActivitiesTableStatic } from "../../../../DynamicFormMUI/staticForm";
import { staticData } from "../../../../DynamicFormMUI/indexData";
import { ActivitiesTableStatic } from "./ActivitiesTable";
import { TableComponentStatic } from "../../../../DynamicFormMUI/staticForm";
import { TableComponentStaticActivities } from "../../DFInputs/DFDataTable";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const Activities = forwardRef((props, _ref) => {
  const {
    // examReviewFormData,
    // setExamReviewFormData,
    // showComment,
    // setShowComment,
    // getSectionInputsData,
    getDisableFlag,
    // getStateValue,
    // setStateValue,
  } = useFillHraContext();
  const {
    storedPlan,
    storedYear,
    getAssessmentData,
    setSideBarSectionDisable,
  } = useUserContext();
  const [loading, setLoading] = useState(undefined);
  const [activitiesFormData, setActivitiesFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [score, setScore] = useState("0");
  const [dressingScore, setDressingScore] = useState("0");
  const [toiletingScore, setToiletingScore] = useState("0");
  const [transferringScore, setTransferringScore] = useState("0");
  const [continenceScore, setContinenceScore] = useState("0");
  const [feedingScore, setFeedingScore] = useState("0");
  const [totalPoints, setTotalPoints] = useState("0");
  const [comment, setComment] = useState("");
  const sectionId = props.currSection || 5;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);

  const updateActivitiesFormData = (key, value) => {
    setActivitiesFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  const location = useLocation();
  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");

  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      // const dbData = await props.getSectionDbData(sectionId);
      const mashedData =
        dbData?.data?.activities?.data &&
        Object.keys(dbData?.data?.activities?.data).length > 0
          ? getActivitiesData(dbData?.data?.activities?.data)
          : {};

      const commentVisibility = calcCommentVisibility(mashedData);
      let mashedCalcData = {
        ...mashedData,
        ...commentVisibility,
      };

      setActivitiesFormData(mashedCalcData);
      setLoading(false);
      setSideBarSectionDisable(false);
    }
    if (clientYear == "2023") {
      getDbData();
    }
    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...activitiesFormData };
    updatedFormData[name] = value;
    setActivitiesFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...activitiesFormData };
    updatedFormData[name] = value;

    setActivitiesFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...activitiesFormData };
    updatedFormData[name] = value;

    setActivitiesFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...activitiesFormData };
    updatedFormData[name] = value;

    setActivitiesFormData(updatedFormData);
  };

  const handleOptionsTreeChange = (name, value, isMulti) => {
    const updatedFormData = { ...activitiesFormData };
    let newSelection = value;
    // let oldSelection = getStateValue(13, name);
    let oldSelection = updatedFormData[name];
    let deletedValue =
      isMulti === true
        ? lodash.difference(oldSelection, newSelection)[0] || ""
        : oldSelection || "";
    if (deletedValue !== "") {
      updatedFormData[name] = value;
      const deleteRegex = getSubTree(name, deletedValue);

      if (deleteRegex != "") {
        let subTreeDeletedData = deleteSubTree(updatedFormData, deleteRegex);
        setActivitiesFormData(subTreeDeletedData);
        return null;
      } else {
        updatedFormData[name] = value;
        setActivitiesFormData(updatedFormData);
      }

      return null;
    } else {
      updatedFormData[name] = value;
      setActivitiesFormData(updatedFormData);
    }
  };
  const handleButtonClick = (name) => {};

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...activitiesFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setActivitiesFormData(updatedFormData);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setSideBarSectionDisable(true);
      //const assessmentDataFromAPI = await getAssessmentData();
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      // const dbData = await props.getSectionDbData(sectionId);
      // const mashedData =
      //   dbData?.data?.activities?.data &&
      //   Object.keys(dbData?.data?.activities?.data).length > 0
      //     ? getActivitiesData(dbData?.data?.activities?.data)
      //     : {};
      if (dbData?.data?.activities?.data) {
        const assessmentDBData = dbData?.data?.activities; //assessmentDataFromAPI.data;

        let dbFormData = {};
        if (assessmentDBData) {
          const { data } = assessmentDBData;

          //const activitiesDBData = data?.activities?.data;

          if (data && Object.keys(data).length > 0) {
            const bathingValue =
              data?.difficultyWithActivities?.bathing?.score || "";
            const dressingValue =
              data?.difficultyWithActivities?.dressing?.score || "";
            const toiletingValue =
              data?.difficultyWithActivities?.toileting?.score || "";
            const transferringValue =
              data?.difficultyWithActivities?.transferring?.score || "";
            const continenceValue =
              data?.difficultyWithActivities?.continence?.score || "";
            const feedingValue =
              data?.difficultyWithActivities?.feeding?.score || "";

            const commentValue = data?.difficultyWithActivities?.comment || "";

            dbFormData = {
              ...dbFormData,
              bathing: bathingValue || "",
              comment: commentValue?.trim(),
            };
            //activities_evidencedBasedComment

            const updatedFormData = { ...activitiesFormData };
            updatedFormData.activities_evidencedBasedComment = commentValue;

            const commentVisibility = calcCommentVisibility(updatedFormData);
            let mashedCalcData = {
              ...updatedFormData,
              ...commentVisibility,
            };

            setActivitiesFormData(mashedCalcData);

            setScore(bathingValue);
            setDressingScore(dressingValue);
            setToiletingScore(toiletingValue);
            setTransferringScore(transferringValue);
            setContinenceScore(continenceValue);
            setFeedingScore(feedingValue);
            setComment(commentValue?.trim());
            setLoading(false);
            setSideBarSectionDisable(false);
          } else {
            setLoading(false);
            setSideBarSectionDisable(false);
          }
        }
      }
      setTimeout(() => {
        setLoading(false);
        setSideBarSectionDisable(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
      setSideBarSectionDisable(false);
    }
  };
  console.log("loading", loading);
  useEffect(() => {
    if (clientYear == "2024") {
      fetchData();
    }
  }, []);

  const getPayload2024 = (commentValue) => {
    const payload = {
      difficultyWithActivities: {
        bathing: {
          score: score || "0",
        },
        dressing: {
          score: dressingScore || "0",
        },
        toileting: {
          score: toiletingScore || "0",
        },
        transferring: {
          score: transferringScore || "0",
        },
        continence: {
          score: continenceScore || "0",
        },
        feeding: {
          score: feedingScore || "0",
        },
        totalScore: totalPoints || "0",
        comment: commentValue?.trim() || "",
      },
    };

    return payload;
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      if (clientYear === "2024") {
        const commentValue = Object?.keys(activitiesFormData)
          ?.filter((key) => key?.endsWith("Comment"))
          ?.map((key) => activitiesFormData[key]?.trim())
          ?.join(" ");
        return getPayload2024(commentValue);
      } else {
        return setActivitiesData(activitiesFormData);
      }
    },
  }));
  useEffect(() => {
    if (clientYear === "2024") {
      const commentValue = Object?.keys(activitiesFormData)
        ?.filter((key) => key?.endsWith("Comment"))
        ?.map((key) => activitiesFormData[key]?.trim())
        ?.join(" ");
      const data = getPayload2024(commentValue);
      props.setAutoSaveActivitiesData(data);
    } else {
      props.setAutoSaveActivitiesData(activitiesFormData);
    }
  }, [activitiesFormData]);

  const getFieldValues = useCallback(
    (name) => {
      return activitiesFormData[name];
    },
    [activitiesFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setActivitiesFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        style={{
          margin: "10px 20px",
        }}
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const { questionId, name, xs, type, isSingleButton } = inputProps;
            const inputValue = activitiesFormData[name] || "";
            // const commentValue = `${
            //   activitiesFormData?.[`${name}Comment`]
            //     ? `${activitiesFormData[`${name}Comment`]}`
            //     : ""
            // }`;
            const commentValue = `${
              activitiesFormData?.[`${name}Comment`]
                ? `${activitiesFormData[`${name}Comment`]}`
                : ""
            }`.trim();

            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFText
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFRadio
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = activitiesFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  // formData={covidScreeningFormData}
                  formData={activitiesFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  // setFormData={setCovidScreeningFormData}
                  setFormData={setActivitiesFormData}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFLabel
                    inputProps={inputProps}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={inputProps}
                    handleButtonClick={handleButtonClick}
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFCheckbox
                    inputProps={inputProps}
                    showComment={showComment}
                    handleCheckboxChange={handleCheckboxChange}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "divider") {
              return <DFRuler />;
            } else if (type === "datatable" && clientYear === "2024") {
              return (
                <Grid item>
                  <ActivitiesTableStatic
                    tableName="Activities Chart"
                    score={score}
                    setScore={setScore}
                    dressingScore={dressingScore}
                    setDressingScore={setDressingScore}
                    toiletingScore={toiletingScore}
                    setToiletingScore={setToiletingScore}
                    transferringScore={transferringScore}
                    setTransferringScore={setTransferringScore}
                    continenceScore={continenceScore}
                    setContinenceScore={setContinenceScore}
                    feedingScore={feedingScore}
                    setFeedingScore={setFeedingScore}
                    totalPoints={totalPoints}
                    setTotalPoints={setTotalPoints}
                    updateActivitiesFormData={updateActivitiesFormData}
                  />

                  <TableComponentStaticActivities
                    tableName="Activities Chart"
                    data={staticData[2].column_data[0].rows}
                  />
                </Grid>
              );
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default Activities;
