import {
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControl,
  Checkbox,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Paper,
} from "@mui/material";
import { DragNDropContext } from "../../../contexts/DragNDropContext";

import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Labels from "../../../resource_files/Labels.resx";
import Toaster from "../../../components/toaster/toaster";
import TableContainer from "@mui/material/TableContainer";

import "./DynamicForms.css";
import "./Tables.css";

import { DatePicker } from "@mui/x-date-pickers";
import Button from "../../button/button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PiInfo } from "react-icons/pi";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Tooltip from "@mui/material/Tooltip";
import { Zoom } from "@mui/material";
import "./DynamicForms.css";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { rowsRenderData } from "./rowdata";

import MuiInput from "../../muiinput/MuiInput";
import { putApi } from "../../../utils/axiosApiWrapper";
import { tab } from "@testing-library/user-event/dist/tab";

let inputid = 0;

const myTheme = createTheme({
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "black",
          margin: "5px 15px 0 0",
          borderStyle: "none",
          borderRadius: "0px!important",
          textTransform: "none",
          border: "1px solid var(--mainTheme)",
          width: "100%",
          whiteSpace: "normal",
          display: "flex",
          flexWrap: "wrap",
          padding: "5px",
          "&::before": {
            content: '""',
            textTransform: "uppercase",
          },
          "&.Mui-selected ": {
            backgroundColor: "var(--mainTheme)",
            color: "white",
            border: "1px solid var(--mainTheme) !important",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "var(--mainTheme)",
            color: "white",
            border: "1px solid var(--mainTheme) !important",
          },
        },
      },
    },
  },
});

const myThemes = createTheme({
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "black",
          borderStyle: "none",
          borderRadius: "1px!important",
          textTransform: "none",
          width: "100px",
          whiteSpace: "normal",
          display: "flex",
          flexWrap: "wrap",
          padding: "5px",
          "&::before": {
            content: '""',
          },
          "&.Mui-selected": {
            backgroundColor: "var(--mainTheme)",
            color: "white",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "var(--mainTheme)",
            color: "white",
          },
        },
      },
    },
  },
});

export function DynamicTable({
  columns,
  rowData,
  inputProps,
  id,
  rowsRenderData,
  rowIndex,
  addTableRow,
  deleteTableRow,
  targettable,
}) {
  const rowsData = [
    {
      id: 1,
      label: "Allergies",
      name: "HRAForm_Allergies",
      columns: [
        {
          label: "Substance",
          type: "text",
        },
        {
          label: "Reaction",
          type: "text",
        },
      ],
      isReadOnly: false,
    },
    {
      id: 2,
      label: "BMI Index Chart",
      name: "HRAForm_BMI_Index_Chart",
      columns: [
        {
          label: "BMI",
          type: "text",
        },
      ],
      isReadOnly: true,
    },

    {
      id: 3,
      label: "Medical History Specialists",
      name: "HRAForm_Medical_History_Speciality",
      columns: [
        {
          label: "Member Specialist",
          type: "select",
        },
        {
          label: "Speciality",
          type: "text",
        },
        {
          label: "For",
          type: "text",
        },
      ],
      isReadOnly: false,
    },
    {
      id: 4,
      label: "Medications",
      name: "HRAForm_Medications",
      columns: [
        {
          label: "Diagnoses",
          type: "text",
        },
        {
          label: "label Name",
          type: "select",
        },
        {
          label: "Dose Per Units",
          type: "text",
        },
        {
          label: "Route",
          type: "select",
        },
        {
          label: "Frequency",
          type: "select",
        },
        {
          label: "Prescribing Physician",
          type: "text",
        },
        {
          label: "Status",
          type: "togglebutton",
        },
      ],
      isReadOnly: false,
    },
    {
      id: 5,
      label: "Mini Cog Versions",
      name: "HRAForm_Minicog_Version",
      columns: [
        {
          label: "Version 1",
          type: "text",
        },
        {
          label: "Version 2",
          type: "text",
        },
        {
          label: "Version 3",
          type: "text",
        },
        {
          label: "Version 4",
          type: "text",
        },
        {
          label: "Version 5",
          type: "text",
        },
        {
          label: "Version 6",
          type: "text",
        },
      ],
      isReadOnly: true,
    },
    {
      id: 6,
      label: "Preventive Care",
      name: "HRAForm_Preventive_Care",
      columns: [
        {
          label: "Screen",
          type: "text",
        },
        {
          label: "Answer",
          type: "select",
        },
        {
          label: "Date",
          type: "text",
        },
        {
          label: "Method",
          type: "text",
        },
        {
          label: "Recommendation",
          type: "checkbox",
        },
        {
          label: "Education Completed",
          type: "checkbox",
        },
      ],
      isReadOnly: true,
    },
    {
      id: 7,
      label: "Previously Documented Conditions",
      name: "HRAForm_Previously_Documented_Conditions",
      columns: [
        {
          label: "Diagnosis",
          type: "text",
        },
        {
          label: "Prior HCC",
          type: "text",
        },
        {
          label: "Diagnosis Code",
          type: "text",
        },
        {
          label: "Date of Service",
          type: "date",
        },
      ],
      isReadOnly: false,
    },
    {
      id: 8,
      label: "Prior Assessments",
      name: "HRAForm_Prior_Assessments",
      columns: [
        {
          label: "Date Time",
          type: "date",
        },
        {
          label: "Document",
          type: "link",
        },
      ],
      isReadOnly: true,
    },
    {
      id: 9,
      label: "Stages Of Kidney Disease",
      name: "HRAForm_Stage_Of_Kidney_Disease",
      columns: [
        {
          label: "Stages",
          type: "text",
        },
      ],
      isReadOnly: true,
    },
    {
      id: 12,
      label: "Family History",
      name: "family_history",
      columns: [
        {
          label: "Family Member",
          type: "text",
        },
        {
          label: "Medical Condition",
          type: "text",
        },
        {
          label: "Cause of Death",
          type: "text",
        },
      ],
      isReadOnly: false,
    },
    {
      id: 7,
      label: "Preventive Care",
      name: "HRAForm_Preventive_Care",
      columns: [
        {
          label: "Screen",
          type: "text",
        },
        {
          label: "Answer",
          type: "select",
        },
        {
          label: "Date",
          type: "text",
        },
        {
          label: "Method",
          type: "text",
        },
        {
          label: "Recommendation",
          type: "checkbox",
        },
        {
          label: "Education Completed",
          type: "checkbox",
        },
      ],
      isReadOnly: true,
    },
    {
      id: 14,
      label: "Activities Chart",
      name: "activities_chart",
      columns: [
        {
          label: "Score",
          type: "text",
        },
        {
          label: "Indication",
          type: "text",
        },
      ],
      isReadOnly: true,
    },
  ];

  const handleTableDataChange = (event, rowIndex, fieldName) => {
    const updatedData = [...tableRowData];
    switch (fieldName) {
      case "Toggles":
        setSelectedToggles((prevToggles) => {
          const updatedToggles = [...prevToggles];
          updatedToggles[rowIndex] = event.target.value;

          return updatedToggles;
        });
        break;

      case "diagnoses":
        updatedData[rowIndex].Diagnoses = event.target.value;
        break;

      case "physician":
        updatedData[rowIndex].PrescribingPhysician = event.target.value;
        break;

      case "dose":
        updatedData[rowIndex].Dose = event.target.value;
        break;

      case "labelname":
        updatedData[rowIndex].LabelName = event.target.value;

        break;

      case "route":
        updatedData[rowIndex].Route = event.target.value;
        break;

      case "frequency":
        updatedData[rowIndex].Frequency = event.target.value;
        break;

      default:
        break;
    }

    setTableRowData(updatedData);
  };
  const { handleToggleOptionChange, handleDateChange } =
    useContext(DragNDropContext);
  const [dataTableIndex, setDataTableIndex] = useState({});
  const [tableRowData, setTableRowData] = useState([...rowsData]);
  const [selectedToggles, setSelectedToggles] = useState(
    tableRowData.map(() => "taken"),
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // margin: "auto",
        marginTop: "15px",
        marginLeft: "10px",
        width: "100%",
      }}
    >
      <Table
        key={rowIndex}
        sx={{
          tableLayout: "auto",

          margin: "10px",
        }}
        size="small"
      >
        <TableHead>
          <TableRow sx={{ fontWeight: 800 }}>
            {columns.map((column, colIndex) => (
              <TableCell
                key={colIndex}
                sx={{
                  border: "1px solid var(--mainTheme)",
                  padding: "8px",
                  textAlign: "center",
                  fontWeight: 600,
                  backgroundColor: "#d2d6d4",
                  height: "40px",
                  whiteSpace: "nowrap",
                  // width: "200px"
                  // wdith:"100px"
                  width: `${100 / columns.length}%`,
                }}
              >
                {column.label}
              </TableCell>
            ))}

            {columns.some(
              (column) =>
                column.label === "Status" ||
                column.label === "Date of Service" ||
                column.label === "For" ||
                column.label === "Reaction",
            ) && (
              <TableCell
                sx={{
                  padding: "8px",
                  textAlign: "center",
                  border: "1px solid var(--mainTheme)",
                }}
              >
                <AddIcon
                  style={{
                    border: "none",
                    backgroundColor: "none",
                    color: "var(--mainTheme)",
                    cursor: "pointer",
                  }}
                  onClick={addTableRow}
                />
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData?.map((medication, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <TableCell
                  key={colIndex}
                  sx={{
                    border: "1px solid var(--mainTheme)",
                    padding: "4px",
                    textAlign: "center",
                    // minWidth: "200px",
                    // minWidth:"100px"
                    width: `${100 / columns.length}%`,
                  }}
                >
                  {column.type === "date" ? (
                    <DatePicker
                      fullWidth
                      disabled
                      name={column.label}
                      variant="standard"
                      onChange={(e) => handleDateChange(e)}
                    />
                  ) : column.type === "togglebutton" ? (
                    <ThemeProvider theme={myTheme}>
                      <ToggleButtonGroup
                        value={selectedToggles[rowIndex]}
                        exclusive
                        onChange={(event) =>
                          handleTableDataChange(event, rowIndex, "Toggles")
                        }
                        aria-label="Toggle Taken/Not Taken"
                      >
                        <ToggleButton value="taken" aria-label="Taken">
                          Taken
                        </ToggleButton>
                        <ToggleButton value="notTaken" aria-label="Not Taken">
                          Not Taken
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </ThemeProvider>
                  ) : column.type === "text" ? (
                    <TextField
                      onChange={(event) =>
                        handleTableDataChange(event, rowIndex, "diagnoses")
                      }
                      multiline
                      // rows={1}
                      maxRows={1}
                      fullWidth
                      variant="standard"
                      sx={{
                        "& .MuiFormLabel-root": {
                          color: "var(--mainTheme)",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "var(--mainTheme)",
                        },
                      }}
                    />
                  ) : column.type === "select" ? (
                    <FormControl
                      sx={{
                        width: "100%",
                        marginTop: "15px",
                        border: "none",
                        outline: "none",
                      }}
                    >
                      <Select
                        sx={{
                          height: "3px",
                        }}
                        value={
                          tableRowData[rowIndex][column.label] !== undefined
                            ? tableRowData[rowIndex][column.label]
                            : []
                        }
                        disabled
                        displayEmpty
                        name={column.label}
                        label={column.label}
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(event) =>
                          handleTableDataChange(event, rowIndex, "fieldName")
                        }
                        MenuProps={{
                          sx: {
                            "& .MuiMenuItem-root.Mui-selected": {
                              backgroundColor: `var(--mainTheme800) !important`,
                            },
                            "& .MuiMenuItem-root:hover": {
                              backgroundColor: `var(--mainTheme300) !important`,
                              color: "#000000",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <span>Select</span>
                        </MenuItem>
                        {column.label === "label Name" && (
                          <div>
                            <MenuItem value="Famotidine">Famotidine</MenuItem>
                            <MenuItem value="Ranitidine">Ranitidine</MenuItem>
                          </div>
                        )}
                        {column.label === "Route" && (
                          <div>
                            <MenuItem value="Option1">Option1</MenuItem>
                            <MenuItem value="Option2">Option2</MenuItem>
                          </div>
                        )}
                        {column.label === "Frequency" && (
                          <div>
                            <MenuItem value="Option1">Option1</MenuItem>
                            <MenuItem value="Option2">Option2</MenuItem>
                          </div>
                        )}
                      </Select>
                    </FormControl>
                  ) : column.type === "link" ? (
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "var(--mainTheme)" }}
                    >
                      Annual Wellness Visit
                    </Link>
                  ) : (
                    medication[column.label]
                  )}
                </TableCell>
              ))}
              {columns.some(
                (column) =>
                  column.label === "Status" ||
                  column.label === "Date of Service" ||
                  column.label === "For" ||
                  column.label === "Reaction",
              ) && (
                <TableCell
                  sx={{
                    border: "1px solid var(--mainTheme)",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  <RemoveIcon
                    style={{
                      border: "none",
                      cursor: "pointer",
                      backgroundColor: "none",
                      color: "var(--mainTheme)",
                    }}
                    onClick={() => deleteTableRow(medication.id)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* </TableContainer> */}
    </div>
  );
}

export function DynamicTablePreviously({
  tableRowData,
  setTableRowData,
  memberId,
}) {
  const { handleCloses } = useContext(DragNDropContext);
  const navigate = useNavigate();
  const [toasterMessage, setToasterMessage] = useState("");

  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);

  const handleAddRow = () => {
    const uniqueId = Date.now().toString();
    setTableRowData([...tableRowData, { id: uniqueId }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...tableRowData];
    updatedRowData.splice(index, 1);
    setTableRowData(updatedRowData);
  };

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...tableRowData];

    if (label === "dateOfService") {
      updatedRowData[index][label] = Array.isArray(e.target.value)
        ? e.target.value[0]
        : e.target.value || "";
    } else {
      updatedRowData[index][label] = e.target.value;
    }

    setTableRowData(updatedRowData);
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    // padding: "8px",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };
  const handleSaveData = async () => {
    try {
      const apiEndpoint = "/hra/assessment";
      const view = tableRowData.length > 0 ? "1" : "2";
      const requestData = {
        id: memberId,
        data: {
          name: "Previously Documented Conditions",
          data: {
            view: view,
            // view: tableRowData.length > 0 ? 1:2,
            viewSubAnswers: tableRowData.map((row) => ({
              diagnosisCode: row.diagnosisCode || "",
              // dateOfService: row.dateOfService || "",
              dateOfService:
                row.dateOfService !== undefined ? row.dateOfService : "",
              diagnosis: row.diagnosis || "",
              priorHcc: row.priorHcc || "",
            })),
          },
        },
      };

      const response = await putApi(apiEndpoint, requestData);

      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage("success");
        handleCloses();
        navigate("/AdminDashboard/MemberList");
      } else {
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Failed to save data");
        setToasterOpen(true);
      }
    } catch (error) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Error during data save");
      setToasterOpen(true);
    }
  };

  return (
    <div>
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",

          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Diagnosis Code</TableCell>
            <TableCell style={tableCellStyle}>Diagnosis</TableCell>
            <TableCell style={tableCellStyle}>Prior HCC</TableCell>
            <TableCell style={tableCellStyle}>Date of Service</TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={handleAddRow}
              />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {tableRowData.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "diagnosisCode")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.diagnosisCode || ""}
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "diagnosis")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.diagnosis || ""}
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "priorHcc")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.priorHcc || ""}
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <MuiInput
                  type="date"
                  size="small"
                  value={row.dateOfService || ""}
                  onChange={(e) =>
                    handleTableDataChange(e, index, "dateOfService")
                  }
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <RemoveIcon
                  style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                  onClick={() => handleRemoveRow(index)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{
            cursor: "pointer",
          }}
          onClick={handleSaveData}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export function DynamicTableFamilyHistory({
  familyRowData,
  setFamilyRowData,
  memberId,
}) {
  const navigate = useNavigate();
  const [toasterMessage, setToasterMessage] = useState("");

  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...familyRowData];
    updatedRowData[index][label] = e.target.value;
    setFamilyRowData(updatedRowData);
  };

  const handleAddRow = () => {
    const uniqueId = Date.now().toString();
    setFamilyRowData([...familyRowData, { id: uniqueId }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...familyRowData];
    updatedRowData.splice(index, 1);
    setFamilyRowData(updatedRowData);
  };

  const familyMemberOptions = [
    "Father",
    "Mother",
    "Sibling1",
    "Sibling2",
    "Sibling3",
    "Other",
  ];
  // const handleTableDataChange = (e, index, label) => {
  //   const updatedRowData = [...familyRowData];

  //   updatedRowData[index][label] = e.target.value;

  //   setFamilyRowData(updatedRowData);
  // };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "ceanter",
    whiteSpace: "nowrap",
  };

  if (!familyRowData || familyRowData.length === 0) {
    setFamilyRowData([{ id: Date.now().toString() }]);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginLeft: "10px",
        width: "80%",
        margin: "auto",
      }}
    >
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Family Member</TableCell>
            <TableCell style={tableCellStyle}>Medical Condition</TableCell>
            <TableCell style={tableCellStyle}>Cause of Death</TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={handleAddRow}
              />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {familyRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={tableCellStyle}>
                <Select
                  value={row?.familyMember || ""}
                  onChange={(e) =>
                    handleTableDataChange(e, index, "familyMember")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  displayEmpty
                >
                  <MenuItem value="">
                    <span>Select Family Member</span>
                  </MenuItem>
                  {familyMemberOptions?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "medicalCondition")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row?.medicalCondition || ""}
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "causeofDeath")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row?.causeofDeath || ""}
                />
              </TableCell>
              {familyRowData.length > 1 && (
                <TableCell style={tableCellStyle}>
                  <RemoveIcon
                    style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                    onClick={() => handleRemoveRow(index)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export function DynamicTableSpecialists({
  medicalRowData,
  setMedicalRowData,
  memberId,
}) {
  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    // padding: "8px",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };
  const handleAddRow = () => {
    const uniqueId = Date.now().toString();
    setMedicalRowData([...medicalRowData, { id: uniqueId }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...medicalRowData];
    updatedRowData.splice(index, 1);
    setMedicalRowData(updatedRowData);
  };

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...medicalRowData];

    updatedRowData[index][label] = e.target.value;

    setMedicalRowData(updatedRowData);
  };

  const medicalSpecOptions = [
    "Allergist / Immunologist",
    "Anesthesiologist",
    "Cardiologist",
    "Dermatologist",
    "ENT",
    "Endocrinologist",
    "Gastroenterologist",
    "Geneticist",
    "Hematologist",
    "Infectious Disease Specialist",
    "Nephrologist",
    "Neurologist",
    "Obstetrician/Gynecologist",
    "Oncologist",
    "Ophthalmologist",
    "Podiatrist",
    "Psychiatrist",
    "Psychologist",
    "Pulmonologist",
    "Urologist",
    "Other",
  ];

  if (!medicalRowData || medicalRowData.length === 0) {
    setMedicalRowData([{ id: Date.now().toString() }]);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginLeft: "10px",
        width: "80%",
        margin: "auto",
      }}
    >
      <Table
        sx={{
          tableLayout: "auto",

          margin: "10px",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Medical Specialty</TableCell>
            <TableCell style={tableCellStyle}>Specialist</TableCell>
            <TableCell style={tableCellStyle}>For</TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={handleAddRow}
              />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {medicalRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={tableCellStyle}>
                <Select
                  value={row.medicalSp || ""}
                  onChange={(e) => handleTableDataChange(e, index, "medicalSp")}
                  fullWidth
                  size="small"
                  variant="standard"
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {medicalSpecOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "specialist")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.specialist || ""}
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "for")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.for || ""}
                />
              </TableCell>

              {medicalRowData.length > 1 && (
                <TableCell style={tableCellStyle}>
                  <RemoveIcon
                    style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                    onClick={() => handleRemoveRow(index)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{
            cursor: "pointer",
          }}
          // onClick={handleSaveData}
        >
          Save
        </Button>
      </div> */}
    </div>
  );
}

export function DynamicTableAlcoholUsage({
  alcoholRowData,
  setAlcoholRowData,
  memberId,
}) {
  const navigate = useNavigate();
  const [toasterMessage, setToasterMessage] = useState("");

  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...alcoholRowData];
    updatedRowData[index][label] = e.target.value;
    setAlcoholRowData(updatedRowData);
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "ceanter",
    whiteSpace: "nowrap",
  };

  return (
    <div>
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>How Many Drinks</TableCell>
            <TableCell style={tableCellStyle}>How Often</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {alcoholRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "howManyDrinks")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.howManyDrinks || ""}
                />
              </TableCell>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "howOften")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.howOften || ""}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export function DynamicTablePreventiveCare({
  preventiveRowData,
  setPreventiveRowData,
}) {
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);

  const handleTableDataChange = (e, index, label) => {
    const updatedData = [...preventiveRowData];
    updatedData[index][label] = e.target.value;
    setPreventiveRowData(updatedData);
  };
  const handleTableDateChange = (newDate, index, label) => {
    if (newDate && dayjs(newDate).isValid()) {
      const updatedRowData = [...preventiveRowData];

      updatedRowData[index][label] = dayjs(newDate).format();

      setPreventiveRowData(updatedRowData);
    }
  };

  const handleCheckboxChange = (index, label) => {
    const updatedData = [...preventiveRowData];
    updatedData[index][label] = updatedData[index][label] == 1 ? 0 : 1;
    setPreventiveRowData(updatedData);
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tooltips = [
    "Family hx or risk start at 40 otherwise 50-74,beinnial memmogram",
    "Family hx  start at 45,otherwise 50-70 years fit q 4 years or colonoscopy q 10 years 76-78 years discuss with pcp ",
    "Annual",
    "Discuss with pcp",
    "Discuss with pcp",
    "Discuss with pcp",
    "Annual , if diabetic hgb A1c screen every 3-4 months",
    "Annual",
    "Annual",
    "Annual",
    "Annual",
    "21-65 pap q3 years or q5 years with HRHpv testing ",
    "DXA screening greater than 65 years or <65 year post menupause",
    "55-65 years discuss with exam and psa testing with pcp",
    "Annual",
  ];

  const defaultScreenings = [
    "Breast Cancer Screening",
    "Colorectal Screening",
    "Influenza Vaccine",
    "COVID-19 Vaccine",
    "Pneumococcal Vaccine",
    "Herpes Zoster Vaccine",
    "Diabetes Screening",
    "Diabetic Foot Exam",
    "Cholesterol Screening",
    "Glaucoma Screening",
    "STIs/HIV Screening",
    "Cervical Cancer Screening",
    "Osteoporosis Screening",
    "Prostate Screening",
    "Fall Risk Screening",
  ];

  const initialPreventiveRowData =
    preventiveRowData ||
    Array.from({ length: 15 }, (_, index) => ({
      screen: defaultScreenings[index] || "",
      answer: "",
      date: "",
      method: "",
      recommendation: 0,
      educationCompleted: 0,
    }));

  return (
    <div>
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Screen</TableCell>
            <TableCell style={tableCellStyle}>Answer</TableCell>
            <TableCell style={tableCellStyle}>Date</TableCell>
            <TableCell style={tableCellStyle}>Method</TableCell>
            <TableCell style={tableCellStyle}>Recommendation</TableCell>
            <TableCell style={tableCellStyle}>Education Completed</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {initialPreventiveRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={tableCellStyle}>
                <TextField
                  // sx={{fontSize:"12px"}}
                  onChange={(e) => handleTableDataChange(e, index, "screen")}
                  fullWidth
                  style={{ fontSize: "12px" }}
                  size="small"
                  variant="standard"
                  value={row.screen || defaultScreenings[index]}
                  // inputProps={{fontSize:"12px"}}
                  // InputProps={{fontSize:"12px"}}
                  InputProps={{ readOnly: true }}
                />
                <Tooltip
                  TransitionComponent={Zoom}
                  title={tooltips[index]}
                  placement="top"
                  arrow
                  enterDelay={150}
                  leaveDelay={100}
                >
                  <span>
                    <PiInfo style={{ cursor: "pointer" }} />
                  </span>
                </Tooltip>
              </TableCell>

              {/* <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "answer")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.answer || ""}
                />
              </TableCell> */}
              <TableCell style={tableCellStyle}>
                <Select
                  onChange={(e) => handleTableDataChange(e, index, "answer")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.answer || ""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={2}>No</MenuItem>
                  <MenuItem value={3}>Not Applicable</MenuItem>
                  <MenuItem value={4}>Don't Know</MenuItem>
                </Select>
              </TableCell>

              <TableCell style={tableCellStyle}>
                {/* <TextField
                  type="date"
                  onChange={(e) => handleTableDataChange(e, index, "date")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.date || ""}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      value={row.date ? dayjs(row.date) : null}
                      onChange={(newDate) =>
                        handleTableDateChange(newDate, index, "date")
                      }
                      slotProps={{
                        textField: {
                          readOnly: true,
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </TableCell>

              {/* <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "method")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.method || ""}
                />
              </TableCell> */}
              <TableCell style={tableCellStyle}>
                {[
                  "Breast Cancer Screening",
                  "Colorectal Screening",
                  "Osteoporosis Screening",
                ].includes(row.screen || defaultScreenings[index]) ? (
                  <TextField
                    onChange={(e) => handleTableDataChange(e, index, "method")}
                    fullWidth
                    size="small"
                    variant="standard"
                    value={row.method || ""}
                  />
                ) : (
                  <TextField
                    fullWidth
                    size="small"
                    variant="standard"
                    value={"NA"}
                    InputProps={{ readOnly: true }}
                  />
                )}
              </TableCell>

              <TableCell style={tableCellStyle}>
                <Checkbox
                  onChange={() => handleCheckboxChange(index, "recommendation")}
                  checked={row.recommendation == 1}
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <Checkbox
                  onChange={() =>
                    handleCheckboxChange(index, "educationCompleted")
                  }
                  checked={row.educationCompleted == 1}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export function DynamicTableAllergies({
  allergiesRowData,
  setAllergiesRowData,
  memberId,
  disableFlag,
}) {
  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    // padding: "8px",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };
  // const handleAddRow = () => {
  //   const uniqueId = Date.now().toString();

  //   if (Array.isArray(allergiesRowData)) {
  //     setAllergiesRowData([...allergiesRowData, { id: uniqueId }]);
  //   } else {
  //     setAllergiesRowData([{ id: uniqueId }]);
  //   }
  // };

  const handleAddRow = () => {
    if (Array.isArray(allergiesRowData) && allergiesRowData.length < 5) {
      // const uniqueId = Date.now().toString();
      setAllergiesRowData([...allergiesRowData, {}]);
    }
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...allergiesRowData];
    updatedRowData.splice(index, 1);
    setAllergiesRowData(updatedRowData);
  };

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...allergiesRowData];

    updatedRowData[index][label] = e.target.value;

    setAllergiesRowData(updatedRowData);
  };

  if (!allergiesRowData || allergiesRowData.length === 0) {
    setAllergiesRowData([{ id: Date.now().toString() }]);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginLeft: "10px",
        width: "80%",
        margin: "auto",
      }}
    >
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Substance</TableCell>
            <TableCell style={tableCellStyle}>Reaction</TableCell>

            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={() => {
                  if (disableFlag) {
                    return null;
                  }
                  handleAddRow();
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {allergiesRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "substance")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.substance || ""}
                  inputProps={{ readOnly: disableFlag }}
                />
              </TableCell>

              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "reaction")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.reaction || ""}
                  inputProps={{ readOnly: disableFlag }}
                />
              </TableCell>
              {allergiesRowData.length > 1 && (
                <TableCell style={tableCellStyle}>
                  <RemoveIcon
                    style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                    onClick={() => {
                      if (disableFlag) {
                        return null;
                      }
                      handleRemoveRow(index);
                    }}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginRight: "20px" }}>
          Click on - button to delete respective row.
        </div>
        <div>Click on + button to add 5 Prescription maximum.</div>
      </div>
    </div>
  );
}

export function DynamicMedicationsTable({
  medicationsRowData,
  setMedicationsRowData,
}) {
  const handleAddRow = () => {
    const uniqueId = Date.now().toString();
    setMedicationsRowData([...medicationsRowData, { id: uniqueId }]);
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...medicationsRowData];
    updatedRowData.splice(index, 1);
    setMedicationsRowData(updatedRowData);
  };
  const handleTableDataChange = (e, index, label) => {
    const updatedData = [...medicationsRowData];
    updatedData[index][label] = e.target.value;
    setMedicationsRowData(updatedData);
  };

  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const handleStatusChange = (index, value) => {
    const updatedData = [...medicationsRowData];

    updatedData[index].status = {
      taking: value === "taking" ? "1" : "2",
      notTaking: value === "notTaking" ? "1" : "2",
    };

    setMedicationsRowData(updatedData);
  };

  const labelNameOptions = [
    "Famotidine",
    "Ranitidine",
    "Rabeprazole",
    "Esomprazole",
    "Lansoprazole",
    "Omeprazole",
    "Pantoprazole",
    "Ondansetron",
    "Promethazine",
    "Metoclopramide",
    "Diclofenac",
    "Ibuprofen",
    "Naproxen",
    "Celecoxib",
    "Clobetasol",
    "Hydrocortisone",
    "Methylprednisolone",
    "Prednisolone (Oral)",
    "Prednisone",
    "Morphine ER",
    "Hydrocodone/Acetaminophen",
    "Oxycodone",
    "Tramadol",
    "Sumatriptan",
    "Alendronate",
    "Ibandronate",
    "Baclofen",
    "Carisoprodol",
    "Cyclobenzaprine",
    "Methocarbamol",
    "Tizanidine",
    "Hydroxyzine HCL",
    "Cetirizine",
    "Levocetirizine",
    "Mometasone",
    "Triamcinolone",
    "Montelukast",
    "Budesonide",
    "Fluticasone",
    "Budesonide/Formoterol",
    "Fluticasone/Salmeterol",
    "Albuterol",
    "Levalbuterol",
    "Ipratropium/Albuterol",
    "Tiotropium",
    "Benzonatate",
    "Meclizine",
    "Allopurinol",
    "Zolpidem",
    "Temazepam",
    "Varenicline",
    "Amoxicillin",
    "Penicillin",
    "Amox/Clavulanate",
    "Cefdinir",
    "Cefuroxime",
    "Cephalexin",
    "Minocycline",
    "Doxycycline",
    "Azithromycin",
    "Clarithromycin",
    "Clindamycin",
    "Trimethoprim/Sulfamethoxazole",
    "Ciprofloxacin",
    "Levofloxacin",
    "Ketoconazole",
    "Fluconazole",
    "Nystatin Systemic",
    "Nystatin Topical",
    "Acyclovir",
    "Valacyclovir",
    "Methotrexate",
    "Etanercept",
    "Adalimumab",
    "Hydroxychloroquine",
    "Trazodone",
    "Citalopram",
    "Escitalopram",
    "Fluoxetine",
    "Paroxetine",
    "Sertraline",
    "Duloxetine",
    "Desvenlafaxine",
    "Venlafaxine",
    "Amitriptyline",
    "Nortriptyline",
    "Mirtazepine",
    "Burpropion",
    "Alprazolam",
    "Clonazepam",
    "Diazepam",
    "Lorazepam",
    "Buspirone",
    "Dexmethylphenidate",
    "Lisdexamfetamine",
    "Atomoxetine",
    "Methylphenidate",
    "Modafinil",
    "Lithium",
    "Aripiprazole",
    "Risperidone",
    "Quetiapine",
    "Divalproex",
    "Phenytoin",
    "Gabapentin",
    "Lamotrigine",
    "Levetiracetam",
    "Oxcarbazepine",
    "Pregabalin",
    "Topiramate",
    "Benztropine",
    "Ropinirole",
    "Pramipexole",
    "Carbidopa/Levodopa",
    "Donepezil",
    "Memantine",
    "Potassium",
    "Isosorbide Mononitrate",
    "Nitroglycerin",
    "Digoxin",
    "Amiodarone",
    "Furosemide",
    "Chlorthalidone",
    "Hydrochlorothiazide",
    "Triamterene/HTCZ",
    "Spironolactone",
    "Doxazosin",
    "Terazosin",
    "Clonidine",
    "Propranolol",
    "Atenolol",
    "Metoprolol",
    "Carvedilol",
    "Nebivolol",
    "Hydralazine",
    "Benazepril",
    "Enalapril",
    "Lisinopril",
    "Quinapril",
    "Ramipril",
    "Losartan",
    "Olmesartan",
    "Valsartan",
    "Diltiazem",
    "Verapamil",
    "Amlodipine",
    "Nifedipine",
    "Simvastatin",
    "Lovastatin",
    "Atorvastatin",
    "Pravastatin",
    "Rosuvastatin",
    "Omega 3 Acid Ethyl Esters",
    "Fenofibrate",
    "Gemfibrozil",
    "Ezetimibe",
    "Warfarin",
    "Dabigatran",
    "Apixaban",
    "Rivaroxaban",
    "Enoxaparin",
    "Prasugrel",
    "Ticagrelor",
    "Clopidogrel",
    "Metformin",
    "Sitagliptin",
    "Glimepiride",
    "Glipizide",
    "Pioglitazone",
    "Liraglutide",
    "Insulin Aspart",
    "Insulin Lispro",
    "Insulin Detemir",
    "Insulin Glargine",
    "Levothyroxine",
    "Thyroid",
    "Testosterone",
    "Estradiol",
    "Conjugated Estrogens",
    "Medroxyprogesterone",
    "levocetirizi",
    "alendronate",
    "clonidine",
    "pantoprazole",
    "vitamin d2",
  ];

  const routeOptions = [
    "PO = By Mouth",
    "SQ = Subcutaneous",
    "M = Intramuscular",
    "IV = Intravenous",
    "N = Nasal",
    "R = Rectal",
    "S = Sublingual",
    "E = Eye",
    "T = Topical",
    "EA = Ear",
    "IN = Inhalation",
  ];

  const frequencyOptions = [
    "AC",
    "PC",
    "AC & HS",
    "BID",
    "TID",
    // "Q Month",
    "QID",
    "QAM",
    "QD",
    "QOD",
    "QPM",
    "QW",
    "QOW",
    "HS",
    "PRN",
  ];

  if (!medicationsRowData || medicationsRowData.length === 0) {
    setMedicationsRowData([{ id: Date.now().toString() }]);
  }
  return (
    <div>
      <Table
        sx={{
          tableLayout: "auto",
          margin: "10px",
          overflow: "hidden",
        }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Diagnosis"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Diagnosis{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Label Name"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Label Name{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Dose/Units"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Dose/Units{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Route"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Route{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Frequency"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Frequency{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            {/* <TableCell style={tableCellStyle}>Prescribing Physician</TableCell> */}
            <TableCell style={tableCellStyle}>
              <Tooltip
                TransitionComponent={Zoom}
                title="Status"
                placement="top"
                arrow
                enterDelay={150}
                leaveDelay={100}
              >
                <span>
                  Status{" "}
                  <PiInfo style={{ cursor: "pointer", fontSize: "15px" }} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={handleAddRow}
              />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {medicationsRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ ...tableCellStyle, maxWidth: "80px" }}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "diagnosis")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.diagnosis || ""}
                />
              </TableCell>

              <TableCell style={{ ...tableCellStyle, maxWidth: "180px" }}>
                <Select
                  onChange={(e) => handleTableDataChange(e, index, "labelName")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.labelName || ""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {labelNameOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell style={{ ...tableCellStyle, maxWidth: "120px" }}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "doseOrUnits")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.doseOrUnits || ""}
                />
              </TableCell>

              <TableCell style={{ ...tableCellStyle, maxWidth: "80px" }}>
                <Select
                  onChange={(e) => handleTableDataChange(e, index, "route")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.route || ""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {routeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell style={{ ...tableCellStyle, maxWidth: "50px" }}>
                <Select
                  onChange={(e) => handleTableDataChange(e, index, "frequency")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.frequency || ""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {frequencyOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell style={tableCellStyle}>
                <ThemeProvider theme={myThemes}>
                  <ToggleButtonGroup
                    value={
                      row?.status?.taking === "1"
                        ? "taking"
                        : row?.status?.notTaking === "1"
                        ? "notTaking"
                        : ""
                    }
                    exclusive
                    onChange={(e, value) => handleStatusChange(index, value)}
                  >
                    <ToggleButton value="taking">Taking</ToggleButton>
                    <ToggleButton value="notTaking">Not Taking</ToggleButton>
                  </ToggleButtonGroup>
                </ThemeProvider>
              </TableCell>
              {medicationsRowData.length > 1 && (
                <TableCell style={tableCellStyle}>
                  <RemoveIcon
                    style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                    onClick={() => handleRemoveRow(index)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export function DynamicTableCounterMedications({
  counterMedicationsRowData,
  setCounterMedicationsRowData,
  memberId,
}) {
  const tableCellStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme100)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const tableHeadStyle = {
    fontWeight: "bold",
    backgroundColor: "var(--mainTheme)",
    border: "1px solid var(--mainTheme)",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  const handleAddRow = () => {
    if (
      Array.isArray(counterMedicationsRowData) &&
      counterMedicationsRowData.length < 5
    ) {
      const uniqueId = Date.now().toString();
      setCounterMedicationsRowData([
        ...counterMedicationsRowData,
        { id: uniqueId },
      ]);
    }
  };

  const handleRemoveRow = (index) => {
    const updatedRowData = [...counterMedicationsRowData];
    updatedRowData.splice(index, 1);
    setCounterMedicationsRowData(updatedRowData);
  };

  const handleTableDataChange = (e, index, label) => {
    const updatedRowData = [...counterMedicationsRowData];
    updatedRowData[index][label] = e.target.value;
    setCounterMedicationsRowData(updatedRowData);
  };
  const handleTableDateChange = (newDate, index, label) => {
    if (newDate && dayjs(newDate).isValid()) {
      const updatedRowData = [...counterMedicationsRowData];

      updatedRowData[index][label] = dayjs(newDate).format();

      setCounterMedicationsRowData(updatedRowData);
    }
  };

  const routeOptions = [
    "PO = By Mouth",
    "SQ = Subcutaneous",
    "M = Intramuscular",
    "IV = Intravenous",
    "N = Nasal",
    "R = Rectal",
    "S = Sublingual",
    // "E = Eye",
    // "T = Topical",
    // "EA = Ear",
    // "IN = Inhalation",
  ];

  if (!counterMedicationsRowData || counterMedicationsRowData.length === 0) {
    setCounterMedicationsRowData([{ id: Date.now().toString() }]);
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginLeft: "10px",
        width: "80%",
        margin: "auto",
      }}
    >
      <Table
        sx={{ tableLayout: "auto", margin: "10px", overflow: "hidden" }}
        size="small"
      >
        <TableHead style={tableHeadStyle}>
          <TableRow>
            <TableCell style={tableCellStyle}>Date</TableCell>
            <TableCell style={tableCellStyle}>Description</TableCell>
            <TableCell style={tableCellStyle}>Dose/Units</TableCell>
            <TableCell style={tableCellStyle}>Route</TableCell>
            <TableCell style={tableCellStyle}>Frequency</TableCell>
            <TableCell style={tableCellStyle}>
              <AddIcon
                style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                onClick={handleAddRow}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {counterMedicationsRowData?.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={tableCellStyle}>
                {/* <TextField
                  onChange={(e) => handleTableDataChange(e, index, "date")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.date || ""}
                  type="date"
                  
                  
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      // value={dayjs(row.date) || ""}
                      value={row.date ? dayjs(row.date) : null}
                      // onChange={(e) => handleTableDataChange(e, index, "date")}
                      onChange={(newDate) =>
                        handleTableDateChange(newDate, index, "date")
                      }
                      slotProps={{
                        textField: {
                          readOnly: true,
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </TableCell>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "description")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.description || ""}
                />
              </TableCell>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) =>
                    handleTableDataChange(e, index, "doseOrUnits")
                  }
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.doseOrUnits || ""}
                />
              </TableCell>
              <TableCell style={tableCellStyle}>
                {/* <TextField
                  onChange={(e) => handleTableDataChange(e, index, "route")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.route || ""}
                /> */}
                <Select
                  value={row.route || ""}
                  onChange={(e) => handleTableDataChange(e, index, "route")}
                  fullWidth
                  displayEmpty
                  size="small"
                  variant="standard"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                    style: { maxHeight: 300 },
                  }}
                >
                  <MenuItem value="" disabled>
                    <span>Select</span>
                  </MenuItem>
                  {routeOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell style={tableCellStyle}>
                <TextField
                  onChange={(e) => handleTableDataChange(e, index, "frequency")}
                  fullWidth
                  size="small"
                  variant="standard"
                  value={row.frequency || ""}
                />
              </TableCell>
              {counterMedicationsRowData.length > 1 && (
                <TableCell style={tableCellStyle}>
                  <RemoveIcon
                    style={{ color: "var(--mainTheme)", cursor: "pointer" }}
                    onClick={() => handleRemoveRow(index)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ marginRight: "20px" }}>
          Click on - button to delete respective row.
        </div>
        <div>Click on + button to add 5 Prescription maximum.</div>
      </div>
    </div>
  );
}

function MiniTable({
  rowData,
  setRowData,
  labelText,
  pointsLabel,
  options,
  description,
  inputType = "select",
  disabled = false,
  cellStyles = {},
  disableFlag,
}) {
  const handleTableDataChange = (e, points) => {
    setRowData((prevData) => ({
      ...prevData,
      [points]: e.target.value,
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "10px",
        marginLeft: "10px",
        width: "100%",
        margin: "auto",
      }}
    >
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "10px",
          fontFamily: "Inter, sans-serif",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                padding: "8px",
                width: "20%",
                ...cellStyles.label,
              }}
            >
              {`${labelText} :`}
            </td>
            <td
              style={{
                padding: "8px",
                width: "5%",
                ...cellStyles.points,
              }}
            >
              {inputType === "select" ? (
                <select
                  value={rowData.points || ""}
                  onChange={(e) => handleTableDataChange(e, "points")}
                  disabled={disableFlag}
                  style={{ width: "100%" }}
                >
                  {options.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type="number"
                  id="pointsInput"
                  value={rowData.points || ""}
                  onChange={(e) => handleTableDataChange(e, "points")}
                  disabled={disableFlag || disabled}
                  style={{ width: "100%" }}
                />
              )}
              <label htmlFor="pointsInput">{pointsLabel}</label>
            </td>
            <td
              style={{
                padding: "8px",
                width: "50%",
                textAlign: "left",
                ...cellStyles.description,
              }}
            >
              {description}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export function DynamicWordRecall({
  wordRecallRowData,
  setWordRecallRowData,
  disableFlag,
}) {
  const options = ["-", "0", "1", "2", "3"];
  const wordRecallCellStyles = {
    label: { width: "12%", padding: "8px" },
    points: { width: "5%", padding: "8px" },
    description: { width: "75%", padding: "8px" },
  };

  return (
    <MiniTable
      rowData={wordRecallRowData}
      setRowData={setWordRecallRowData}
      labelText="Word Recall"
      pointsLabel="Points"
      options={options}
      cellStyles={wordRecallCellStyles}
      description={`1 point for each word spontaneously recalled without cueing.`}
      disableFlag={disableFlag}
    />
  );
}

export function DynamicClockDraw({
  clockDrawRowData,
  setClockDrawRowData,
  disableFlag,
}) {
  const options = ["-", "0", "2"];
  const clockDrawCellStyles = {
    label: { width: "12%", padding: "8px" },
    points: { width: "5%", padding: "8px" },
    description: { width: "75%", padding: "8px" },
  };

  return (
    <MiniTable
      rowData={clockDrawRowData}
      setRowData={setClockDrawRowData}
      labelText="Clock Draw"
      pointsLabel="Points"
      options={options}
      cellStyles={clockDrawCellStyles}
      description={`Normal clock = 2 points. A normal clock has all numbers placed in the correct positions  with no missing or duplicate numbers. Hands are pointing to the 11 sequence and approximately correct position (e.g., 12, 3, 6 and 9 are in anchor Inability or refusal to draw a clock (abnormal) = 0 points and 2 (11:10). Hand length is not scored).`}
      disableFlag={disableFlag}
    />
  );
}

export function DynamicTotalScore({ totalScoreRowData, setTotalScoreRowData }) {
  const totalScoreStyles = {
    label: { width: "12%", padding: "8px" },
    points: { width: "5%", padding: "8px" },
    description: { width: "75%", padding: "8px" },
  };
  return (
    <MiniTable
      rowData={totalScoreRowData}
      setRowData={setTotalScoreRowData}
      labelText="Total Score"
      pointsLabel="Points"
      inputType="number"
      disabled={true}
      cellStyles={totalScoreStyles}
      description={`Total score = Word Recall score + Clock Draw score. A cut point of < 3 on the Mini-Cog™ has been validated for dementia screening, but many individuals with clinically meaningful cognitive impairment will score higher. When greater sensitivity is desired, a cut point of < 4 is recommended as it may indicate a need for further evaluation of cognitive status.`}
    />
  );
}
