const json2023 = {
  sectionId: 4,
  sectionName: "Self-Assessment and Social History",
  isEditable: false,
  questions: [
    {
      id: 6,
      name: "self_school",
      type: "options_tree",
      label: "How much school have you completed?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Completed less than 3rd grade",
          name: "lessThanThirdGrade",
          value: "lessThanThirdGrade",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Completed less than 8th grade",
          name: "lessThanEighthGrade",
          value: "lessThanEighthGrade",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Completed less than 12th grade",
          name: "lessThanTwelthGrade",
          value: "lessThanTwelthGrade",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          name: "completedTwelthGrade",
          label: "Completed  12th grade or attended college",
          value: "completedTwelthGrade",
          type: "toggle_button",
        },
      ],
      questionId: 1,
    },
    {
      id: 6,
      name: "self_writtenInfo",
      type: "options_tree",
      label:
        "When you get written information at a doctor's office would you say it is",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Very difficult",
          value: "veryDifficult",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          name: "someWhatDifficult",
          label: "Somewhat difficult",
          value: "someWhatDifficult",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Easy",
          name: "easy",
          value: "easy",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Very easy to understand",
          name: "veryEasy",
          value: "veryEasy",
          type: "toggle_button",
        },
      ],
      questionId: 2,
    },
    {
      id: 6,
      name: "self_presciptionBottle",
      type: "options_tree",
      label:
        "When you read the instructions on a prescription bottle would you say that it is",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Very difficult",
          value: "veryDifficult",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Somewhat difficult",
          value: "someWhatDifficult",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Easy",
          value: "easy",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Very easy to understand",
          value: "veryEasy",
          type: "toggle_button",
        },
      ],
      questionId: 3,
    },
    {
      id: 6,
      name: "self_fillingMedicalForm",
      type: "options_tree",
      label: "How confident are you in filling out medical forms by yourself?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Not at All Confident",
          value: "notAtAllConfident",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Not Very Confident",
          value: "notVeryConfident",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Confident",
          value: "confident",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Very Confident",
          value: "veryConfident",
          type: "toggle_button",
        },
      ],
      questionId: 4,
    },
    {
      id: 6,
      name: "self_ratYourHealth",
      type: "options_tree",
      label:
        "How would you rate your health compared to other persons your age?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Excellent",
          value: "excellent",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Good",
          value: "good",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Fair",
          value: "fair",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Poor",
          value: "poor",
          type: "toggle_button",
        },
      ],
      questionId: 5,
    },
    {
      id: 6,
      name: "self_socialActivities",
      type: "options_tree",
      label:
        "During past 3 months, has your physical and or emotional health limited your social activities with family, friends, neighbours or groups?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Often",
          value: "often",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Sometimes",
          value: "sometimes",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Almost Never",
          value: "almostNever",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never",
          value: "never",
          type: "toggle_button",
        },
      ],
      questionId: 6,
    },
    {
      id: 6,
      name: "self_currentLive",
      type: "options_tree",
      label: "Where do you currently live?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Home",
          value: "home",
          name: "home",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Apartment",
          value: "apartment",
          name: "apartment",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Assisted Living",
          value: "assistedLiving",
          name: "assistedLiving",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Nursing Home",
          value: "nursingHome",
          name: "nursingHome",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_currentLiveHomelessSubDesc",
              type: "text",
              value: "descibe",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: true,
          label: "Homeless",
          value: "homeless",
          name: "homeless",
          type: "toggle_button",
        },

        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_currentLiveOtherSubDesc",
              type: "text",
              value: "descibe",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
              disabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Other",
          value: "other",
          type: "toggle_button",
        },
      ],
      questionId: 7,
    },
    {
      id: 6,
      name: "self_relyOn",
      type: "options_tree",
      label:
        "Do you have someone you can rely on to help if you are sick or have problems you need to discuss?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          name: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          name: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 8,
    },
    {
      id: 6,
      name: "self_currentlyLive",
      type: "options_tree",
      label: "Who do you currently live with?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_currentlyLiveAloneSubDesc",
              value: "descibe",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Alone",
          value: "alone",
          name: "alone",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Spouse",
          value: "spouse",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Partner",
          value: "partner",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Relative",
          value: "relative",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Family",
          value: "family",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Friend",
          value: "friend",
          type: "toggle_button",
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Personal Care Worker",
          value: "personalCareWorker",
          type: "toggle_button",
        },
      ],
      questionId: 9,
    },
    {
      id: 6,
      name: "self_careGiver",
      type: "options_tree",
      label: "Are you currently a caregiver for someone?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_careGiverSubDesc",
              type: "text",
              label: "Describe",
              value: "describe",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 10,
    },
    {
      id: 6,
      name: "self_currentlyEmploy",
      type: "options_tree",
      label: "Are you currently employed?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 11,
    },
    {
      id: 6,
      name: "self_interestEmploy",
      type: "options_tree",
      label: "Are you interested in employment?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 12,
    },
    {
      id: 6,
      name: "self_volunCurrently",
      type: "options_tree",
      label: "Do you volunteer currently?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 13,
    },
    {
      id: 6,
      name: "self_tobUse",
      type: "options_tree",
      label: "Tobacco Use",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "self_tobUseSubCurrSubType",
              type: "options_tree",
              label: "Type",
              value: "type",

              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "self_tobUseSubCurrSubTypeCigaretteSubHowMany",
                      type: "options_tree",
                      label: "How many",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1-3 a day",
                          value: "oneToThree",
                          name: "oneToThree",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1/2 a pack",
                          value: "halfAPack",
                          name: "halfAPack",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1 pack",
                          value: "onePack",
                          name: "onePack",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "More than 1 pack",
                          value: "moreThanOne",
                          name: "moreThanOne",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "self_tobUseSubCurrSubTypeCigaretteSubHowManyOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              value: "decribe",
                              xs: 12,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          name: "other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: true,
                  label: "Cigarettes",
                  value: "cigarettes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: true,
                  label: "Cigars",
                  value: "cigars",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Chewing Tobacco",
                  value: "chewingTobacco",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Vaping",
                  value: "vaping",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "self_tobUseSubCurrSubTypeOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 10,
              name: "self_tobUseSubCurrSubSmokeCess",
              label: "",
              value: "",
              type: "checkbox",
              options: [
                [
                  "Discussed smoking cessation options, member verbalized understanding",
                  "smokingCessation",
                ],
              ],
              xs: 12,
              isCommentEnabled: true,
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Current",
          name: "current",
          value: "current",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "self_tobUseSubFormerSubWhen",
              type: "options_tree",
              label: "When",

              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Stopped within the last year",
                  value: "withinLastYear",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Stopped within the last 3 years",
                  value: "withinLastThreeYear",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Stopped 5 or more years ago",
                  value: "moreYearAgo",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "self_tobUseSubFormerSubType",
              type: "options_tree",
              label: "Type",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "self_tobUseSubFormerSubTypeCigaretteSubHowMany",
                      type: "options_tree",
                      label: "How many",

                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1-3  a day",
                          value: "oneToThree",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1/2 a pack",
                          value: "halfAPack",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1 pack",
                          value: "onePack",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "More than 1 pack",
                          value: "moreThanOne",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "self_tobUseSubFormerSubTypeCigaretteSubHowManyOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 12,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Cigarettes",
                  value: "cigarettes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cigars",
                  value: "cigars",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Chewing Tobacco",
                  value: "chewingTobacco",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Vaping",
                  value: "vaping",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "self_tobUseSubFormerSubTypeOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Former",
          value: "former",
          name: "former",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never",
          value: "never",
          name: "never",
          type: "toggle_button",
        },
      ],
      questionId: 14,
    },
    {
      id: 6,
      name: "self_alcUse",
      type: "options_tree",
      label: "Alcohol Use",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_alcUseCurrSubHowMany",
              value: "",
              type: "select",
              label: "How many drinks",
              options: [
                ["Less than 1", "1"],
                ["1", "2"],
                ["2", "3"],
                ["3", "4"],
                ["4", "5"],
                ["5-8", "6"],
                ["9-12", "7"],
                ["13-20", "8"],
                ["More than 20", "9"],
              ],
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 1,
              name: "self_alcUseCurrSubHowOften",
              value: "",
              type: "select",
              label: "How often",
              options: [
                ["Day", "1"],
                ["Week", "2"],
                ["Month", "3"],
                ["Year", "4"],
              ],
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Current",
          value: "current",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_alcUseFormerSubHowMany",
              value: "",
              type: "select",
              label: "How many drinks",
              options: [
                ["Less than 1", "1"],
                ["1", "2"],
                ["2", "3"],
                ["3", "4"],
                ["4", "5"],
                ["5-8", "6"],
                ["9-12", "7"],
                ["13-20", "8"],
                ["More than 20", "9"],
              ],
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 1,
              name: "self_alcUseFormerSubHowOften",
              value: "",
              type: "select",
              label: "How often",
              options: [
                ["Day", "1"],
                ["Week", "2"],
                ["Month", "3"],
                ["Year", "4"],
              ],
              xs: 4,
              isCommentEnabled: false,
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Former",
          value: "former",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never",
          value: "never",
          type: "toggle_button",
        },
      ],
      questionId: 15,
    },
    {
      id: 6,
      name: "self_recreaDrugs",
      type: "options_tree",
      label: "Do you or have you used recreational drugs?",
      value: "recreationalDrugs",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_recreaDrugsSubWhich",
              value: "whichDrugs",
              type: "text",
              label: "Which drugs",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 16,
    },
    {
      id: 6,
      name: "self_healthProxy",
      value: "healthcareProxy",
      type: "options_tree",
      label: "Do you have a Healthcare Proxy?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_healthProxySubName",
              type: "text",
              label: "Name",
              value: "name",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 1,
              name: "self_healthProxySubRelation",
              type: "text",
              label: "Relationship",
              value: "relationship",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Don't Know",
          value: "dontKnow",
          type: "toggle_button",
        },
      ],
      questionId: 17,
    },
    {
      id: 6,
      name: "self_powerAttny",
      type: "options_tree",
      value: "powerAttorney",
      label: "Do you have a Durable Power of Attorney?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_powerAttnySubName",
              type: "text",
              label: "Name",
              value: "name",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 1,
              name: "self_powerAttnySubRelation",
              type: "text",
              label: "Relationship",
              value: "relationship",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Don't Know",
          value: "dontKnow",
          type: "toggle_button",
        },
      ],
      questionId: 18,
    },
    {
      id: 6,
      name: "self_advDirect",
      type: "options_tree",
      value: "advanceDirective",
      label: "Do you have an Advance Directive?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_advDirectSubKept",
              type: "text",
              value: "kept",
              label: "Where is it kept?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Don't Know",
          value: "dontKnow",
          type: "toggle_button",
        },
      ],
      questionId: 19,
    },
    {
      id: 10,
      name: "self_eduAdvPlan",
      value: "educatedOnAdvanceCarePlanning",
      label: " ",
      type: "checkbox",
      options: [
        [
          "Member educated on advance care planning",
          "educatedOnAdvanceCarePlanning",
        ],
      ],
      variant: 2,
      xs: 12,
      isCommentEnabled: true,
      questionId: 20,
    },
    {
      id: 10,
      name: "self_declineDisc",
      value: "declinesDiscussion",
      label: " ",
      type: "checkbox",
      options: [["Declines discussion at this time", "declinesDiscussion"]],
      variant: 2,
      xs: 12,
      isCommentEnabled: true,
      questionId: 21,
    },
    {
      id: 6,
      name: "self_foodRunOut",
      type: "options_tree",
      value: "foodWouldRunOut",
      label:
        "Within the past 12 months we worried whether our food would run out before we got money to buy more.\n Was that ______ for your household?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Often True",
          value: "oftenTrue",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Sometimes True",
          value: "sometimesTrue",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never True",
          value: "neverTrue",
          type: "toggle_button",
        },
      ],
      questionId: 22,
    },
    {
      id: 6,
      name: "self_foodBought",
      type: "options_tree",
      value: "foodWeBought",
      label:
        "Within the past 12 months the food we bought just didn't last and we didn't have money to get more.\n Was that ______ for your household?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Often True",
          value: "oftenTrue",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Sometimes True",
          value: "sometimesTrue",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never True",
          value: "neverTrue",
          type: "toggle_button",
        },
      ],
      questionId: 23,
    },
    {
      id: 10,
      name: "self_Recommendations",
      label: "Recommendations",
      value: "smokingOrTobacco",
      type: "checkbox",
      options: [
        ["Smoking/Tobacco", "smokingOrTobacco"],
        ["Substance Abuse", "substanceAbuse"],
        ["Durable power of attorney", "durablePower"],
        ["Healthcare Proxy", "healthcareProxy"],
        ["Advance Directive", "advancedDirective"],
        ["Food Disparity", "foodDisparity"],
        ["Literacy", "literacy"],
        ["Social support evaluation", "socialSupport"],
      ],
      xs: 12,
      isCommentEnabled: true,
      questionId: 24,
    },
  ],
};

const json2024 = {
  sectionId: 4,
  sectionName: "Self-Assessment and Social History 2024",
  isEditable: false,
  questions: [
    {
      id: 9,
      name: "literacy",
      type: "heading",
      label: "Literacy",
      xs: 12,
      isCommentEnabled: false,
      questionId: 1,
    },
    {
      id: 6,
      name: "self_school",
      type: "options_tree",
      label: "How much school have you completed?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Completed less than 3rd grade",
          name: "lessThanThirdGrade",
          value: "lessThanThirdGrade",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Completed less than 8th grade",
          name: "lessThanEighthGrade",
          value: "lessThanEighthGrade",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Completed less than 12th grade",
          name: "lessThanTwelthGrade",
          value: "lessThanTwelthGrade",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          name: "completedTwelthGrade",
          label: "Completed  12th grade",
          value: "completedTwelthGrade",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          name: "attendedOrgraduated",
          label: "Attended/Graduated College",
          value: "attendedOrgraduated",
          type: "toggle_button",
        },
      ],
      questionId: 2,
    },
    {
      id: 6,
      name: "self_fillingMedicalForm",
      type: "options_tree",
      label: "How confident are you in filling out medical forms by yourself?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Not at All Confident",
          value: "notAtAllConfident",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Not Very Confident",
          value: "notVeryConfident",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Confident",
          value: "confident",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Very Confident",
          value: "veryConfident",
          type: "toggle_button",
        },
      ],
      questionId: 3,
    },
    {
      id: 6,
      name: "self_writtenInfo",
      type: "options_tree",
      label:
        "When you get written information at a doctor's office would you say it is",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Very difficult",
          value: "veryDifficult",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          name: "someWhatDifficult",
          label: "Somewhat difficult",
          value: "someWhatDifficult",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Easy",
          name: "easy",
          value: "easy",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Very easy to understand",
          name: "veryEasy",
          value: "veryEasy",
          type: "toggle_button",
        },
      ],
      questionId: 4,
    },
    {
      id: 6,
      name: "self_presciptionBottle",
      type: "options_tree",
      label:
        "When you read the instructions on a prescription bottle would you say that it is",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Very difficult",
          value: "veryDifficult",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Somewhat difficult",
          value: "someWhatDifficult",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Easy",
          value: "easy",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Very easy to understand",
          value: "veryEasy",
          type: "toggle_button",
        },
      ],
      questionId: 5,
    },
    {
      id: 9,
      name: "housing",
      type: "heading",
      label: "Housing & Transportation",
      xs: 12,
      isCommentEnabled: false,
      questionId: 6,
    },
    {
      questionId: 7,
      name: "self_currLiveSituation",
      label:
        "Choose the statement below that best describes your current living situation",
      type: "radio",
      isColumn: true,
      options: [
        ["I have a steady place to live", "1"],
        [
          "I have a place to live today, but I am worried about losing it in the future",
          "2",
        ],
        [
          "I do not have a steady place to live (I am temporarily staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car, abandoned building, bus or train station, or in a park) ",
          "3",
        ],
      ],
      xs: 12,
      isCommentEnabled: true,
    },
    {
      id: 6,
      name: "self_currentLive",
      type: "options_tree",
      label: "Where do you currently live?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Home",
          value: "home",
          name: "home",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Apartment",
          value: "apartment",
          name: "apartment",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Assisted Living",
          value: "assistedLiving",
          name: "assistedLiving",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Nursing Home",
          value: "nursingHome",
          name: "nursingHome",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_currentLiveHomelessSubDesc",
              type: "text",
              value: "descibe",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: true,
          label: "Homeless",
          value: "homeless",
          name: "homeless",
          type: "toggle_button",
        },

        {
          subQuestionId: 6,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_currentLiveOtherSubDesc",
              type: "text",
              value: "descibe",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
              disabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Other",
          value: "other",
          type: "toggle_button",
        },
      ],
      questionId: 8,
    },
    {
      id: 6,
      name: "self_thinkPlace",
      type: "options_tree",
      label:
        "Think about the place you live. Do you have problems with any of the following? (Choose all that apply)",
      xs: 12,
      isMulti: true,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Pests such as bugs,ants or mice",
          value: "bugsAntsMice",
          name: "bugsAntsMice",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Mold",
          value: "mold",
          name: "mold",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Lead Paint or Pipes",
          value: "leadPaintOrPipes",
          name: "leadPaintOrPipes",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Lack of Heat",
          value: "lackOfHeat",
          name: "lackOfHeat",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: true,
          label: "Oven or Stove not working",
          value: "ovenOrStove",
          name: "ovenOrStove",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Water Leaks",
          value: "waterLeaks",
          type: "toggle_button",
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None of the above",
          value: "none",
          type: "toggle_button",
        },
      ],
      questionId: 9,
    },
    {
      id: 6,
      name: "self_currentlyLive",
      type: "options_tree",
      label: "Who do you currently live with?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_currentlyLiveAloneSubDesc",
              value: "descibe",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Alone",
          value: "alone",
          name: "alone",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Spouse",
          value: "spouse",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Partner",
          value: "partner",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Relative",
          value: "relative",
          type: "toggle_button",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Family",
          value: "family",
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Friend",
          value: "friend",
          type: "toggle_button",
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Personal Care Worker",
          value: "personalCareWorker",
          type: "toggle_button",
        },
      ],
      questionId: 10,
    },
    {
      id: 6,
      name: "self_last12Months",
      type: "options_tree",
      label:
        "In the last 12 months have you had difficulty paying your utility bills?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 5,
              name: "self_last12MonthsSubDesc",
              label: "Select",
              defaultValue: "",
              type: "select",
              options: [
                ["I didn't have enough money to pay my utility bills", "1"],
                [
                  "I received government subsidies to assist with my utility bills",
                  "2",
                ],
                ["Utilities were disconnected", "3"],
                ["Other", "4"],
              ],
              xs: 6,
              isCommentEnabled: true,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          name: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          name: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 11,
    },
    {
      id: 6,
      name: "self_dailyLiving",
      type: "options_tree",
      label:
        "Has lack of reliable transportation kept you from medical appointments, meetings, work or from getting things needed for daily living?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          name: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          name: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 12,
    },
    {
      id: 9,
      name: "social",
      type: "heading",
      label: "Social",
      xs: 12,
      isCommentEnabled: false,
      questionId: 13,
    },
    {
      id: 6,
      name: "self_ratYourHealth",
      type: "options_tree",
      label:
        "How would you rate your health compared to other persons your age?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Excellent",
          value: "excellent",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Good",
          value: "good",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Fair",
          value: "fair",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Poor",
          value: "poor",
          type: "toggle_button",
        },
      ],
      questionId: 14,
    },
    {
      id: 6,
      name: "self_relyOn",
      type: "options_tree",
      label:
        "Do you have someone you can rely on to help if you are sick or have problems you need to discuss?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          name: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          name: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 15,
    },
    {
      id: 6,
      name: "self_socialActivities",
      type: "options_tree",
      label:
        "During past 3 months, has your physical and or emotional health limited your social activities with family, friends, neighbors or groups?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Often",
          value: "often",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Sometimes",
          value: "sometimes",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Almost Never",
          value: "almostNever",
          type: "toggle_button",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never",
          value: "never",
          type: "toggle_button",
        },
      ],
      questionId: 16,
    },
    {
      id: 6,
      name: "self_goOut",
      type: "options_tree",
      label: "How often do you go out to meet with family or friends",
      fullWidth: true,
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Often",
          value: "often",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Sometimes",
          value: "sometimes",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never",
          value: "never",
          type: "toggle_button",
        },
      ],
      questionId: 17,
    },
    {
      id: 6,
      name: "self_careGiver",
      type: "options_tree",
      label: "Are you currently a caregiver for someone?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_careGiverSubDesc",
              type: "text",
              label: "Describe",
              value: "describe",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 18,
    },
    {
      id: 9,
      name: "employment",
      type: "heading",
      label: "Employment",
      xs: 12,
      isCommentEnabled: false,
      questionId: 19,
    },
    {
      id: 6,
      name: "self_currentlyEmploy",
      type: "options_tree",
      label: "Are you currently employed?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 20,
    },
    {
      id: 6,
      name: "self_interestEmploy",
      type: "options_tree",
      label: "Are you interested in employment?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 21,
    },
    {
      id: 6,
      name: "self_volunCurrently",
      type: "options_tree",
      label: "Do you volunteer currently?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 22,
    },
    {
      id: 9,
      name: "substanceUse",
      type: "heading",
      label: "Substance Use",
      xs: 12,
      isCommentEnabled: false,
      questionId: 23,
    },
    {
      id: 6,
      name: "self_tobUse",
      type: "options_tree",
      label: "Tobacco Use",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "self_tobUseSubCurrSubType",
              type: "options_tree",
              label: "Type",
              value: "type",

              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "self_tobUseSubCurrSubTypeCigaretteSubHowMany",
                      type: "options_tree",
                      label: "How many",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1-3 a day",
                          value: "oneToThree",
                          name: "oneToThree",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1/2 a pack",
                          value: "halfAPack",
                          name: "halfAPack",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1 pack",
                          value: "onePack",
                          name: "onePack",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "More than 1 pack",
                          value: "moreThanOne",
                          name: "moreThanOne",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "self_tobUseSubCurrSubTypeCigaretteSubHowManyOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              value: "",
                              xs: 12,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          name: "other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 5,
                      name: "self_tobUseSubCurrSubTypeCigaretteSubInterestSmoke",
                      label: "Are you interested in smoking cessation?",
                      defaultValue: "",
                      type: "select",
                      options: [
                        ["I want to work on it.", "1"],
                        ["I am already working on it but need some help", "2"],
                        [
                          "I am already working on it and don't need any help",
                          "3",
                        ],
                        ["I am not interested at this time", "4"],
                      ],
                      xs: 6,
                      isCommentEnabled: true,
                      subQuestionId: 2,
                    },
                    {
                      subQuestionId: 3,
                      label:
                        "Are there things preventing you from making changes?",
                      value: "",
                      type: "options_tree",
                      xs: 12,
                      isMulti: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          label: "Education Needed",
                          type: "toggle_button",
                          value: "educationNeeded",
                          hasSubQuestions: false,
                          subQuestions: [{}],
                        },
                        {
                          subQuestionId: 2,
                          label: "Social Support",
                          type: "toggle_button",
                          value: "socialSupport",
                          hasSubQuestions: false,
                          subQuestions: [{}],
                        },
                        {
                          subQuestionId: 3,
                          label: "Environmental Factors",
                          type: "toggle_button",
                          value: "environmentalFactors",
                          hasSubQuestions: false,
                          subQuestions: [{}],
                        },
                        {
                          subQuestionId: 4,
                          label: "Stress",
                          type: "toggle_button",
                          value: "stress",
                          hasSubQuestions: false,
                          subQuestions: [{}],
                        },
                        {
                          subQuestionId: 5,
                          label: "Self Confidence",
                          type: "toggle_button",
                          value: "selfConfidence",
                          hasSubQuestions: false,
                          subQuestions: [{}],
                        },
                        {
                          subQuestionId: 8,
                          label: "Lack of Motivation",
                          type: "toggle_button",
                          value: "lackOfMotivation",
                          hasSubQuestions: true,
                          subQuestions: [{}],
                        },
                        {
                          subQuestionId: 9,
                          label: "Not a Priority at this time",
                          type: "toggle_button",
                          value: "notAPriority",
                          hasSubQuestions: true,
                          subQuestions: [{}],
                        },
                        {
                          subQuestionId: 6,
                          label: "Other",
                          type: "toggle_button",
                          value: "other",
                          hasSubQuestions: false,
                          subQuestions: [{}],
                        },
                        {
                          subQuestionId: 7,
                          label: "None",
                          type: "toggle_button",
                          value: "none",
                          hasSubQuestions: false,
                          subQuestions: [{}],
                        },
                      ],
                      isCommentEnabled: true,
                      name: "self_tobUseSubCurrSubTypeCigaretteSubThingsPrevent",
                    },
                  ],
                  isCommentEnabled: true,
                  label: "Cigarettes",
                  value: "cigarettes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: true,
                  label: "Cigars",
                  value: "cigars",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Chewing Tobacco",
                  value: "chewingTobacco",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Vaping",
                  value: "vaping",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "self_tobUseSubCurrSubTypeOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 10,
              name: "self_tobUseSubCurrSubSmokeCess",
              label: "",
              value: "",
              type: "checkbox",
              options: [
                [
                  "Discussed smoking cessation options, member verbalized understanding",
                  "smokingCessation",
                ],
              ],
              xs: 12,
              isCommentEnabled: true,
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Current",
          name: "current",
          value: "current",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "self_tobUseSubFormerSubWhen",
              type: "options_tree",
              label: "When",

              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Stopped within the last year",
                  value: "withinLastYear",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Stopped within the last 3 years",
                  value: "withinLastThreeYear",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Stopped 5 or more years ago",
                  value: "moreYearAgo",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "self_tobUseSubFormerSubType",
              type: "options_tree",
              label: "Type",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "self_tobUseSubFormerSubTypeCigaretteSubHowMany",
                      type: "options_tree",
                      label: "How many",

                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1-3  a day",
                          value: "oneToThree",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1/2 a pack",
                          value: "halfAPack",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1 pack",
                          value: "onePack",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "More than 1 pack",
                          value: "moreThanOne",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "self_tobUseSubFormerSubTypeCigaretteSubHowManyOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              xs: 12,
                              isCommentEnabled: false,
                              subQuestionId: 1,
                            },
                          ],
                          isCommentEnabled: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Cigarettes",
                  value: "cigarettes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Cigars",
                  value: "cigars",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Chewing Tobacco",
                  value: "chewingTobacco",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Vaping",
                  value: "vaping",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "self_tobUseSubFormerSubTypeOtherSubDesc",
                      type: "text",
                      label: "Describe",
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Former",
          value: "former",
          name: "former",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never",
          value: "never",
          name: "never",
          type: "toggle_button",
        },
      ],
      questionId: 24,
    },
    {
      id: 6,
      name: "self_alcUse",
      type: "options_tree",
      label: "Have you ever consumed Alcohol?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "self_alcUseCurrSubHowMany",
              type: "options_tree",
              label:
                "How many times in the past year have you had 4-5 drinks or more in a day?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "None",
                  value: "isNone",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 9,
                      name: "auditScore",
                      type: "heading",
                      label:
                        "Alcohol Use Disorders Identification Test-Consumption (AUDIT-C)",
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "self_alcUseCurrSubHowManyOneMoreSubHowOften",
                      type: "options_tree",
                      label:
                        "How often do you have a drink containing alcohol?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Never (+0)",
                          value: "never",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Monthly or less (+1)",
                          value: "monthly",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "2 to 4 times a month (+2)",
                          value: "twoTimesMonth",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "2 to 3 times a week (+3)",
                          value: "twoTimesWeek",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "4 or more times a week (+4)",
                          value: "fourTimesWeek",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "self_alcUseCurrSubHowManyOneMoreSubHowMany",
                      type: "options_tree",
                      label:
                        "How many standard drinks containing alcohol do you have on a typical day?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1 or 2 (+0)",
                          value: "oneOrTwo",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "3 or 4 (+1)",
                          value: "threeOrFour",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "5 or 6 (+2)",
                          value: "fiveOrSix",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "7,8 or 9 (+3)",
                          value: "sevenEight",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "10 or more (+4)",
                          value: "tenOrMore",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "self_alcUseCurrSubHowManyOneMoreSubSixMore",
                      type: "options_tree",
                      label:
                        "How often do you have six or more drinks on one occassion?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Never (+0)",
                          value: "never",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Less than Monthly (+1)",
                          value: "lessThanMonthly",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Monthly (+2)",
                          value: "monthly",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Weekly (+3)",
                          value: "weekly",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Daily or almost daily (+4)",
                          value: "daily",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                    {
                      id: 1,
                      name: "self_alcUseCurrSubHowManyOneMoreSubAuditScore",
                      type: "text",
                      label: "Audit C Score",
                      disabled: true,
                      xs: 3,
                      isCommentEnabled: false,
                      subQuestionId: 5,
                    },
                    {
                      id: 1,
                      name: "self_alcUseCurrentSubHowManyDrinksSubScoreWhiteSpace",
                      type: "label",
                      labelType: "error",
                      label: (
                        <p style={{ marginTop: "-33px", marginLeft: "40px" }}>
                          Men: a score of 4 or more is considered positive{" "}
                          <br />
                          <br />
                          Women: a score of 3 or more is considered positive
                        </p>
                      ),
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 6,
                    },
                    {
                      id: 1,
                      name: "self_alcUseCurrSubHowManyOneMoreSubScoreComment",
                      type: "text",
                      label: "Comment",
                      xs: 8,
                      isCommentEnabled: false,
                      subQuestionId: 7,
                    },
                    {
                      id: 1,
                      name: "self_alcUseCurrSubHowManyOneMoreSubWeeklyAverage",
                      type: "text",
                      label:
                        "Weekly Average? (how many drinks x how many days a week)",
                      xs: 8,
                      isCommentEnabled: false,
                      isVisible:
                        "self_alcUseCurrSubHowManyOneMoreSubAuditScore",
                      subQuestionId: 8,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "1 or more",
                  value: "oneOrMore",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "self_alcUseCurrSubCurrRecovery",
              type: "options_tree",
              label:
                "Are you currently in recovery for alcohol or substance use?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Current",
          value: "current",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "self_alcUseFormerSubHowMany",
              type: "options_tree",
              label:
                "How many times in the past year have you had 4-5 drinks or more in a day?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "None",
                  value: "isNone",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 9,
                      name: "auditScore",
                      type: "heading",
                      label:
                        "Alcohol Use Disorders Identification Test-Consumption (AUDIT-C)",
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "self_alcUseFormerSubHowManyOneMoreSubHowOften",
                      type: "options_tree",
                      label:
                        "How often do you have a drink containing alcohol?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Never (+0)",
                          value: "never",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Monthly or less (+1)",
                          value: "monthly",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "2 to 4 times a month (+2)",
                          value: "twoTimesMonth",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "2 to 3 times a week (+3)",
                          value: "twoTimesWeek",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "4 or more times a week (+4)",
                          value: "fourTimesWeek",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 2,
                    },
                    {
                      id: 6,
                      name: "self_alcUseFormerSubHowManyOneMoreSubHowMany",
                      type: "options_tree",
                      label:
                        "How many standard drinks containing alcohol do you have on a typical day?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "1 or 2 (+0)",
                          value: "oneOrTwo",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "3 or 4 (+1)",
                          value: "threeOrFour",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "5 or 6 (+2)",
                          value: "fiveOrSix",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "7,8 or 9 (+3)",
                          value: "sevenEight",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "10 or more (+4)",
                          value: "tenOrMore",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 3,
                    },
                    {
                      id: 6,
                      name: "self_alcUseFormerSubHowManyOneMoreSubSixMore",
                      type: "options_tree",
                      label:
                        "How often do you have six or more drinks on one occassion?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Never (+0)",
                          value: "never",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Less than Monthly (+1)",
                          value: "lessThanMonthly",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: true,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Monthly (+2)",
                          value: "monthly",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Weekly (+3)",
                          value: "weekly",
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Daily or almost daily (+4)",
                          value: "daily",
                          type: "toggle_button",
                        },
                      ],
                      subQuestionId: 4,
                    },
                    {
                      id: 1,
                      name: "self_alcUseFormerSubHowManyOneMoreSubAuditScore",
                      type: "text",
                      label: "Audit C Score",
                      disabled: true,
                      xs: 3,
                      isCommentEnabled: false,
                      subQuestionId: 5,
                    },
                    {
                      id: 1,
                      name: "self_alcUseFormerSubHowManyDrinksSubScoreWhiteSpace",
                      type: "label",
                      labelType: "error",
                      // label:
                      //   "Men: a score of 4 or more is considered positive, Women: a score of 3 or more is considered positive",
                      label: (
                        <p style={{ marginTop: "-33px", marginLeft: "40px" }}>
                          Men: a score of 4 or more is considered positive{" "}
                          <br />
                          <br />
                          Women: a score of 3 or more is considered positive
                        </p>
                      ),
                      xs: 12,
                      isCommentEnabled: false,
                      subQuestionId: 6,
                    },
                    {
                      id: 1,
                      name: "self_alcUseFormerSubHowManyOneMoreSubScoreComment",
                      type: "text",
                      label: "Comment",
                      xs: 6,
                      isCommentEnabled: false,
                      subQuestionId: 7,
                    },
                    {
                      id: 1,
                      name: "self_alcUseFormerSubHowManyOneMoreSubWeeklyAverage",
                      type: "text",
                      label:
                        "Weekly Average? (how many drinks x how many days a week)",
                      xs: 6,
                      isCommentEnabled: false,
                      isVisible:
                        "self_alcUseFormerSubHowManyOneMoreSubAuditScore",
                      subQuestionId: 8,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "1 or more",
                  value: "oneOrMore",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "self_alcUseFormerSubCurrRecovery",
              type: "options_tree",
              label:
                "Are you currently in recovery for alcohol or substance use?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Former",
          value: "former",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never",
          value: "never",
          type: "toggle_button",
        },
      ],
      questionId: 25,
    },
    {
      id: 10,
      name: "self_educatedOnAlcoholUse",
      value: "educatedOnAlcoholUse",
      label: " ",
      type: "checkbox",
      options: [
        ["Education provided regarding alcohol use", "educatedOnAlcoholUse"],
      ],
      variant: 2,
      xs: 12,
      isCommentEnabled: true,
      questionId: 26,
    },
    {
      id: 1,
      name: "self_educGuidelines",
      type: "label",
      label: "Education Guidelines",
      xs: 6,
      isCommentEnabled: false,
      questionId: 27,
    },
    {
      id: 6,
      name: "self_eduGuide",
      type: "options_tree",
      label:
        "How many times in the past year have you used a recreational drug or used a prescription medication for nonmedical reasons?",
      value: "recreationalDrugs",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "isNone",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 10,
              name: "self_eduGuideOneMoreSubEduResource",
              value: "educatedOnRecDrugsUse",
              label: "",
              type: "checkbox",
              options: [
                [
                  "Education resources provided to the member regarding substance use",
                  "educatedOnRecDrugsUse",
                ],
              ],
              variant: 2,
              xs: 12,
              isCommentEnabled: true,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "1 or more",
          value: "oneOrMore",
          type: "toggle_button",
        },
      ],
      questionId: 28,
    },
    {
      id: 9,
      name: "advancedCarePlanning",
      type: "heading",
      label: "Advanced Care Planning",
      xs: 12,
      isCommentEnabled: false,
      questionId: 29,
    },
    {
      id: 6,
      name: "self_healthProxy",
      value: "healthcareProxy",
      type: "options_tree",
      label: "Do you have a Healthcare Proxy?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_healthProxySubName",
              type: "text",
              label: "Name",
              value: "name",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 1,
              name: "self_healthProxySubRelation",
              type: "text",
              label: "Relationship",
              value: "relationship",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Don't Know",
          value: "dontKnow",
          type: "toggle_button",
        },
      ],
      questionId: 30,
    },
    {
      id: 6,
      name: "self_powerAttny",
      type: "options_tree",
      value: "powerAttorney",
      label: "Do you have a Durable Power of Attorney?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_powerAttnySubName",
              type: "text",
              label: "Name",
              value: "name",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 1,
              name: "self_powerAttnySubRelation",
              type: "text",
              label: "Relationship",
              value: "relationship",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Don't Know",
          value: "dontKnow",
          type: "toggle_button",
        },
      ],
      questionId: 31,
    },
    {
      id: 6,
      name: "self_advDirect",
      type: "options_tree",
      value: "advanceDirective",
      label: "Do you have an Advance Directive?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "self_advDirectSubKept",
              type: "text",
              value: "kept",
              label: "Where is it kept?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Don't Know",
          value: "dontKnow",
          type: "toggle_button",
        },
      ],
      questionId: 32,
    },
    {
      id: 10,
      name: "self_eduAdvPlan",
      value: "educatedOnAdvanceCarePlanning",
      label: " ",
      type: "checkbox",
      options: [
        [
          "Member educated on advance care planning",
          "educatedOnAdvanceCarePlanning",
        ],
      ],
      variant: 2,
      xs: 12,
      isCommentEnabled: true,
      questionId: 33,
    },
    {
      id: 10,
      name: "self_declineDisc",
      value: "declinesDiscussion",
      label: " ",
      type: "checkbox",
      options: [["Declines discussion at this time", "declinesDiscussion"]],
      variant: 2,
      xs: 12,
      isCommentEnabled: true,
      questionId: 34,
    },
    {
      id: 9,
      name: "food",
      type: "heading",
      label: "Food",
      xs: 12,
      isCommentEnabled: false,
      questionId: 35,
    },
    {
      id: 6,
      name: "self_foodRunOut",
      type: "options_tree",
      value: "foodWouldRunOut",
      label:
        "Within the past 12 months we worried whether our food would run out before we got money to buy more.\n Was that ______ for your household?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "self_foodRunOutOftenTrueSubSelect",
              type: "options_tree",
              value: "self_foodRunOutOften",
              label: "Select",
              xs: 12,
              isMulti: true,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "The food we bought just didn't last",
                  value: "foodWeBought",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "We didn't have money to get more",
                  value: "dontHaveMoney",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Often True",
          value: "oftenTrue",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "self_foodRunOutSomeTrueSubSelect",
              type: "options_tree",
              value: "self_foodRunOutOneMore",
              label: "Select",
              xs: 12,
              isMulti: true,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "The food we bought just didn't last",
                  value: "foodWeBought",
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "We didn't have money to get more",
                  value: "dontHaveMoney",
                  type: "toggle_button",
                },
              ],
              subQuestionId: 1,
            },
          ],
          isCommentEnabled: false,
          label: "Sometimes True",
          value: "sometimesTrue",
          type: "toggle_button",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Never True",
          value: "neverTrue",
          type: "toggle_button",
        },
      ],
      questionId: 36,
    },
    {
      id: 10,
      name: "self_Recommendations",
      label: "Recommendations",
      value: "smokingOrTobacco",
      type: "checkbox",
      options: [
        [
          "Discuss smoking and tobacco cessation options with provider",
          "smokingOrTobacco",
        ],
        ["Discuss substance use counseling with provider", "substanceUse"],
        ["Resources  for Food/Housing Disparity", "foodDisparity"],
        ["Literacy", "literacy"],
        ["Social support evaluation", "socialSupport"],
        [
          "Discuss Advanced Care Planning with provider and family",
          "advanceCare",
        ],
        ["Transportation assistance", "transportation"],
        ["Case management referral for follow up", "careManagement"],
        ["Follow up with Clinician/Specialist", "followUp"],
      ],
      xs: 12,
      isCommentEnabled: true,
      questionId: 37,
    },
  ],
};

export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
