const Question = [
  // {
  //   questions: [
  {
    type: "input",
    questionId: "I-lowx",
    question:
      "Sign your name if you have reviewed, understand and agree to follow all the criteria listed on the Focus Care COVID-19 Policy for in person meetings",
    options: [{ text: "", optionId: "w4v" }],
  },
  {
    type: "radio",
    questionId: "R-74st",
    question:
      "In the last 7 days, have you had known exposure to anyone diagnosed with Corona virus (COVID-19)? ",
    options: [
      { text: "Yes", optionId: "vu8" },
      { text: "No", optionId: "ldp" },
    ],
  },
  {
    type: "radio",
    questionId: "R-jtcz",
    question:
      "In the last 7 days, had close contact with someone who has traveled to a high-risk area? ",
    options: [
      { text: "Yes", optionId: "vu8" },
      { text: "No", optionId: "ldp" },
    ],
  },
  // {
  //   type: "radio",
  //   questionId: "R-n8u3",
  //   question:
  //     "Have you been in close contact (6 fee for more than 15 minutes with a person(s)) who has test positive for COVID-19 infection within the last 14 days?",
  //   options: [
  //     { text: "Yes", optionId: "cbv" },
  //     { text: "No", optionId: "2ww" },
  //   ],
  // },
  {
    type: "checkbox",
    questionId: "C-w165",
    question:
      "In the last 7 days, have you recently or do you currently have any of the following symptoms? ",
    options: [
      { text: "Fever or Chills", optionId: "yv5" },
      { text: "Cough", optionId: "8l5" },
      {
        text: "Shortness of Breath or Difficulty Breathing",
        optionId: "9z0",
      },
      { text: "Fatigue", optionId: "dox" },
      { text: "Muscle or Body Aches", optionId: "0wz" },
      { text: "Headache", optionId: "1qa" },
      { text: "New loss of taste or Smell", optionId: "ep0" },
      { text: "Sore Throat", optionId: "kvw" },
      { text: "Congestion or Runny Nose", optionId: "s11" },
      { text: "Nausea or Vomiting", optionId: "0k7" },
      { text: "Flu like symptoms", optionId: "lgv" },
      { text: "None", optionId: "7v4" },
    ],
  },
  // {
  //   type: "radio",
  //   questionId: "I-eq2h",
  //   question:
  //     "Members have agreed to in-person and are aware of potential risk related to COVID-19?",
  //   options: [
  //     { text: "Yes", optionId: "u6o" },
  //     { text: "No", optionId: "qe2" },
  //   ],
  // },
  // {
  //   type: "input",
  //   questionId: "I-gbms",
  //   question: "Clinician Name:",
  //   options: [{ text: "", optionId: "m6l" }],
  // },
  {
    type: "checkbox",
    questionId: "C-ilxq",
    question: "Name of Client ",
    options: [
      { text: "Alliant Health", optionId: "96x" },
      { text: "Health Alliance", optionId: "v7d" },
      { text: "Molina", optionId: "4qx" },
      { text: "OHP", optionId: "h01" },
      { text: "Southwest", optionId: "h21" },
      { text: "VPHP", optionId: "xy3" },
      { text: "SHP", optionId: "SHPClient" },
      { text: "Demo", optionId: "Demo" },
    ],
  },
  {
    type: "date",
    questionId: "D-iz6p",
    question: "Date Completed ",
    options: [{ text: "", id: "gdb" }],
  },
  //   ],
  // },
];
export default Question;
