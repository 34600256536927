import { useState, useContext, useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import { Close } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { Typography, Fade, TextField } from "@mui/material";
import Button from "../../button/button";
//import EyeLogo from "../../../assets/logo_eye.png";
import EyeLogo from "./logo_eye.png";
export default function ConfirmDigitalSignatureDialog({
  openConfirmDigitalSign,
  onCloseConfirmDigitalSignatureDialogBox,
  onSubmitCommentForClinician,
  onBack,
  clinicianName,
  signCanvasImg,
  speciality,
  fromFillHra,
}) {
  const canvasImageRef = useRef(null);
  const getCurrentDateAndTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours24 = now.getHours();
    const amPm = hours24 >= 12 ? "PM" : "AM";
    const hours = hours24 % 12 || 12;
    const minutes = now.getMinutes();
    const dateStr = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    const timeStr = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${amPm}`;

    return `${dateStr} ${timeStr}`;
  };
  const [render, setRender] = useState(false);
  const [reSignDisable, setReSignDisable] = useState(false);
  const [disableSign, setDisableSign] = useState(true);

  setTimeout(() => {
    setRender(true);
  }, [2000]);
  const onResign = () => {
    setReSignDisable(true);
    const signCanvas = document.getElementById("imageCanvas");
    const dataUrl = signCanvas.toDataURL();
    onSubmitCommentForClinician(dataUrl);
  };
  useEffect(() => {
    // if (openConfirmDigitalSign) {
    const currentTime = getCurrentDateAndTime();
    const canvas = document?.getElementById("imageCanvas"); //canvasImageRef?.current;
    const context = canvas?.getContext("2d");
    // if (Boolean(canvas) && Boolean(context)) {
    if (context && (render == true || true)) {
      const image = new Image();
      image.src = EyeLogo;
      context.globalAlpha = 0.2;
      image.onload = () => {
        setDisableSign(false);
        context.globalAlpha = 0.2;
        context?.drawImage(image, 80, 0, canvas.width / 2, canvas.height);
        const leftSideImage = new Image();
        leftSideImage.src = signCanvasImg;

        leftSideImage.onload = () => {
          setDisableSign(false);
          const leftImageWidth = canvas.width;
          const leftImageHeight =
            (leftSideImage.height * leftImageWidth) / leftSideImage.width;
          context.globalAlpha = 1; //0.8;
          context?.drawImage(
            leftSideImage,
            0,

            (canvas.height - leftImageHeight) / 2 + 40,
            leftImageWidth,
            leftImageHeight,
          ); // Center vertically

          context.font = "bold 10pt monospace"; // "20px serif 200"; // Adjust the font size and family as needed bold 12pt Courier
          context.fillStyle = "#00000";
          const text1 = "Digitally Signed By";
          context?.fillText(text1, canvas.width / 2 + 65, 15);
          const text2 = clinicianName.split(" ")[0]; //"First Name";
          context?.fillText(text2, canvas.width / 2 + 65, 30);
          const text3 = clinicianName.split(" ")[1]; //"Last Name";
          context?.fillText(text3, canvas.width / 2 + 65, 45);
          const text4 = speciality; //"Speciality";
          context?.fillText(text4, canvas.width / 2 + 65, 60);
          const text5 = currentTime;
          context?.fillText(text5, canvas.width / 2 + 65, 75);
        };
      };
    }
    //}
  }, [render]);
  return (
    <>
      <Dialog
        open={openConfirmDigitalSign}
        onClose={onCloseConfirmDigitalSignatureDialogBox}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            maxHeight: "300px", // Adjust the maxHeight to decrease the height
            width: "550px",
          },
        }}
      >
        <DialogTitle
          sx={{
            background: "var(--mainTheme)",
            fontSize: "20px",
            color: "white",
            fontFamily: "Inter,sans-serif",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="alert-dialog-title"
        >
          Digital Signature
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography>{getCurrentDateAndTime()}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingTop={2}
          >
            <canvas
              id="imageCanvas"
              ref={canvasImageRef}
              width={450}
              height={80}
              style={{ border: "1px solid #ccc" }}
            />
          </Box>
          <Box
            paddingY={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {fromFillHra == true ? (
              <Typography variant="body1">
                Are you sure you are ready to Sign this Assessment
              </Typography>
            ) : (
              <Typography variant="body1">
                "Re-sign" the form to save the QA edit and to Submit Query form
                OR To go back to assessment click on "Cancel" Button in query
                form
              </Typography>
            )}
          </Box>
          <Box
            // paddingY={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <Button
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (!reSignDisable) {
                    onResign();
                  }
                }}
                disabled={fromFillHra == true ? disableSign : reSignDisable} // Disable the button if clicked is false
              >
                {fromFillHra == true ? "Sign" : "Re-sign"}
              </Button>
            </Box>
            <Box paddingX={2}></Box>
            <Box>
              <Button onClick={onBack} sx={{ cursor: "pointer" }}>
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
