// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert .css-2rbg70-MuiPaper-root-MuiDialog-paper {
  width: 37% !important;
}

.alertDialog {
  border-radius: 1px;
  border: 1px solid var(--mainTheme);
  background: #fff;
  width: 468px;
  height: 50px;
  margin: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Alert/Alert.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,YAAY;AACd","sourcesContent":[".alert .css-2rbg70-MuiPaper-root-MuiDialog-paper {\r\n  width: 37% !important;\r\n}\r\n\r\n.alertDialog {\r\n  border-radius: 1px;\r\n  border: 1px solid var(--mainTheme);\r\n  background: #fff;\r\n  width: 468px;\r\n  height: 50px;\r\n  margin: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
