import {
  Box,
  Button,
  Card,
  CardHeader,
  Fab,
  Grid,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { BrowserRouter, useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import "../../../../components/Dashboard/DynamicFormMUI/Tables.css";
import MicNoneIcon from "@mui/icons-material/MicNone";
import StopIcon from "@mui/icons-material/Stop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import toast from "react-hot-toast";
import {
  CardSections,
  HeaderCard,
} from "../../../../components/Dashboard/DynamicFormMUI/customFields";
import { getApi } from "../../../../utils/axiosApiWrapper";
import { initialWordList } from "../../../../components/Dashboard/DynamicFormMUI/staticForm";
import { useUserContext } from "../../../../contexts/UserContext";
import Labels from "../../../../resource_files/Labels.resx";
import { putApi } from "../../../../utils/axiosApiWrapper";
import { getObjectData } from "../../../../utils/helpers.DynamicForm";
import Toaster from "../../../toaster/toaster";
import QueryForm from "./QueryForm";
import Activities from "./Sections/Activities/Activities";
import Allergies from "./Sections/Allergies/allergies";
import CovidScreening from "./Sections/CovidScreening/CovidScreening";
import Demographics from "./Sections/Demographics/Demographics";
import ExamReview from "./Sections/ExamReview/ExamReview";
import FamilyHistory from "./Sections/FamilyHistory/FamilyHistory";
import HomeSafety from "./Sections/HomeSafety/HomeSafety";
import MedicalHistory from "./Sections/MedicalHistory/medicalHistory";
import MiniCog from "./Sections/Minicog/Mini-cog";
import Pain from "./Sections/Pain/Pain";
import PatientSummary from "./Sections/PatientSummary/PatientSummary";
import ROS from "./Sections/ROS/ROS";
import Screenings from "./Sections/Screenings/Screenings";
import SelfAssess from "./Sections/SelfAssessment/SelfAssessment";
import Vital from "./Sections/Vital/Vital";
import Preventive from "./Sections/preventiveCare/preventiveCare";
import Previously from "./Sections/previouslyDocumented/previouslyDocumented";
import { getInputsData } from "./fillHraFormData";
import { setRosData2024 } from "./Sections/ROS/reviewOfSystemMapping2024";
import { setRosData } from "./Sections/ROS/reviewOfSystemMapping";
import { setPainData } from "./Sections/Pain/painMapping";
import { setCovidScreeningData } from "./Sections/CovidScreening/covidScreeningMapping";
import { setSelfAssessData } from "./Sections/SelfAssessment/selfAssessmentMapping";
import { setfamilyData } from "./Sections/FamilyHistory/familyHistoryMapping";
import { setMedicalData } from "./Sections/MedicalHistory/medicalHistoryMapping";
import { setActivitiesData } from "./Sections/Activities/activitiesMapping";
import { setVitalData } from "./Sections/Vital/vitalMapping";
import { setMinicogData } from "./Sections/Minicog/minicogMapping";
import { setScreeningsData } from "./Sections/Screenings/screeningsMapping";
import { setExamReviewData } from "./Sections/ExamReview/examReviewMapping";
import { setHomeSafetyData } from "./Sections/HomeSafety/homeSafetyMapping";
import { setPreventiveData } from "./Sections/preventiveCare/preventiveMapping";
import { setAllergiesData } from "./Sections/Allergies/allergiesMapping";
import { setDemographicsData } from "./Sections/Demographics/demographicsMapping";
import { SaveOutlined } from "@mui/icons-material";
import { styled } from "@mui/styles";
import { QueryFormIcon } from "../../../../assets/Icons/FillHra/QueryFormIcon";
import FillHRASummary from "./FillHraSummary/FillHraSummary";
import { useSpeech } from "../../../../contexts/SpeechContext";
import { generateRTTReport } from "../../../../api/realTimeScriptsApi";
/* 
const ExamReviewLazy = React.lazy(() =>
  import("./Sections/ExamReview/ExamReview"),
);
const ScreeningsLazy = React.lazy(() =>
  import("./Sections/Screenings/Screenings"),
);
const HomeSafetyLazy = React.lazy(() =>
  import("./Sections/HomeSafety/HomeSafety"),
);
const CovidScreeningLazy = React.lazy(() =>
  import("./Sections/CovidScreening/CovidScreening"),
);
const ActivitiesLazy = React.lazy(() =>
  import("./Sections/Activities/Activities"),
);
const PainLazy = React.lazy(() => import("./Sections/Pain/Pain"));
const VitalLazy = React.lazy(() => import("./Sections/Vital/Vital"));
const PatientSummaryLazy = React.lazy(() =>
  import("./Sections/PatientSummary/PatientSummary"),
);
const SelfAssessLazy = React.lazy(() =>
  import("./Sections/SelfAssessment/SelfAssessment"),
);
const FamilyHistoryLazy = React.lazy(() =>
  import("./Sections/FamilyHistory/FamilyHistory"),
);
const MedicalHistoryLazy = React.lazy(() =>
  import("./Sections/MedicalHistory/medicalHistory"),
);
const AllergiesLazy = React.lazy(() =>
  import("./Sections/Allergies/allergies"),
);
const PreventiveLazy = React.lazy(() =>
  import("./Sections/preventiveCare/preventiveCare"),
);
const MiniCogLazy = React.lazy(() => import("./Sections/Minicog/Mini-cog"));
const ROSLazy = React.lazy(() => import("./Sections/ROS/ROS"));

import "../../../components/Dashboard/DynamicFormMUI/DynamicForms.css";

*/

const sectionMap2023 = {
  1: ["demographics", "Demographics"],
  2: ["previously", "Previously Documented Conditions"],
  3: ["covid", "Covid Screening"],
  4: ["self", "Self-Assessment and Social History"],
  5: ["activities", "Activities of Daily Living"],
  6: ["medical", "Medical History"],
  7: ["family", "Family History"],
  8: ["preventive", "Preventive Care"],
  9: ["allergies", "Allergies / Medications"],
  10: ["review", "Review Of Systems And Diagnoses"],
  11: ["pain", "Pain"],
  12: ["vital", "Vital Signs"],
  13: ["exam", "Exam Review"],
  14: ["screenings", "Screening Needed"],
  15: ["mini", "Mini - Cog"],
  16: ["home", "Home Safety & Personal Goals"],
  17: ["patient", "Patient Summary"],
};

const sectionMap2024 = {
  1: ["demographics", "Demographics"],
  2: ["previously", "Previously Documented Conditions"],
  3: ["covid", "Covid Screening"],
  4: ["self", "Self-Assessment and Social History"],
  5: ["activities", "Activities of Daily Living"],
  6: ["medical", "Medical History"],
  7: ["family", "Family History"],
  8: ["preventive", "Preventive Care"],
  9: ["allergies", "Allergies / Medications"],
  10: ["review", "Assessment Of Diagnoses"],
  11: ["pain", "Pain"],
  12: ["vital", "Vital Signs"],
  13: ["exam", "Exam Review"],
  14: ["screenings", "Screening Needed"],
  15: ["mini", "Mini - Cog"],
  16: ["home", "Home Safety & Personal Goals"],
  17: ["patient", "Patient Summary"],
};

const FillHraHome = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    saveSectionFormData(currSection + 1).then((resp) => {
      const statusCode = resp?.status;
      if (statusCode === 200) {
        const data = resp?.data || {};
        if (data && Object.keys(data).length > 0) {
          const toastMsg = data.message;
          toast.remove();
          if (toastMsg === "Assessment updated successfully.") {
            toast.success(toastMsg, {});
          } else {
            toast.error(toastMsg, {});
          }
        }
      }
    });
    setOpen(true);
  };

  const [sections, setSections] = useState([]);
  // const [currSection, setCurrSection] = useState(0);
  const [prevSelectedSection, setPrevSelectedSection] = useState(-1);
  // const sectionIdRef = useRef(0);
  // let sections = [];
  // let currSection = 0;
  const [isShowSection, setIsShowSection] = useState({});
  // const [toasterMessage, setToasterMessage] = useState("");
  // const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  // const [toasterOpen, setToasterOpen] = useState(false);
  const sectionRefs = sections.map(() => React.createRef());
  const [isLoading, setIsLoading] = useState(false);

  const {
    user,
    storedPlan,
    storedYear,
    autoSaveRosData,
    setAutoSaveRosData,
    autoSavePainData,
    setAutoSavePainData,
    autoSaveCovidScreeningData,
    setAutoSaveCovidScreeningData,
    autoSaveSelfAssessmentData,
    setAutoSaveSelfAssessmentData,
    autoSaveFamilyHistoryData,
    setAutoSaveFamilyHistoryData,
    autoSaveMedicalHistory,
    setAutoSaveMedicalHistory,
    autoSaveActivitiesData,
    setAutoSaveActivitiesData,
    autoSaveVitalData,
    setAutoSaveVitalData,
    autoSaveMinicogData,
    setAutoSaveMiniCogData,
    autoSaveScreeningNeeded,
    setAutoSaveScreeningNeeded,
    autoSaveExamReviewData,
    setAutoSaveExamReviewData,
    autoSaveHomeSafetyData,
    setAutoSaveHomeSafety,
    autoSavePatientData,
    setAutoSavePatientData,
    autoSavePreventiveCareData,
    setAutoSavePreventiveCareData,
    autoSaveAllergiesData,
    setAutoSaveAllergiesData,
    autoSaveDemographicsData,
    setAutoSaveDemographicsData,
    autoSavePrevDocData,
    setAutoSavePrevDocData,
    currSection,
    setCurrSection,
    toasterMessage,
    setToasterMessage,
    toasterSeverity,
    setToasterSeverity,
    toasterOpen,
    setToasterOpen,
    sideBarSectionDisable,
  } = useUserContext();
  const { roleId } = user;
  const { getAssessmentData, _memberIdForQueryForm, getAssessment } =
    useUserContext();
  const [formData, setFormData] = useState({});

  const location = useLocation();

  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");

  const hraStatus = location?.state?.hraStatus
    ? location?.state?.hraStatus
    : sessionStorage.getItem("hraStatus");
  const patientName = location?.state?.patientName
    ? location?.state?.patientName
    : sessionStorage.getItem("patientName");
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
    setToasterMessage("");
  };

  const updateHraStatusToInProgress = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 2,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        // setSignSubmitDialog(false);
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        toast.remove();
        toast.success("Assessment Updated Successfully", {});
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage(response.data.message);
        // fetchMemberData(storedPlan, storedYear);
      } else {
        return;
      }
    } catch (error) {
      return;
    }

    // setIsCancelAppointmentAlertOpen(false);
    // } else {
    //   console.error("Selected HRA status not found in data array");
    // }
  };

  const handleCardClick = (index) => {
    setIsLoading(true);
    setCurrSection(index);
    setPrevSelectedSection(currSection);
    // sectionIdRef.current = index;
    const sectionRef = sectionRefs[index];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 1500);
    }
  };

  // useEffect(() => {
  //   console.log("prevSelectedSection", prevSelectedSection);
  //   console.log("currSection", currSection);
  //   saveSectionFormData(prevSelectedSection + 1);
  // }, [currSection]);

  useEffect(() => {
    const getData = async () => {
      const data = await getInputsData(clientYear);
      setSections(data);
    };
    getData();
  }, []);

  const showQueryFormBtn = sessionStorage.getItem("showQueryFormBtn");

  // const inViewOptions = {
  //   triggerOnce: true,
  //   rootMargin: "100px",
  // };
  // const { ref: examInViewRef, inView: examInView } = useInView({
  //   ...inViewOptions,
  // });
  const examReviewRef = useRef();
  // const { ref: homeInViewRef, inView: homeInView } = useInView({
  //   ...inViewOptions,
  // });
  const homeSafetyRef = useRef();
  // const { ref: covidInViewRef, inView: covidInView } = useInView({
  //   ...inViewOptions,
  // });
  const covidScreeningRef = useRef();
  // const { ref: activitiesInViewRef, inView: activitiesInView } = useInView({
  //   ...inViewOptions,
  // });
  const activitiesRef = useRef();
  // const { ref: painInViewRef, inView: painInView } = useInView({
  //   ...inViewOptions,
  // });
  const painRef = useRef();
  // const { ref: vitalInViewRef, inView: vitalInView } = useInView({
  //   ...inViewOptions,
  // });
  const vitalRef = useRef();
  // const patientSummaryRef = useRef();
  const demographicsRef = useRef();
  // const { ref: selfInViewRef, inView: selfInView } = useInView({
  //   ...inViewOptions,
  // });
  const selfAssessRef = useRef();
  // const { ref: rosInViewRef, inView: rosInView } = useInView({
  //   ...inViewOptions,
  // });
  const rosRef = useRef();
  // const { ref: miniCogInViewRef, inView: miniCogInView } = useInView({
  //   ...inViewOptions,
  // });
  const minicogRef = useRef();
  // const { ref: familyInViewRef, inView: familyInView } = useInView({
  //   ...inViewOptions,
  // });
  const familyHistoryRef = useRef();
  // const { ref: medicalInViewRef, inView: medicalInView } = useInView({
  //   ...inViewOptions,
  // });
  const medicalRef = useRef();
  // const { ref: allergyInViewRef, inView: allergyInView } = useInView({
  //   ...inViewOptions,
  // });
  const allergiesRef = useRef();
  // const { ref: preventiveInViewRef, inView: preventiveInView } = useInView({
  //   ...inViewOptions,
  // });
  const preventiveRef = useRef();
  // const { ref: screeningsInViewRef, inView: screeningsInView } = useInView({
  //   ...inViewOptions,
  // });
  const screeningsRef = useRef();
  const previouslyRef = useRef();
  // const { ref: patientInViewRef, inView: patientInView } = useInView({
  //   ...inViewOptions,
  // });
  const patientSummaryRef = useRef();

  const handlePatientSave = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });
      let tempData = patientSummaryRef?.current?.getPayloadData();
      if (!Boolean(tempData)) {
        tempData = autoSavePatientData;
      }
      const patientData = {};

      const visitType = {
        isVisitType: tempData?.visitTypeSaveBtn
          ? tempData?.visitTypeSaveBtn
          : "",
        consentedToVideo:
          tempData?.visitTypeSaveBtn == 2
            ? tempData?.consentedToVideoSaveBtn
              ? tempData?.consentedToVideoSaveBtn
              : ""
            : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = tempData?.assessorsCommentsSaveBtn
        ? tempData?.assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = tempData?.memberAcknowledgementSaveBtn
        ? tempData?.memberAcknowledgementSaveBtn
        : "";

      patientData.acceptDisclosure = tempData?.acceptDisclosureSaveBtn
        ? tempData?.acceptDisclosureSaveBtn
        : "";
      patientData.evaluationDate = tempData?.evaluationDate;
      patientData.examFinishDate = tempData?.examFinishDateSaveBtn
        ? tempData?.examFinishDateSaveBtn
        : tempData?.examFinishDate
        ? tempData?.examFinishDate
        : "";

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature
              ? providerSignature
              : tempData?.providerSignAllDashboard
              ? tempData?.providerSignAllDashboard
              : "",
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response?.data?.code === 406 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
        //toast.success(response?.data?.message, {});
      }
      if (response.status == 404 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (
        response.status === 200 &&
        response?.data?.message == "Assessment updated successfully."
      ) {
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        toast.success("Assessment Updated Successfully", {});
        const roleId = sessionStorage.getItem("roleId");
      } else {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Error saving data");
      }
    } catch (error) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Error saving data");
    }
  };

  const handlePatientSave2024 = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });
      let tempData = patientSummaryRef?.current?.getPayloadData();
      if (!Boolean(tempData)) {
        tempData = autoSavePatientData;
      }
      const patientData = {};
      const visitType = {
        isVisitType: tempData?.visitTypeSaveBtn
          ? tempData?.visitTypeSaveBtn
          : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = tempData?.assessorsCommentsSaveBtn
        ? tempData?.assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = tempData?.memberAcknowledgementSaveBtn
        ? tempData?.memberAcknowledgementSaveBtn
        : "";

      patientData.acceptDisclosure = tempData?.acceptDisclosureSaveBtn
        ? tempData?.acceptDisclosureSaveBtn
        : "";

      patientData.memberConsentedToCompleteVirtualVisit =
        tempData?.memberConsentedToCompleteVirtualVisitSaveBtn
          ? tempData?.memberConsentedToCompleteVirtualVisitSaveBtn
          : "";

      patientData.membersIdentityConfirmedWithID =
        tempData?.membersIdentityConfirmedWithIDSaveBtn
          ? tempData?.membersIdentityConfirmedWithIDSaveBtn
          : "";

      patientData.comment = tempData?.commentSaveBtn
        ? tempData?.commentSaveBtn
        : "";

      patientData.evaluationDate = tempData?.evaluationDate;
      patientData.examFinishDate = tempData?.examFinishDateSaveBtn
        ? tempData?.examFinishDateSaveBtn
        : tempData?.examFinishDate
        ? tempData?.examFinishDate
        : "";

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature
              ? providerSignature
              : tempData?.providerSignAllDashboard
              ? tempData?.providerSignAllDashboard
              : "",
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response?.data?.code === 406 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (response.status == 404 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (
        response.status === 200 &&
        response?.data?.message == "Assessment updated successfully."
      ) {
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        toast.success("Assessment Updated Successfully", {});
        const roleId = sessionStorage.getItem("roleId");
      } else {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Error saving data");
      }
    } catch (error) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Error saving datass");
    }
  };

  const handleSave = () => {
    if (clientYear === "2024") {
      handlePatientSave2024();
    } else {
      handlePatientSave();
    }
  };
  useEffect(() => {
    const getData = async () => {
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      );
      getAssessment(secondApiResponse);
      getSectionDbData(currSection + 1);
      //saveSectionFormData(prevSelectedSection + 1);
      if (prevSelectedSection + 1 != 17 && prevSelectedSection >= 0) {
        saveSectionFormData(prevSelectedSection + 1).then((resp) => {
          const statusCode = resp?.status;
          if (statusCode === 200) {
            const data = resp?.data || {};
            if (data && Object.keys(data).length > 0) {
              const toastMsg = data.message;
              toast.remove();
              if (toastMsg === "Assessment updated successfully.") {
                toast.success(toastMsg, {});
              } else {
                toast.error(toastMsg, {});
              }
            }
          }
        });
      } else if (prevSelectedSection + 1 == 17) {
        // handlePatientSave();
        handleSave();
      }
    };
    getData();
  }, [currSection]);

  const getSectionDbData = async (sectionId) => {
    const assessmentDataFromAPI = await getAssessmentData();
    let sectionDbData = {};

    let sectionMap = {};
    if (clientYear === "2023") {
      sectionMap = { ...sectionMap2023 };
    } else if (clientYear === "2024") {
      sectionMap = { ...sectionMap2024 };
    }

    let sectionData = sectionMap[sectionId];
    let sectionKey = sectionData[0];
    let tempDbData = getObjectData(
      assessmentDataFromAPI,
      `data.data.${sectionKey}.data`,
      {},
    );

    sectionDbData = {
      ...tempDbData,
    };

    return sectionDbData;
  };
  useEffect(() => {
    getSectionDbData(currSection + 1);
  }, [currSection]);

  const saveSectionFormData = async (sectionId) => {
    try {
      let payload = {};
      let assessId = assessmentId;

      let sectionMap = {};
      if (clientYear === "2023") {
        sectionMap = { ...sectionMap2023 };
      } else if (clientYear === "2024") {
        sectionMap = { ...sectionMap2024 };
      }

      let sectionData = sectionMap[sectionId];
      let sectionName = sectionData[1];
      let tempData = {};
      if (sectionId === 1) {
        tempData = demographicsRef?.current?.getPayloadData(); //getSectionFormData(sectionData);
        if (!Boolean(tempData)) {
          tempData = autoSaveDemographicsData;
        }
      } else if (sectionId === 4) {
        tempData = selfAssessRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setSelfAssessData(autoSaveSelfAssessmentData, clientYear);
        }
      } else if (sectionId === 7) {
        tempData = familyHistoryRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setfamilyData(autoSaveFamilyHistoryData, clientYear);
        }
        const subAnswers = tempData?.familyHistory?.familyHistorySubAnswers;
        for (let i = 0; i < subAnswers.length; i++) {
          delete subAnswers[i]["id"];
        }
      } else if (sectionId === 6) {
        tempData = medicalRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setMedicalData(autoSaveMedicalHistory, clientYear);
        }
        const subAnswers = tempData?.specialists?.specialistsSubAnswers;
        for (let i = 0; i < subAnswers.length; i++) {
          delete subAnswers[i]["id"];
        }
      } else if (sectionId === 10) {
        tempData = rosRef?.current?.getPayloadData();

        if (!Boolean(tempData)) {
          if (clientYear === "2024") {
            tempData = setRosData2024(autoSaveRosData);
          } else {
            tempData = setRosData(autoSaveRosData);
          }
        }
      } else if (sectionId === 14) {
        tempData = screeningsRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setScreeningsData(autoSaveScreeningNeeded, clientYear);
        }
      } else if (sectionId === 13) {
        tempData = examReviewRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setExamReviewData(autoSaveExamReviewData, clientYear);
        }
      } else if (sectionId === 15) {
        tempData = minicogRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setMinicogData(autoSaveMinicogData, clientYear);
        }
        const subAnswers = tempData?.threeWordRecall;

        if (
          clientYear === "2024" &&
          subAnswers &&
          typeof subAnswers.wordList === "string"
        ) {
          subAnswers.wordList = { ...initialWordList };
          if (subAnswers["0"]) {
            delete subAnswers["0"];
          }
        }

        payload = {
          id: assessId,
          year: clientYear,
          data: {
            name: "Mini - Cog",
            data: {
              ...tempData,
            },
          },
        };
      } else if (sectionId === 16) {
        tempData = homeSafetyRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setHomeSafetyData(autoSaveHomeSafetyData, clientYear);
        }
      } else if (sectionId === 3) {
        tempData = covidScreeningRef?.current?.getPayloadData();

        if (!Boolean(tempData)) {
          tempData = setCovidScreeningData(
            autoSaveCovidScreeningData,
            clientYear,
          );
        }
      } else if (sectionId === 5) {
        tempData = activitiesRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          if (clientYear == "2024") {
            tempData = autoSaveActivitiesData;
          } else {
            tempData = setActivitiesData(autoSaveActivitiesData, clientYear);
          }
        }
      } else if (sectionId === 11) {
        tempData = painRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setPainData(autoSavePainData, clientYear);
        }
      } else if (sectionId === 12) {
        tempData = vitalRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setVitalData(autoSaveVitalData, clientYear);
        }
      } else if (sectionId === 9) {
        tempData = allergiesRef?.current?.getPayloadData();
        //setAllergiesData
        if (!Boolean(tempData)) {
          tempData = setAllergiesData(autoSaveAllergiesData, clientYear);
        }
        const subAnswers = tempData?.allergies?.allergiesSubAnswers;
        for (let i = 0; i < subAnswers?.length; i++) {
          delete subAnswers[i]["id"];
        }

        const subAnswersMed = tempData?.medications?.medication;
        for (let i = 0; i < subAnswersMed?.length; i++) {
          delete subAnswersMed[i]["id"];
          if (clientYear === "2024") {
            delete subAnswersMed[i]["prescribingPhysician"];
          }
        }
        const subAnswersCounterMed =
          tempData?.counterMedications?.counterMedicationsSubAnswers;
        for (let i = 0; i < subAnswersCounterMed?.length; i++) {
          delete subAnswersCounterMed[i]["id"];
        }
      } else if (sectionId === 17) {
        handleSave();
      } else if (sectionId === 2) {
        tempData = previouslyRef?.current?.getPayloadData();

        if (!Boolean(tempData)) {
          tempData = autoSavePrevDocData; //setAllergiesData(autoSaveAllergiesData, clientYear);
        }
        if (clientYear === "2023") {
          delete tempData.suspectCodes;
        }

        const view =
          tempData?.viewSubAnswers?.length > 0
            ? "1"
            : "2" || tempData?.suspectCodes?.length > 0
            ? "1"
            : "2";
        tempData = {
          ...tempData,
          view,
        };
        payload = {
          id: assessId,
          year: clientYear,
          data: {
            name: sectionName,
            data: {
              ...tempData,
            },
          },
        };
      } else if (sectionId === 8) {
        tempData = preventiveRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setPreventiveData(autoSavePreventiveCareData, clientYear);
        }
        const subAnswersMed =
          tempData?.preventiveCare?.pastThreeYearsSubAnswers;
        for (let i = 0; i < subAnswersMed?.length; i++) {
          if (clientYear === "2024") {
            delete subAnswersMed[i]["educationCompleted"];
          }
        }
        if (clientYear === "2024") {
          delete tempData?.recommendations;
        }
      }

      payload = {
        id: assessId,
        year: clientYear,
        data: {
          name: sectionName,
          data: {
            ...tempData,
          },
        },
      };

      if (sectionId !== 17) {
        const response = await putApi("/hra/assessment", payload);

        if (tabId == 1) {
          updateHraStatusToInProgress();
        }
        return response;
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      saveSectionFormData(currSection + 1).then((resp) => {
        const statusCode = resp?.status;
        if (statusCode === 200) {
          const data = resp?.data || {};
          if (data && Object.keys(data).length > 0) {
            const toastMsg = data.message;
            toast.remove();
            if (toastMsg === "Assessment updated successfully.") {
              toast.success(toastMsg, {});
            } else {
              toast.error(toastMsg, {});
            }
          }
        }
      });
    }, 60000);
    return () => {
      clearInterval(id);
    };
  }, [currSection]);

  const userRoleId = sessionStorage.getItem("roleId");
  const isAdm = userRoleId === "1" || userRoleId === "2";
  const isClinician = userRoleId === "5";
  const isSaveBtn =
    hraStatus === "In Progress" ||
    hraStatus === "Scheduled" ||
    hraStatus === "Unscheduled";
  const isQfBtn =
    hraStatus === "Clinician Review - QA" ||
    hraStatus === "Clinician Review - Coding" ||
    hraStatus === "Submitted - Ready for Coding" ||
    hraStatus === "Submitted - Ready for QA";

  // Function to handle saving data
  const handleSaveData = async (sectionId) => {
    try {
      const response = await saveSectionFormData(sectionId);
      //Fix for HRA-1553
      if (response) {
        const { status, data = {} } = response;
        if (status === 200) {
          const toastMsg = data.message || "Unknown error occurred";
          if (toastMsg === "Assessment updated successfully.") {
            toast.success(toastMsg);
          } else {
            toast.error(toastMsg);
          }
        } else {
          toast.error("Failed to save data. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  // Function to call saving data function
  const handleSaveAndClickOpen = async () => {
    const currentSection = sections[currSection];
    await handleSaveData(currentSection.sectionId);
  };

  //Handle Open Query Form
  const handleOpenQueryForm = () => setOpen(true);

  //Managing State for open & close speed dial
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const handleOpenSpeedDial = () => setOpenSpeedDial(true);
  const handleCloseSpeedDial = () => setOpenSpeedDial(false);

  //Speed dial Options
  const actions = [
    {
      icon: <SaveOutlined />,
      name: "Save Form Data",
      handler: handleSaveAndClickOpen,
      visibility: (isAdm && isSaveBtn) || (isClinician && tabId !== 3),
    },
    {
      icon: <QueryFormIcon />,
      name: "Open Query Form",
      handler: handleOpenQueryForm,
      visibility: showQueryFormBtn === "true" || (isAdm && isQfBtn),
    },
  ];

  //Custom style for Speed Dial
  const StyledSpeedDial = styled(SpeedDial)({
    position: "fixed",
    bottom: 16,
    right: 16,
    "& .MuiSpeedDial-fab": {
      backgroundColor: `var(--mainTheme900)`,
      "&:hover": {
        backgroundColor: `var(--mainTheme900)`,
      },
    },
  });

  //Filter Visible action alone
  const visibleActions = actions.filter((action) => action.visibility);

  const actionLength = visibleActions.length ? visibleActions.length : 0;

  return (
    <>
      {actionLength === 1 && (
        <Tooltip title={visibleActions[0].name} arrow>
          <StyledSpeedDial
            ariaLabel={visibleActions[0].name}
            icon={visibleActions[0].icon}
            onClick={visibleActions[0].handler}
            transitionDuration={0}
          />
        </Tooltip>
      )}
      {actionLength === 2 && (
        <StyledSpeedDial
          ariaLabel="Save and Open Query Form"
          icon={<SpeedDialIcon />}
          onClose={handleCloseSpeedDial}
          onOpen={handleOpenSpeedDial}
          open={openSpeedDial}
          transitionDuration={0}
        >
          {visibleActions.map((action) =>
            action.visibility ? (
              <SpeedDialAction
                sx={{
                  bgcolor: `var(--mainTheme900)`,
                  color: "white",
                  "&:hover": {
                    bgcolor: `var(--mainTheme900)`,
                  },
                }}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.handler}
              />
            ) : (
              <></>
            ),
          )}
        </StyledSpeedDial>
      )}
      <QueryForm
        open={open}
        setOpen={setOpen}
        memberId={_memberIdForQueryForm}
        formData={formData}
        _patientName={patientName}
      />
      <Grid item xs={10} marginTop="10px" marginBottom={1}>
        <HeaderCard formData={formData} getSectionDbData={getSectionDbData} />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        style={{ fontFamily: "Inter !important" }}
        className={"fillHraForm"}
      >
        <Grid item xs={2} style={{ padding: "3px 7px" }}>
          <CardSections
            routedFrom="FillHra"
            sections={sections}
            currSection={currSection}
            // currSection={sectionIdRef.current}
            handleCardClick={handleCardClick}
            saveSectionFormData={saveSectionFormData}
            sideBarSectionDisable={sideBarSectionDisable}
          />
        </Grid>
        <Grid
          item
          xs={10}
          style={{ padding: "2px", textAlign: "center !important" }}
        >
          <Card
            style={{
              height: "auto",
              minHeight: "76vh",
              maxHeight: "450px",
              overflowY: "auto",
              marginLeft: "10px",
            }}
          >
            {sections.map((section, index) => {
              return (
                <div key={index} id={section.sectionId}>
                  <div ref={sectionRefs[index]} className="section">
                    <span
                      style={{
                        width: "100% !important",
                        margin: "0 !important",
                        padding: "0 !important",
                      }}
                    >
                      <CardHeader
                        title={section.sectionName}
                        action={
                          <a
                            style={{
                              textDecoration: "none",

                              color: "rgba(0, 0, 0, 0.87)",
                            }}
                            sx={{
                              textDecoration: "none",
                              color: "var(--mainTheme)",
                            }}
                            href={`#${section.sectionId}`}
                          >
                            <div
                              style={{
                                width: "100% !important",
                                margin: "0 !important",
                                padding: "0 !important",
                              }}
                            >
                              <IconButton
                                style={{
                                  float: "right",
                                  color: "#FFFFFF",
                                }}
                                aria-label="Section minimise"
                                size="small"
                                onClick={() => {
                                  //   if (isShowSection[index] !== undefined) {
                                  //     setIsShowSection({
                                  //       ...isShowSection,
                                  //       [index]: !isShowSection[index],
                                  //     });
                                  //   } else {
                                  //     setIsShowSection({
                                  //       ...isShowSection,
                                  //       [index]: false,
                                  //     });
                                  //   }
                                  // }}

                                  // if (currSection != index) {
                                  //   handleCardClick(index);
                                  // } else {
                                  //   handleCardClick(0);
                                  // }

                                  // if (section?.sectionId !== currSection) {
                                  if (index !== currSection) {
                                    //  setCurrSection(section?.sectionId);
                                    setCurrSection(section?.sectionId - 1);
                                    setPrevSelectedSection(currSection);
                                  } else {
                                    setCurrSection(section?.sectionId);
                                    setPrevSelectedSection(currSection);
                                    //setCurrentSectionId(undefined);
                                    //setCurrSection(undefined);
                                  }
                                }}
                              >
                                {currSection == index ? (
                                  <KeyboardControlKeyIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                            </div>
                          </a>
                        }
                        sx={{
                          backgroundColor: `var(--mainTheme900)`,
                          color: "white",
                          padding: "8px",
                          textAlign: "center !important",
                          // textTransform: "uppercase",
                          fontWeight: 700,
                        }}
                        titleTypographyProps={{ variant: "outline" }}
                      />
                    </span>
                    <div
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <Suspense fallback={<Loader />}> */}

                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {isShowSection[index] === true ||
                        isShowSection[index] === undefined ? (
                          (() => {
                            if (
                              section.sectionId === 1 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                <Demographics
                                  currSection={1}
                                  getSectionDbData={getSectionDbData}
                                  ref={demographicsRef}
                                  setAutoSaveDemographicsData={
                                    setAutoSaveDemographicsData
                                  }
                                />
                              );
                            } else if (
                              section.sectionId === 2 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                <Previously
                                  currSection={2}
                                  getSectionDbData={getSectionDbData}
                                  ref={previouslyRef}
                                  setAutoSavePrevDocData={
                                    setAutoSavePrevDocData
                                  }
                                />
                              );
                            } else if (
                              section.sectionId === 3 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={covidInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <CovidScreening
                                    currSection={3}
                                    getSectionDbData={getSectionDbData}
                                    ref={covidScreeningRef}
                                    setAutoSaveCovidScreeningData={
                                      setAutoSaveCovidScreeningData
                                    }
                                    // inView={covidInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 4 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={selfInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <SelfAssess
                                    currSection={4}
                                    getSectionDbData={getSectionDbData}
                                    ref={selfAssessRef}
                                    setAutoSaveSelfAssessmentData={
                                      setAutoSaveSelfAssessmentData
                                    }
                                    // inView={selfInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 5 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={activitiesInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Activities
                                    currSection={5}
                                    getSectionDbData={getSectionDbData}
                                    ref={activitiesRef}
                                    //inView={activitiesInView}
                                    assessmentId={assessmentId}
                                    setAutoSaveActivitiesData={
                                      setAutoSaveActivitiesData
                                    }
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 6 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <></>
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={medicalInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <MedicalHistory
                                    currSection={6}
                                    getSectionDbData={getSectionDbData}
                                    ref={medicalRef}
                                    setAutoSaveMedicalHistory={
                                      setAutoSaveMedicalHistory
                                    }
                                    //inView={medicalInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 7 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={familyInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <FamilyHistory
                                    currSection={7}
                                    getSectionDbData={getSectionDbData}
                                    ref={familyHistoryRef}
                                    setAutoSaveFamilyHistoryData={
                                      setAutoSaveFamilyHistoryData
                                    }
                                    // inView={familyInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 8 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <></>
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={preventiveInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Preventive
                                    currSection={8}
                                    getSectionDbData={getSectionDbData}
                                    ref={preventiveRef}
                                    setAutoSavePreventiveCareData={
                                      setAutoSavePreventiveCareData
                                    }
                                    //inView={preventiveInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 9 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={allergyInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Allergies
                                    currSection={9}
                                    getSectionDbData={getSectionDbData}
                                    ref={allergiesRef}
                                    setAutoSaveAllergiesData={
                                      setAutoSaveAllergiesData
                                    }
                                    // inView={allergyInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 10 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={rosInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <ROS
                                    currSection={10}
                                    getSectionDbData={getSectionDbData}
                                    ref={rosRef}
                                    setAutoSaveRosData={setAutoSaveRosData}
                                    // inView={rosInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 11 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={painInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Pain
                                    currSection={11}
                                    getSectionDbData={getSectionDbData}
                                    ref={painRef}
                                    setAutoSavePainData={setAutoSavePainData}
                                    // inView={painInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 12 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={vitalInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Vital
                                    currSection={12}
                                    getSectionDbData={getSectionDbData}
                                    ref={vitalRef}
                                    setAutoSaveVitalData={setAutoSaveVitalData}
                                    // inView={vitalInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 13 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={examInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <ExamReview
                                    currSection={13}
                                    getSectionDbData={getSectionDbData}
                                    ref={examReviewRef}
                                    // inView={examInView}
                                    setIsLoading={setIsLoading}
                                    currentSection={currSection}
                                    isLoading={isLoading}
                                    setAutoSaveExamReviewData={
                                      setAutoSaveExamReviewData
                                    }
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 14 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={screeningsInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Screenings
                                    currSection={14}
                                    getSectionDbData={getSectionDbData}
                                    ref={screeningsRef}
                                    setAutoSaveScreeningNeeded={
                                      setAutoSaveScreeningNeeded
                                    }
                                    // inView={screeningsInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 15 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={miniCogInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <MiniCog
                                    currSection={15}
                                    getSectionDbData={getSectionDbData}
                                    ref={minicogRef}
                                    setAutoSaveMiniCogData={
                                      setAutoSaveMiniCogData
                                    }
                                    // inView={miniCogInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 16 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={homeInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <HomeSafety
                                    currSection={16}
                                    getSectionDbData={getSectionDbData}
                                    ref={homeSafetyRef}
                                    setAutoSaveHomeSafety={
                                      setAutoSaveHomeSafety
                                    }
                                    // inView={homeInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 17 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                <PatientSummary
                                  currSection={17}
                                  getSectionDbData={getSectionDbData}
                                  ref={patientSummaryRef}
                                  setAutoSavePatientData={
                                    setAutoSavePatientData
                                  }
                                />
                              );
                            }
                          })()
                        ) : (
                          <></>
                        )}
                      </Grid>
                      {/* </Suspense> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </Card>
        </Grid>
      </Grid>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
};

const FillHRAWrapper = () => {
  const [tabId, setTabId] = useState(1);
  const [transcript, setTranscript] = useState("");
  const {
    isTranscribe,
    setTranscribedText,
    startTranscriber,
    stopTranscriber,
    pauseTranscriber,
    resumeTranscriber,
    isTranscribePause,
  } = useSpeech();
  const assessmentId = sessionStorage?.getItem("assessmentId");

  const handleTabChange = (newValue) => {
    setTabId(newValue);
  };

  useEffect(() => {
    if (
      transcript &&
      transcript !== "" &&
      assessmentId !== "" &&
      !isTranscribe
    ) {
      // console.log("hitting the RTT");

      generateRTTReport({ assessmentId, textTranscript: transcript }).then(
        (response) => {
          toast.success("RTT data generated successfully");
        },
      );
    }
  }, [transcript, assessmentId, isTranscribe]);

  const tabPanelStyle = { padding: "0px", margin: "10px 0px" };

  const RecordSpeedDial = styled(SpeedDial)({
    position: "fixed",
    bottom: 16,
    // top: 50,
    right: 80,
    borderRadius: "30%",
    "& .MuiSpeedDial-fab": {
      backgroundColor: isTranscribe ? "tomato" : `var(--mainTheme900)`,
      "&:hover": {
        backgroundColor: isTranscribe ? "tomato" : `var(--mainTheme900)`,
      },
    },
  });
  const PauseSpeedDial = styled(SpeedDial)({
    position: "fixed",
    bottom: 16,
    right: 144,
    "& .MuiSpeedDial-fab": {
      backgroundColor: `var(--mainTheme900)`,
      "&:hover": {
        backgroundColor: `var(--mainTheme900)`,
      },
    },
  });

  return (
    <Box sx={{ marginTop: "70px", marginLeft: "10px" }}>
      {/* {tabId !== 2 && (
        <RecordSpeedDial
          ariaLabel={"Audio Recorder Speed Dial"}
          icon={isTranscribe ? <StopIcon /> : <MicNoneIcon />}
          transitionDuration={0}
          onClick={
            isTranscribe
              ? () => {
                  stopTranscriber();
                  setTranscribedText(setTranscript);
                }
              : () => {
                  startTranscriber();
                }
          }
        />
      )} */}

      {tabId !== 2 && isTranscribe && (
        <PauseSpeedDial
          ariaLabel={"Audio Pause/Resume Speed Dial"}
          icon={isTranscribePause ? <PlayArrowIcon /> : <PauseIcon />}
          transitionDuration={0}
          onClick={
            isTranscribePause
              ? () => {
                  resumeTranscriber();
                }
              : () => {
                  pauseTranscriber();
                  setTranscribedText(setTranscript);
                }
          }
        />
      )}

      <TabContext value={tabId}>
        <Box>
          <TabList
            className="custom-tabs"
            value={tabId}
            onChange={(e, newValue) => handleTabChange(newValue)}
            aria-label="fill hra tabs"
          >
            <Tab label="Fill HRA Form" value={1} />
            <Tab label="Summary" value={2} />
          </TabList>
        </Box>
        <TabPanel value={1} style={tabPanelStyle}>
          <FillHraHome />
        </TabPanel>
        <TabPanel value={2} style={tabPanelStyle}>
          <FillHRASummary />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
export default FillHRAWrapper;
