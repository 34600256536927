const json2023 = {
  sectionId: 5,

  sectionName: "Patient Summary",

  isEditable: false,

  questions: [
    {
      id: 7,

      name: "assessors_comments_:",

      type: "label",

      label: "Assessors Comments :",

      editToggle: false,

      xs: 12,

      required: false, //"no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 1,
    },

    {
      id: 1,

      name: "assessorsComments",

      value: "assessorsComments",

      type: "text",

      label: "Assessors Comments :",

      editToggle: false,

      xs: 12,

      required: false, //"no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 2,
    },

    {
      id: 7,

      name: "member_acknowledgment",

      type: "label",

      label: "Member Acknowledgment",

      editToggle: false,

      xs: 6,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 3,
    },

    {
      id: 2,

      name: "total_score",

      type: "number",

      label: "Total Score",

      editToggle: false,

      xs: 6,

      disabled: true,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 4,
    },

    {
      id: 7,

      name: "advised",

      type: "label",

      label:
        "I have been advised by the evaluator and understand that the services performed by the evaluator are limited to the evaluation performed today; the evaluator has no further duties to me once the evaluation performed today is completed ; the evaluator is not liable for abandonment by refusing to provide me treatment or continuing care to me beyond this evaluation; and I should contact my primary care or treating physician for all questions and concerns regarding medical care and treatment or, in the event of an emergency, call 911",

      editToggle: false,

      xs: 12,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 5,
    },

    {
      id: 10,

      name: "memberAcknowledgement",

      value: "memberAcknowledgement",

      label: "       ",

      placeholder: "",

      editToggle: false,

      defaultValue: "",

      type: "checkbox",

      options: [
        ["Member informed of acknowledgement", "memberAcknowledgement"],
      ],

      xs: 6,

      required: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 6,
    },

    {
      id: 10,

      name: "acceptDisclosure",

      value: "acceptDisclosure",

      label: "    ",

      placeholder: "",

      editToggle: false,

      defaultValue: "",

      type: "checkbox",

      options: [["I accept the disclosure statement", "acceptDisclosure"]],

      xs: 6,

      required: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 7,
    },

    {
      id: 10,

      name: "consentedToVideo",

      value: "consentedToVideo",

      label: "    ",

      placeholder: "",

      editToggle: false,

      defaultValue: "",

      type: "checkbox",

      options: [["Consented to Video", "consentedToVideo"]],

      xs: 6,

      required: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 7,
    },

    {
      id: 4,

      name: "evaluationDate",

      value: "evaluationDate",

      label: "Date/Time of Service/Evaluation:",

      placeholder: "",

      type: "date",

      editToggle: false,

      xs: 4,

      defaultValue: "2023-09-11",

      value: "2023-09-11",

      fullWidth: true,

      required: "no",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 8,
    },

    {
      id: 4,

      name: "examFinishDate",

      value: "examFinishDate",

      label: "Time exam finished :",

      placeholder: "",

      type: "date",

      editToggle: false,

      xs: 4,

      defaultValue: "2023-09-11",

      value: "2023-09-11",

      fullWidth: true,

      required: "no",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 9,
    },

    {
      id: 5,

      name: "visitType",

      value: "visitType",

      label: "Visit Type",

      editToggle: false,

      defaultValue: "",

      type: "select",

      options: [
        ["Select", "Select"],

        ["In Person", "inPerson"],

        ["Virtual:Video & Audio Capability", "virtual"],
      ],

      xs: 4,

      required: "no",

      fullWidth: true,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 10,
    },

    {
      id: 8,

      name: "signSubmit",

      type: "button",

      label: "Sign & Submit",

      editToggle: false,

      xs: 4,

      required: "no",

      isSingleButton: false,

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 19,
    },

    {
      id: 8,

      name: "view_full_report",

      type: "button",

      label: "View Full Report",

      editToggle: false,

      xs: 4,

      required: "no",

      isSingleButton: false,

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 11,
    },

    {
      id: 8,

      name: "view_summary",

      type: "button",

      label: "View Summary",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 12,
    },

    {
      id: 8,

      name: "member_summary",

      type: "button",

      label: "Member Summary",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 13,
    },

    {
      id: 8,

      name: "referral_form",

      type: "button",

      label: "Referral Form",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      isSingleButton: false,

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 14,
    },

    {
      id: 8,

      name: "cancel_assessment",

      type: "button",

      label: "Cancel Assessment",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 15,
    },

    {
      id: 8,

      name: "no_show",

      type: "button",

      label: "No show",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 16,
    },

    {
      id: 8,

      name: "xml",

      type: "button",

      label: "XML",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 17,
    },

    {
      id: 8,

      name: "json",

      type: "button",

      label: "JSON",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 18,
    },

    {
      id: 12,

      name: "image",

      label: "Image",

      placeholder: "",

      editToggle: false,

      defaultValue: "",

      type: "image",

      src: "",

      alt: "",

      xs: 12,

      required: "no",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 19,
    },

    {
      id: 7,

      name: "disclosure_statement",

      type: "heading",

      label: "Disclosure Statement",

      editToggle: false,

      xs: 12,

      required: false,

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 21,
    },

    {
      id: 7,

      name: "your_health_plan",

      type: "label",

      label: (
        <span>
          <br />
          Your health plan, has contracted with Focus Care to conduct a health
          exam on all of its Medicare members, including you. The health exam
          includes questions to help your health plan learn more about your
          current health. The exam may also find things that could effect your
          health. The results of the exam will help your health plan and your
          doctor keep you as healthy as possible. <br /> <br />
          Personal health information, or PHI, is information in your medical
          record that identifies the record as your record. PHI includes things
          like your date of birth, age, address, telephone number, and your
          medical history. <br /> <br />
          Most of the time, Focus Care will not release your personal
          information without your permission. Measures are in place to prevent
          your personal information from being accidentally released in writing,
          including by use of a computer, or orally. You may request more
          information about how your personal information is protected.
          <br /> <br />
          There are times when Focus Care is allowed to release your personal
          information without your permission. For example, your medical
          information may be given to other health care providers who take care
          of you. The results of this exam will be sent to your health plan and
          to your doctor.
          <br /> <br />
          Focus Care may release your personal health information to a 'business
          associate'. A 'business associate' is another agency that Focus Care
          uses to do things, such as billing. We require our 'business
          associates' to have security measures in place to prevent your
          personal information from being accidentally released in writing,
          including by use of a computer, or orally.
          <br /> <br />
          Focus Care may be required to release your personal health
          information, without your permission, by law. including statutes,
          regulations, or valid court orders.
          <br /> <br />
          Focus Care will obtain your permission to use or release your personal
          health information for any other reason.
          <br /> <br />
          Do you have any questions about this information? Would you like to
          receive this information in a different language?
          <br /> <br />
          Focus Care will not disclose your individual personal health
          information to your employer, union, or any other employee
          organization without your permission.
          <br /> <br />
          Your agreement to have this medical exam means you have given your
          permission to Focus Care to release the results of your medical exam
          to your health plan and to your doctor. Do you agree?
          <br /> <br />
          The information obtained today and any applicable lab results (some of
          which may become available after subsequent analysis) may be sent to
          your primary care physician (PCP).
        </span>
      ),

      editToggle: false,

      xs: 12,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 20,
    },
  ],
};

const json2024 = {
  sectionId: 5,

  sectionName: "Patient Summary",

  isEditable: false,

  questions: [
    {
      id: 7,

      name: "assessors_comments_:",

      type: "label",

      label: "Assessors Comments :",

      editToggle: false,

      xs: 12,

      required: false, //"no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 1,
    },

    {
      id: 1,

      name: "assessorsComments",

      value: "assessorsComments",

      type: "text",

      label: "Assessors Comments :",

      editToggle: false,

      xs: 12,

      required: false, //"no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 2,
    },

    {
      id: 7,

      name: "member_acknowledgment",

      type: "label",

      label: "Member Acknowledgment",

      editToggle: false,

      xs: 6,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 3,
    },

    {
      id: 2,

      name: "total_score",

      type: "number",

      label: "Total Score",

      editToggle: false,

      xs: 6,

      disabled: true,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 4,
    },

    {
      id: 7,

      name: "advised",

      type: "label",

      label:
        "I have been advised by the evaluator and understand that the services performed by the evaluator are limited to the evaluation performed today; the evaluator has no further duties to me once the evaluation performed today is completed ; the evaluator is not liable for abandonment by refusing to provide me treatment or continuing care to me beyond this evaluation; and I should contact my primary care or treating physician for all questions and concerns regarding medical care and treatment or, in the event of an emergency, call 911",

      editToggle: false,

      xs: 12,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 5,
    },

    {
      id: 10,

      name: "memberAcknowledgement",

      value: "memberAcknowledgement",

      label: "       ",

      placeholder: "",

      editToggle: false,

      defaultValue: "",

      type: "checkbox",

      options: [
        ["Member informed of acknowledgement", "memberAcknowledgement"],
      ],

      xs: 6,

      required: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 6,
    },

    {
      id: 10,

      name: "acceptDisclosure",

      value: "acceptDisclosure",

      label: "    ",

      placeholder: "",

      editToggle: false,

      defaultValue: "",

      type: "checkbox",

      options: [["I accept the disclosure statement", "acceptDisclosure"]],

      xs: 6,

      required: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 7,
    },

    {
      id: 10,

      name: "memberConsentedToCompleteVirtualVisit",

      value: "memberConsentedToCompleteVirtualVisit",

      label: "    ",

      placeholder: "",

      editToggle: false,

      defaultValue: "",

      type: "checkbox",

      options: [
        [
          "Member consented to complete the virtual visit using audio and video, understands the purpose of the visit and their participation.",

          "memberConsentedToCompleteVirtualVisit",
        ],
      ],

      xs: 6,

      required: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 8,
    },

    {
      id: 11,

      name: "membersIdentityConfirmedWithID",

      value: "membersIdentityConfirmedWithID",

      label: "    ",

      placeholder: "",

      editToggle: false,

      defaultValue: "",

      type: "checkbox",

      options: [
        [
          "Members identify confirmed with Driver's license or valid photo ID",

          "membersIdentityConfirmedWithID",
        ],
      ],

      xs: 6,

      required: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 9,
    },

    {
      id: 7,

      name: "comment",

      type: "label",

      label: "Comments :",

      editToggle: false,

      xs: 12,

      required: false, //"no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 1,
    },

    {
      id: 1,

      name: "comment",

      value: "comment",

      type: "text",

      label: "Comments :",

      editToggle: false,

      xs: 12,

      required: false, //"no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 2,
    },

    {
      id: 4,

      name: "evaluationDate",

      value: "evaluationDate",

      label: "Date/Time of Service/Evaluation:",

      placeholder: "",

      type: "date",

      editToggle: false,

      xs: 4,

      defaultValue: "2023-09-11",

      value: "2023-09-11",

      fullWidth: true,

      required: "no",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 11,
    },

    {
      id: 4,

      name: "examFinishDate",

      value: "examFinishDate",

      label: "Time exam finished :",

      placeholder: "",

      type: "date",

      editToggle: false,

      xs: 4,

      defaultValue: "2023-09-11",

      value: "2023-09-11",

      fullWidth: true,

      required: "no",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 12,
    },

    {
      id: 5,

      name: "visitType",

      value: "visitType",

      label: "Visit Type",

      editToggle: false,

      defaultValue: "",

      type: "select",

      options: [
        ["Select", "Select"],

        ["In Person", "inPerson"],

        ["Virtual:Video & Audio Capability", "virtual"],
      ],

      xs: 4,

      sm: 4,

      required: "no",

      fullWidth: true,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 13,
    },

    {
      id: 8,

      name: "signSubmit",

      type: "button",

      label: "Sign & Submit",

      editToggle: false,

      xs: 4,

      required: "no",

      isSingleButton: false,

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 14,
    },

    {
      id: 8,

      name: "view_full_report",

      type: "button",

      label: "View Full Report",

      editToggle: false,

      xs: 4,

      required: "no",

      isSingleButton: false,

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 11,
    },

    {
      id: 8,

      name: "view_summary",

      type: "button",

      label: "View Summary",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 12,
    },

    {
      id: 8,

      name: "member_summary",

      type: "button",

      label: "Member Summary",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 13,
    },

    {
      id: 8,

      name: "referral_form",

      type: "button",

      label: "Referral Form",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      isSingleButton: false,

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 14,
    },

    {
      id: 8,

      name: "cancel_assessment",

      type: "button",

      label: "Cancel Assessment",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 15,
    },

    {
      id: 8,

      name: "no_show",

      type: "button",

      label: "No show",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 16,
    },

    {
      id: 8,

      name: "xml",

      type: "button",

      label: "XML",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 17,
    },

    {
      id: 8,

      name: "json",

      type: "button",

      label: "JSON",

      editToggle: false,

      xs: 4,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isSingleButton: false,

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 18,
    },

    {
      id: 12,

      name: "image",

      label: "Image",

      placeholder: "",

      editToggle: false,

      defaultValue: "",

      type: "image",

      src: "",

      alt: "",

      xs: 12,

      required: "no",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 19,
    },

    {
      id: 7,

      name: "disclosure_statement",

      type: "heading",

      label: "Disclosure Statement",

      editToggle: false,

      xs: 12,

      required: false,

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 21,
    },

    {
      id: 7,

      name: "your_health_plan",

      type: "label",

      label: (
        <span>
          <br />
          Your health plan, has contracted with Focus Care to conduct a health
          exam on all of its Medicare members, including you. The health exam
          includes questions to help your health plan learn more about your
          current health. The exam may also find things that could effect your
          health. The results of the exam will help your health plan and your
          doctor keep you as healthy as possible. <br /> <br />
          Personal health information, or PHI, is information in your medical
          record that identifies the record as your record. PHI includes things
          like your date of birth, age, address, telephone number, and your
          medical history. <br /> <br />
          Most of the time, Focus Care will not release your personal
          information without your permission. Measures are in place to prevent
          your personal information from being accidentally released in writing,
          including by use of a computer, or orally. You may request more
          information about how your personal information is protected.
          <br /> <br />
          There are times when Focus Care is allowed to release your personal
          information without your permission. For example, your medical
          information may be given to other health care providers who take care
          of you. The results of this exam will be sent to your health plan and
          to your doctor.
          <br /> <br />
          Focus Care may release your personal health information to a 'business
          associate'. A 'business associate' is another agency that Focus Care
          uses to do things, such as billing. We require our 'business
          associates' to have security measures in place to prevent your
          personal information from being accidentally released in writing,
          including by use of a computer, or orally.
          <br /> <br />
          Focus Care may be required to release your personal health
          information, without your permission, by law. including statutes,
          regulations, or valid court orders.
          <br /> <br />
          Focus Care will obtain your permission to use or release your personal
          health information for any other reason.
          <br /> <br />
          Do you have any questions about this information? Would you like to
          receive this information in a different language?
          <br /> <br />
          Focus Care will not disclose your individual personal health
          information to your employer, union, or any other employee
          organization without your permission.
          <br /> <br />
          Your agreement to have this medical exam means you have given your
          permission to Focus Care to release the results of your medical exam
          to your health plan and to your doctor. Do you agree?
          <br /> <br />
          The information obtained today and any applicable lab results (some of
          which may become available after subsequent analysis) may be sent to
          your primary care physician (PCP).
        </span>
      ),

      editToggle: false,

      xs: 12,

      required: "no",

      placeholder: "",

      defaultValue: "",

      isCommentEnabled: false,

      isConfigured: true,

      isModified: false,

      questionId: 20,
    },
  ],
};

export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};

  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,

      ...json2023,
    };
  }

  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,

      ...json2024,
    };
  }

  return formLayoutData;
};
