import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import dayjs from "dayjs";
import lodash from "lodash";
import PropTypes from "prop-types";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";
import LoadingOverlay from "react-loading-overlay";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import toast from "react-hot-toast";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useUserContext } from "../../../../../../contexts/UserContext";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import { DFButton } from "../../../Fill_HRA/DFInputs/DFButton";
import { DFImage } from "../../../Fill_HRA/DFInputs/DFImage";
import { getInputsData } from "./demographicsFormData";
import {
  deleteSubTree,
  getDemographicsData,
  getSubTree,
  setDemographicsData,
} from "./DemographicsMapping";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";
import { getApi } from "../../../../../../utils/axiosApiWrapper";
import { useLocation } from "react-router-dom";
const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const Demographics = forwardRef((props, _ref) => {
  const {
    // DemographicsFormData,
    // setDemographicsFormData,
    // showComment,
    // setShowComment,
    // getSectionInputsData,
    // getStateValue,
    // setStateValue,
    getDisableFlag,
  } = useFillHraContext();

  const { storedPlan, storedYear } = useUserContext();
  const [demographicsFormData, setDemographicsFormData] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});
  const [disableFlag, setDisableFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sectionId = props.currSection || 1;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);
  const location = useLocation();
  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  useEffect(() => {
    async function getDbData() {
      setIsLoading(true);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      );
      const dbData = secondApiResponse[`data`];
      //const dbData = await props.getSectionDbData(sectionId);
      let mashedData =
        dbData &&
        dbData?.data?.demographics?.data &&
        Object.keys(dbData?.data?.demographics?.data).length > 0
          ? getDemographicsData(dbData?.data?.demographics?.data, clientYear)
          : {};

      localStorage.setItem(
        "demographics_actual_data",
        JSON.stringify(dbData?.data?.demographics?.data || {}),
      );

      mashedData["demograph_age"] = calcFieldValue(
        "demograph_age",
        mashedData["demograph_dob"],
      );
      const firstName = mashedData["demograph_firstName"] || "";
      const middleName = mashedData["demograph_middleName"] || "";
      const lastName = mashedData["demograph_lastName"] || "";
      mashedData[
        "demograph_fullName"
      ] = `${firstName} ${middleName} ${lastName}`;

      /*

      tried adding validations in frontend & checked with react profiler. 
      it slows down the page mildly. so commenting it out for now.

      Object.keys(demographicsFormData).forEach((fieldName) => {
        let validatedVal = "";
        if (
          fieldName === "demograph_primary" ||
          fieldName === "demograph_secondary" ||
          fieldName === "demograph_aContact" ||
          fieldName === "demograph_eNo" ||
          fieldName === "demograph_pPhone" ||
          fieldName === "demograph_pFax" ||
          fieldName === "demograph_zip" ||
          fieldName === "demograph_mZip" ||
          fieldName === "demograph_pZip" ||
          fieldName === "demograph_email"
        ) {
          validatedVal = validateValues(
            fieldName,
            mashedData?.[fieldName] || ""
          );
          mashedData[fieldName] = validatedVal;
        }
      });
*/
      const commentVisibility = calcCommentVisibility(mashedData);
      mashedData = {
        ...mashedData,
        ...commentVisibility,
      };
      setDemographicsFormData(mashedData);
      setIsLoading(false);
    }

    getDbData();
    setDisableFlag(getDisableFlag());
  }, []);

  const validateValues = (name, value) => {
    let tempVal = "";
    if (
      name === "demograph_primary" ||
      name === "demograph_secondary" ||
      name === "demograph_aContact" ||
      name === "demograph_eNo" ||
      name === "demograph_pPhone" ||
      name === "demograph_pFax"
    ) {
      const keepRegex = /[^\d-]$/;
      tempVal = value?.replace(keepRegex, "");
      if (tempVal.length < 10) {
        setFieldErrors((prev) => ({
          ...prev,
          [name]: "Minimum length is 10 chars",
        }));
      } else if (tempVal.length > 12) {
        setFieldErrors((prev) => ({
          ...prev,
          [name]: "Maximum length is 12 chars",
        }));
      } else {
        setFieldErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else if (
      name === "demograph_zip" ||
      name === "demograph_mZip" ||
      name === "demograph_pZip"
    ) {
      const keepRegex = /[^\d-]$/;
      tempVal = value?.replace(keepRegex, "");
      if (tempVal.length < 5) {
        setFieldErrors((prev) => ({
          ...prev,
          [name]: "Minimum length is 5 chars",
        }));
      } else {
        setFieldErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else if (name === "demograph_email") {
      const keepRegex =
        /^([0-9a-zA-Z]([+-_.][0-9a-zA-Z]+)*)+@(([0-9a-zA-Z][-w]*[0-9a-zA-Z]*.)+[a-zA-Z0-9]{2,3})$/;
      tempVal = value;
      if (!keepRegex.test(value)) {
        setFieldErrors((prev) => ({
          ...prev,
          [name]: "Email is invalid",
        }));
      } else {
        setFieldErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    }

    return tempVal;
  };

  const handleTextChange = useCallback((name, value, routedFrom) => {
    let validatedVal = "";
    if (routedFrom === "delete") {
      setDemographicsFormData((prevData) => {
        return {
          ...prevData,
          [name]: value,
        };
      });
      return null;
    }

    if (
      name === "demograph_primary" ||
      name === "demograph_secondary" ||
      name === "demograph_aContact" ||
      name === "demograph_eNo" ||
      name === "demograph_pPhone" ||
      name === "demograph_pFax" ||
      name === "demograph_zip" ||
      name === "demograph_mZip" ||
      name === "demograph_pZip" ||
      name === "demograph_email"
    ) {
      if (value === "") {
        setFieldErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      } else {
        validatedVal = validateValues(name, value);
      }
    } else {
      validatedVal = value;
    }
    setDemographicsFormData((prevData) => {
      return {
        ...prevData,
        [name]: validatedVal,
      };
    });
  }, []);

  const handlePaste = useCallback((e, name, value) => {
    if (
      name === "demograph_primary" ||
      name === "demograph_secondary" ||
      name === "demograph_aContact" ||
      name === "demograph_eNo" ||
      name === "demograph_pPhone" ||
      name === "demograph_pFax"
    ) {
      e.preventDefault();
      let pasteData = value;
      // Remove all spaces and keep only digits and hyphens
      let cleanedData = pasteData.replace(/[^0-9-]/g, "");
      // Limit the length to 12 characters
      cleanedData = cleanedData.slice(0, 12);
      // Set the cleaned data to the input field
      setDemographicsFormData((prevData) => {
        return {
          ...prevData,
          [name]: cleanedData,
        };
      });
    }
  }, []);

  const handleRadioChange = useCallback((name, value) => {
    setDemographicsFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  }, []);

  const handleDateChange = useCallback((name, value) => {
    setDemographicsFormData((prevData) => {
      if (name === "demograph_dob") {
        prevData["demograph_age"] = calcFieldValue(
          "demograph_age",
          prevData[name],
        );
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
  }, []);

  const handleSelectChange = useCallback((name, value) => {
    setDemographicsFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  }, []);

  const handleOptionsTreeChange = useCallback((name, value, isMulti) => {
    /* togglebutton group type -> scenario -> actual behaviour -> our pattern

    <- multi ->
        pass values -> array -> array
        if value selected -> array -> array

    <- single -> 

        pass values -> string -> always pass as array
        if value selected -> string -> always change to array and set back to state

    */
    setDemographicsFormData((prevData) => {
      let updatedFormData = { ...prevData };
      let oldSelection = updatedFormData[name];
      let newSelection = [];
      if (isMulti) {
        newSelection = value;
      } else {
        if (!oldSelection?.includes(value)) {
          newSelection.push(value);
        }
      }

      let deletedValue =
        isMulti === true
          ? lodash.difference(oldSelection, newSelection)?.[0] || ""
          : oldSelection?.[0] || "";

      if (deletedValue && deletedValue !== "") {
        let subTreeDeletedData = handleDeleteSubTree(
          updatedFormData,
          name,
          deletedValue,
        );
        subTreeDeletedData[name] = newSelection;
        updatedFormData = { ...subTreeDeletedData };
      } else {
        updatedFormData[name] = newSelection;
      }
      return { ...updatedFormData };
    });
  }, []);

  const getFieldValues = useCallback(
    (name) => {
      return demographicsFormData[name];
    },
    [demographicsFormData],
  );

  const setFieldValues = useCallback((name, value) => {
    setDemographicsFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  }, []);

  const getNestingValues = (name) => {
    let tempData = {};
    let treeKeys = Object.keys(demographicsFormData);
    treeKeys.forEach((node) => {
      if (node.includes(name)) {
        tempData = { ...tempData, [node]: demographicsFormData[node] };
      }
    });
    return tempData;
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = useCallback((name, value, checked) => {
    setDemographicsFormData((prevData) => {
      const updatedFormData = { ...prevData };
      const oldData = updatedFormData?.[name] || [];
      let newData = [];
      if (oldData.length < 1) {
        newData.push(value);
      } else {
        if (checked) {
          newData = [...oldData];
          newData.push(value);
        } else {
          newData = oldData.filter((ele) => ele !== value);
        }
      }

      updatedFormData[name] = newData;
      return updatedFormData;
    });
  }, []);

  const getPayload = () => {
    let updatedData = setDemographicsData(demographicsFormData, clientYear);
    let oldData =
      JSON.parse(localStorage.getItem("demographics_actual_data")) || null;
    if (oldData && updatedData) {
      let keysBeforeUpdate = Object.keys(oldData);
      let keysAfterUpdate = Object.keys(updatedData);
      const unwantedDataKeys = keysBeforeUpdate.filter(
        (element) => !keysAfterUpdate.includes(element),
      );
      unwantedDataKeys.forEach((key) => (updatedData[key] = oldData[key]));
    }

    return updatedData;
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      let noErrors = Object.keys(fieldErrors).every(
        (key) => fieldErrors[key] === "",
      );
      if (noErrors) {
        const payload = getPayload();
        return payload;
      } else {
        toast.error("Please enter valid values");
      }
    },
  }));
  useEffect(() => {
    const payload = getPayload();
    props.setAutoSaveDemographicsData({ ...payload });
  }, [demographicsFormData]);
  const handleDeleteSubTree = (objectData, name, deletedValue) => {
    let deleteRegex = "";
    if (deletedValue === "yes" || deletedValue === "no") {
      deleteRegex = `${name}Sub`;
    } else {
      deleteRegex = getSubTree(name, deletedValue, clientYear);
    }

    if (deleteRegex !== "") {
      let updatedObjectData = deleteSubTree(objectData, deleteRegex);
      return updatedObjectData;
    }
    return objectData;
  };

  const calcFieldValue = (name, value) => {
    if (name === "demograph_age") {
      const dateVal = value || "";
      if (dateVal !== "") {
        const dobVal = dayjs(dateVal);
        const todayVal = dayjs();
        const ageTemp = todayVal.diff(dobVal, "years");
        return `${ageTemp}`;
      }
    }

    return null;
  };

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={isLoading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        style={{
          margin: "10px 20px",
        }}
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const {
              questionId,
              name,
              xs,
              type,
              isSingleButton,
              isCommentEnabled,
              label,
              disabled,
            } = inputProps;
            const inputValue = demographicsFormData[name] || "";
            const commentValue = demographicsFormData?.[`${name}Comment`]
              ? `${demographicsFormData[`${name}Comment`]}`
              : "";

            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              if (
                name === "demograph_primary" ||
                name === "demograph_secondary" ||
                name === "demograph_aContact" ||
                name === "demograph_eNo" ||
                name === "demograph_pPhone" ||
                name === "demograph_pFax" ||
                name === "demograph_zip" ||
                name === "demograph_mZip" ||
                name === "demograph_pZip" ||
                name === "demograph_email"
              ) {
                let inputPropsCalc = {
                    readOnly: disableFlag || disabled,
                  },
                  minLength = 0,
                  maxLength = 0;
                if (
                  name === "demograph_primary" ||
                  name === "demograph_secondary" ||
                  name === "demograph_aContact" ||
                  name === "demograph_eNo" ||
                  name === "demograph_pPhone" ||
                  name === "demograph_pFax"
                ) {
                  minLength = 10;
                  maxLength = 12;
                  inputPropsCalc = {
                    ...inputPropsCalc,
                    minLength,
                    maxLength,
                  };
                } else if (
                  name === "demograph_zip" ||
                  name === "demograph_mZip" ||
                  name === "demograph_pZip"
                ) {
                  minLength = 5;
                  maxLength = 10;
                  inputPropsCalc = {
                    ...inputPropsCalc,
                    minLength,
                    maxLength,
                  };
                }

                return (
                  <Grid
                    key={quesSlug}
                    xs={xs}
                    /* sm={inputProps.sm}*/
                    item
                  >
                    <>
                      <Stack direction="row">
                        <Grid item xs={isCommentEnabled ? 11 : 12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                            }}
                          >
                            <InputLabel
                              sx={{
                                color: "var(--mainTheme)",
                                fontWeight: "600",
                              }}
                            >
                              {label || ""}
                            </InputLabel>
                          </div>
                          <TextField
                            value={inputValue}
                            multiline
                            sx={{
                              borderRadius: "5px",
                              border: "1.5px solid var(--mainTheme) !important",
                              "& .MuiFormLabel-root": {
                                backgroundColor: "white",
                              },
                              "& .MuiInputLabel-root": {
                                backgroundColor: "white",
                              },
                            }}
                            inputProps={{ ...inputPropsCalc }}
                            fullWidth
                            onKeyUp={(event) => {
                              if (
                                event.code === "Backspace" ||
                                event.code === "Delete"
                              ) {
                                handleTextChange(
                                  event.target.name,
                                  event.target.value,
                                  "delete",
                                );
                              }
                            }}
                            onChange={(event) => {
                              handleTextChange(
                                event.target.name,
                                event.target.value,
                              );
                            }}
                            onPaste={(event) =>
                              handlePaste(
                                event,
                                event.target.name,
                                event.clipboardData.getData("text"),
                              )
                            }
                            name={name}
                            InputLabelProps={{ shrink: true }}
                          />
                          {fieldErrors?.[name] &&
                            fieldErrors?.[name] !== "" && (
                              <p style={{ margin: "0px", color: "tomato" }}>
                                {fieldErrors[name]}
                              </p>
                            )}
                        </Grid>
                        {isCommentEnabled === true && (
                          <DFCommentIconMemo
                            name={name}
                            value={commentValue}
                            setFieldValues={setFieldValues}
                            disableFlag={disableFlag}
                          />
                        )}
                      </Stack>
                      {isCommentEnabled &&
                        isCommentVisibleMemo(getFieldValues, name) && (
                          <DFCommentTextBoxMemo
                            label={label}
                            handleTextChange={handleTextChange}
                            name={name}
                            value={commentValue}
                            disableFlag={disableFlag}
                          />
                        )}
                    </>
                  </Grid>
                );
              } else {
                return (
                  <Grid
                    key={quesSlug}
                    xs={xs}
                    /* sm={inputProps.sm}*/
                    item
                  >
                    <DFTextMemo
                      inputProps={inputProps}
                      value={inputValue}
                      handleTextChange={handleTextChange}
                      setFieldValues={setFieldValues}
                      getFieldValues={getFieldValues}
                      commentValue={commentValue}
                      disableFlag={disableFlag}
                    />
                  </Grid>
                );
              }
            } else if (type === "radio") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFRadioMemo
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange}
                    setFieldValues={setFieldValues}
                    getFieldValues={getFieldValues}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = demographicsFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDateMemo
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange}
                    setFieldValues={setFieldValues}
                    getFieldValues={getFieldValues}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelectMemo
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange}
                    setFieldValues={setFieldValues}
                    getFieldValues={getFieldValues}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              const treeValues = getNestingValues(name);
              return (
                <DFOptionsTreeMemo
                  inputProps={inputProps}
                  formData={treeValues}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  getNestingValues={getNestingValues}
                  disableFlag={disableFlag}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFLabelMemo
                    inputProps={inputProps}
                    handleTextChange={handleTextChange}
                    name={name}
                    commentValue={commentValue}
                    setFieldValues={setFieldValues}
                    getFieldValues={getFieldValues}
                    disableFlag={disableFlag}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={inputProps}
                    handleButtonClick={handleButtonClick}
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeadingMemo inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFCheckboxMemo
                    inputProps={inputProps}
                    handleCheckboxChange={handleCheckboxChange}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    setFieldValues={setFieldValues}
                    getFieldValues={getFieldValues}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                  />
                </Grid>
              );
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "divider") {
              return <DFRulerMemo />;
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default Demographics;

const dontReRender = (oldProps, newProps) => {
  /*
   
  returns false - if there is a change between oldProps & newProps
  returns true - if there is no change between oldProps & newProps

  console.log(
    JSON.stringify(oldProps),
    JSON.stringify(newProps),
    lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps)),
    "check memo"
  );

  */

  return lodash.isEqual(JSON.stringify(oldProps), JSON.stringify(newProps));
};

const DFTextMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label, disabled, pattern } = inputProps;

    const inputPropsCalc = {
      readOnly: disableFlag || disabled,
    };
    if (pattern && pattern !== "") {
      inputPropsCalc.pattern = pattern;
    }

    return (
      <>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <InputLabel
                sx={{
                  color: "var(--mainTheme)",
                  fontWeight: "600",
                }}
              >
                {label || ""}
              </InputLabel>
            </div>
            <TextField
              value={value}
              multiline={
                name !== "demograph_address" &&
                name !== "demograph_addressTwo" &&
                name !== "demograph_addressThree" &&
                name !== "demograph_city" &&
                name !== "demograph_state" &&
                name !== "demograph_mAddressOne" &&
                name !== "demograph_mAddTwo" &&
                name !== "demograph_mAddressThree" &&
                name !== "demograph_mCity" &&
                name !== "demograph_mState" &&
                name !== "demograph_pName" &&
                name !== "demograph_pAddOne" &&
                name !== "demograph_pCity" &&
                name !== "demograph_pState" &&
                name !== "demograph_pcpcounty" &&
                name !== "demograph_ename" &&
                name !== "demograph_pcpNpi" &&
                name !== "demograph_pId" &&
                name !== "demograph_pOfcName"
              }
              sx={{
                borderRadius: "5px",
                border: "1.5px solid var(--mainTheme) !important",
                "& .MuiFormLabel-root": {
                  backgroundColor: "white",
                },
                "& .MuiInputLabel-root": {
                  backgroundColor: "white",
                },
              }}
              inputProps={{ ...inputPropsCalc }}
              fullWidth
              onChange={(event) => {
                handleTextChange(event.target.name, event.target.value);
              }}
              name={name}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          {isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              setFieldValues={setFieldValues}
              getFieldValues={getFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFTextMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  commentValue: PropTypes.string,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

const DFRadioMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleRadioChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label } = inputProps;

    return (
      <>
        <InputLabel
          sx={{
            color: "var(--mainTheme)",
            fontWeight: "600",
            width: "100%",
          }}
        >
          {label}
        </InputLabel>
        <Stack direction="row">
          <Grid
            item
            xs={inputProps.isCommentEnabled ? 11 : 12}
            component="fieldset"
            style={{
              borderWidth: 0.5,
              borderColor: "var(--mainTheme850)",
              borderStyle: "solid",
              borderRadius: "5px",
            }}
          >
            <FormControl className="inputStyle" fullWidth>
              <RadioGroup
                className="inputStyle"
                variant="outlined"
                sx={{ marginLeft: "10px" }}
                row
                name={inputProps.name}
                value={value}
                onChange={(event) =>
                  handleRadioChange(event.target.name, event.target.value)
                }
              >
                {inputProps.options.map((option, index) => (
                  <FormControlLabel
                    key={option[1]}
                    {...(disableFlag && { disabled: true })}
                    control={<Radio />}
                    label={option[0]}
                    value={option[1]}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          {inputProps.isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFRadioMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  commentValue: PropTypes.string,
  handleRadioChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

export const DFDateMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleDateChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label, variant, disableFutureFlag } =
      inputProps;
    const dateValue = value && value !== "" ? dayjs(value) : {};

    return (
      <>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <InputLabel
              sx={{
                color: "var(--mainTheme)",
                fontWeight: "600",
              }}
            >
              {label || ""}
            </InputLabel>
            <FormControl fullWidth>
              {(() => {
                if (variant === "1") {
                  return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        {...(disableFutureFlag && { disableFuture: true })}
                        name={name}
                        value={dateValue}
                        sx={{
                          border: "1.5px solid var(--mainTheme) !important",
                          "& .MuiFormLabel-root": {
                            backgroundColor: "white",
                          },
                          "& .MuiInputLabel-root": {
                            backgroundColor: "white",
                          },
                          borderRadius: "5px",
                        }}
                        onChange={(newValue) =>
                          handleDateChange(name, newValue)
                        }
                        {...(disableFlag && { disabled: true })}
                        views={["year", "month", "day"]}
                        slotProps={{
                          textField: {
                            readOnly: true,
                            InputLabelProps: { shrink: true },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  );
                }
              })()}
            </FormControl>
          </Grid>
          {inputProps.isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFDateMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  commentValue: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

export const DFSelectMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    handleSelectChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label } = inputProps;

    const selectedValue = value;

    return (
      <>
        <InputLabel
          sx={{
            color: "var(--mainTheme)",
            fontWeight: "600",
            display: "flex",
          }}
        >
          {label}
        </InputLabel>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <FormControl variant="outlined" fullWidth>
              <Select
                label={label}
                style={{ borderRadius: "5px" }}
                sx={{
                  border: "1.5px solid var(--mainTheme) !important",
                  "& .MuiFormLabel-root": {
                    backgroundColor: "white",
                    color: "rgba(0,0,0,0.38)!important",
                  },
                  "& .MuiInputLabel-root": {
                    backgroundColor: "white",
                    color: "rgba(0,0,0,0.38)!important",
                  },
                }}
                name={name}
                value={selectedValue || "Select"}
                onChange={(event) => {
                  handleSelectChange(event.target.name, event.target.value);
                }}
                {...(disableFlag && { disabled: true })}
                fullWidth
              >
                <MenuItem
                  /* disabled */
                  value="Select"
                >
                  Select
                </MenuItem>
                {inputProps.options.map((item, index) => (
                  <MenuItem key={item[1]} value={item[1]}>
                    {item[0]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {inputProps.isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {inputProps.isCommentEnabled &&
          isCommentVisibleMemo(getFieldValues, name) && (
            <DFCommentTextBoxMemo
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          )}
      </>
    );
  },
  dontReRender,
);

DFSelectMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  commentValue: PropTypes.string,
  handleSelectChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

export const DFLabelMemo = React.memo(
  ({
    inputProps,
    commentValue,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label } = inputProps;
    const text =
      "Members should wear their regular footwear and can use a walking aid, if needed. Observe the patient’s postural stability, gait, stride length, and sway. Begin by having the patient sit back in a standard armchair and identify a line 3 meters or 10 feet away, on the floor.";
    return (
      <>
        <Stack direction="row">
          <Grid item xs={isCommentEnabled ? 11 : 12}>
            <FormControl
              className="inputStyle"
              component="fieldset"
              variant="outlined"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "3px", fontWeight: "600" }}
                >
                  {label}
                  {name === "home_directions" && (
                    <Tooltip
                      title={
                        <div style={{ fontSize: "16px", width: "250px" }}>
                          {text}
                        </div>
                      }
                      placement="top"
                      arrow
                    >
                      <InfoIcon
                        variant="outlined"
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  )}
                </FormLabel>
              </div>
            </FormControl>
          </Grid>

          {isCommentEnabled === true && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </Stack>
        {isCommentEnabled && isCommentVisibleMemo(getFieldValues, name) && (
          <DFCommentTextBoxMemo
            label={label}
            handleTextChange={handleTextChange}
            name={name}
            value={commentValue}
            disableFlag={disableFlag}
          />
        )}
      </>
    );
  },
  dontReRender,
);

DFLabelMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  commentValue: PropTypes.string,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

export const DFCheckboxMemo = React.memo(
  ({
    inputProps,
    value,
    commentValue,
    quesSlug,
    sectionId,
    rosFormData,
    allergiesFormData,
    handleCheckboxChange,
    handleTextChange,
    getFieldValues,
    setFieldValues,
    disableFlag,
  }) => {
    const { isCommentEnabled, name, label, variant } = inputProps;
    const inputValue = value && value?.length > 0 ? value : [];

    if (
      (sectionId == 10 && name === "gait_Recommendations") ||
      inputProps.name === "respiratory_Recommendations" ||
      inputProps.name === "integument_Recommendations" ||
      inputProps.name === "musko_Recommendations" ||
      inputProps.name === "hema_Recommendations" ||
      inputProps.name === "ros_cardiovascularRecommendations" ||
      inputProps.name === "ros_gastrorecommendations" ||
      inputProps.name === "ros_genitoRecommendations" ||
      inputProps.name === "ros_endocrineIsEndocrineRecommendations" ||
      inputProps.name === "ros_diagnosisOfCancerRecommendations" ||
      inputProps.name === "ros_eyeENTNeckRecommendations"
    ) {
      return (
        <Grid key={quesSlug} item>
          <Grid key={quesSlug} container alignItems="center">
            <Grid item xs={12}>
              <FormControl
                className="inputStyle"
                component="fieldset"
                variant="outlined"
                style={{
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel
                    className="inputStyle"
                    style={{ marginLeft: "15px" }}
                  >
                    {label}
                  </FormLabel>
                </div>
                {inputProps.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className="inputStyle"
                              variant="outlined"
                              name={name}
                              checked={inputValue?.includes(option[1])}
                              onChange={(event) =>
                                handleCheckboxChange(
                                  event.target.name,
                                  option[1],
                                  event.target.checked,
                                )
                              }
                              {...(disableFlag && { disabled: true })}
                            />
                          }
                          label={option[0]}
                        />
                        {isCommentEnabled &&
                          isCommentVisibleMemo(
                            getFieldValues,
                            `${name}${option[1]}`,
                          ) && (
                            <DFCommentTextBoxMemo
                              label={label}
                              handleTextChange={handleTextChange}
                              name={`${name}${option[1]}`}
                              value={rosFormData[`${name}${option[1]}Comment`]}
                              disableFlag={disableFlag}
                              routedFrom={"checkbox"}
                            />
                          )}
                      </div>
                      {inputProps.isCommentEnabled === true && (
                        <DFCommentIconMemo
                          name={name}
                          value={commentValue}
                          getFieldValues={getFieldValues}
                          setFieldValues={setFieldValues}
                          disableFlag={disableFlag}
                        />
                      )}
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    if (
      sectionId == 9 &&
      (name === "allergies_Recommendations2" ||
        name === "allergies_Recommendations")
    ) {
      return (
        <Grid key={quesSlug} item>
          <Grid key={quesSlug} container alignItems="center">
            <Grid item xs={12}>
              <FormControl
                className="inputStyle"
                component="fieldset"
                variant="outlined"
                style={{
                  width: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormLabel
                    className="inputStyle"
                    style={{ marginLeft: "15px" }}
                  >
                    {label}
                  </FormLabel>
                </div>
                {inputProps.options.map((option, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className="inputStyle"
                              variant="outlined"
                              name={name}
                              checked={inputValue?.includes(option[1])}
                              onChange={(event) =>
                                handleCheckboxChange(
                                  event.target.name,
                                  option[1],
                                  event.target.checked,
                                )
                              }
                              {...(disableFlag && { disabled: true })}
                            />
                          }
                          label={option[0]}
                        />
                        {isCommentEnabled &&
                          isCommentVisibleMemo(
                            getFieldValues,
                            `${name}${option[1]}`,
                          ) && (
                            <DFCommentTextBoxMemo
                              label={label}
                              handleTextChange={handleTextChange}
                              name={`${name}${option[1]}`}
                              value={
                                allergiesFormData[`${name}${option[1]}Comment`]
                              }
                              disableFlag={disableFlag}
                              routedFrom={"checkbox"}
                            />
                          )}
                      </div>
                      {inputProps.isCommentEnabled === true && (
                        <DFCommentIconMemo
                          name={name}
                          value={commentValue}
                          getFieldValues={getFieldValues}
                          setFieldValues={setFieldValues}
                          disableFlag={disableFlag}
                        />
                      )}
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    /*
  to-do

  variant-1
    group of checkbox under single label

  variant-2
    single checkbox with no label
  */
    if (variant === 1) {
      return (
        <>
          <Stack direction="row">
            <Grid
              item
              xs={isCommentEnabled ? 11 : 12}
              component="fieldset"
              style={{
                borderWidth: 0.5,
                borderColor: "var(--mainTheme850)",
                borderStyle: "solid",
                borderRadius: "5px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "15px" }}
                >
                  {label}
                </FormLabel>
              </div>
              <br />
              <FormGroup className="inputStyle" fullWidth row>
                {inputProps.options.map((option, index) => (
                  <div
                    key={quesSlug}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="inputStyle"
                          variant="outlined"
                          name={name}
                          checked={inputValue?.includes(option[1])}
                          onChange={(event) =>
                            handleCheckboxChange(
                              event.target.name,
                              option[1],
                              event.target.checked,
                            )
                          }
                          {...(disableFlag && { disabled: true })}
                        />
                      }
                      label={option[0]}
                    />
                  </div>
                ))}
              </FormGroup>
            </Grid>
            {inputProps.isCommentEnabled === true && (
              <DFCommentIconMemo
                name={name}
                value={commentValue}
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
                disableFlag={disableFlag}
              />
            )}
          </Stack>
          {inputProps.isCommentEnabled &&
            isCommentVisibleMemo(getFieldValues, name) && (
              <DFCommentTextBoxMemo
                label={label}
                handleTextChange={handleTextChange}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            )}
        </>
      );
    } else if (variant === 2) {
      return (
        <>
          <Stack direction="row">
            <Grid item xs={isCommentEnabled ? 11 : 12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormLabel
                  className="inputStyle"
                  style={{ marginLeft: "15px" }}
                >
                  {label}
                </FormLabel>
              </div>
              <br />
              <FormGroup className="inputStyle" fullWidth row>
                {inputProps.options.map((option, index) => (
                  <div
                    key={quesSlug}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="inputStyle"
                          variant="outlined"
                          name={name}
                          checked={value?.includes(option[1])}
                          onChange={(event) =>
                            handleCheckboxChange(
                              event.target.name,
                              option[1],
                              event.target.checked,
                            )
                          }
                          {...(disableFlag && { disabled: true })}
                        />
                      }
                      label={option[0]}
                    />
                  </div>
                ))}
              </FormGroup>
            </Grid>
            {inputProps.isCommentEnabled === true && (
              <DFCommentIconMemo
                name={name}
                value={commentValue}
                getFieldValues={getFieldValues}
                setFieldValues={setFieldValues}
                disableFlag={disableFlag}
              />
            )}
          </Stack>
          {inputProps.isCommentEnabled &&
            isCommentVisibleMemo(getFieldValues, name) && (
              <DFCommentTextBoxMemo
                label={label}
                handleTextChange={handleTextChange}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            )}
        </>
      );
    } else {
      return (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputLabel
              sx={{
                color: "var(--mainTheme)",
                fontWeight: "600",
              }}
            >
              {label || ""}
            </InputLabel>
          </div>
          <Stack direction="row">
            <Grid
              item
              // xs={isCommentEnabled ? 11 : 12}
              xs={12}
              // component="fieldset"
              // style={{
              //   borderWidth: 0.5,
              //   borderColor: "var(--mainTheme850)",
              //   borderStyle: "solid",
              //   borderRadius: "5px",
              // }}
            >
              <FormGroup className="inputStyle" fullWidth row>
                {inputProps.options.map((option, index) => (
                  <div
                    key={`${quesSlug}_${index}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="inputStyle"
                            variant="outlined"
                            name={name}
                            checked={inputValue?.includes(option[1])}
                            onChange={(event) =>
                              handleCheckboxChange(
                                event.target.name,
                                option[1],
                                event.target.checked,
                              )
                            }
                            {...(disableFlag && { disabled: true })}
                          />
                        }
                        label={option[0]}
                      />
                    </div>
                    {inputProps.isCommentEnabled === true && (
                      <DFCommentIconMemo
                        name={name}
                        value={commentValue}
                        getFieldValues={getFieldValues}
                        setFieldValues={setFieldValues}
                        disableFlag={disableFlag}
                      />
                    )}
                  </div>
                ))}
              </FormGroup>
            </Grid>
          </Stack>
          {inputProps.isCommentEnabled &&
            isCommentVisibleMemo(getFieldValues, name) && (
              <DFCommentTextBoxMemo
                label={label}
                handleTextChange={handleTextChange}
                name={name}
                value={commentValue}
                disableFlag={disableFlag}
              />
            )}
        </>
      );
    }
  },
  dontReRender,
);

DFCheckboxMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  value: PropTypes.any.isRequired,
  commentValue: PropTypes.any.isRequired,
  quesSlug: PropTypes.any.isRequired,
  sectionId: PropTypes.any.isRequired,
  rosFormData: PropTypes.object,
  allergiesFormData: PropTypes.object,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
};

export const DFHeadingMemo = React.memo(({ inputProps }) => {
  return (
    <Stack direction="row">
      <Grid
        item
        xs={12}
        style={{
          backgroundColor: "var(--mainTheme850)",
          // textTransform: "uppercase",
          // borderRadius: "5px 5px 0 0",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "10px",
            color: "white",
            // textTransform: "uppercase",
            fontWeight: "500",
          }}
        >
          {inputProps.label}
        </div>
      </Grid>
    </Stack>
  );
}, dontReRender);

DFHeadingMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
};

export const DFRulerMemo = () => {
  return (
    <Grid xs={12} item>
      <Divider orientation="horizontal" style={{ backgroundColor: "black" }} />
    </Grid>
  );
};

DFRulerMemo.propTypes = {};

export const ToggleTree = ({
  depth,
  sectionId,
  quesIdSlug,
  quesNameSlug,
  inputProps,
  formData,
  handleOptionsTreeChange,
  handleTextChange,
  handleRadioChange,
  handleDateChange,
  handleSelectChange,
  handleButtonClick,
  handleCheckboxChange,
  getFieldValues,
  setFieldValues,
  disableFlag,
  getNestingValues,
  getFieldVisibility,
}) => {
  const { isCommentEnabled, name, label, xs, isMulti, disabled } = inputProps;
  const isButtonDisabled = disableFlag || disabled;
  // const { getStateValue, setStateValue } = useFillHraContext();

  const selectedValue = formData?.[name] || [];
  const commentValue = formData ? formData[name + "Comment"] : "";

  const shouldShowIcon = !(
    (name === "ros_eyes" ||
      name === "ros_ears" ||
      name === "ros_nose" ||
      name === "ros_mouth" ||
      name === "ros_neck" ||
      name === "ros_neuroPsych" ||
      name === "ros_musculoskeletal") &&
    Array.isArray(selectedValue) &&
    selectedValue.includes("yes")
  );

  return (
    <Grid
      container
      style={{
        marginLeft: depth < 2 ? "0px" : "15px",
      }}
      fullWidth
    >
      <Grid
        item
        key={quesIdSlug}
        style={{ display: "grid", flexBasis: "100%", maxWidth: "100%" }}
        xs={xs || 12}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {depth > 1 ? (
              <MdOutlineSubdirectoryArrowRight
                style={{
                  color: "var(--mainTheme)",
                  marginRight: "10px",
                  height: "25px",
                  width: "25px",
                }}
              />
            ) : (
              <div style={{ margin: "0px !important" }}></div>
            )}

            <InputLabel
              style={{
                color: "var(--mainTheme)",
                fontWeight: "700",
              }}
            >
              {inputProps.label}
            </InputLabel>
          </div>
          {isCommentEnabled === true && shouldShowIcon && (
            <DFCommentIconMemo
              name={name}
              value={commentValue}
              getFieldValues={getFieldValues}
              setFieldValues={setFieldValues}
              disableFlag={disableFlag}
            />
          )}
        </div>
        <ToggleButtonGroup
          {...(!isMulti && { exclusive: true })}
          fullWidth
          role="group"
          size="large"
          onChange={(e, newValues) => {
            handleOptionsTreeChange(name, newValues, isMulti);
          }}
          selected={selectedValue}
          name={inputProps.name}
          value={selectedValue}
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {inputProps?.subQuestions?.map((subQuestion, index) => {
            return (
              <ToggleButton
                key={`${quesIdSlug}_${index}`}
                value={subQuestion.value}
                aria-pressed
                disableRipple
                name={subQuestion.name}
                role="button"
                aria-label={subQuestion.label}
                className="toggleButtonGroup"
                {...(isButtonDisabled && { disabled: true })}
                sx={{
                  overflow: "hidden",
                  display: "inline-block",
                  minHeight: "60px",
                  margin: "12px",
                  width: "230px",
                  textAlign: "center",
                  justifyContent: "center",
                  borderRadius: "5px",
                  color: "black",
                  borderStyle: "none",
                  textTransform: "none",
                  border: `1px solid var(--mainTheme) !important`,
                  flexWrap: "wrap",
                  padding: "5px",
                  fontSize: "0.85rem",
                  "&.Mui-selected ": {
                    backgroundColor: "var(--mainTheme900)",
                    color: "white",
                    border: "1px solid var(--mainTheme) !important",
                    fontWeight: "600",
                    fontSize: "0.85rem",
                  },
                  "&.Mui-disabled ": {
                    color: "black",
                    border: "1px solid var(--mainTheme) !important",
                    fontWeight: "600",
                    fontSize: "0.85rem",
                  },
                  "&.Mui-selected:hover": {
                    backgroundColor: "var(--mainTheme750)",
                    color: "white",
                    border: "1px solid var(--mainTheme) !important",
                  },
                  "&.MuiToggleButtonGroup-grouped": {
                    borderRadius: "5px !important",
                  },
                  ":hover": {
                    backgroundColor: "var(--mainTheme400)",
                    border: "1px solid var(--mainTheme) !important",
                  },
                }}
              >
                {subQuestion.label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        {isCommentEnabled &&
          shouldShowIcon &&
          isCommentVisibleMemo(getFieldValues, name) && (
            <DFCommentTextBoxMemo
              label={label}
              handleTextChange={handleTextChange}
              name={name}
              value={commentValue}
              disableFlag={disableFlag}
            />
          )}
        {selectedValue &&
          inputProps?.subQuestions?.map((subQuestion) => {
            const isValueSelected = selectedValue?.includes(subQuestion.value);
            const isLinkedFieldSelected =
              getFieldVisibility !== undefined
                ? getFieldVisibility(name)
                : false;

            const canCallRecursive =
              subQuestion.hasSubQuestions === true &&
              (isLinkedFieldSelected || isValueSelected);
            if (canCallRecursive) {
              const newQuesSlug = `${quesIdSlug}_${subQuestion.subQuestionId}`;
              const nestedTreeValues = getNestingValues(name);
              return (
                <NestedTree
                  key={newQuesSlug}
                  depth={depth + 1}
                  quesIdSlug={newQuesSlug}
                  quesNameSlug={`${quesNameSlug}_${
                    subQuestion.name || subQuestion.value || ""
                  }`}
                  sectionId={sectionId}
                  subQuestions={subQuestion.subQuestions}
                  formData={nestedTreeValues}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  selectedValue={selectedValue}
                  disableFlag={disableFlag}
                />
              );
            }
            return null;
          })}
      </Grid>
    </Grid>
  );
};

ToggleTree.propTypes = {
  depth: PropTypes.number,
  sectionId: PropTypes.number,
  quesIdSlug: PropTypes.string,
  quesNameSlug: PropTypes.string,
  inputProps: PropTypes.object,
  formData: PropTypes.object,
  familyRowData: PropTypes.object,
  setFamilyRowData: PropTypes.func,
  medicalRowData: PropTypes.object,
  setMedicalRowData: PropTypes.func,
  allergiesRowData: PropTypes.object,
  setAllergiesRowData: PropTypes.func,
  medicationsRowData: PropTypes.object,
  setMedicationsRowData: PropTypes.func,
  counterMedicationsRowData: PropTypes.object,
  setCounterMedicationsRowData: PropTypes.func,
  tableName: PropTypes.string,
  data: PropTypes.object,
  handleOptionsTreeChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleButtonClick: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  getFieldValues: PropTypes.func,
  setFieldValues: PropTypes.func,
  getNestingValues: PropTypes.func,
  getFieldVisibility: PropTypes.func,
  disableFlag: PropTypes.bool,
};

export const NestedTree = ({
  depth,
  quesIdSlug,
  quesNameSlug,
  sectionId,
  subQuestions,
  formData,
  handleOptionsTreeChange,
  handleTextChange,
  handleRadioChange,
  handleDateChange,
  handleSelectChange,
  handleButtonClick,
  handleCheckboxChange,
  getFieldValues,
  setFieldValues,
  disableFlag,
}) => {
  // const { getStateValue, setStateValue } = useFillHraContext();

  return (
    <div style={{ marginLeft: "15px" }}>
      <Grid container gap={1} style={{ marginLeft: "0px" }}>
        {subQuestions?.map((subQuestion) => {
          const { subQuestionId, name, xs, type, isSingleButton } = subQuestion;
          const inputValue = formData?.[name] || "";
          const commentValue = formData?.[name + "Comment"]
            ? formData[name + "Comment"]
            : "";
          const quesIdSlugVal = `${quesIdSlug}_${subQuestionId}`;
          const quesNameSlugVal = `${quesNameSlug}_${
            subQuestion.name || subQuestion.value || ""
          }`;
          if (
            type === "text" ||
            type === "number" ||
            type === "email" ||
            type === "password"
          ) {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    {depth > 2 ? (
                      <MdOutlineSubdirectoryArrowRight
                        style={{
                          color: "var(--mainTheme)",
                          marginRight: "10px",
                          height: "25px",
                          width: "25px",
                        }}
                      />
                    ) : (
                      <div style={{ margin: "0px !important" }}></div>
                    )}
                  </div>
                  <div style={{ width: "90%" }}>
                    <DFTextMemo
                      inputProps={subQuestion}
                      value={inputValue}
                      commentValue={commentValue}
                      handleTextChange={handleTextChange}
                      getFieldValues={getFieldValues}
                      setFieldValues={setFieldValues}
                      disableFlag={disableFlag}
                    />
                  </div>
                </div>
              </Grid>
            );
          } else if (type === "radio") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFRadioMemo
                  inputProps={subQuestion}
                  value={inputValue}
                  commentValue={commentValue}
                  handleRadioChange={handleRadioChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "date") {
            const dateValue = formData[name] || {};
            // getFieldValues(name) === "" ? {} : getFieldValues(name);
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFDateMemo
                  inputProps={subQuestion}
                  value={dateValue}
                  commentValue={commentValue}
                  handleDateChange={handleDateChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "select") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFSelectMemo
                  inputProps={subQuestion}
                  value={inputValue}
                  commentValue={commentValue}
                  handleSelectChange={handleSelectChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "options_tree") {
            return (
              <ToggleTree
                key={quesIdSlugVal}
                inputProps={subQuestion}
                formData={formData}
                handleOptionsTreeChange={handleOptionsTreeChange}
                handleTextChange={handleTextChange}
                handleRadioChange={handleRadioChange}
                handleDateChange={handleDateChange}
                handleSelectChange={handleSelectChange}
                handleButtonClick={handleButtonClick}
                handleCheckboxChange={handleCheckboxChange}
                // setFormData={setFormData}
                setFieldValues={setFieldValues}
                getFieldValues={getFieldValues}
                commentValue={commentValue}
                disableFlag={disableFlag}
                selectedValue={inputValue}
                depth={depth + 1}
                quesIdSlug={quesIdSlugVal}
                sectionId={sectionId}
                quesNameSlug={quesNameSlugVal}
              />
            );
          } else if (type === "label") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFLabelMemo
                  inputProps={subQuestion}
                  commentValue={commentValue}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "button") {
            return (
              <Grid key={quesIdSlugVal} xs={isSingleButton ? 12 : 0} item>
                <DFButton
                  inputProps={subQuestion}
                  handleButtonClick={handleButtonClick}
                />
              </Grid>
            );
          } else if (type === "heading") {
            return (
              <Grid key={quesIdSlugVal} xs={12} sm={12} item>
                <DFHeadingMemo inputProps={subQuestion} />
              </Grid>
            );
          } else if (type === "checkbox") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFCheckboxMemo
                  inputProps={subQuestion}
                  value={inputValue}
                  commentValue={commentValue}
                  quesSlug={quesIdSlugVal}
                  sectionId={sectionId}
                  handleCheckboxChange={handleCheckboxChange}
                  handleTextChange={handleTextChange}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                  disableFlag={disableFlag}
                />
              </Grid>
            );
          } else if (type === "image") {
            return (
              <Grid key={quesIdSlugVal} xs={xs} item>
                <DFImage inputProps={subQuestion} />
              </Grid>
            );
          }

          return null;
        })}
      </Grid>
    </div>
  );
};

NestedTree.propTypes = {
  depth: PropTypes.number,
  quesIdSlug: PropTypes.string,
  quesNameSlug: PropTypes.string,
  sectionId: PropTypes.number,
  subQuestions: PropTypes.array,
  formData: PropTypes.object,
  handleOptionsTreeChange: PropTypes.func,
  handleTextChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  handleDateChange: PropTypes.func,
  handleSelectChange: PropTypes.func,
  handleButtonClick: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  getFieldValues: PropTypes.func,
  setFieldValues: PropTypes.func,
  disableFlag: PropTypes.bool,
};

export const DFOptionsTreeMemo = React.memo(
  ({
    inputProps,
    formData,
    quesSlug,
    sectionId,
    handleOptionsTreeChange,
    handleTextChange,
    handleRadioChange,
    handleDateChange,
    handleSelectChange,
    handleButtonClick,
    handleCheckboxChange,
    getFieldValues,
    setFieldValues,
    getFieldVisibility,
    getNestingValues,
    disableFlag,
    familyRowData,
    setFamilyRowData,
    medicalRowData,
    setMedicalRowData,
    allergiesRowData,
    setAllergiesRowData,
    medicationsRowData,
    setMedicationsRowData,
    counterMedicationsRowData,
    setCounterMedicationsRowData,
    tableName,
    data,
  }) => {
    const { name, xs } = inputProps;
    // const { getStateValue, setStateValue } = useFillHraContext();

    return (
      <Grid
        item
        key={quesSlug}
        xs={xs || 12}
        style={{ marginLeft: "0px" }}
        zeroMinWidth
      >
        <ToggleTree
          depth={1}
          sectionId={sectionId}
          quesIdSlug={`${quesSlug}`}
          quesNameSlug={`${name}`}
          inputProps={inputProps}
          formData={formData}
          familyRowData={familyRowData}
          setFamilyRowData={setFamilyRowData}
          medicalRowData={medicalRowData}
          setMedicalRowData={setMedicalRowData}
          allergiesRowData={allergiesRowData}
          setAllergiesRowData={setAllergiesRowData}
          medicationsRowData={medicationsRowData}
          setMedicationsRowData={setMedicationsRowData}
          counterMedicationsRowData={counterMedicationsRowData}
          setCounterMedicationsRowData={setCounterMedicationsRowData}
          tableName={tableName}
          data={data}
          handleOptionsTreeChange={handleOptionsTreeChange}
          handleTextChange={handleTextChange}
          handleRadioChange={handleRadioChange}
          handleDateChange={handleDateChange}
          handleSelectChange={handleSelectChange}
          handleButtonClick={handleButtonClick}
          handleCheckboxChange={handleCheckboxChange}
          getFieldValues={getFieldValues}
          setFieldValues={setFieldValues}
          getNestingValues={getNestingValues}
          getFieldVisibility={getFieldVisibility}
          disableFlag={disableFlag}
        />
      </Grid>
    );
  },
  dontReRender,
);

DFOptionsTreeMemo.propTypes = {
  inputProps: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  handleOptionsTreeChange: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  getFieldValues: PropTypes.func.isRequired,
  setFieldValues: PropTypes.func.isRequired,
  getFieldVisibility: PropTypes.func.isRequired,
  getNestingValues: PropTypes.func.isRequired,
  disableFlag: PropTypes.bool,
  familyRowData: PropTypes.object,
  setFamilyRowData: PropTypes.func,
  medicalRowData: PropTypes.object,
  setMedicalRowData: PropTypes.func,
  allergiesRowData: PropTypes.object,
  setAllergiesRowData: PropTypes.func,
  medicationsRowData: PropTypes.object,
  setMedicationsRowData: PropTypes.func,
  counterMedicationsRowData: PropTypes.object,
  setCounterMedicationsRowData: PropTypes.func,
  tableName: PropTypes.string,
  data: PropTypes.object,
};

export const toggleCommentBoxMemo = (getFieldValues, setFieldValues, name) => {
  let toggleSlug = `${name}Comment_toggle`;
  let newFlag = !getFieldValues(toggleSlug);

  setFieldValues(toggleSlug, newFlag);
};

export const isCommentVisibleMemo = (getFieldValues, name) => {
  let toggleSlug = `${name}Comment_toggle`;
  let toggleValue = getFieldValues(toggleSlug);

  return toggleValue;
};

export const DFCommentIconMemo = React.memo(
  ({ getFieldValues, setFieldValues, name, value, disableFlag }) => {
    return (
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        <ChatBubbleIcon
          style={{
            cursor: "pointer",
            color: "var(--mainTheme)",
          }}
          onClick={() => {
            if (disableFlag) {
              return null;
            }
            toggleCommentBoxMemo(getFieldValues, setFieldValues, name);
          }}
        />
      </Grid>
    );
  },
);

DFCommentIconMemo.propTypes = {
  quesSlug: PropTypes.string.isRequired,
  sectionId: PropTypes.number.isRequired,
  showComment: PropTypes.array.isRequired,
  setShowComment: PropTypes.func.isRequired,
};

export const DFCommentTextBoxMemo = React.memo(
  ({
    label,
    handleTextChange,
    name,
    value,
    disableFlag,
    style,
    routedFrom,
    // getFieldValues,
    // setFieldValues,
  }) => {
    const inputPropsCalc = {
      readOnly: disableFlag,
    };

    let commentLabel = "";
    if (label === "35. Allergies") {
      commentLabel = "Comments - Allergies";
    } else if (label === "36. Over the Counter Medications / Supplements") {
      commentLabel = "Comments - Over the Counter Medications / Supplements";
    } else {
      commentLabel = "Comments" + (label ? " - " + label : "");
    }
    let commentPlaceholder = "";
    if (label === "35. Allergies") {
      commentPlaceholder = "for Allergies";
    } else if (label === "36. Over the Counter Medications / Supplements") {
      commentPlaceholder = "for Over the Counter Medications / Supplements";
    } else {
      commentPlaceholder = label ? "for " + label : "";
    }

    return (
      <Grid item xs={11}>
        <span style={{ display: "flex" }}>
          <TextField
            label={commentLabel}
            placeholder={commentPlaceholder}
            onChange={(event) =>
              handleTextChange(`${name}Comment`, event.target.value)
            }
            inputProps={{ ...inputPropsCalc }}
            multiline
            maxRows={4}
            fullWidth
            value={value || ""}
            variant="outlined"
            className="inputStyle"
            sx={{
              ...style,
              "& .MuiFormLabel-root": {
                color: "var(--mainTheme)",
              },
              border: "1.5px solid var(--mainTheme) !important",
              borderRadius: "5px",
              "& .MuiFormLabel-root.Mui-focused": {
                color: "var(--mainTheme)",
              },
              marginTop: "20px",
              marginLeft: "10px",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ArrowForwardIcon
                    sx={{
                      size: "medium",
                      color: "var(--mainTheme)",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </span>
      </Grid>
    );
  },
);

DFCommentTextBoxMemo.propTypes = {
  label: PropTypes.string,
  handleTextChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  disableFlag: PropTypes.bool,
};
