import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setResetSuccess } from "../../../GlobalFunction";
import BG from "../../../assets/focuscares_bg.jpg";
import Logo from "../../../assets/focuscares_logo.png";
import Button from "../../../components/button/button";
import Image from "../../../components/image/image";
import Input from "../../../components/input/input";
import Toaster from "../../../components/toaster/toaster";
import { passwordRegex } from "../../../components/utils";
import { useSession } from "../../../contexts/SessionContext";
import Labels from "../../../resource_files/Labels.resx";
import ValidationMessage from "../../../resource_files/ValidationMessage.resx";
import { postApi } from "../../../utils/axiosApiWrapper";
import "../../OTP/OTPScreen.css";
const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterMessage, setToasterMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIcon, setPasswordIcon] = useState(<Visibility />);
  const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(
    <Visibility />,
  );
  const location = useLocation(); // Use the useLocation hook to get location state
  const { state } = location;
  const { username } = state || {}; // Extract the email from the location state
  const { setResetSessionExpired } = useSession();

  const passwordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  const confirmPasswordToggle = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  useEffect(() => {
    if (!confirmPasswordVisible) {
      setConfirmPasswordIcon(<VisibilityOff />);
    } else {
      setConfirmPasswordIcon(<Visibility />);
    }
  }, [confirmPasswordVisible]);

  const onChangeHandler = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);

    if (!inputValue) {
      setError((prevError) => ({
        ...prevError,
        password: ValidationMessage.Password_Required,
      }));
    } else if (inputValue.length < 8) {
      setError((prevError) => ({
        ...prevError,
        password: ValidationMessage.Password_Length,
      }));
    } else if (!passwordRegex.test(inputValue)) {
      setError((prevError) => ({
        ...prevError,
        password: ValidationMessage.Password_Format,
      }));
    } else {
      setError((prevError) => ({ ...prevError, password: "" }));
    }
  };

  const confirmPasswordChangeHandler = (e) => {
    const inputValue = e.target.value;

    setConfirmPassword(inputValue);

    if (!inputValue) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Required,
      }));
    } else if (inputValue.length < 8) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Length,
      }));
    } else if (inputValue !== password) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Mismatch,
      }));
    } else {
      setError((prevError) => ({ ...prevError, confirmPassword: "" }));
    }
  };

  useEffect(() => {
    // Check if the Reset screen has been displayed before
    const hasResetPageBeenRefreshed = localStorage.getItem(
      "hasResetPageBeenRefreshed",
    );

    // If Reset screen has been displayed before, then remove the flag and navigate
    if (hasResetPageBeenRefreshed) {
      localStorage.removeItem("hasResetPageBeenRefreshed");
      setResetSessionExpired(true);
      navigate(`/`);
    } else {
      // Add an event listener for beforeunload to handle page refresh
      const handlePageRefresh = (event) => {
        localStorage.removeItem("messageDisplayed");
        localStorage.setItem("hasResetPageBeenRefreshed", "true");
      };
      window.addEventListener("beforeunload", handlePageRefresh);

      // Define the cleanup function to remove the event listener
      return () => {
        window.removeEventListener("beforeunload", handlePageRefresh);
      };
    }
  }, [setResetSessionExpired, navigate]);

  useEffect(() => {
    if (!passwordVisible) {
      setPasswordIcon(<VisibilityOff />);
    } else {
      setPasswordIcon(<Visibility />);
    }
  }, [passwordVisible]);

  const handleSubmit = async () => {
    setError({});

    if (!password) {
      setError((prevError) => ({
        ...prevError,
        password: ValidationMessage.Password_Required,
      }));
      return;
    } else if (password.length < 8) {
      setError((prevError) => ({
        ...prevError,
        password: ValidationMessage.Password_Length,
      }));
      return;
    } else if (!passwordRegex.test(password)) {
      setError((prevError) => ({
        ...prevError,
        password: ValidationMessage.Password_Format,
      }));
      return;
    }
    if (!confirmPassword) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Required,
      }));
      return;
    } else if (confirmPassword.length < 8) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Length,
      }));
      return;
    } else if (password !== confirmPassword) {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: ValidationMessage.Confirm_Password_Mismatch,
      }));
      return;
    }

    // Send data to the server for password reset
    try {
      const response = await postApi(`/users/updatePassword/forgot`, {
        email: sessionStorage.getItem("emailId"),
        otp: sessionStorage.getItem("OTP"),
        password: password,
      });

      if (response.status == 200) {
        setToasterSeverity(Labels.SUCCESS);
        setResetSuccess(true);
        axios
          .get(`/`)
          .then(() => {
            navigate(`/`);
          })
          .catch((error) => {
            return;
          });
      } else {
        setResetSuccess(false);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response.data.message);
        setToasterOpen(true);
      }
    } catch (error) {
      setResetSuccess(false);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage(error.message);
      setToasterOpen(true);
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
  };
  const [confirmPasswordShowPassword, setConfirmPasswordShowPassword] =
    useState(false); // Separate state for confirm password field

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="login">
      <div className="top-ripple"></div>
      <div className="bottom-ripple"></div>
      <div className="image-container">
        <Image imgClass="bgImg" imgPath={BG} imgName="Focuscare Background" />
      </div>
      <div className="input-container">
        <Image imgClass="logoImg" imgPath={Logo} imageName="Focuscare Logo" />
        <div className="main-container">
          <h3 className="password-container">Enter Password</h3>
          <div className="confirmPassword">
            <Input
              type={showPassword ? "text" : "password"}
              inputtype="iconInput"
              onChangeHandler={onChangeHandler}
              value={password}
              name="Password"
              inputIcon={<LockIcon />}
              inputEndIcon={
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              mb="40px"
              placeholder="Password"
              // inputWidth="258px"
              width="100%"
              height="55px"
              // error={error.password || passwordError}
            />
            <h3
              className="password"
              style={{
                color: "#d32f2f",
                lineHeight: "14px",
                width: "400px",
                marginTop: "-42px",
              }}
            >
              {error.password || passwordError}
            </h3>
          </div>
          <h3 className="password-container">Confirm Password</h3>
          <div className="confirmPassword">
            <Input
              type={confirmPasswordShowPassword ? "text" : "password"}
              onChangeHandler={confirmPasswordChangeHandler}
              inputtype="iconInput"
              value={confirmPassword}
              name="ConfirmPassword"
              inputIcon={<LockIcon />}
              inputEndIcon={
                <IconButton onClick={toggleConfirmPasswordVisibility}>
                  {confirmPasswordShowPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={toggleConfirmPasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              mb="40px"
              placeholder="Confirm Password"
              // inputWidth="258px"
              width="100%"
              height="55px"
              // error={error.confirmPassword}
            />
            <h3
              className="password"
              style={{
                color: "#d32f2f",
                lineHeight: "14px",
                width: "400px",
                marginTop: "-42px",
              }}
            >
              {error.confirmPassword}
            </h3>
          </div>
          <div>
            <Button
              // type="submit"
              onClick={handleSubmit}
              width="100%"
              mb="20px"
              height="40px"
              color="white"
              background="#1076BC"
              cursor="pointer"
              fontSize="16px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </div>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </div>
  );
};

export default ResetPassword;
