import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import lodash from "lodash";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useCallback,
} from "react";
import LoadingOverlay from "react-loading-overlay";
import { DFButton } from "../../DFInputs/DFButton";
import { DFCheckbox } from "../../DFInputs/DFCheckbox";
import { DFDate } from "../../DFInputs/DFDate";
import { DFHeading } from "../../DFInputs/DFHeading";
import { DFImage } from "../../DFInputs/DFImage";
import { DFLabel } from "../../DFInputs/DFLabel";
import { DFOptionsTree } from "../../DFInputs/DFOptionsTree";
import { DFSelect } from "../../DFInputs/DFSelect";
import DFText from "../../DFInputs/DFText";
import { DFRuler } from "../../DFInputs/DFRuler";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";
import {
  deleteSubTree,
  getSelfAssessData,
  getSubTree,
  setSelfAssessData,
} from "./selfAssessmentMapping";
import { useUserContext } from "../../../../../../contexts/UserContext";
import { getInputsData } from "./selfAssessFormdata";
import { useLocation } from "react-router-dom";
import { getApi } from "../../../../../../utils/axiosApiWrapper";
import focusCaresLoader from "../../../../../../loader/focuscares_loader.gif";
import {
  DFCommentIcon,
  DFCommentIconMemo,
  DFCommentTextBox,
  isCommentVisible,
  isCommentVisibleMemo,
} from "../../DFInputs/DFComment";
import DFRadio from "../../DFInputs/DFRadio";

const alcAuditScoreMap_howOften = {
  never: 0,
  monthly: 1,
  twoTimesMonth: 2,
  twoTimesWeek: 3,
  fourTimesWeek: 4,
};
const alcAuditScoreMap_howMany = {
  oneOrTwo: 0,
  threeOrFour: 1,
  fiveOrSix: 2,
  sevenEight: 3,
  tenOrMore: 4,
};
const alcAuditScoreMap_howOftenSixMore = {
  never: 0,
  lessThanMonthly: 1,
  monthly: 2,
  weekly: 3,
  daily: 4,
};

const calcAlcAuditScore = (objectData) => {
  let currAuditScore = 0,
    formerAuditScore = 0;

  const currHowOften =
    objectData?.["self_alcUseCurrSubHowManyOneMoreSubHowOften"]?.[0] || "";
  const currHowMany =
    objectData?.["self_alcUseCurrSubHowManyOneMoreSubHowMany"]?.[0] || "";
  const currHowOftenSixMore =
    objectData?.["self_alcUseCurrSubHowManyOneMoreSubSixMore"]?.[0] || "";
  const formerHowOften =
    objectData?.["self_alcUseFormerSubHowManyOneMoreSubHowOften"]?.[0] || "";
  const formerHowMany =
    objectData?.["self_alcUseFormerSubHowManyOneMoreSubHowMany"]?.[0] || "";
  const formerHowOftenSixMore =
    objectData?.["self_alcUseFormerSubHowManyOneMoreSubSixMore"]?.[0] || "";

  if (currHowOften === "" && currHowMany === "" && currHowOftenSixMore === "") {
    currAuditScore = "";
  } else {
    currAuditScore += alcAuditScoreMap_howOften[currHowOften] || 0;
    currAuditScore += alcAuditScoreMap_howMany[currHowMany] || 0;
    currAuditScore +=
      alcAuditScoreMap_howOftenSixMore[currHowOftenSixMore] || 0;
  }

  if (
    formerHowOften === "" &&
    formerHowMany === "" &&
    formerHowOftenSixMore === ""
  ) {
    formerAuditScore = "";
  } else {
    formerAuditScore += alcAuditScoreMap_howOften[formerHowOften] || 0;
    formerAuditScore += alcAuditScoreMap_howMany[formerHowMany] || 0;
    formerAuditScore +=
      alcAuditScoreMap_howOftenSixMore[formerHowOftenSixMore] || 0;
  }

  objectData[
    "self_alcUseCurrSubHowManyOneMoreSubAuditScore"
  ] = `${currAuditScore}`;
  objectData[
    "self_alcUseFormerSubHowManyOneMoreSubAuditScore"
  ] = `${formerAuditScore}`;
  return objectData;
};

let demograph_genderLimit = "";

const calcCommentVisibility = (formData) => {
  let tempData = {};
  Object.keys(formData).forEach((stateVar) => {
    if (stateVar.includes("Comment")) {
      let isValueThere = formData[stateVar] !== "" ? true : false;
      tempData = {
        ...tempData,
        [`${stateVar}_toggle`]: isValueThere,
      };
    }
  });

  return tempData;
};

const SelfAssess = forwardRef((props, _ref) => {
  const {
    // SelfAssessFormData,
    // setSelfAssessFormData,
    // showComment,
    // setShowComment,
    // getSectionInputsData,
    // getStateValue,
    // setStateValue,
    getDisableFlag,
  } = useFillHraContext();
  const { storedPlan, storedYear, setSideBarSectionDisable } = useUserContext();

  const [selfAssessFormData, setSelfAssessFormData] = useState({});
  const [showComment, setShowComment] = useState([]);
  const [disableFlag, setDisableFlag] = useState(false);
  const [loading, setLoading] = useState(undefined);
  const sectionId = props.currSection || 4;
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const inputsJsonData = getInputsData(clientPlan, clientYear);
  const location = useLocation();
  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  useEffect(() => {
    async function getDbData() {
      setLoading(true);
      setSideBarSectionDisable(true);
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      ); //await props.getSectionDbData(sectionId);
      const dbData = secondApiResponse[`data`];
      //  const dbData = await props.getSectionDbData(sectionId);

      let demographData =
        dbData?.data?.demographics?.data ||
        JSON.parse(localStorage.getItem("demographics_actual_data"));
      let demograph_genderVal = demographData["genderAtBirth"] || "";
      /* 1 - female, 2 - male */
      demograph_genderLimit =
        demograph_genderVal === "1" ? 3 : demograph_genderVal === "2" ? 4 : 0;
      const mashedData =
        dbData?.data?.self?.data &&
        Object.keys(dbData?.data?.self?.data).length > 0
          ? getSelfAssessData(dbData?.data?.self?.data, clientYear)
          : {};
      const commentVisibility = calcCommentVisibility(mashedData);
      let mashedCalcData = {
        ...mashedData,
        ...commentVisibility,
      };
      setSelfAssessFormData(mashedCalcData);
      setLoading(false);
      setSideBarSectionDisable(false);
    }

    getDbData();
    setDisableFlag(getDisableFlag());
  }, []);

  const handleTextChange = (name, value) => {
    const updatedFormData = { ...selfAssessFormData };
    updatedFormData[name] = value;

    setSelfAssessFormData(updatedFormData);
  };

  const handleRadioChange = (name, value) => {
    const updatedFormData = { ...selfAssessFormData };
    updatedFormData[name] = value;

    setSelfAssessFormData(updatedFormData);
  };

  const handleDateChange = (name, value) => {
    const updatedFormData = { ...selfAssessFormData };
    updatedFormData[name] = value;

    setSelfAssessFormData(updatedFormData);
  };

  const handleSelectChange = (name, value) => {
    const updatedFormData = { ...selfAssessFormData };
    updatedFormData[name] = value === "Select" ? "" : value;

    setSelfAssessFormData(updatedFormData);
  };

  const handleOptionsTreeChange = (name, value, isMulti) => {
    /* togglebutton group type -> scenario -> actual behaviour -> our pattern

    <- multi ->
        pass values -> array -> array
        if value selected -> array -> array

    <- single -> 

        pass values -> string -> always pass as array
        if value selected -> string -> always change to array and set back to state

    */
    let updatedFormData = { ...selfAssessFormData };
    let oldSelection = updatedFormData[name];
    let newSelection = [];
    if (isMulti) {
      newSelection = value;
    } else {
      if (!oldSelection?.includes(value)) {
        newSelection.push(value);
      }
    }

    /* 
      This code of new value part is to tackle these scenarios

      1. if "none" is selected, then we should unselect the other options if any selected
      2. when "none" is already selected, if any other value is selected newly, we should unselect "none"
      
      ----- from here -------
    */
    let newValue =
      isMulti === true
        ? lodash.difference(newSelection, oldSelection)?.[0] || ""
        : oldSelection?.[0] || "";
    if (newValue === "none") {
      newSelection = ["none"];
    } else if (newSelection.includes("none") && newSelection.length > 1) {
      newSelection = newSelection.filter((option) => option !== "none");
    }

    /* ----- till here ------- */

    let deletedValue =
      isMulti === true
        ? lodash.difference(oldSelection, newSelection)?.[0] || ""
        : oldSelection?.[0] || "";

    if (deletedValue && deletedValue !== "") {
      let subTreeDeletedData = handleDeleteSubTree(
        updatedFormData,
        name,
        deletedValue,
      );
      subTreeDeletedData[name] = newSelection;
      updatedFormData = {
        ...subTreeDeletedData,
      };
    } else {
      updatedFormData[name] = newSelection;
    }

    if (
      name === "self_alcUseCurrSubHowManyOneMoreSubHowOften" ||
      name === "self_alcUseCurrSubHowManyOneMoreSubHowMany" ||
      name === "self_alcUseCurrSubHowManyOneMoreSubSixMore" ||
      name === "self_alcUseFormerSubHowManyOneMoreSubHowOften" ||
      name === "self_alcUseFormerSubHowManyOneMoreSubHowMany" ||
      name === "self_alcUseFormerSubHowManyOneMoreSubSixMore"
    ) {
      const calcUpdatedFormData = calcAlcAuditScore(updatedFormData);
      updatedFormData = {
        ...calcUpdatedFormData,
      };
    }
    updatedFormData[name] = newSelection;
    setSelfAssessFormData(updatedFormData);
  };

  const handleButtonClick = (name) => {
    return null;
  };

  const handleCheckboxChange = (name, value, checked) => {
    const updatedFormData = { ...selfAssessFormData };
    const oldData = updatedFormData?.[name] || [];
    let newData = [];
    if (oldData.length < 1) {
      newData.push(value);
    } else {
      if (checked) {
        newData = [...oldData];
        newData.push(value);
      } else {
        newData = oldData.filter((ele) => ele !== value);
      }
    }

    updatedFormData[name] = newData;
    setSelfAssessFormData(updatedFormData);
  };

  useImperativeHandle(_ref, () => ({
    getPayloadData: () => {
      return setSelfAssessData(selfAssessFormData, clientYear);
    },
  }));

  useEffect(() => {
    props.setAutoSaveSelfAssessmentData({ ...selfAssessFormData });
  }, [selfAssessFormData]);

  const handleDeleteSubTree = (objectData, name, deletedValue) => {
    let deleteRegex = "";
    if (deletedValue === "yes" || deletedValue === "no") {
      deleteRegex = `${name}Sub`;
    } else {
      deleteRegex = getSubTree(name, deletedValue, clientYear);
    }
    if (deleteRegex !== "") {
      let updatedObjectData = deleteSubTree(objectData, deleteRegex);
      return updatedObjectData;
    }
    return objectData;
  };

  const getFieldVisibility = (name) => {
    let computedVisibility = false;
    if (name === "self_alcUseCurrSubHowManyOneMoreSubWeeklyAverage") {
      const auditScore =
        selfAssessFormData?.["self_alcUseCurrSubHowManyOneMoreSubAuditScore"];
      if (auditScore >= demograph_genderLimit) {
        computedVisibility = true;
      }
    } else if (name === "self_alcUseFormerSubHowManyOneMoreSubWeeklyAverage") {
      const auditScore =
        selfAssessFormData?.["self_alcUseFormerSubHowManyOneMoreSubAuditScore"];
      if (auditScore >= demograph_genderLimit) {
        computedVisibility = true;
      }
    }

    return computedVisibility;
  };

  const getFieldValues = useCallback(
    (name) => {
      return selfAssessFormData[name];
    },
    [selfAssessFormData],
  );

  const setFieldValues = useCallback((name, value, type = "") => {
    setSelfAssessFormData((prevData) => {
      let tempData = { ...prevData };
      if (type === "datatable") {
        tempData = {
          ...tempData,
          ...value,
        };
      }
      tempData = {
        ...tempData,
        [name]: value,
      };

      return { ...tempData };
    });
  }, []);

  return (
    <LoadingOverlay
      styles={{
        // wrapper: { marginLeft: "50px", marginTop: "50px" },
        // overlay: {},
        content: { marginLeft: "40vw", marginTop: "50px" },
        // spinner: { marginLeft: "50px", marginTop: "50px" },
        spinner: (base) => ({
          ...base,
          marginLeft: "50px",
          marginTop: "50px",
        }),
      }}
      style={{ marginTop: "20px" }}
      active={loading}
      spinner={<img src={focusCaresLoader} alt="Loader" />}
      // text="Loading your content..."
    >
      <div
        style={{
          margin: "10px 20px",
        }}
      >
        <Grid container spacing={2}>
          {inputsJsonData?.questions?.map((inputProps) => {
            const {
              questionId,
              name,
              xs,
              type,
              isSingleButton,
              label,
              isCommentEnabled,
            } = inputProps;
            const inputValue = selfAssessFormData[name] || "";
            const commentValue = `${
              selfAssessFormData?.[`${name}Comment`]
                ? `${selfAssessFormData[`${name}Comment`]}`
                : ""
            }`;
            const quesSlug = `${sectionId}_${questionId}`;
            if (
              type === "text" ||
              type === "number" ||
              type === "email" ||
              type === "password"
            ) {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFText
                    inputProps={inputProps}
                    value={inputValue}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "radio") {
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={inputProps.sm}*/
                  item
                >
                  <DFRadio
                    inputProps={inputProps}
                    value={inputValue}
                    handleRadioChange={handleRadioChange}
                    handleTextChange={handleTextChange}
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={quesSlug}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "date") {
              const dateValue = selfAssessFormData[name] || {};
              return (
                <Grid
                  key={quesSlug}
                  xs={xs}
                  /* sm={sm} */
                  item
                >
                  <DFDate
                    inputProps={inputProps}
                    value={dateValue}
                    handleDateChange={handleDateChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                    // dtPickProps={{
                    //   disableFuture:true,
                    //   disablePast:true,
                    //   minDate:minDOB,
                    //   maxDate:maxDOB,
                    //   views:["year", "month", "day"]
                    // }}
                  />
                </Grid>
              );
            } else if (type === "select") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFSelect
                    inputProps={inputProps}
                    value={inputValue}
                    handleSelectChange={handleSelectChange}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "options_tree") {
              return (
                <DFOptionsTree
                  inputProps={inputProps}
                  formData={selfAssessFormData}
                  handleOptionsTreeChange={handleOptionsTreeChange}
                  handleTextChange={handleTextChange}
                  handleRadioChange={handleRadioChange}
                  handleDateChange={handleDateChange}
                  handleSelectChange={handleSelectChange}
                  handleButtonClick={handleButtonClick}
                  handleCheckboxChange={handleCheckboxChange}
                  showComment={showComment}
                  setShowComment={setShowComment}
                  setFormData={setSelfAssessFormData}
                  getFieldVisibility={getFieldVisibility}
                  quesSlug={`${questionId}`}
                  sectionId={sectionId}
                  // commentValue={commentValue}
                  disableFlag={disableFlag}
                  needMemo={true}
                  getFieldValues={getFieldValues}
                  setFieldValues={setFieldValues}
                />
              );
            } else if (type === "label") {
              return (
                <Grid key={quesSlug} item xs={xs}>
                  <DFLabel
                    inputProps={inputProps}
                    handleTextChange={handleTextChange} // added for comment box event handling
                    showComment={showComment}
                    setShowComment={setShowComment}
                    quesSlug={`${questionId}`}
                    sectionId={sectionId}
                    commentValue={commentValue}
                    disableFlag={disableFlag}
                    needMemo={true}
                    getFieldValues={getFieldValues}
                    setFieldValues={setFieldValues}
                  />
                </Grid>
              );
            } else if (type === "button") {
              return (
                <Grid key={quesSlug} xs={isSingleButton ? 12 : 0} item>
                  <DFButton
                    inputProps={inputProps}
                    handleButtonClick={handleButtonClick}
                    // disableFlag={disableFlag} - check & implement
                  />
                </Grid>
              );
            } else if (type === "heading") {
              return (
                <Grid key={quesSlug} xs={12} item>
                  <DFHeading inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "checkbox") {
              if (name === "self_Recommendations") {
                return (
                  <Grid key={quesSlug} xs={xs} item>
                    <Grid key={quesSlug} item>
                      <Grid key={quesSlug} container alignItems="center">
                        <Grid item xs={12}>
                          <FormControl
                            className="inputStyle"
                            component="fieldset"
                            variant="outlined"
                            style={{
                              width: "100%",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <FormLabel
                                className="inputStyle"
                                style={{ marginLeft: "15px" }}
                              >
                                {label}
                              </FormLabel>
                            </div>
                            {inputProps.options.map((option, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ width: "100%" }}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          className="inputStyle"
                                          variant="outlined"
                                          name={name}
                                          checked={inputValue?.includes(
                                            option[1],
                                          )}
                                          onChange={(event) =>
                                            handleCheckboxChange(
                                              event.target.name,
                                              option[1],
                                              event.target.checked,
                                            )
                                          }
                                          {...(disableFlag && {
                                            disabled: true,
                                          })}
                                        />
                                      }
                                      label={option[0]}
                                    />
                                    {isCommentEnabled &&
                                      isCommentVisibleMemo(
                                        getFieldValues,
                                        `${name}${option[1]}`,
                                      ) && (
                                        <DFCommentTextBox
                                          label={label}
                                          handleTextChange={handleTextChange}
                                          name={`${name}${option[1]}`}
                                          value={
                                            selfAssessFormData[
                                              `${name}${option[1]}Comment`
                                            ]
                                          }
                                          disableFlag={disableFlag}
                                          routedFrom={"checkbox"}
                                        />
                                      )}
                                  </div>
                                  {inputProps.isCommentEnabled === true && (
                                    <DFCommentIconMemo
                                      // showComment={showComment}
                                      // setShowComment={setShowComment}
                                      // sectionId={sectionId}
                                      // quesSlug={`${quesSlug}_${index}`}
                                      disableFlag={disableFlag}
                                      getFieldValues={getFieldValues}
                                      setFieldValues={setFieldValues}
                                      name={`${name}${option[1]}`}
                                      value={commentValue}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              } else {
                return (
                  <Grid key={quesSlug} xs={xs} item>
                    <DFCheckbox
                      inputProps={inputProps}
                      showComment={showComment}
                      handleCheckboxChange={handleCheckboxChange}
                      value={inputValue}
                      handleTextChange={handleTextChange}
                      setShowComment={setShowComment}
                      quesSlug={quesSlug}
                      sectionId={sectionId}
                      commentValue={commentValue}
                      disableFlag={disableFlag}
                      needMemo={true}
                      getFieldValues={getFieldValues}
                      setFieldValues={setFieldValues}
                    />
                  </Grid>
                );
              }
            } else if (type === "image") {
              return (
                <Grid key={quesSlug} xs={xs} item>
                  <DFImage inputProps={inputProps} />
                </Grid>
              );
            } else if (type === "divider") {
              return <DFRuler />;
            }

            return null;
          })}
        </Grid>
      </div>
    </LoadingOverlay>
  );
});

export default SelfAssess;
