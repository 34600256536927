// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-width-table {
    width: 100%;
  }
  
  .half-width-table {
    width: 50%;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Dashboard/DynamicFormMUI/Tables.css"],"names":[],"mappings":"AAAA;IACI,WAAW;EACb;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".full-width-table {\r\n    width: 100%;\r\n  }\r\n  \r\n  .half-width-table {\r\n    width: 50%;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
