import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  Suspense,
} from "react";
import DashboardComponent from "../../../components/Dashboard/Dashboard";
import DataTableComponent from "../../../components/Dashboard/DataTable/DataTable";
//import MemberList from "../../../components/Dashboard/MemberList/MemberList";
import { SelectDropdown } from "../../../components/select/select";
import MuiInput from "../../../components/muiinput/MuiInput";
import TooltipEllipsisCell from "../../../components/TooltipEllipsisCell/TooltipEllipsisCell";
import "./MemberListPage.css";
import Button from "../../../components/button/button";
import { useNavigate } from "react-router-dom";
import { getApi, postApi, putApi } from "../../../utils/axiosApiWrapper";
import { useUserContext } from "../../../contexts/UserContext";
import Loader from "../../../components/Loader/Loader";
import VphpDialog from "../../../components/Dashboard/MemberList/vphp/vphp";
import Alert from "../../../components/Alert/Alert";
import Toaster from "../../../components/toaster/toaster";
import Labels from "../../../resource_files/Labels.resx";
import AppointmentModal from "../../../components/AppointmentModal/AppointmentModal";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import AlertContext from "../../../contexts/AlertContext";
import _, { filter } from "lodash";
import moment from "moment";
import { TextField } from "@mui/material";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { DatePicker } from "@mui/x-date-pickers";
import Referral from "./Referral/Referral";
const MemberList = React.lazy(() =>
  import("../../../components/Dashboard/MemberList/MemberList"),
);
const MemberListPage = () => {
  const [assignedClinicianValues, setAssignedClinicianValues] = useState([]);
  const [assignedCodingValues, setAssignedCodingValues] = useState([]);
  const [assignedQaValues, setAssignedQaValues] = useState([]);
  const [assignedTermDateValues, setAssignedTermDateValues] = useState([]);
  const [assignedScheduleDateValues, setAssignedScheduleDateValues] = useState(
    [],
  );
  const [assignedEvaluationDateValues, setAssignedEvaluationDateValues] =
    useState([]);
  const [visitTypeValues, setVisitTypeValues] = useState([]);
  const [scheduleDateValues, setScheduleDateValues] = useState({});
  const [termDateValues, setTermDateValues] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] =
    useState("Member List");
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    clientData,
    isDialogOpen,
    setClientData,
    apiResponseData,
    setApiResponseData,
    selectedClientIdContext,
    selectedTimeZoneUtc,
  } = useUserContext();
  const [hraStatusOptions, setHraStatusOptions] = useState([]);
  const [visitTypeOptions, setVisitTypeOptions] = useState([]);
  const [clinicianData, setClinicianData] = useState([]);
  const { updateAssessmentData, getAssessment, _setMemberIdForQueryForm } =
    useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowCompletedCheckboxes, setSelectedRowCompletedCheckboxes] =
    useState({});
  const [
    selectAllCompletedHeaderCheckbox,
    setSelectAllCompletedHeaderCheckbox,
  ] = useState(false);

  const [selectedRowUploadCheckboxes, setSelectedRowUploadCheckboxes] =
    useState({});
  const [selectAllUploadHeaderCheckbox, setSelectAllUploadHeaderCheckbox] =
    useState(false);
  const [selectedRowCheckboxes, setSelectedRowCheckboxes] = useState({});
  const [, setAreCheckboxesSelected] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [isActivateAlertOpen, setIsActivateAlertOpen] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  const [toasterOpen, setToasterOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({
    memberId: "",
    assessmentId: "",
    Name: "",
    _id: "",
  });

  const [hraStatus, setHraStatus] = useState([]);
  const [isVisitTypeAlertOpen, setIsVisitTypeAlertOpen] = useState(false);
  const [isAssignedClinicianAlertOpen, setIsAssignedClinicianAlertOpen] =
    useState(false);
  const [isAssignedCodingAlertOpen, setIsAssignedCodingAlertOpen] =
    useState(false);
  const [isAssignedQaAlertOpen, setIsAssignedQaAlertOpen] = useState(false);
  const [isAssignedTermDateAlertOpen, setIsAssignedTermDateAlertOpen] =
    useState(false);
  const [isAssignedScheduleDateAlertOpen, setIsAssignedScheduleDateAlertOpen] =
    useState(false);
  const [
    isAssignedEvaluationDateAlertOpen,
    setIsAssignedEvaluationDateAlertOpen,
  ] = useState(false);
  const [selectedVisitTypeChange, setSelectedVisitTypeChange] = useState(null);
  const [selectedHraStatusTypeChange, setSelectedHraStatusTypeChange] =
    useState(null);
  const [selectedAssignedClinicianChange, setSelectedAssignedClinicianChange] =
    useState(null);
  const [selectedAssignedCodingChange, setSelectedAssignedCodingChange] =
    useState(null);
  const [selectedAssignedTermDateChange, setSelectedAssignedTermDateChange] =
    useState(null);
  const [
    selectedAssignedScheduleDateChange,
    setSelectedAssignedScheduleDateChange,
  ] = useState(null);
  const [
    selectedAssignedEvaluationDateChange,
    setSelectedAssignedEvaluationDateChange,
  ] = useState(null);
  const [selectedAssignedQaChange, setSelectedAssignedQaChange] =
    useState(null);

  const [visitTypeData, setVisitTypeData] = useState([]);
  const [isCancelAppointmentAlertOpen, setIsCancelAppointmentAlertOpen] =
    useState(false);
  const [clinicianOptions, setClinicianOptions] = useState([]);
  const [coderOptions, setCoderOptions] = useState([]);
  const [qaOptions, setQaOptions] = useState([]);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedCoderRowIndex, setSelectedCoderRowIndex] = useState(null);
  const [selectedTermDateRowIndex, setSelectedTermDateRowIndex] =
    useState(null);
  const [selectedScheduleDateRowIndex, setSelectedScheduleDateRowIndex] =
    useState(null);
  const [selectedEvaluationDateRowIndex, setSelectedEvaluationDateRowIndex] =
    useState(null);
  const [selectedQaRowIndex, setSelectedQaRowIndex] = useState(null);
  const [usersByProgramRoleId, setUsersByProgramRoleId] = useState([]);
  const [dateError, setDateError] = useState(false);
  const [schedulerUserData, setSchedulerUserData] = useState([]);
  const [selectedMemberForFax, setSelectedMemberForFax] = useState([]);
  const navigate = useNavigate();
  const storedPlan = localStorage.getItem("clientPlan"); //|| clientData?.plan;
  const storedYear = localStorage.getItem("clientYear"); //|| clientData?.year;
  const storedScreeningTypes = localStorage.getItem("storedScreeningTypes");
  const [isReferralFormOpen, setIsReferralFormOpen] = useState(false);
  const [referralStatus, setReferralStatus] = useState([
    "New",
    "Under Review",
    "Clinician Review",
    "Completed",
  ]);
  const [isReferralActivateAlertOpen, setIsReferralActivateAlertOpen] =
    useState(false);
  const [
    selectedReferralStatusTypeChange,
    setSelectedReferralStatusTypeChange,
  ] = useState(null);

  const alertContext = useContext(AlertContext);
  const mapHraStatusToTabs = (status) => {
    if (status === "") {
      setTabId(0);
      return "Unassigned";
    }
    switch (status) {
      case 0: {
        setTabId(0);
        return "Unassigned";
      }
      case 1: {
        setTabId(1);
        return "Scheduled";
      }
      case 2: {
        setTabId(2);
        return "In Progress";
      }
      case 6: {
        setTabId(6);
        return "QA";
      }
      case 7: {
        setTabId(7);
        return "Coding";
      }
      case 8:
      case 9: {
        setTabId(8 || 9);
        return "Clinician Review";
      }
      case 10: {
        setTabId(10);
        return "Ready for Upload";
      }
      case 11: {
        setTabId(11);
        return "Completed";
      }
      case 3:
      // case 5:
      case 12:
      case 19: {
        setTabId(3 || 12 || 19);
        return "Cancelled / No Show";
      }
      case 18: {
        setTabId(18);
        return "Term Expired";
      }
      default:
        return "Member List";
    }
  };
  // Extend Day.js with plugins
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [noDataFound, setNoDataFound] = useState(false);
  const formatDateAndTime = (_date) => {
    if (_date) {
      const date = new Date(_date);
      const year = date?.getFullYear();
      const month = date?.getMonth() + 1;
      const day = date?.getDate();
      // Get the time parts in 12-hour format
      const hours24 = date?.getHours();
      const amPm = hours24 >= 12 ? "PM" : "AM";
      const hours = hours24 % 12 || 12;
      const minutes = date?.getMinutes();

      // Format the date and time as strings
      const dateStr = `${year}-${month.toString()?.padStart(2, "0")}-${day
        ?.toString()
        ?.padStart(2, "0")}`;
      const timeStr = `${hours?.toString()?.padStart(2, "0")}:${minutes
        ?.toString()
        ?.padStart(2, "0")} ${amPm}`;
      return `${dateStr}  ${timeStr}`;
    } else {
      return "";
    }
  };
  const handleVphpSelection = (responseData, searchTerm) => {
    if (searchTerm) {
      // Search by memberId
      const matchedMember = responseData?.data?.find(
        (member) => member?.memberId === searchTerm,
      );

      // Search by last name
      const matchedLastMember = searchByLastName(responseData, searchTerm);

      // Search by first name
      const matchedFirstMember = searchByFirstName(responseData, searchTerm);

      handleMatchedMembers(
        matchedMember,
        matchedLastMember,
        matchedFirstMember,
      );
    }
    setResponseData(responseData);
  };

  const searchByLastName = (responseData, searchTerm) => {
    return responseData?.data?.filter(
      (member) =>
        member?.assessment?.[0]?.demographics?.data?.lastName?.toLowerCase() ===
        searchTerm?.toLowerCase(),
    );
  };

  const searchByFirstName = (responseData, searchTerm) => {
    return responseData?.data?.filter(
      (member) =>
        member?.assessment?.[0]?.demographics?.data?.firstName?.toLowerCase() ===
        searchTerm?.toLowerCase(),
    );
  };

  const handleMatchedMembers = (
    matchedMember,
    matchedLastMember,
    matchedFirstMember,
  ) => {
    if (matchedMember) {
      handleMatchedMember(matchedMember);
      return;
    }

    if (matchedLastMember && matchedLastMember.length === 1) {
      handleMatchedMember(matchedLastMember[0]);
      return;
    }

    if (matchedFirstMember && matchedFirstMember.length === 1) {
      handleMatchedMember(matchedFirstMember[0]);
      return;
    }

    if (
      !responseData?.data ||
      responseData?.data?.length === 0 ||
      responseData?.error
    ) {
      setNoDataFound(true);
    }
  };

  const handleMatchedMember = (matchedMember) => {
    const hraStatus = parseInt(matchedMember?.hraStatus?.hraStatus) || "";
    const tabName = mapHraStatusToTabs(hraStatus);
    setSelectedDropdownValue(tabName);
    handleTabChange(tabName);
    handleDropdownChange(tabName);
  };

  const fetchData = async () => {
    try {
      if (apiResponseData) {
        const clinicianUsers =
          apiResponseData?.find((obj) => obj?.clinician)?.clinician || [];
        const coderUsers =
          apiResponseData?.find((obj) => obj?.coder)?.coder || [];
        const qaUsers = apiResponseData?.find((obj) => obj?.qa)?.qa || [];

        setClinicianOptions(clinicianUsers);
        setCoderOptions(coderUsers);
        setQaOptions(qaUsers);
      } else {
        setClinicianOptions([]);
        setCoderOptions([]);
        setQaOptions([]);
      }

      //const planId = localStorage.getItem("selectedClientId") || "";
      const response = await postApi("/users/get-users-by-program-roleId", {
        planId: selectedClientIdContext,
      });

      if (response.status === 404) {
        return;
      }

      if (response?.data && response?.data?.data) {
        const clinicianUsers =
          response?.data?.data?.find((obj) => obj?.clinician)?.clinician || [];
        const coderUsers =
          response?.data?.data?.find((obj) => obj?.coder)?.coder || [];
        const qaUsers = response?.data?.data?.find((obj) => obj?.qa)?.qa || [];
        const schedulerUsers =
          response?.data?.data?.find((obj) => obj?.scheduler)?.scheduler || [];
        setSchedulerUserData(schedulerUsers);
        setClinicianOptions(clinicianUsers);
        setCoderOptions(coderUsers);
        setQaOptions(qaUsers);
      } else {
        setClinicianOptions([]);
        setCoderOptions([]);
        setQaOptions([]);
        setSchedulerUserData([]);
      }
    } catch (error) {
      setClinicianOptions([]);
      setCoderOptions([]);
      setQaOptions([]);
    }
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("defaultColumnName");
  const [tabId, setTabId] = useState(null);

  const handleTabChange = (newValue) => {
    let updatedTabId;

    if (newValue === "In Progress") {
      updatedTabId = 2;
    } else if (newValue === "Scheduled") {
      updatedTabId = 1;
    } else if (newValue === "Cancelled / No Show") {
      updatedTabId = 3;
    } else if (newValue === "QA") {
      updatedTabId = 6;
    } else if (newValue === "Coding") {
      updatedTabId = 7;
    } else if (newValue === "Clinician Review") {
      updatedTabId = 8;
    } else if (newValue === "Ready for Upload") {
      updatedTabId = 10;
    } else if (newValue === "Completed") {
      updatedTabId = 11;
    } else if (newValue === "Term Expired") {
      updatedTabId = 12;
    } else if (newValue === "Unassigned") {
      updatedTabId = 13;
    } else if (newValue === "Member List") {
      updatedTabId = "";
    }
    // else if (newValue === "Referral") {
    //   //updatedTabId = "";
    //   fetchReferralMemberData();
    // }
    else {
      updatedTabId = null;
    }

    setTabId(updatedTabId);
    setSelectedDropdownValue(newValue);
  };

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (selectedDropdownValue === "Referral") {
      return;
    }
    const fetchData = async () => {
      if (storedPlan && storedYear && searchTerm == "") {
        await fetchMemberData(storedPlan, storedYear, tabId);
        setSearchTerm("");
      }
    };
    fetchData();
  }, [tabId, storedPlan, storedYear, page, rowsPerPage, searchTerm]);

  useEffect(() => {
    if (!_.isNull(alertContext?.filterDialogData)) {
      handleFilter();
    } else {
      if (storedPlan && storedYear && tabId && searchTerm == "") {
        fetchMemberData(storedPlan, storedYear, tabId);
      }
    }
  }, [alertContext?.filterDialogData]);

  useEffect(() => {
    fetchData();
  }, [
    apiResponseData,
    clientData?.plan,
    setClinicianOptions,
    setCoderOptions,
    setQaOptions,
  ]);

  useEffect(() => {
    updateAssignedClinicianValues(responseData);
  }, [responseData, clinicianOptions]);

  useEffect(() => {
    const isAllSelected = localStorage?.getItem("isAllSelected");

    if (storedPlan && storedYear && storedScreeningTypes && isAllSelected) {
      const newValue = {
        plan: storedPlan,
        year: storedYear,
        screeningTypes: storedScreeningTypes,
        isAllSelected: JSON?.parse(isAllSelected),
      };

      setClientData(newValue);
    }
    fetchHRAStatusOptions();
    fetchVisitTypeOptions();
  }, []);

  useEffect(() => {
    hraVisitTypeUpdate(responseData);
    hraDropdownUpdate(responseData);
    scheduleDateUpdate(responseData);
    evaluationDateUpdate(responseData);
    termDateUpdate(responseData);
  }, [responseData]);

  useEffect(() => {
    updateAssignedCodingValues(responseData);
  }, [responseData, coderOptions]);

  useEffect(() => {
    updateAssignedQaValues(responseData);
  }, [responseData, qaOptions]);

  useEffect(() => {
    setSelectedDataForFax([]);
    setSelectedMemberForFax([]);
    setResponseData([]);
  }, [tabId]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchMemberDataAfterFax = () => {
    fetchMemberData(storedPlan, storedYear, tabId);
  };

  const fetchMemberData = async (
    plan,
    date,
    tabId,
    filter,
    searchTerm,
    defaultSortBy,
    defaultOrder,
    filteredData,
  ) => {
    setLoading(true);
    try {
      var DaySorting = "";
      if (defaultSortBy == "Days") {
        if (tabId == 2) {
          var DaySorting = "inProgressDate";
        } else if (tabId == 6) {
          var DaySorting = "readyForQaDate";
        } else if (tabId == 7) {
          var DaySorting = "readyForCoderDate";
        } else if (tabId == 8) {
          var DaySorting = "clinicianReviewCoderDate";
        } else if (tabId == 10) {
          var DaySorting = "readyForUploadDate";
        } else if (tabId == 11) {
          var DaySorting = "completedDate";
        } else {
          var DaySorting = "";
        }
      }
      const columnMappings = {
        ID: "assessment.memberId",
        Name: "assessment.demographics.data.firstName",
        Program: "program",
        LOB: "assessment.demographics.data.lob",
        Contact: "assessment.demographics.data.city",
        HRA_Status: "currentStatus.hrastatusName",
        Assigned_Clinician: "nurseId",
        Schedule_Date: "scheduleDate",
        Visit_Type: "visitType",
        Term_date: "assessment.demographics.data.termDate",
        Qa_Analyst: "qaId",
        Scheduler_Name: "schedulerId",
        Days: DaySorting,
      };

      const params = {
        page: page + 1,
        limit: rowsPerPage,
        year: date,
        planId: plan,
        searchTerm,
        fieldName:
          filter === "First name"
            ? "assessment.demographics.data.firstName"
            : filter === "Identification#"
            ? "memberId"
            : filter === "Last name"
            ? "assessment.demographics.data.lastName"
            : null,
        sortValue: defaultOrder,
        sortBy: columnMappings[defaultSortBy],
        //tabId: tabId,
        //tabId: !_.isEmpty(searchTerm) ? "" : tabId,
        tabId: Boolean(searchTerm) ? "" : tabId,
      };
      // if (searchTerm && searchTerm !== "null" && searchTerm !== "") {
      //   params.searchTerm = searchTerm;
      //   params.tabId = tabId;
      // }
      const response = await getApi("/members/member", { params });
      const totalCount = response?.data?.count;
      setTotalRowCount(totalCount);
      handleVphpSelection(response?.data, searchTerm);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // const fetchReferralMemberData = async (
  //   plan,
  //   date,
  // ) => {
  //   setLoading(true);
  //   try {

  //     const params = {
  //       page: page + 1,
  //       limit: rowsPerPage,
  //       year: date,
  //       planId: plan,
  //       referralStatus: 1,
  //     };
  //     // if (searchTerm && searchTerm !== "null" && searchTerm !== "") {
  //     //   params.searchTerm = searchTerm;
  //     //   params.tabId = tabId;
  //     // }
  //     const response = await getApi("/members/member", { params });
  //     console.log(response,"response")
  //     const totalCount = response?.data?.count;
  //     setTotalRowCount(totalCount);
  //     // handleVphpSelection(response?.data, searchTerm);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchReferralMemberData = async (plan, date) => {
    setLoading(true);
    try {
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        year: date,
        planId: plan,
        referralStatus: 1,
      };

      const response = await getApi("/members/member", { params });
      setResponseData(response?.data);
      const totalCount = response?.data?.count;
      setTotalRowCount(totalCount);
    } catch (error) {
      console.error("API call failed", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedDropdownValue === "Referral") {
      fetchReferralMemberData(storedPlan, storedYear);
    }
  }, [selectedDropdownValue]);

  const handleFilter = async () => {
    try {
      const filterData = alertContext?.filterDialogData;
      const params = {
        page: page + 1,
        limit: rowsPerPage,
        year: storedYear,
        planId: storedPlan,
        tabId: tabId,
      };
      if (!_.isEmpty(filterData?.program)) {
        params.program = filterData?.program;
      }
      if (!_.isEmpty(filterData?.lob)) {
        params.lob = filterData?.lob;
      }
      if (!_.isEmpty(filterData?.visitType)) {
        params.visitType = filterData?.visitType;
      }
      if (!_.isEmpty(filterData?.scheduleDateFrom)) {
        params.scheduleDateFrom = moment(filterData?.scheduleDateFrom).format(
          "YYYY-MM-DD",
        );
      }
      if (!_.isEmpty(filterData?.scheduleDateTo)) {
        params.scheduleDateTo = moment(filterData?.scheduleDateTo).format(
          "YYYY-MM-DD",
        );
      }
      if (
        !_.isEmpty(filterData?.hraStatus) ||
        !_.isNull(filterData?.hraStatus)
      ) {
        params.hraStatus = filterData?.hraStatus;
      }
      if (!_.isEmpty(filterData?.nurseId)) {
        params.nurseId = filterData?.nurseId;
      }
      const response = await getApi("/members/getMemberByFilter", { params });
      const totalCount = response?.data?.data?.[0]?.count;
      setTotalRowCount(totalCount);
      handleVphpSelection(response?.data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleSorting = (defaultSortBy, defaultOrder, filter) => {
    const isAsc = order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    const sortBy = !_.isUndefined(defaultSortBy)
      ? defaultSortBy
      : "assessment.memberId";

    fetchMemberData(
      storedPlan,
      storedYear,
      tabId,
      filter,
      null,
      sortBy,
      newOrder === "asc" ? 1 : -1,
    );

    setOrder(newOrder);
    setOrderBy(defaultSortBy);
  };

  const handleSearch = (filter, searchTerm) => {
    setSearchTerm(searchTerm);
    setSelectedDropdownValue("Member List");
    if (storedPlan && storedYear && searchTerm) {
      fetchMemberData(storedPlan, storedYear, tabId, filter, searchTerm);
    }
  };

  const resetSearch = () => {
    setSearchTerm("");
    //fetchMemberData(storedPlan, storedYear);
  };

  const updateAssignedClinicianValues = (responseData) => {
    if (responseData?.data) {
      const updatedClinicianValues = responseData.data.map((item) => {
        const nurseId = item.nurseId || "";

        const selectedNurse = clinicianOptions.find(
          (nurse) => nurse.userid === nurseId,
        );

        if (selectedNurse) {
          const fullName = `${selectedNurse.firstName} ${selectedNurse.lastName}`;
          return fullName;
        } else {
          return "";
        }
      });

      setAssignedClinicianValues(updatedClinicianValues);
    }
  };

  const handleAssignedClinicianTypeUpdate = async () => {
    if (selectedRowIndex !== null) {
      const selectedRowData = responseData.data[selectedRowIndex];
      const memberObjId = selectedRowData._id;
      const clientId = selectedRowData.clientId;

      const selectedClinician = clinicianOptions.find(
        (clinician) =>
          `${clinician.firstName} ${clinician.lastName}` ===
          assignedClinicianValues[selectedRowIndex],
      );

      if (selectedClinician) {
        const selectedUserId = selectedClinician.userid;
        //const planId = localStorage.getItem("selectedClientId") || "";
        const planId = selectedClientIdContext;

        try {
          const response = await putApi(
            "/assignClinician/updateAssigneeBasedOnRole",
            {
              members: [
                {
                  memberObjId,
                  clientId,
                  userRoleId: "5",
                  userid: selectedUserId,
                  planId,
                },
              ],
            },
          );

          if (response.status === 200) {
            setToasterOpen(true);
            setToasterSeverity(Labels.SUCCESS);
            setToasterMessage("Clinician Updated Successfully");
            if (storedPlan && storedYear) {
              fetchMemberData(storedPlan, storedYear);
            }
          }
        } catch (error) {
          return;
        }

        setIsAssignedClinicianAlertOpen(false);
      }
    }
  };

  // Assigned Clinician
  const handleAssignedClinicianTypeCancel = () => {
    setAssignedClinicianValues((prevValues) => {
      const updatedValues = [...prevValues];

      if (selectedAssignedClinicianChange?.rowIndex !== undefined) {
        updatedValues[selectedAssignedClinicianChange.rowIndex] =
          selectedAssignedClinicianChange.originalValue;
      }

      return updatedValues;
    });

    setIsAssignedClinicianAlertOpen(false);
    setSelectedAssignedClinicianChange(null);
  };

  //Assigned Clinician
  const handleAssignedClinicianChange = async (event, rowIndex) => {
    const { value } = event.target;

    setAssignedClinicianValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessment?.demographics?.data?.firstName} ${selectedRowData?.assessment?.demographics?.data?.middleName} ${selectedRowData?.assessment?.demographics?.data?.lastName}` ||
          "";

        setSelectedRowData({
          memberId,
          Name,
        });

        setIsAssignedClinicianAlertOpen(true);

        setSelectedAssignedClinicianChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };

  const fetchHRAStatusOptions = async () => {
    try {
      const response = await getApi("/hra-status");
      const data = await response.data.data;
      setData(data);
      const hraOptions = data.map((status) => status.statusName);
      setHraStatusOptions(hraOptions);
    } catch (error) {
      return;
    }
  };

  //visit type api
  const fetchVisitTypeOptions = async () => {
    try {
      const response = await getApi("/visitTypes/get-visit-types");
      const data = await response.data.data;
      setVisitTypeData(data);
      const visitOptions = data.map((visit) => visit.visitType);
      setVisitTypeOptions(visitOptions);
      //hraVisitTypeUpdate(responseData);
    } catch (error) {
      return;
    }
  };

  // Function to handle updating visit type
  const handleVisitTypeUpdate = async () => {
    const { _id, visitType } = selectedRowData;

    const visitTypeString = String(visitType);

    const selectedVisitType = visitTypeData.find(
      (visit) => visit.visitType === visitTypeString,
    );

    if (selectedVisitType) {
      const { id: visitTypeId } = selectedVisitType;

      const requestBody = {
        data: {
          visitType: visitTypeId,
          _id,
        },
      };

      try {
        const response = await putApi(
          "/visitTypes/updateVisiteType",
          requestBody,
        );

        if (response.status === 200) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          if (storedPlan && storedYear) {
            fetchMemberData(storedPlan, storedYear);
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsVisitTypeAlertOpen(false);
        setSelectedVisitTypeChange(null);
      }
    } else {
      return;
    }
  };

  const handleVisitTypeCancel = () => {
    setVisitTypeValues((prevValues) => {
      const updatedValues = [...prevValues];

      updatedValues[selectedVisitTypeChange.rowIndex] =
        selectedVisitTypeChange.originalValue;
      console.log(
        selectedVisitTypeChange.originalValue,
        " selectedReferralStatusTypeChange.originalValue",
      );

      return updatedValues;
    });
    setIsVisitTypeAlertOpen(false);
    setSelectedVisitTypeChange(null);
  };

  //hra status
  const handleHraStatus = (event, rowIndex) => {
    const { value } = event.target;

    // Check if the selected hraStatus is "Appointment Cancelled"
    if (value === "Appointment Cancelled") {
      setIsCancelAppointmentAlertOpen(true);
    } else {
      setIsActivateAlertOpen(true);
    }
    setSelectedHraStatusTypeChange({
      rowIndex,
      originalValue: hraStatus[rowIndex],
    });
    setHraStatus((prevValues) => {
      const updatedHraStatus = [...prevValues];
      updatedHraStatus[rowIndex] = value;

      // Store memberId and _id from the selected row
      const selectedRowData = responseData?.data[rowIndex];
      const memberId = selectedRowData?.memberId || "";
      const memberIdTwo = selectedRowData?.memberIdTwo || "";
      const plan = selectedRowData?.assessment?.plan || "";
      const clientId = selectedRowData?.clientId || "";
      const year = selectedRowData?.year || "";
      const assessmentId = selectedRowData?._id || "";
      const _id = selectedRowData?._id || "";

      setSelectedRowData({
        memberId,
        assessmentId,
        memberIdTwo,
        plan,
        clientId,
        year,
        _id,
        selectedHraStatus: value,
      });

      setSelectedHraStatusTypeChange({
        rowIndex,
        originalValue: prevValues[rowIndex],
      });

      return updatedHraStatus;
    });
  };

  const [previousValue, setPreviousValue] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null);

  const handleReferralStatus = (event, rowIndex) => {
    const { value } = event.target;
    //setPreviousReferralStatus(referralStatus[rowIndex]); // Store the current value

    // setIsReferralActivateAlertOpen(true);
    // setSelectedReferralStatusTypeChange({
    //   rowIndex,
    //   originalValue: referralStatus[rowIndex],
    // });
    setPreviousValue(referralStatus[rowIndex]);
    setCurrentRowIndex(rowIndex);

    setReferralStatus((prevValues) => {
      const updatedReferralStatus = [...prevValues];
      updatedReferralStatus[rowIndex] = value;

      // Store memberId and _id from the selected row
      const selectedRowData = responseData?.data[rowIndex];

      const assessmentId = selectedRowData?._id || "";
      const _id = selectedRowData?._id || "";

      setSelectedRowData({
        assessmentId,

        _id,
        selectedReferralStatus: value,
      });
      setIsReferralActivateAlertOpen(true);
      setSelectedReferralStatusTypeChange({
        rowIndex,
        originalValue: prevValues[rowIndex],
      });

      // console.log(prevValues[rowIndex],"prevValues[rowIndex]")

      return updatedReferralStatus;
    });
  };

  const handleCancelAppointmentAlertYesClick = async (cancelComment) => {
    const { _id, selectedHraStatus } = selectedRowData;

    const selectedStatus = data.find(
      (status) => status.statusName === selectedHraStatus,
    );

    if (selectedStatus) {
      const { id: hraStatusId } = selectedStatus;

      const requestBody = {
        data: {
          _id,
          hraStatus: {
            hraStatus: hraStatusId,
            subStatus: "",
            cancelStatus: "",
            cancelText: cancelComment,
          },
        },
      };
      try {
        const response = await putApi(
          "/hra-status/updateHraStatus",
          requestBody,
        );

        if (response.data.code === 200) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          if (storedPlan && storedYear) {
            fetchMemberData(storedPlan, storedYear, tabId);
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      }

      setIsCancelAppointmentAlertOpen(false);
    } else {
      return;
    }
  };

  const handleCancelAppointmentAlertExitClick = () => {
    setHraStatus((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedHraStatusTypeChange.rowIndex] =
        selectedHraStatusTypeChange.originalValue;
      return updatedValues;
    });
    setIsCancelAppointmentAlertOpen(false);
    setSelectedHraStatusTypeChange(null);
  };

  // Visit Type
  const handleVisitTypeChange = (event, rowIndex) => {
    const { value } = event.target;

    setVisitTypeValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      const selectedRow = responseData?.data[rowIndex];
      const memberId = selectedRow?.memberId || "";
      const memberIdTwo = selectedRow?.memberIdTwo || "";
      const planId = selectedRow?.planId || "";
      const year = selectedRow?.year || "";
      const clientId = selectedRow?.clientId || "";
      const _id = selectedRow?._id || "";

      const Name =
        `${selectedRow?.assessment?.demographics?.data?.firstName} ${selectedRow?.assessment?.demographics?.data?.middleName} ${selectedRow?.assessment?.demographics?.data?.lastName}` ||
        "";
      setSelectedRowData({
        ...selectedRowData,
        memberId,
        Name,
        memberIdTwo,
        planId,
        clientId,
        year,
        visitType: value,
        _id,
      });

      setIsVisitTypeAlertOpen(true);

      setSelectedVisitTypeChange({
        rowIndex,
        originalValue: prevValues[rowIndex],
      });

      return updatedValues;
    });
  };

  const hraVisitTypeUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedVisitTypeValues = responseData.data.map((item) => {
        const visitTypeId = item.visitType;

        const selectedVisitType = visitTypeData.find(
          (visit) => visit.id === visitTypeId,
        );

        if (selectedVisitType) {
          return selectedVisitType.visitType;
        } else {
          return "";
        }
      });

      setVisitTypeValues(updatedVisitTypeValues);
    }
  };

  const hraDropdownUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedHraStatusValues = responseData.data.map((item) => {
        const hraStatusId = Number(item.hraStatus.hraStatus);

        const selectedStatus = data.find((status) => status.id === hraStatusId);

        if (selectedStatus) {
          return selectedStatus.statusName;
        } else {
          return "";
        }
      });
      setHraStatus(updatedHraStatusValues);
    }
  };

  //Scheduled Date
  const handleAssignedScheduleDateTypeUpdate = async () => {
    if (selectedScheduleDateRowIndex !== null) {
      const selectedRowData = responseData.data[selectedScheduleDateRowIndex];
      const memberObjIds = [selectedRowData._id];
      const scheduleDate =
        assignedScheduleDateValues[selectedScheduleDateRowIndex];

      try {
        setIsLoading(true);
        const requestBody = {
          memberObjIds,
          scheduleDate,
        };

        if (scheduleDate && scheduleDate.trim() !== "") {
          const formattedDate = new Date(scheduleDate);
          // .toISOString()
          // .split("T")[0];
          requestBody.scheduleDate = formattedDate;
        }

        const response = await putApi(
          "/assignClinician/updateScheduleandTermDate",
          requestBody,
        );
        if (tabId == 13) {
          updateHraStatusToSchedule(memberObjIds);
        }
        setToasterMessage(response.data.message);
        setToasterSeverity(Labels.SUCCESS);
        setToasterOpen(true);
        if (storedPlan && storedYear) {
          fetchMemberData(storedPlan, storedYear);
        }
      } catch (error) {
        return;
      } finally {
        setIsLoading(false);
      }
      setIsAssignedScheduleDateAlertOpen(false);
    }
  };
  const handleAssignedEvaluationDateTypeUpdate = async () => {
    if (selectedEvaluationDateRowIndex !== null) {
      const selectedRowData = responseData.data[selectedEvaluationDateRowIndex];
      const memberObjIds = [selectedRowData._id];
      const scheduleDate =
        assignedEvaluationDateValues[selectedEvaluationDateRowIndex];

      try {
        setIsLoading(true);
        const requestBody = {
          memberObjIds,
          scheduleDate,
        };

        if (scheduleDate && scheduleDate.trim() !== "") {
          const formattedDate = new Date(scheduleDate); //new Date(scheduleDate).toISOString()//.split("T")[0];
          requestBody.scheduleDate = formattedDate;
        }

        const response = await putApi(
          "/assignClinician/updateScheduleandTermDate",
          requestBody,
        );
        if (tabId == 13) {
          updateHraStatusToSchedule(memberObjIds[0]);
        }
        setToasterMessage(response.data.message);
        setToasterSeverity(Labels.SUCCESS);
        setToasterOpen(true);
        if (storedPlan && storedYear) {
          fetchMemberData(storedPlan, storedYear, tabId);
        }
      } catch (error) {
        return;
      } finally {
        setIsLoading(false);
      }
      setIsAssignedEvaluationDateAlertOpen(false);
    }
  };

  const scheduleDateUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedScheduleDateValues = responseData.data.map((item) => {
        // const scheduleDate = item.scheduleDate || "";
        const scheduleDate = item.finalDate || "";
        if (scheduleDate && scheduleDate.trim() !== "") {
          const formattedDate = new Date(scheduleDate);
          // .toISOString()
          // .split("T")[0];
          return formattedDate;
        } else {
          return "";
        }
      });

      setAssignedScheduleDateValues(updatedScheduleDateValues);
    }
  };

  const formatEvaluationDate = (_date) => {
    if (_date) {
      const date = _date.split("T");
      return `${date[0]} ${date[1]}`;
    } else return "";
  };
  const evaluationDateUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedEvaluationDateValues = responseData.data.map((item) => {
        const _evaluationDate = item?.finalDate || "";

        if (_evaluationDate && _evaluationDate?.trim() !== "") {
          // const evaluationDate = new Date(_evaluationDate);
          // evaluationDate.setHours(evaluationDate.getHours() + 5);
          // evaluationDate.setMinutes(evaluationDate.getMinutes() + 30);
          // evaluationDate.setHours(evaluationDate.getHours());
          // evaluationDate.setMinutes(evaluationDate.getMinutes());
          const formattedDate = new Date(_evaluationDate);
          // evaluationDate;
          // `${new Date(evaluationDate).toISOString().split(":")[0]}:${
          //   new Date(evaluationDate).toISOString().split(":")[1]
          // }`;
          return formattedDate;
        } else {
          return "";
        }
      });
      setAssignedEvaluationDateValues(updatedEvaluationDateValues);
    }
  };

  const [previousEvaluationDate, setPreviousEvaluationDate] = useState();

  const handleEvaluationDateChange = (date, rowIndex, previousDate) => {
    setPreviousEvaluationDate(previousDate);
    const value = date;
    const enteredDate = new Date(value);
    const minAllowedDate = new Date("2023-01-01");
    const maxAllowedDate = new Date("2025-12-31");

    if (enteredDate < minAllowedDate || enteredDate > maxAllowedDate) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Invalid date");
      setToasterOpen(true);

      setDateError(true);

      // if (enteredDate < minAllowedDate) {
      //   event.target.value = minAllowedDate?.toISOString()?.slice(0, 10);
      // }

      // if (enteredDate > maxAllowedDate) {
      //   event.target.value = maxAllowedDate?.toISOString()?.slice(0, 10);
      // }

      return;
    }
    setDateError(false);

    setAssignedEvaluationDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value?.toString();

      if (rowIndex !== undefined) {
        setSelectedEvaluationDateRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessment?.demographics?.data?.firstName} ${selectedRowData?.assessment?.demographics?.data?.middleName} ${selectedRowData?.assessment?.demographics?.data?.lastName}` ||
          "";
        const evaluationDate = updatedValues[rowIndex] || "";

        setSelectedRowData({
          memberId,
          Name,
          evaluationDate,
        });
        // setIsAssignedEvaluationDateAlertOpen(true);

        setSelectedAssignedEvaluationDateChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        if (evaluationDate && evaluationDate?.trim() !== "") {
          updatedValues[rowIndex] = evaluationDate;
        } else {
          return;
        }

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };

  const handleAssignedEvaluationDateTypeCancel = () => {
    setAssignedEvaluationDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedEvaluationDateChange?.rowIndex] =
        previousEvaluationDate;
      return updatedValues;
    });

    setIsAssignedEvaluationDateAlertOpen(false);
    setSelectedAssignedEvaluationDateChange(null);
  };
  const [previousScheduleDate, setPreviousScheduleDate] = useState();
  //Term Date
  const handleScheduleDateChange = (date, rowIndex, previousDate) => {
    setPreviousScheduleDate(previousDate);
    //const { value } = event.target;
    const value = date;
    const enteredDate = new Date(value);
    const minAllowedDate = new Date("2023-01-01");
    const maxAllowedDate = new Date("2025-12-31");

    if (enteredDate < minAllowedDate || enteredDate > maxAllowedDate) {
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Invalid date");
      setToasterOpen(true);

      setDateError(true);

      // if (enteredDate < minAllowedDate) {
      //   event.target.value = minAllowedDate.toISOString().slice(0, 10);
      // }

      // if (enteredDate > maxAllowedDate) {
      //   event.target.value = maxAllowedDate.toISOString().slice(0, 10);
      // }

      return;
    }
    setDateError(false);

    setAssignedScheduleDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value?.toString();

      if (rowIndex !== undefined) {
        setSelectedScheduleDateRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessment?.demographics?.data?.firstName} ${selectedRowData?.assessment?.demographics?.data?.middleName} ${selectedRowData?.assessment?.demographics?.data?.lastName}` ||
          "";
        const scheduleDate = updatedValues[rowIndex] || "";

        setSelectedRowData({
          memberId,
          Name,
          scheduleDate,
        });
        setSelectedAssignedScheduleDateChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        if (scheduleDate && scheduleDate?.trim() !== "") {
          updatedValues[rowIndex] = scheduleDate;
        } else {
          return;
        }

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };

  const handleAssignedScheduleDateTypeCancel = () => {
    setAssignedScheduleDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedScheduleDateChange?.rowIndex] =
        previousScheduleDate;
      // selectedAssignedScheduleDateChange?.originalValue;
      return updatedValues;
    });

    setIsAssignedScheduleDateAlertOpen(false);
    setSelectedAssignedScheduleDateChange(null);
  };

  // Term Date
  const handleAssignedTermDateTypeUpdate = async () => {
    if (selectedTermDateRowIndex !== null) {
      const selectedRowData = responseData.data[selectedTermDateRowIndex];
      const memberObjIds = [selectedRowData._id];
      const termDate = assignedTermDateValues[selectedTermDateRowIndex];

      try {
        setIsLoading(true);
        const requestBody = {
          memberObjIds,
          termDate,
        };

        if (termDate && termDate?.trim() !== "") {
          const formattedDate = new Date(termDate);
          // .toISOString().split("T")[0];
          requestBody.termDate = formattedDate;
        }

        const response = await putApi(
          "/assignClinician/updateScheduleandTermDate",
          requestBody,
        );

        setToasterMessage(response.data.message);
        setToasterSeverity(Labels.SUCCESS);
        setToasterOpen(true);
        if (storedPlan && storedYear) {
          fetchMemberData(storedPlan, storedYear);
        }
      } catch (error) {
        return;
      } finally {
        setIsLoading(false);
      }
      setIsAssignedTermDateAlertOpen(false);
    }
  };

  const termDateUpdate = (responseData) => {
    if (responseData?.data) {
      const updatedTermDateValues = responseData?.data?.map((item) => {
        const termDate = item?.termDate || "";
        if (termDate && termDate.trim() !== "") {
          const formattedDate = new Date(termDate);
          // .toISOString()
          // .split("T")[0];
          return formattedDate;
        } else {
          return "";
        }
      });
      setAssignedTermDateValues(updatedTermDateValues);
    }
  };

  const [previousTermDate, setPreviousTermDate] = useState();

  //Term Date
  const handleTermDateChange = (date, rowIndex, previousDate) => {
    setPreviousTermDate(previousDate);
    // const { value } = event.target;
    const value = date;
    const enteredDate = new Date(value);
    const minAllowedDate = new Date("2023-01-01");
    const maxAllowedDate = new Date("2025-12-31");

    // if (enteredDate < minAllowedDate || enteredDate > maxAllowedDate) {
    //   setToasterSeverity(Labels.ERROR);
    //   setToasterMessage("Invalid date");
    //   setToasterOpen(true);

    //   setDateError(true);
    //   // if (enteredDate < minAllowedDate) {
    //   //   event.target.value = minAllowedDate?.toISOString()?.split("T")[0];
    //   // }

    //   // if (enteredDate > maxAllowedDate) {
    //   //   event.target.value = maxAllowedDate?.toISOString()?.split("T")[0];
    //   // }

    //   return;
    // }

    setDateError(false);

    setAssignedTermDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      // const formattedDate = new Date(value)?.toISOString()?.split("T")[0];
      updatedValues[rowIndex] = value?.toString();

      if (rowIndex !== undefined) {
        setSelectedTermDateRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${
            selectedRowData?.assessment?.demographics?.data?.firstName
              ? selectedRowData?.assessment?.demographics?.data?.firstName
              : ""
          } ${
            selectedRowData?.assessment?.demographics?.data?.middleName
              ? selectedRowData?.assessment?.demographics?.data?.middleName
              : ""
          } ${
            selectedRowData?.assessment?.demographics?.data?.lastName
              ? selectedRowData?.assessment?.demographics?.data?.lastName
              : ""
          }` || "";
        const termDate = updatedValues[rowIndex] || "";

        setSelectedRowData({
          memberId,
          Name,
          termDate,
        });

        // setIsAssignedTermDateAlertOpen(true);

        setSelectedAssignedTermDateChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        if (termDate && termDate.trim() !== "") {
          updatedValues[rowIndex] = termDate;
        } else {
          return;
        }

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };

  const handleAssignedTermDateTypeCancel = () => {
    setAssignedTermDateValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedTermDateChange?.rowIndex] =
        previousTermDate;
      // selectedAssignedTermDateChange.originalValue;
      return updatedValues;
    });

    setIsAssignedTermDateAlertOpen(false);
    setSelectedAssignedTermDateChange(null);
  };
  const [selectedDataForFax, setSelectedDataForFax] = useState([]);
  const handleCheckboxSelection = (isChecked, rowIndex) => {
    //
    setAreCheckboxesSelected(isChecked);
    if (isChecked) {
      setSelectedDataForFax([...selectedDataForFax, selectedRowData]);
    } // write else condition for unchecking selected box,
  };

  const handleReadyForUploadHeaderCheckboxChange = (event) => {
    const checked = event.target.checked;
    setSelectAllUploadHeaderCheckbox(checked);

    setSelectedRowUploadCheckboxes((prevValues) => {
      const updatedCheckboxes = {};
      for (const rowIndex in prevValues) {
        updatedCheckboxes[rowIndex] = checked;
      }
      return updatedCheckboxes;
    });
    if (checked && responseData?.data?.length > 0) {
      setSelectedDataForFax(responseData?.data);
      setSelectedMemberForFax(responseData?.data);
    } else {
      setSelectedDataForFax([]);
      setSelectedMemberForFax([]);
    }
  };

  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
    setToasterMessage("");
  };

  const handleCompletedHeaderCheckboxChange = (event) => {
    const checked = event.target.checked;
    setSelectAllCompletedHeaderCheckbox(checked);

    setSelectedRowCompletedCheckboxes((prevValues) => {
      const updatedCheckboxes = {};
      for (const rowIndex in prevValues) {
        updatedCheckboxes[rowIndex] = checked;
      }
      return updatedCheckboxes;
    });
    if (checked) {
      setSelectedDataForFax(responseData?.data);
      setSelectedMemberForFax(responseData?.data);
    } else {
      setSelectedDataForFax([]);
      setSelectedMemberForFax([]);
      setSelectedRowCheckboxes({});
    }
  };

  const handleFillHRAClick = async (
    assessmentId,
    memberIdForQueryForm,
    hraStatus,
    finalDate,
    evaluationDate,
    patientName,
    screenIha,
    memberType,
  ) => {
    sessionStorage.setItem("finalDate", finalDate);
    sessionStorage.setItem("evaluationDate", evaluationDate);
    sessionStorage.setItem("patientName", patientName);
    sessionStorage.setItem("assessmentId", assessmentId);
    sessionStorage.setItem("screenIha", screenIha);
    sessionStorage.setItem("hraStatus", hraStatus);
    sessionStorage.setItem("tabId", tabId);
    try {
      setIsLoading(true);
      if (responseData.data.length > 0) {
        updateAssessmentData(responseData);

        //const assessmentId = responseData.data[0].assessment[0]?._id;
        if (assessmentId) {
          const secondApiResponse = await getApi(
            `/hra/get-assessment?id=${assessmentId}`,
          );

          getAssessment(secondApiResponse, memberIdForQueryForm);
          // Navigate to the FillHRAForm page
          navigate(`/AdminDashboard/FillHRAForm`, {
            state: {
              assessmentId,
              hraStatus,
              patientName,
              screenIha,
            },
          });
        } else if (memberType == 2) {
          navigate(`/AdminDashboard/FillHRAForm`, {
            state: {
              assessmentId,
              hraStatus,
              patientName,
              screenIha,
            },
          });
          _setMemberIdForQueryForm(memberIdForQueryForm);
        } else {
          return;
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowCheckboxChange = (checked, rowIndex) => {
    setSelectedRowCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: checked,
    }));
    const selectedRowData = responseData?.data[rowIndex];
    const allRowCheckboxesChecked = Object.values({
      ...selectedRowCheckboxes,
      [rowIndex]: checked,
    }).every((isChecked) => isChecked);

    setSelectAllCompletedHeaderCheckbox(allRowCheckboxesChecked);
    //setSelectAllUploadHeaderCheckbox(allRowCheckboxesChecked);
    setSelectedCheckboxes((prevValues) => ({
      ...prevValues,
      [rowIndex]: checked,
    }));
    if (checked) {
      setSelectedMemberForFax([...selectedMemberForFax, selectedRowData]);
    } else {
      const unselectRowData = selectedMemberForFax?.filter(
        (member) => member?._id != selectedRowData?._id,
      );
      setSelectedMemberForFax([...unselectRowData]);
    }
  };
  //handleCheckBoxForFax handleHeaderCheckboxForFax responseData.data

  const handleAlertYesClick = async () => {
    const { _id, selectedHraStatus } = selectedRowData;

    const selectedStatus = data.find(
      (status) => status.statusName === selectedHraStatus,
    );

    if (selectedStatus) {
      const { id: hraStatusId } = selectedStatus;

      const requestBody = {
        data: {
          _id,
          hraStatus: {
            hraStatus: hraStatusId,
            subStatus: "",
            cancelStatus: "",
            cancelText: "",
          },
        },
      };

      try {
        const response = await putApi(
          "/hra-status/updateHraStatus",
          requestBody,
        );

        if (response.status === 200) {
          setToasterOpen(true);
          setToasterSeverity(Labels.SUCCESS);
          setToasterMessage(response.data.message);
          if (storedPlan && storedYear) {
            fetchMemberData(storedPlan, storedYear);
          }
        } else {
          return;
        }
      } catch (error) {
        return;
      } finally {
        setIsActivateAlertOpen(false);
        setSelectedHraStatusTypeChange(null);
      }
    } else {
      return;
    }
  };

  const handleReferralAlertYesClick = async () => {
    const { _id } = selectedRowData;

    const requestBody = {
      //data: {
      id: _id,
      status: "4",
      //},
    };

    try {
      const response = await putApi(
        "/referral-form/update-status",
        requestBody,
      );
      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(response.data.message);
      } else {
        return;
      }
    } catch (error) {
      return;
    } finally {
      setIsReferralActivateAlertOpen(false);
      //setSelectedHraStatusTypeChange(null);
    }
  };

  const updateHraStatusToSchedule = async (_id) => {
    const requestBody = {
      data: {
        _id,
        hraStatus: {
          hraStatus: 1,
          subStatus: "",
          cancelStatus: "",
          cancelText: "",
        },
      },
    };

    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.status === 200) {
        setToasterOpen(true);
        setToasterSeverity(Labels.SUCCESS);
        setToasterMessage(response.data.message);
        if (storedPlan && storedYear) {
          fetchMemberData(storedPlan, storedYear);
        }
      } else {
        return;
      }
    } catch (error) {
      return;
    } finally {
      setIsActivateAlertOpen(false);
      setSelectedHraStatusTypeChange(null);
    }
  };

  const handleHraStatusCancel = () => {
    setHraStatus((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedHraStatusTypeChange.rowIndex] =
        selectedHraStatusTypeChange.originalValue;
      return updatedValues;
    });

    setIsActivateAlertOpen(false);

    setSelectedHraStatusTypeChange(null);
  };

  //   const handleReferralStatusCancel = () => {
  //     setReferralStatus((prevValues) => {
  //       const updatedValues = [...prevValues];
  // console.log(prevValues,"prevValues")
  //       console.log(updatedValues,"updatedValues")
  //       updatedValues[selectedReferralStatusTypeChange.rowIndex] =
  //         selectedReferralStatusTypeChange.originalValue;

  //         console.log(selectedReferralStatusTypeChange.rowIndex,"selectedReferralStatusTypeChange.rowIndex")
  //         console.log( selectedReferralStatusTypeChange.originalValue," selectedReferralStatusTypeChange.originalValue")

  //         return updatedValues;
  //     });

  //     setIsReferralActivateAlertOpen(false);

  //     setSelectedReferralStatusTypeChange(null);
  //   };

  const handleReferralStatusCancel = () => {
    // Revert to the previous value
    setReferralStatus((prevValues) => {
      const updatedValues = [...prevValues];
      if (currentRowIndex == null) {
        updatedValues[currentRowIndex] = previousValue;
      }
      return updatedValues;
    });

    // Close the alert and reset state
    setIsReferralActivateAlertOpen(false);
    setPreviousValue(null);
    setCurrentRowIndex(null);
    setSelectedReferralStatusTypeChange(null);
  };

  const [tableHeaders, setTableHeaders] = useState([
    { field: "Action", headerName: "Action", hidesortable: true },
    { field: "Program", headerName: "Program" },
    { field: "LOB", headerName: "LOB" },
    {
      field: "ID",
      headerName: "ID#",
      type: "number",
    },
    {
      field: "Name",
      headerName: "Name",
    },
    {
      field: "Contact",
      headerName: "City",
      renderCell: (params) => (
        <TooltipEllipsisCell value={params.value} maxWidth={200} />
      ),
    },
    { field: "HRA_Status", headerName: "HRA_Status" },
    { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
    { field: "Schedule_Date", headerName: "Scheduled_Date/Time" },
    { field: "Visit_Type", headerName: "Visit_Type" },
    { field: "Term_date", headerName: "Term_date" },
    { field: "Scheduler_Name", headerName: "Scheduler_Name" },
  ]);

  let showCheckboxHeader = false;

  if (
    selectedDropdownValue === "Completed" ||
    selectedDropdownValue === "Ready for Upload"
  ) {
    showCheckboxHeader = true;
  }

  const handleDropdownChange = (newValue) => {
    setSelectedDropdownValue(newValue);
    handleTabChange(newValue);

    if (newValue === "Completed" || newValue === "Ready for Upload") {
      showCheckboxHeader = true;
    }

    let dynamicHeaders = [
      { field: "Action", headerName: "Action", hidesortable: true },
      { field: "Program", headerName: "Program" },
      { field: "LOB", headerName: "LOB" },
      { field: "ID", headerName: "ID#", type: "number" },
      { field: "Name", headerName: "Name" },
      {
        field: "Contact",
        headerName: "City",
        renderCell: (params) => (
          <TooltipEllipsisCell value={params.value} maxWidth={200} />
        ),
      },
    ];

    if (newValue === "Member List") {
      dynamicHeaders.push(
        { field: "HRA_Status", headerName: "HRA_Status" },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Schedule_Date", headerName: "Scheduled_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "Term_date", headerName: "Term_date" },
        { field: "Scheduler_Name", headerName: "Scheduler_Name" },
      );
    } else if (newValue === "Unassigned") {
      dynamicHeaders.push(
        { field: "HRA_Status", headerName: "HRA_Status" },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "Term_date", headerName: "Term_date" },
      );
    } else if (newValue === "Scheduled") {
      dynamicHeaders.push(
        { field: "HRA_Status", headerName: "HRA_Status" },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "Term_date", headerName: "Term_date" },
        { field: "Scheduler_Name", headerName: "Scheduler_Name" },
      );
    } else if (newValue === "In Progress") {
      dynamicHeaders.push(
        { field: "HRA_Status", headerName: "HRA_Status" },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "Term_date", headerName: "Term_date" },
        { field: "Days", headerName: "Days" },
      );
    } else if (newValue === "QA") {
      dynamicHeaders.push(
        { field: "HRA_Status", headerName: "HRA_Status" },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "Qa_Analyst", headerName: "Qa_Analyst" },
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      );
    } else if (newValue === "Coding") {
      dynamicHeaders.push(
        { field: "HRA_Status", headerName: "HRA_Status" },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "Qa_Analyst", headerName: "Qa_Analyst" },
        { field: "Coding_Analyst", headerName: "Coding_Analyst" },
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      );
    } else if (newValue === "Clinician Review") {
      dynamicHeaders.push(
        { field: "HRA_Status", headerName: "HRA_Status" },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "Qa_Analyst", headerName: "Qa_Analyst" },
        { field: "Coding_Analyst", headerName: "Coding_Analyst" },
        { field: "Sub_Status", headerName: "Sub_Status" },
        { field: "Days", headerName: "Days" },
      );
    } else if (newValue === "Ready for Upload") {
      dynamicHeaders.unshift({
        field: "checkboxHeader",
        headerName: (
          <input
            type="checkbox"
            style={{ width: "20px", height: "18px" }}
            checked={selectAllUploadHeaderCheckbox}
            onChange={handleReadyForUploadHeaderCheckboxChange}
          />
        ),
      });
      dynamicHeaders.push(
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "Qa_Analyst", headerName: "Qa_Analyst" },
        { field: "Coding_Analyst", headerName: "Coding_Analyst" },
        { field: "Days", headerName: "Days" },
        { field: "Upload_Status", headerName: "Upload_Status" },
      );
    } else if (newValue === "Completed") {
      dynamicHeaders.unshift(
        {
          field: "checkboxHeader",
          headerName: (
            <input
              type="checkbox"
              style={{ width: "20px", height: "18px" }}
              checked={selectAllCompletedHeaderCheckbox}
              onChange={handleCompletedHeaderCheckboxChange}
            />
          ),
        },
        {
          field: "Edit",
          headerName: "Edit",
          renderCell: (params) => (
            <Button type="submit" ml="15px" fontSize="15px" height="unset">
              Edit
            </Button>
          ),
        },
      );
      dynamicHeaders.push(
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "Qa_Analyst", headerName: "Qa_Analyst" },
        { field: "Coding_Analyst", headerName: "Coding_Analyst" },
        { field: "Days", headerName: "Days" },
        { field: "Fax_Sent", headerName: "Fax_Sent" },
      );
    } else if (newValue === "Cancelled / No Show") {
      dynamicHeaders.push(
        { field: "HRA_Status", headerName: "HRA_Status" },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
      );
    } else if (newValue === "Term Expired") {
      dynamicHeaders.push(
        { field: "HRA_Status", headerName: "HRA_Status" },
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Evaluation_Date", headerName: "Evaluation_Date/Time" },
        { field: "Visit_Type", headerName: "Visit_Type" },
        { field: "Term_date", headerName: "Term_date" },
      );
    } else if (newValue === "Referral") {
      // Filter out the unwanted columns
      dynamicHeaders = dynamicHeaders?.filter(
        (header) =>
          header.field !== "Action" &&
          header.field !== "Program" &&
          header.field !== "LOB" &&
          header.field !== "Contact",
        //  &&
        // header.field !== "Contact"
      );
      // console.log("Filtered dynamicHeaders:", dynamicHeaders);

      dynamicHeaders.push(
        { field: "Assigned_Clinician", headerName: "Assigned_Clinician" },
        { field: "Assigned_Specialist", headerName: "Assigned Specialist" },
        { field: "Referral_Status", headerName: "Referral Status" },
        { field: "Referral_Type", headerName: "Referral Type" },
        { field: "Date_Of_Referral", headerName: "Date Of Referral" },
        {
          field: "Referral_Sent_To_Qa_Date",
          headerName: "Referral Sent To Qa Date",
        },
        {
          field: "Referral_Sent_To_Plan_Date",
          headerName: "Referral Sent To Plan Date",
        },
        {
          field: "View_Pdf",
          headerName: "Action",
          renderCell: (params) => (
            <Button type="submit" ml="15px" fontSize="15px" height="unset">
              Action
            </Button>
          ),
        },
        {
          field: "Review",
          headerName: "Review",
          renderCell: (params) => (
            <Button type="submit" ml="15px" fontSize="15px" height="unset">
              Review
            </Button>
          ),
        },
      );
    }
    setTableHeaders(dynamicHeaders);
  };

  // Assigned Coding Analyst
  const handleAssignedCodingTypeUpdate = async () => {
    if (selectedCoderRowIndex !== null) {
      const selectedRowData = responseData.data[selectedCoderRowIndex];
      const memberObjId = selectedRowData._id;
      const clientId = selectedRowData.clientId;

      const selectedCoder = coderOptions.find(
        (coder) =>
          `${coder.firstName} ${coder.lastName}` ===
          assignedCodingValues[selectedCoderRowIndex],
      );

      if (selectedCoder) {
        const selectedUserId = selectedCoder.userid;
        //const planId = localStorage.getItem("selectedClientId") || "";
        const planId = selectedClientIdContext;

        try {
          const response = await putApi(
            "/assignClinician/updateAssigneeBasedOnRole",
            {
              members: [
                {
                  memberObjId,
                  clientId,
                  userRoleId: "9",
                  userid: selectedUserId,
                  planId,
                },
              ],
            },
          );

          if (response.status === 200) {
            setToasterOpen(true);
            setToasterSeverity(Labels.SUCCESS);
            setToasterMessage("Coding Analyst Updated Successfully");
            if (storedPlan && storedYear) {
              fetchMemberData(storedPlan, storedYear);
            }
          }
        } catch (error) {
          return;
        } finally {
          setIsAssignedCodingAlertOpen(false);
        }
      }
    }
  };

  // Assigned Coding Analyst
  const handleAssignedCodingTypeCancel = () => {
    setAssignedCodingValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedCodingChange.rowIndex] =
        selectedAssignedCodingChange.originalValue;
      return updatedValues;
    });

    setIsAssignedCodingAlertOpen(false);
    setSelectedAssignedCodingChange(null);
  };

  const handleAssignedCodingChange = async (event, rowIndex) => {
    const { value } = event.target;

    setAssignedCodingValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedCoderRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${
            selectedRowData?.assessment?.demographics?.data?.firstName
              ? selectedRowData?.assessment?.demographics?.data?.firstName
              : ""
          } ${
            selectedRowData?.assessment?.demographics?.data?.middleName
              ? selectedRowData?.assessment?.demographics?.data?.middleName
              : ""
          } ${
            selectedRowData?.assessment?.demographics?.data?.lastName
              ? selectedRowData?.assessment?.demographics?.data?.lastName
              : ""
          }` || "";

        setSelectedRowData({
          memberId,
          Name,
        });

        setIsAssignedCodingAlertOpen(true);

        setSelectedAssignedCodingChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };
  const updateAssignedCodingValues = (responseData) => {
    if (responseData?.data) {
      const updatedCodingValues = responseData.data.map((item) => {
        const coderId = item.coderId || "";

        const selectedCoder = coderOptions.find(
          (coder) => coder.userid === coderId,
        );

        if (selectedCoder) {
          const fullName = `${selectedCoder.firstName} ${selectedCoder.lastName}`;
          return fullName;
        } else {
          return "";
        }
      });
      setAssignedCodingValues(updatedCodingValues);
    }
  };

  const renderCodingAnalystField = (newValue, index, data1) => {
    if (["Coding", "Clinician Review"].includes(newValue)) {
      return (
        <SelectDropdown
          label="Coding Analyst"
          value={data1.Coding_Analyst || ""}
          onChange={(event) => handleAssignedCodingChange(event, index)}
          options={coderOptions.map(
            (coder) => `${coder.firstName} ${coder.lastName}`,
          )}
        />
      );
    } else {
      return <div>{data1.Coding_Analyst || ""}</div>;
    }
  };

  //Assigned Qa
  const handleAssignedQaChange = async (event, rowIndex) => {
    const { value } = event.target;

    setAssignedQaValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[rowIndex] = value.toString();

      if (rowIndex !== undefined) {
        setSelectedQaRowIndex(rowIndex);

        const selectedRowData = responseData?.data[rowIndex];
        const memberId = selectedRowData?.memberId || "";
        const Name =
          `${selectedRowData?.assessment?.demographics?.data?.firstName} ${selectedRowData?.assessment?.demographics?.data?.middleName} ${selectedRowData?.assessment?.demographics?.data?.lastName}` ||
          "";

        setSelectedRowData({
          memberId,
          Name,
        });

        setIsAssignedQaAlertOpen(true);

        setSelectedAssignedQaChange({
          rowIndex,
          originalValue: prevValues[rowIndex],
        });

        return updatedValues;
      } else {
        return prevValues;
      }
    });
  };

  // Assigned Coding Analyst
  const handleAssignedQaTypeUpdate = async () => {
    if (selectedQaRowIndex !== null) {
      const selectedRowData = responseData.data[selectedQaRowIndex];
      const memberObjId = selectedRowData._id;
      const clientId = selectedRowData.clientId;

      const selectedQa = qaOptions.find(
        (qa) =>
          `${qa.firstName} ${qa.lastName}` ===
          assignedQaValues[selectedQaRowIndex],
      );

      if (selectedQa) {
        const selectedUserId = selectedQa.userid;
        //const planId = localStorage.getItem("selectedClientId") || "";
        const planId = selectedClientIdContext;

        try {
          const response = await putApi(
            "/assignClinician/updateAssigneeBasedOnRole",
            {
              members: [
                {
                  memberObjId,
                  clientId,
                  userRoleId: "7",
                  userid: selectedUserId,
                  planId,
                },
              ],
            },
          );

          if (response.status === 200) {
            setToasterOpen(true);
            setToasterSeverity(Labels.SUCCESS);
            setToasterMessage("Qa Analyst Updated Successfully");
            if (storedPlan && storedYear) {
              fetchMemberData(storedPlan, storedYear);
            }
          }
        } catch (error) {
          return;
        } finally {
          setIsAssignedQaAlertOpen(false);
        }
      }
    }
  };

  // Assigned Qa Analyst
  const handleAssignedQaTypeCancel = () => {
    setAssignedQaValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[selectedAssignedQaChange.rowIndex] =
        selectedAssignedQaChange.originalValue;
      return updatedValues;
    });

    setIsAssignedQaAlertOpen(false);
    setSelectedAssignedQaChange(null);
  };

  const updateAssignedQaValues = (responseData) => {
    if (responseData?.data) {
      const updatedQaValues = responseData.data.map((item) => {
        const qaId = item.qaId || "";

        const selectedQa = qaOptions.find((qa) => qa.userid === qaId);

        if (selectedQa) {
          const fullName = `${selectedQa.firstName} ${selectedQa.lastName}`;
          return fullName;
        } else {
          return "";
        }
      });

      setAssignedQaValues(updatedQaValues);
    }
  };

  const renderQaAnalystField = (newValue, index, data1) => {
    if (["QA"].includes(newValue)) {
      return (
        <SelectDropdown
          label="QA Analyst"
          value={data1.Qa_Analyst || ""}
          onChange={(event) => handleAssignedQaChange(event, index)}
          options={qaOptions.map((qa) => `${qa.firstName} ${qa.lastName}`)}
        />
      );
    } else {
      return <div>{data1.Qa_Analyst || ""}</div>;
    }
  };

  const renderAssignedSpecialistField = (newValue, index, data1) => {
    if (["Referral"].includes(newValue)) {
      return (
        <SelectDropdown
          label="Select QA"
          value={data1.Assigned_Specialist || ""}
          onChange={(event) => handleAssignedQaChange(event, index)}
          options={qaOptions.map((qa) => `${qa.firstName} ${qa.lastName}`)}
        />
      );
    } else {
      return <div>{data1.Assigned_Specialist || ""}</div>;
    }
  };

  const handleReviewClick = () => {
    setIsReferralFormOpen(true);
  };

  const handleCloseForm = () => {
    setIsReferralFormOpen(false);
  };

  const calculateDaysFromGivenDate = (item) => {
    let givenDate; //new Date(givenDateString);
    const currentDate = new Date();
    if (
      tabId == 2 ||
      tabId == 6 ||
      tabId == 7 ||
      tabId == 8 ||
      tabId == 10 ||
      tabId == 11
    ) {
      if (item?.hraStatus?.hraStatus == 2) {
        givenDate =
          item?.hraStatusHistory?.inProgress?.length > 0
            ? item?.hraStatusHistory?.inProgress?.[
                item?.hraStatusHistory?.inProgress?.length - 1
              ].date
            : null;
      } else if (item?.hraStatus?.hraStatus == 6) {
        givenDate =
          item?.hraStatusHistory?.readyForQa?.length > 0
            ? item?.hraStatusHistory?.readyForQa?.[
                item?.hraStatusHistory?.readyForQa?.length - 1
              ].date
            : null;
      } else if (item?.hraStatus?.hraStatus == 7) {
        givenDate =
          item?.hraStatusHistory?.readyForCoder?.length > 0
            ? item?.hraStatusHistory?.readyForCoder?.[
                item?.hraStatusHistory?.readyForCoder?.length - 1
              ].date
            : null;
      } else if (item?.hraStatus?.hraStatus == 8) {
        givenDate =
          item?.hraStatusHistory?.clinicianReviewQa?.length > 0
            ? item?.hraStatusHistory?.clinicianReviewQa?.[
                item?.hraStatusHistory?.clinicianReviewQa?.length - 1
              ].date
            : null;
      } else if (item?.hraStatus?.hraStatus == 9) {
        givenDate =
          item?.hraStatusHistory?.clinicianReviewCoder?.length > 0
            ? item?.hraStatusHistory?.clinicianReviewCoder?.[
                item?.hraStatusHistory?.clinicianReviewCoder?.length - 1
              ].date
            : null;
      } else if (item?.hraStatus?.hraStatus == 1) {
        givenDate =
          item?.hraStatusHistory?.scheduled?.length > 0
            ? item?.hraStatusHistory?.scheduled?.[
                item?.hraStatusHistory?.scheduled?.length - 1
              ].date
            : null;
      } else if (item?.hraStatus?.hraStatus == 10) {
        givenDate =
          item?.hraStatusHistory?.readyForUpload?.length > 0
            ? item?.hraStatusHistory?.readyForUpload?.[
                item?.hraStatusHistory?.readyForUpload?.length - 1
              ].date
            : null;
      } else if (item?.hraStatus?.hraStatus == 11) {
        givenDate =
          item?.hraStatusHistory?.completed?.length > 0
            ? item?.hraStatusHistory?.completed?.[
                item?.hraStatusHistory?.completed?.length - 1
              ].date
            : null;
      }
    }
    if (givenDate) {
      const _givenDate = new Date(givenDate);
      const differenceInMilliseconds = currentDate - _givenDate;
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      return Math.floor(differenceInDays); // Return the integer part
    } else {
      return 0;
    }
  };
  const getSubStatus = (item) => {
    let count = "";
    if (tabId == 6) {
      count = item?.qaReviewCount;
    } else if (tabId == 7) {
      count = item?.coderReviewCount;
    } else if (tabId == 8 && item?.hraStatus?.hraStatus == 8) {
      count = item?.qaReviewCount;
    } else if (tabId == 8 && item?.hraStatus?.hraStatus == 9) {
      count = item?.coderReviewCount;
    }
    if (count == 1) {
      return "1st Review";
    } else if (count == 2) {
      return "2nd Review";
    } else if (count == 3) {
      return "3rd Review";
    } else if (count == 4) {
      return "4th Review";
    } else if (count >= 4) {
      return `${count}th Review`;
    } else {
      return "New";
    }
  };
  const getValueForFaxSent = (member) => {
    //  if(member?.pcpFaxResponse?.errormessage == 'Fax number is not present' ||(member?.pcpFaxResponse?.message == 'Fax number is not present') || member?.pcpFaxResponse == '' ){
    //     return 'No'
    //  }else if(member?.pcpFaxResponse?.errormessage != 'Fax number is not present' ||(member?.pcpFaxResponse?.message != 'Fax number is not present') ){
    //     return 'Yes'
    //  }else {
    //   return ''
    //  }
    if (member?.pcpFaxResponse?.status == 4) {
      return "No";
    } else if (member?.pcpFaxResponse == "") {
      return "";
    } else if (member?.pcpFaxResponse?.status == 2) {
      return "Pending";
    } else if (member?.pcpFaxResponse?.status == 3) {
      return "Error";
    } else if (member?.pcpFaxResponse?.status == 1) {
      return "Yes";
    }
  };
  function calculateAge(dob) {
    if (dob != "undefined" && Boolean(dob)) {
      //const dateOfBirth = excelSerialToDate(JSON.parse(JSON.stringify(dob)));
      // const dateOfBirth = dob;
      const dateOfBirth = JSON.parse(JSON?.stringify(dob));
      var birthDateObj = new Date(dateOfBirth);
      // dateOfBirth?.includes("-")
      //   ? new Date(dateOfBirth)
      //   : new Date(excelSerialToDate(dateOfBirth));
      var currentDate = new Date();
      var timeDifference = currentDate?.getTime() - birthDateObj?.getTime();
      var age = Math?.floor(timeDifference / (1000 * 60 * 60 * 24 * 365.25));
      return age;
    } else {
      return 1000;
    }
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const formattedDate = date?.toISOString()?.split("T")[0]; // Extracts "YYYY-MM-DD" part
    return formattedDate;
  };

  const fetchReferalReport = async (assessmentId) => {
    sessionStorage.setItem("assessmentId", assessmentId);

    try {
      const assessId = sessionStorage.getItem("assessmentId", assessmentId);
      const response = await getApi(
        `/referral-form/generate-pdf?id=${assessId}`,
      );

      if (response.status === 200) {
        const url = `${process.env.REACT_APP_API_BASE_URL}${response.data.path}`;
        const newTab = window.open(url, "_blank");
        if (newTab) {
          newTab.focus();
        }
      } else {
        console.error("Error: Failed to fetch PDF data.");
      }
    } catch (error) {
      console.error("Error fetching referral report:", error);
    }
  };

  const dataMemberList =
    (Array.isArray(responseData?.data) ? responseData?.data : []).map(
      (item, index) => {
        const demographics = item?.assessment?.demographics?.data;
        const firstName = demographics?.firstName || "";
        const middleName = demographics?.middleName || "";
        const lastName = demographics?.lastName || "";
        const pedsFormPatientName = demographics?.name;
        const address = demographics?.address || "";
        const city = demographics?.city || "";
        const primary = demographics?.primary || "";
        const _id = item?._id || "";
        const schedulerUserData = item?.schedulerUserData?.[0];
        const schedulerFirstName = schedulerUserData?.firstName || "";
        const schedulerLastName = schedulerUserData?.lastName || "";
        const screenIha = demographics?.screenIha === "2" ? true : false || "";
        const memberType = item?.memberType;
        const dateOfBirth = demographics?.dob;
        const name = `${firstName} ${middleName} ${lastName}`;
        const memberId = item?.memberId;

        const coderFirstName = item?.coderUserData?.firstName;
        const coderLastName = item?.coderUserData?.lastName;

        return {
          Edit: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              id="memberlist_screen_type_button"
            >
              Edit
            </Button>
          ),
          Program: item?.program,
          LOB: demographics?.lob || "",
          ID: item?.memberId,
          Name: pedsFormPatientName?.length > 0 ? pedsFormPatientName : name,
          Contact: `${city}`,
          //Scheduler_Name: item.schedulerId,
          Scheduler_Name: `${schedulerFirstName} ${schedulerLastName}`,
          Schedule_Date: item?.scheduleDate || "",
          Evaluation_Date: item?.finalDate
            ? item?.finalDate
            : item?.evaluationDate || "",
          Visit_Type: visitTypeValues[index] || "",
          Coding_Analyst: assignedCodingValues[index] || "",
          Qa_Analyst: assignedQaValues[index] || "",
          Assigned_Clinician: assignedClinicianValues[index] || "",
          Term_date: item?.termDate || "",
          HRA_Status: hraStatus[index] || "",
          Action: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              // background="var(--mainTheme)"
              background={
                screenIha
                  ? "var(--colorDarkBlue)"
                  : calculateAge(dateOfBirth) >= 18
                  ? "var(--mainTheme)"
                  : "#ed8e22"
              }
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              onClick={() => {
                sessionStorage.setItem(
                  "selectedMemberVisitType",
                  visitTypeValues[index],
                );
                sessionStorage.setItem(
                  "selectedClinicianName",
                  assignedClinicianValues[index] || "",
                );
                if (screenIha) {
                  handleFillHRAClick(
                    item?.uniquMemberId,
                    item._id,
                    hraStatus[index],
                    item?.finalDate,
                    item?.evaluationDate,
                    `${firstName} ${middleName} ${lastName}`,

                    "Screen Only",
                  );
                } else {
                  sessionStorage.setItem("dob", dateOfBirth);
                  sessionStorage.setItem("memberType", memberType);
                  sessionStorage.setItem("Name", name);
                  sessionStorage.setItem("memberId", memberId);
                  handleFillHRAClick(
                    item.uniquMemberId,
                    item._id,
                    hraStatus[index],
                    item?.finalDate,
                    item?.evaluationDate,
                    pedsFormPatientName?.length > 0
                      ? pedsFormPatientName
                      : `${firstName} ${middleName} ${lastName}`,
                    undefined,
                    memberType,
                  );
                }
              }}
              id="memberlist_screen_type_button"
            >
              {screenIha
                ? "Screen Only"
                : calculateAge(dateOfBirth) >= 18
                ? "Fill HRA"
                : "Fill PEDS"}
            </Button>
          ),
          Days: calculateDaysFromGivenDate(item),
          Sub_Status: getSubStatus(item),
          Fax_Sent: getValueForFaxSent(item),
          _id: _id,
          View_Pdf: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              // background={
              //   screenIha
              //     ? "var(--colorDarkBlue)"
              //     : calculateAge(dateOfBirth) >= 18
              //     ? "var(--mainTheme)"
              //     : "#ed8e22"
              // }
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              id="memberlist_View_Pdf_button"
            >
              View PDF
            </Button>
          ),
          Review: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              // background={
              //   screenIha
              //     ? "var(--colorDarkBlue)"
              //     : calculateAge(dateOfBirth) >= 18
              //     ? "var(--mainTheme)"
              //     : "#ed8e22"
              // }
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              onClick={handleReviewClick} // Button click handler
              id="memberlist_View_Pdf_button"
            >
              Review
            </Button>
          ),
        };
      },
    ) || [];

  const mapReferralType = (type) => {
    switch (type) {
      case "1":
        return "Urgent";
      case "2":
        return "Non-Urgent";
      case "3":
        return "Critical Incident Report";
      default:
        return ""; // Handle other cases or empty values
    }
  };

  const mapReferralStatus = (status) => {
    switch (status) {
      case "1":
        return "New";
      case "2":
        return "Under Review";
      case "3":
        return "Clinician Review";
      case "4":
        return "Completed";
      default:
        return "";
    }
  };

  const dataMemberListReferral =
    (Array.isArray(responseData?.data) ? responseData?.data : []).map(
      (item, index) => {
        const demographics = item?.assessment?.demographics?.data;
        const firstName = demographics?.firstName || "";
        const middleName = demographics?.middleName || "";
        const lastName = demographics?.lastName || "";

        // Extract the last referral form entry
        const referralForm = item?.assessment?.referral?.referralForm || [];
        const lastReferralFormEntry =
          referralForm.length > 0
            ? referralForm[referralForm.length - 1]
            : null;
        const dateOfReferral = formatDate(lastReferralFormEntry?.dateOfReport); // Format the date
        const referralDateSentQaDate = formatDate(lastReferralFormEntry?.date);
        const referralType = mapReferralType(lastReferralFormEntry?.type);

        const referralStatus = mapReferralStatus(item?.referralStatus);

        return {
          ID: item?.memberId,
          Name: `${firstName} ${middleName} ${lastName}`,
          Assigned_Clinician: assignedClinicianValues[index] || "",
          Assigned_Specialist: assignedQaValues[index] || "",
          Date_Of_Referral: dateOfReferral,
          Referral_Sent_To_Qa_Date: referralDateSentQaDate,
          Referral_Type: referralType,
          Referral_Status: referralStatus,
          View_Pdf: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              id="memberlist_View_Pdf_button"
              onClick={() => {
                fetchReferalReport(item.uniquMemberId);
              }}
              // onClick={fetchReferalReport}
            >
              View PDF
            </Button>
          ),
          Review: (
            <Button
              type="submit"
              width="70px"
              height="28px"
              cursor="pointer"
              color="white"
              background="var(--mainTheme)"
              // background={
              //   screenIha
              //     ? "var(--colorDarkBlue)"
              //     : calculateAge(dateOfBirth) >= 18
              //     ? "var(--mainTheme)"
              //     : "#ed8e22"
              // }
              fontSize="12px"
              border="none"
              borderRadius="100px"
              fontFamily="Inter,sans-serif"
              onClick={handleReviewClick} // Button click handler
              id="memberlist_View_Pdf_button"
            >
              Review
            </Button>
          ),
        };
      },
    ) || [];

  const dataMemberListWithDropdown = dataMemberList.filter((data) => {
    // Filter rows based on the selected HRA_Status
    if (selectedDropdownValue === "In Progress") {
      return data.HRA_Status === "In Progress";
    } else if (selectedDropdownValue === "Unassigned") {
      return data.HRA_Status === "Unscheduled";
    } else if (selectedDropdownValue === "Scheduled") {
      return data.HRA_Status === "Scheduled";
    } else if (selectedDropdownValue === "QA") {
      return data.HRA_Status === "Submitted - Ready for QA";
    } else if (selectedDropdownValue === "Coding") {
      return data.HRA_Status === "Submitted - Ready for Coding";
    } else if (selectedDropdownValue === "Clinician Review") {
      return (
        data.HRA_Status === "Clinician Review - QA" ||
        data.HRA_Status === "Clinician Review - Coding"
      );
    } else if (selectedDropdownValue === "Ready for Upload") {
      return data.HRA_Status === "Submitted - Ready for Upload";
    } else if (selectedDropdownValue === "Cancelled / No Show") {
      return (
        data.HRA_Status === "Appointment Cancelled" ||
        data.HRA_Status === "No Show" ||
        data.HRA_Status === "Assessment Cancelled"
      );
    } else if (selectedDropdownValue === "Term Expired") {
      return data.HRA_Status === "Term Expired";
    } else if (selectedDropdownValue === "Completed") {
      return data.HRA_Status === "Completed";
    } else {
      return true;
    }
  });

  hraDropdownUpdate(dataMemberListWithDropdown);
  hraVisitTypeUpdate(dataMemberListWithDropdown);
  const getMaxDate = () => {
    const maxDate = new Date();
    maxDate.setFullYear(new Date()?.getFullYear() + 1);
    return dayjs(maxDate);
  };
  const getMinDate = () => {
    const minDate = new Date();
    minDate.setFullYear(new Date()?.getFullYear() - 1);
    return dayjs(minDate);
  };

  const mappingData = useMemo(() => {
    if (selectedDropdownValue == "Referral") {
      return dataMemberListReferral?.map((data1, index) => {
        return {
          ...data1,
          Assigned_Clinician: (
            <SelectDropdown
              label="Assigned Clinician"
              value={data1?.Assigned_Clinician || ""}
              onChange={(event) => handleAssignedClinicianChange(event, index)}
              options={clinicianOptions?.map(
                (clinician) => `${clinician.firstName} ${clinician.lastName}`,
              )}
            />
          ),

          Assigned_Specialist: renderAssignedSpecialistField(
            selectedDropdownValue,
            index,
            data1,
          ),

          Referral_Status: (
            <SelectDropdown
              label="Referral Status"
              value={data1?.Referral_Status}
              disabledOptions={true}
              enableCompletedOnly={true}
              onChange={(event) => handleReferralStatus(event, index)}
              options={["New", "Under Review", "Clinician Review", "Completed"]}
            />
          ),

          Date_Of_Referral: data1?.Date_Of_Referral,
          Referral_Sent_To_Qa_Date: data1?.Referral_Sent_To_Qa_Date,
          Referral_Type: data1?.Referral_Type,
        };
      });
    } else {
      return dataMemberListWithDropdown?.map((data1, index) => {
        var assigned_scheduler = _?.find(schedulerUserData, {
          userid: data1?.Scheduler_Name,
        });
        return {
          ...data1,
          Assigned_Clinician: (
            <SelectDropdown
              label="Assigned Clinician"
              value={data1?.Assigned_Clinician || ""}
              onChange={(event) => handleAssignedClinicianChange(event, index)}
              options={clinicianOptions?.map(
                (clinician) => `${clinician.firstName} ${clinician.lastName}`,
              )}
            />
          ),
          Coding_Analyst: renderCodingAnalystField(
            selectedDropdownValue,
            index,
            data1,
          ),
          Qa_Analyst: renderQaAnalystField(selectedDropdownValue, index, data1),
          Visit_Type: (
            <SelectDropdown
              label="Select"
              value={data1?.Visit_Type || ""}
              onChange={(event) => handleVisitTypeChange(event, index)}
              options={visitTypeOptions}
            />
          ),
          Schedule_Date: (
            //  ["Unscheduled","Scheduled","UTC (Unable to Contact)","No Show","Patient Refusal" ].includes(data1.HRA_Status)?(
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DateTimePicker", "DateTimePicker"]}
                >
                  <DateTimePicker
                    label=" "
                    disabled={
                      ![
                        "Unscheduled",
                        "Scheduled",
                        "UTC (Unable to Contact)",
                        "No Show",
                        "Patient Refusal",
                      ]?.includes(data1.HRA_Status)
                    }
                    id="datetime-local"
                    format="MM/DD/YYYY hh:mm a"
                    views={["year", "month", "day", "hours", "minutes"]}
                    // minDate={dayjs(new Date("2023-01-01"))}
                    // maxDate={dayjs(new Date("2025-12-31"))}
                    minDate={getMinDate()}
                    maxDate={getMaxDate()}
                    ampm
                    autoOk={false}
                    onClose={() => {
                      setIsAssignedScheduleDateAlertOpen(true);
                    }}
                    value={
                      assignedScheduleDateValues[index]
                        ? dayjs(
                            dayjs(assignedScheduleDateValues[index] || "")
                              .tz(selectedTimeZoneUtc)
                              .format("lll"),
                          )
                        : null
                    }
                    onChange={(newValue) => {
                      handleScheduleDateChange(
                        new Date(newValue),
                        index,
                        data1?.Schedule_Date,
                      );
                    }}
                    slotProps={{
                      textField: {
                        disabled: true,
                        variant: "outlined",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          ),
          Term_date: (
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label=" "
                    id="datetime-local"
                    format="MM/DD/YYYY"
                    views={["year", "month", "day"]}
                    // minDate={dayjs(new Date("2023-01-01"))}
                    // maxDate={dayjs(new Date("2025-12-31"))}
                    // minDate={getMinDate()}
                    // maxDate={getMaxDate()}
                    ampm
                    autoOk={false}
                    onClose={() => {
                      setIsAssignedTermDateAlertOpen(true);
                    }}
                    value={
                      assignedTermDateValues[index]
                        ? dayjs(
                            dayjs(assignedTermDateValues[index] || "")
                              // .tz(selectedTimeZoneUtc)
                              .format("MM/DD/YYYY"),
                          )
                        : null
                    }
                    onChange={(newValue) => {
                      handleTermDateChange(
                        new Date(newValue),
                        index,
                        data1?.Term_date,
                      );
                    }}
                    slotProps={{
                      textField: {
                        disabled: true,
                        variant: "outlined",
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            // <MuiInput
            //   type="date"
            //   value={dayjs(
            //     dayjs(assignedTermDateValues[index] || "")
            //       .tz(selectedTimeZoneUtc)
            //       .format("lll"),
            //   )}
            //   onChange={(event) => handleTermDateChange(event, index)}
            // />
          ),
          HRA_Status: (
            <SelectDropdown
              label="HRA status"
              value={data1?.HRA_Status || ""}
              onChange={(event) => handleHraStatus(event, index)}
              options={hraStatusOptions}
              // sx={{backgroundColor:"green"}}
            />
          ),
          Contact: (
            <TooltipEllipsisCell value={data1?.Contact} maxWidth={100} />
          ),
          // Scheduler_Name: (
          //   <TooltipEllipsisCell
          //     value={assigned_scheduler?.firstName}
          //     maxWidth={100}
          //   />
          // ),

          Evaluation_Date:
            tabId == 13 || tabId == 1 || tabId == 3 ? (
              // <MuiInput
              //   type="datetime-local"
              //   value={
              //     assignedEvaluationDateValues[index]
              //       ? dayjs(
              //           dayjs(assignedEvaluationDateValues[index] || "")
              //             .tz(selectedTimeZoneUtc)
              //             .format("lll"),
              //         )
              //       : null
              //   }
              //   onChange={(event) => handleEvaluationDateChange(event, index)}
              // />
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DateTimePicker", "DateTimePicker"]}
                  >
                    <DateTimePicker
                      label=" "
                      id="datetime-local"
                      format="MM/DD/YYYY hh:mm a"
                      views={["year", "month", "day", "hours", "minutes"]}
                      // minDate={dayjs(new Date("2023-01-01"))}
                      // maxDate={dayjs(new Date("2025-12-31"))}
                      minDate={getMinDate()}
                      maxDate={getMaxDate()}
                      ampm
                      autoOk={false}
                      onClose={() => {
                        setIsAssignedEvaluationDateAlertOpen(true);
                      }}
                      value={
                        assignedEvaluationDateValues[index]
                          ? dayjs(
                              dayjs(assignedEvaluationDateValues[index] || "")
                                .tz(selectedTimeZoneUtc)
                                .format("lll"),
                            )
                          : null
                      }
                      onChange={(newValue) => {
                        handleEvaluationDateChange(
                          new Date(newValue),
                          index,
                          data1?.Evaluation_Date,
                        );
                      }}
                      slotProps={{
                        textField: {
                          disabled: true,
                          variant: "outlined",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            ) : (
              <p>
                {assignedEvaluationDateValues[index] || data1?.Evaluation_Date
                  ? dayjs(
                      assignedEvaluationDateValues[index] ||
                        data1?.Evaluation_Date,
                    )
                      .tz(selectedTimeZoneUtc)
                      .format("YYYY-MM-DD h:mm:ss A")
                  : ""}
              </p>
            ),
          Days: data1?.Days,
          Sub_Status: data1?.Sub_Status,
          Assigned_Specialist: (
            <SelectDropdown
              label="Select QA"
              // value={data1?.Assigned_Clinician || ""}
              // onChange={(event) => handleAssignedClinicianChange(event, index)}
              // options={clinicianOptions?.map(
              //   (clinician) => `${clinician.firstName} ${clinician.lastName}`,
              // )}
              options={["Assigned specialist 1", "Assigned specialist 2"]}
            />
          ),
          Referral_Status: (
            <SelectDropdown
              label="Referral Status"
              // value={data1?.Assigned_Clinician || ""}
              // onChange={(event) => handleAssignedClinicianChange(event, index)}
              // options={clinicianOptions?.map(
              //   (clinician) => `${clinician.firstName} ${clinician.lastName}`,
              // )}
              options={["Referral Status 1", "Referral Status 2"]}
            />
          ),
        };
      });
    }
  }, [selectedTimeZoneUtc, dataMemberListWithDropdown]);

  return (
    <div className="right-content">
      <div>
        <DashboardComponent>
          <Suspense fallback={<div>Loading...</div>}>
            <MemberList
              onDropdownChange={handleDropdownChange}
              onVphpSelection={handleVphpSelection}
              hraStatusOptions={hraStatusOptions}
              visitTypeOptions={visitTypeOptions}
              clinicianData={clinicianData}
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              onSearch={handleSearch}
              resetSearch={resetSearch}
              selectedDataForFax={selectedDataForFax}
              setSelectedDataForFax={setSelectedDataForFax}
              fetchMemberDataAfterFax={fetchMemberDataAfterFax}
              responseData={responseData}
              setTabId={setTabId}
              tabId={tabId}
              selectedDropdownValue={selectedDropdownValue}
              setSelectedDropdownValue={setSelectedDropdownValue}
              setNoDataFound={setNoDataFound}
              selectedMemberForFax={selectedMemberForFax}
              setSelectedMemberForFax={setSelectedMemberForFax}
              selectedRowCheckboxes={selectedRowCheckboxes}
              setSelectedRowCheckboxes={setSelectedRowCheckboxes}
            />
          </Suspense>
        </DashboardComponent>
      </div>

      {isDialogOpen === true ? (
        <VphpDialog
          onSelection={handleVphpSelection}
          fetchMemberData={fetchMemberData}
          setLoading={setLoading}
          routedFrom="memberList"
        />
      ) : (
        <div className="container">
          {
            // clientData &&
            // clientData.isAllSelected === true
            (clientData?.isAllSelected === true ||
              localStorage.getItem("isAllSelected") === "true") &&
            (clientData?.plan !== null || storedPlan) &&
            (clientData?.screeningTypes !== null || storedScreeningTypes) &&
            (clientData?.year !== null || storedYear) ? (
              <div className="datatable-memberlist">
                {loading ? (
                  <Loader />
                ) : (
                  <DataTableComponent
                    samp="a"
                    data={mappingData}
                    handleSorting={handleSorting}
                    showCheckboxHeader={showCheckboxHeader}
                    header={tableHeaders}
                    selectedHeader={orderBy}
                    selectedSorting={order}
                    selectedDropdownValue={selectedDropdownValue}
                    selectAllCompletedHeaderCheckboxState={
                      selectAllCompletedHeaderCheckbox
                    }
                    selectAllUploadHeaderCheckboxState={
                      selectAllUploadHeaderCheckbox
                    }
                    selectedRowCompletedCheckboxes={
                      selectedRowCompletedCheckboxes
                    }
                    selectedRowUploadCheckboxes={selectedRowUploadCheckboxes}
                    selectedRowCheckboxes={selectedRowCheckboxes}
                    setSelectedRowCheckboxes={setSelectedRowCheckboxes}
                    handleReadyForUploadHeaderCheckboxChange={
                      handleReadyForUploadHeaderCheckboxChange
                    }
                    handleCompletedHeaderCheckboxChange={
                      handleCompletedHeaderCheckboxChange
                    }
                    //handleHeaderCheckboxChange={handleHeaderCheckboxChange}
                    handleRowCheckboxChange={handleRowCheckboxChange}
                    handleCheckboxSelection={handleCheckboxSelection}
                    selectedCheckboxes={selectedCheckboxes}
                    setSelectedCheckboxes={setSelectedCheckboxes}
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={totalRowCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    noDataFound={noDataFound}
                    setNoDataFound={setNoDataFound}
                  />
                )}
              </div>
            ) : (
              <VphpDialog
                onSelection={handleVphpSelection}
                routedFrom="memberList"
                fetchMemberData={fetchMemberData}
                setLoading={setLoading}
              />
            )
          }
          {hraStatus && isActivateAlertOpen && (
            <Alert
              open={isActivateAlertOpen}
              handleClose={() => setIsActivateAlertOpen(false)}
              title="Do you want to change HRA Status"
              okText="Yes"
              cancelText="No"
              onAddClick={handleAlertYesClick}
              onNoClick={handleHraStatusCancel}
            />
          )}
          {visitTypeValues && isVisitTypeAlertOpen && (
            <Alert
              open={isVisitTypeAlertOpen}
              handleClose={() => setIsVisitTypeAlertOpen(false)}
              title={`Do you want to change Visit Type of ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleVisitTypeUpdate}
              onNoClick={handleVisitTypeCancel}
            />
          )}
          {assignedClinicianValues && isAssignedClinicianAlertOpen && (
            <Alert
              open={isAssignedClinicianAlertOpen}
              handleClose={() => setIsAssignedClinicianAlertOpen(false)}
              title={`Do you want to assign ${assignedClinicianValues[selectedRowIndex]} to ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedClinicianTypeUpdate}
              onNoClick={handleAssignedClinicianTypeCancel}
            />
          )}
          {assignedCodingValues && isAssignedCodingAlertOpen && (
            <Alert
              open={isAssignedCodingAlertOpen}
              handleClose={() => setIsAssignedCodingAlertOpen(false)}
              title={`Do you want to assign ${assignedCodingValues[selectedCoderRowIndex]} to ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedCodingTypeUpdate}
              onNoClick={handleAssignedCodingTypeCancel}
            />
          )}
          {assignedQaValues && isAssignedQaAlertOpen && (
            <Alert
              open={isAssignedQaAlertOpen}
              handleClose={() => setIsAssignedQaAlertOpen(false)}
              title={`Do you want to assign ${assignedQaValues[selectedQaRowIndex]} to ${selectedRowData.Name} ${selectedRowData.memberId}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedQaTypeUpdate}
              onNoClick={handleAssignedQaTypeCancel}
            />
          )}
          {assignedTermDateValues && isAssignedTermDateAlertOpen && (
            <Alert
              open={isAssignedTermDateAlertOpen}
              handleClose={() => setIsAssignedTermDateAlertOpen(false)}
              title={`Are you sure you want to change the term date for ${selectedRowData.Name}?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedTermDateTypeUpdate}
              onNoClick={handleAssignedTermDateTypeCancel}
            />
          )}
          {assignedScheduleDateValues && isAssignedScheduleDateAlertOpen && (
            <Alert
              open={isAssignedScheduleDateAlertOpen}
              handleClose={() => setIsAssignedScheduleDateAlertOpen(false)}
              title={`Do you want to schedule the selected date?`}
              okText="Yes"
              cancelText="No"
              onAddClick={handleAssignedScheduleDateTypeUpdate}
              onNoClick={handleAssignedScheduleDateTypeCancel}
            />
          )}
          {assignedEvaluationDateValues &&
            isAssignedEvaluationDateAlertOpen && (
              <Alert
                open={isAssignedEvaluationDateAlertOpen}
                handleClose={() => setIsAssignedEvaluationDateAlertOpen(false)}
                title={`Do you want to schedule the selected date?`}
                okText="Yes"
                cancelText="No"
                onAddClick={handleAssignedEvaluationDateTypeUpdate}
                onNoClick={handleAssignedEvaluationDateTypeCancel}
              />
            )}
          {isCancelAppointmentAlertOpen && (
            <AppointmentModal
              open={isCancelAppointmentAlertOpen}
              handleClose={() => setIsCancelAppointmentAlertOpen(false)}
              onSave={(cancelComment) =>
                handleCancelAppointmentAlertYesClick(cancelComment)
              }
              onExit={handleCancelAppointmentAlertExitClick}
            />
          )}
          {isReferralFormOpen && (
            <Referral open={isReferralFormOpen} onClose={handleCloseForm} />
          )}
          {referralStatus && isReferralActivateAlertOpen && (
            <Alert
              open={isReferralActivateAlertOpen}
              handleClose={() => setIsReferralActivateAlertOpen(false)}
              title="Do you want to change Referral Status"
              okText="Yes"
              cancelText="No"
              onAddClick={handleReferralAlertYesClick}
              onNoClick={handleReferralStatusCancel}
            />
          )}

          <Toaster
            open={toasterOpen}
            onClose={onCloseToaster}
            severity={toasterSeverity}
            message={toasterMessage}
          />
        </div>
      )}

      {isLoading && <Loader />}
    </div>
  );
};

export default MemberListPage;
