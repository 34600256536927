import React, { useState, useEffect } from "react";
import { Grid, FormControl, MenuItem, Select } from "@mui/material";
import { useFillHraContext } from "../../../../../../contexts/fillHraFormContext";

export const ActivitiesTableStatic = ({
  score,
  setScore,
  dressingScore,
  setDressingScore,
  toiletingScore,
  setToiletingScore,
  transferringScore,
  setTransferringScore,
  continenceScore,
  setContinenceScore,
  tableName,
  feedingScore,
  setFeedingScore,
  totalPoints,
  setTotalPoints,
  updateActivitiesFormData,
  data,
}) => {
  const { getDisableFlag } = useFillHraContext();

  const handleScoreChange = (event) => {
    const newValue = event.target.value;
    setScore(newValue);
    updateActivitiesFormData("score", newValue);
  };
  const handleDressingScoreChange = (event) => {
    const newValue = event.target.value;
    setDressingScore(newValue);
    updateActivitiesFormData("dressingScore", newValue);
  };
  const handleToiletingScoreChange = (event) => {
    const newValue = event.target.value;
    setToiletingScore(newValue);
    updateActivitiesFormData("toiletingScore", newValue);
  };
  const handleTransferringScoreChange = (event) => {
    const newValue = event.target.value;
    setTransferringScore(newValue);
    updateActivitiesFormData("transferringScore", newValue);
  };
  const handleContinenceScoreChange = (event) => {
    const newValue = event.target.value;
    setContinenceScore(newValue);
    updateActivitiesFormData("continenceScore", newValue);
  };
  const handleFeedingScoreChange = (event) => {
    const newValue = event.target.value;
    setFeedingScore(newValue);
    updateActivitiesFormData("feedingScore", newValue);
  };

  const updateTotalPoints = (total) => {
    setTotalPoints(total);
    updateActivitiesFormData("totalPoints", total);
  };

  useEffect(() => {
    const total =
      score * 1 +
      dressingScore * 1 +
      toiletingScore * 1 +
      transferringScore * 1 +
      continenceScore * 1 +
      feedingScore * 1;
    const totalString = total.toString();

    updateTotalPoints(totalString);
  }, [
    score,
    dressingScore,
    toiletingScore,
    transferringScore,
    continenceScore,
    feedingScore,
  ]);

  if (tableName === "Activities Chart") {
    return (
      <Grid>
        <table
          style={{
            //margin: "0 auto",
            borderCollapse: "collapse",
            border: "1px solid var(--mainTheme)",
            width: "80vw",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  border: "1px solid var(--mainTheme)",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                Activities
              </th>
              <th
                style={{
                  border: "1px solid var(--mainTheme)",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                Independence (1 Point)
                <p>NO supervision, direction or personal assistance.</p>
              </th>
              <th
                style={{
                  border: "1px solid var(--mainTheme)",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                Dependence (2-5 Points)
                <p>
                  WITH supervision, direction, personal assistance or total
                  care.
                </p>
              </th>
              <th
                style={{
                  border: "1px solid var(--mainTheme)",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                Score
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>Bathing</p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>
                  (1 POINT) Bathes self completely or needs help in bathing only
                  a single part of the body such as the back, genital area or
                  disabled extremity.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>
                  (2-5 POINTS) Need help with bathing more than one part of the
                  body, getting in or out of the tub or shower. Requires total
                  bathing.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p
                  style={{
                    width: "100%",
                    border: "1px solid var(--mainTheme800)",
                  }}
                >
                  <FormControl
                    disabled={getDisableFlag()}
                    variant="outlined"
                    style={{ width: "100px" }}
                  >
                    <Select value={score} onChange={handleScoreChange}>
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                    </Select>
                  </FormControl>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>Dressing</p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%", textWrap: "wrap" }}>
                  (1 POINT) Get clothes from closets and drawers and puts on
                  clothes and outer garments complete with fasteners. May have
                  help tying shoes.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%", textWrap: "wrap" }}>
                  (2-5 POINTS) Needs help with dressing self or needs to be
                  completely dressed.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p
                  style={{
                    width: "100%",
                    border: "1px solid var(--mainTheme800)",
                  }}
                >
                  <FormControl
                    disabled={getDisableFlag()}
                    variant="outlined"
                    style={{ width: "100px" }}
                  >
                    <Select
                      value={dressingScore}
                      onChange={handleDressingScoreChange}
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                    </Select>
                  </FormControl>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>Toileting</p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>
                  (1 POINT) Goes to toilet, gets on and off, arranges clothes,
                  cleans genital area without help.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>
                  (2-5 POINTS) Needs help transferring to the toilet, cleaning
                  self or uses bedpan or commode.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p
                  style={{
                    width: "100%",
                    border: "1px solid var(--mainTheme800)",
                  }}
                >
                  <FormControl
                    disabled={getDisableFlag()}
                    variant="outlined"
                    style={{ width: "100px" }}
                  >
                    <Select
                      value={toiletingScore}
                      onChange={handleToiletingScoreChange}
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                    </Select>
                  </FormControl>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>Transferring</p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%", textWrap: "wrap" }}>
                  (1 POINT) Moves in and out of bed or chair unassisted.
                  Mechanical transfer aids are acceptable.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%", textWrap: "wrap" }}>
                  (2-5 POINTS) Needs help in moving from bed to chair or
                  requires a complete transfer.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p
                  style={{
                    width: "100%",
                    border: "1px solid var(--mainTheme800)",
                  }}
                >
                  <FormControl
                    disabled={getDisableFlag()}
                    variant="outlined"
                    style={{ width: "100px" }}
                  >
                    <Select
                      value={transferringScore}
                      onChange={handleTransferringScoreChange}
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                    </Select>
                  </FormControl>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>Continence</p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>
                  (1 POINT) Exercises complete self control over urination and
                  defecation.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>
                  (2-5 POINTS) Is partially or totally incontinent of bowel or
                  bladder.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p
                  style={{
                    width: "100px",
                    border: "1px solid var(--mainTheme800)",
                  }}
                >
                  <FormControl
                    disabled={getDisableFlag()}
                    variant="outlined"
                    style={{ width: "100%" }}
                  >
                    <Select
                      value={continenceScore}
                      onChange={handleContinenceScoreChange}
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                    </Select>
                  </FormControl>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%" }}>Feeding</p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%", textWrap: "wrap" }}>
                  (1 POINT) Gets food from plate into mouth without help.
                  Preparation of food may be done by another person.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p style={{ width: "100%", textWrap: "wrap" }}>
                  (2-5 POINTS) Needs partial or total help with feeding or
                  requires parenteral feeding.
                </p>
              </td>
              <td
                style={{ border: "1px solid var(--mainTheme)", padding: "8px" }}
              >
                <p
                  style={{
                    width: "100%",
                    border: "1px solid var(--mainTheme800)",
                  }}
                >
                  <FormControl
                    disabled={getDisableFlag()}
                    variant="outlined"
                    style={{ width: "100px" }}
                  >
                    <Select
                      value={feedingScore}
                      onChange={handleFeedingScoreChange}
                    >
                      <MenuItem value="0">0</MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                    </Select>
                  </FormControl>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p style={{ fontSize: "24px", fontWeight: "bold" }}>
          <div style={{ display: "flex", width: "100vw" }}>
            <p>Total Points: </p>{" "}
            <p
              style={{
                textDecoration: "underline",
                marginLeft: "11px",
                color: "var(--mainTheme)",
              }}
            >
              {totalPoints}
            </p>
          </div>
        </p>
      </Grid>
    );
  }
};
