// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: fixed;
  padding: 5px;
  bottom: 0;
  width: 100%;
  background: var(--colorWhite);
  box-shadow: -4px -4px 4px 0px rgba(0, 0, 0, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/footer.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,SAAA;EACA,WAAA;EACA,6BAAA;EACA,iDAAA;AACJ","sourcesContent":[".footer {\r\n    position: fixed;\r\n    padding: 5px;\r\n    bottom: 0;\r\n    width: 100%;\r\n    background: var(--colorWhite);\r\n    box-shadow: -4px -4px 4px 0px rgba(0, 0, 0, 0.25)\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
