import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import { Paper, Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import Button from "../button/button";
import _ from "lodash";
import PropTypes from "prop-types";
import "./table.scss";
import CallLog from "../Modal/callLog";
import HistoryPopup from "../Modal/schedulerHistory";
import SelectDropdown from "../select/select";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    whiteSpace: "nowrap",
  },
  body: {
    whiteSpace: "nowrap",
  },
}))(TableCell);

//Table head part

function EnhancedTableHead(props) {
  const headCell = props.TableTitle || [];
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow
        className="table-row"
        sx={{
          "& .MuiTableSortLabel-root": {
            color: "white",
          },
          "& .MuiTableSortLabel-root:hover": {
            color: "white !important",
          },
          "& .MuiTableSortLabel-icon": {
            color: "white !important",
            opacity: 1,
          },
        }}
      >
        {headCell.map((headCell) =>
          headCell.label === "Select All/ Deselect All" ? (
            <StyledTableCell
              key={headCell.id}
              align="center"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                fontSize: "14px",
                fontFamily: "Inter",
                fontWeight: "600",
                borderBottom: "1px solid rgba(207, 221, 235, 0.2)",
                color: `var(--mainTheme)`,
                padding: "16px",
              }}
            >
              <Checkbox
                color="primary"
                size="large"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "Select All/ Deselect All",
                }}
              />
            </StyledTableCell>
          ) : (
            <StyledTableCell
              key={headCell.id}
              align="center"
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{
                fontSize: "14px",
                fontFamily: "Inter",
                color: "#fff",
                background: `var(--mainTheme)`,
                padding: "10px",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function Index(props) {
  const [orderBy, setOrderBy] = useState(props?.selectedHeader);
  const [order, setOrder] = useState(props?.selectedSorting);

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(props?.rowsPerPage || 10);
  const [rows, setRows] = useState([]);
  const [, setCheckedList] = useState("");
  const [unCheckedList, setUnCheckedList] = useState("");
  const [SearchedRows, setSearchedRows] = useState([]);

  const [isView, setIsView] = useState(false);
  const [isHistoryPopupView, setIsHistoryPopupView] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const fields = props?.TableBodyFields;
  const tableHeadJsonName = props?.TableHead;
  const getFieldsApi = props?.getFieldsApi;
  const buttonLabel = props.buttonLabel;
  const handleButtonClick = props.handleButtonClick;
  // const HRAStatusOptions = useMemo(()=>commonConst().HRAStatusOptions() ,[])
  // const HRAStatusOptions = ['Unscheduled', 'Scheduled', 'In progress', 'Appointment Cancelled', 'Patient refusal', 'UTC(Unable to Contact)', 'Submitted - Ready for QA', 'Submitted - Ready for coding', 'Clinicians Review - QA', 'Clinicians Review - Coding', 'Submitted - Ready for upload', 'Completed', 'Assessment Cancelled']

  const handleHraLog = () => {
    setIsView(true);
    setIsOpen(true);
  };

  const tableHeaders = [
    { id: "Action", label: "Action" },
    { id: "clientName", label: "Client Name" },
    { id: "plan", label: "Plan" },
    { id: "program", label: "Program" },
    { id: "yearList", label: "Years List" },
  ];

  const clinicianTableHeaders = [
    { id: "Action", label: "Action" },
    { id: "licenseNumber", label: "Identification" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "status", label: "Status" },
  ];

  const adminTableHeaders = [
    { id: "Action", label: "Action" },
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "status", label: "Status" },
  ];

  const tableTitle = props.useAdminHeaders
    ? adminTableHeaders
    : props.useClinicianHeaders
    ? clinicianTableHeaders
    : tableHeaders;

  const ModalClose = () => {
    setIsHistoryPopupView(false);
  };

  const HandleClose = () => {
    setIsView(false);
  };
  const LogHandleOpen = () => {
    setIsView(true);
  };

  const LogHandleClose = () => {
    setIsView(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    let checked = rows?.map((el) => el.ID);
    // if (idValue) {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n.name);
      setSelected(newSelecteds);
      setCheckedList(checked);
      setUnCheckedList([]);
      return;
    } else {
      setUnCheckedList(checked);
      setCheckedList([]);
    }
    //}
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    props?.handleSorting(property, newOrder);
  };

  useEffect(() => {
    // Update the 'rows' state with the 'apiData' from props
    setRows(props.rows);
    setSearchedRows(props.rows);
  }, [props.rows]);

  useEffect(() => {
    if (props?.TableSearchValue) {
      const filterTable = SearchedRows.filter((item) =>
        Object.keys(item).some((k) =>
          String(item[k])
            .toLowerCase()
            .includes(props?.TableSearchValue.toLowerCase()),
        ),
      );
      setRows(filterTable);
    } else {
      //setRows(schedulerData)
    }
  }, [props?.TableSearchValue]);

  const HistoryPopupEvent = (e) => {
    setIsHistoryPopupView(true);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order || "asc"}
                orderBy={orderBy || ""}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
                TableTitle={tableTitle}
              />

              <TableBody>
                {_.isEmpty(rows) ? (
                  <TableRow>
                    <StyledTableCell
                      colSpan={tableTitle.length + 1}
                      align="center"
                      sx={{
                        fontSize: "16px",
                        fontFamily: "Inter",
                        color: "#000",
                        padding: "20px",
                      }}
                    >
                      No data found.
                    </StyledTableCell>
                  </TableRow>
                ) : (
                  stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          {fields?.map((field) => {
                            if (field["type"] === "action") {
                              return (
                                <StyledTableCell align="center" key={field.id}>
                                  <Button
                                    type="submit"
                                    cursor="pointer"
                                    onClick={() => props.handleButtonClick(row)}
                                  >
                                    {/* {field['label']} */}
                                    {buttonLabel}
                                  </Button>
                                </StyledTableCell>
                              );
                            }
                            if (field["type"] === "select") {
                              return (
                                <StyledTableCell
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Inter",
                                    color: "#000",
                                    padding: "10px",
                                  }}
                                  align="center"
                                  key={field.id}
                                  s
                                >
                                  <SelectDropdown
                                    title="Select"
                                    options={field["options"]}
                                    className="dropdownMemberListDialog"
                                    //onChange={handlePlanChange}
                                    //value={selectedPlan}
                                    sx={{
                                      width: "120px",
                                      border: "none",
                                      margin: 0,
                                      padding: 0,
                                      background: "transparent",
                                    }}
                                  />
                                </StyledTableCell>
                              );
                            } else {
                              return (
                                <StyledTableCell
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    fontFamily: "Inter",
                                    color: "#000",
                                    padding: "10px",
                                  }}
                                  align="center"
                                  onClick={
                                    field["id"] === "Name"
                                      ? () => HistoryPopupEvent(field["popup"])
                                      : () => {}
                                  }
                                  key={field.id}
                                >
                                  {field["id"] === "status"
                                    ? row[field["id"]] === 1
                                      ? "Active"
                                      : "Inactive"
                                    : field["id"] === "program"
                                    ? row[field["id"]]
                                        .map((program) => program.name)
                                        .join(", ")
                                    : Array.isArray(row[field["id"]])
                                    ? row[field["id"]].join(", ")
                                    : row[field["id"]]}
                                </StyledTableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={props.rowsPerPageOptions}
            component="div"
            count={props.count}
            rowsPerPage={props.rowsPerPage}
            page={props.page}
            onPageChange={props.onPageChange}
            onRowsPerPageChange={props.onRowsPerPageChange}
          />
        </Paper>
      </Box>
      {/* {isView &&
                <CallLog ModalClose={ModalClose} />
            }
            {isHistoryPopupView &&
                <HistoryPopup ModalClose={ModalClose} />
            } */}
    </>
  );
}
