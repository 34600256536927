// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nurse-dashboard {
  width: 100%;
}
.nurse-dashboard .css-1l9nj0y-MuiPaper-root-MuiTableContainer-root {
  max-height: 502px !important;
}
.nurse-dashboard .datatable-nurse {
  margin-top: 23px;
}
.nurse-dashboard .datatable-nurse .css-75kgeb-MuiTableCell-root {
  padding: 0px !important;
  font-family: sans-serif;
  border-bottom: unset !important;
}
.nurse-dashboard .datatable-nurse .css-1l9nj0y-MuiPaper-root-MuiTableContainer-root {
  box-shadow: 0px 2px 9px 4px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.nurse-dashboard .datatable-nurse .css-75kgeb-MuiTableCell-root.MuiTableCell-body {
  font-size: 13px;
  text-align: -webkit-center;
}
.nurse-dashboard .datatable-nurse .css-ojb8nx-MuiTableCell-root {
  line-height: 1px;
}
.nurse-dashboard .datatable-nurse .css-8g76k9 > :not(style) {
  width: unset !important;
}
.nurse-dashboard .datatable-nurse .css-11bf4st-MuiTableCell-root {
  padding: 3px;
}`, "",{"version":3,"sources":["webpack://./src/pages/NurseDashboard/NurseDashboard.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AACI;EACI,4BAAA;AACR;AAEI;EACI,gBAAA;AAAR;AAEQ;EACI,uBAAA;EACA,uBAAA;EACA,+BAAA;AAAZ;AAGQ;EACI,wHAAA;AADZ;AAIQ;EACI,eAAA;EACA,0BAAA;AAFZ;AAKQ;EACI,gBAAA;AAHZ;AAMQ;EACI,uBAAA;AAJZ;AAOQ;EACI,YAAA;AALZ","sourcesContent":[".nurse-dashboard {\r\n    width: 100%;\r\n\r\n    .css-1l9nj0y-MuiPaper-root-MuiTableContainer-root {\r\n        max-height: 502px !important;\r\n    }\r\n\r\n    .datatable-nurse {\r\n        margin-top: 23px;\r\n\r\n        .css-75kgeb-MuiTableCell-root {\r\n            padding: 0px !important;\r\n            font-family: sans-serif;\r\n            border-bottom: unset !important;\r\n        }\r\n\r\n        .css-1l9nj0y-MuiPaper-root-MuiTableContainer-root {\r\n            box-shadow: 0px 2px 9px 4px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);\r\n        }\r\n\r\n        .css-75kgeb-MuiTableCell-root.MuiTableCell-body {\r\n            font-size: 13px;\r\n            text-align: -webkit-center;\r\n        }\r\n\r\n        .css-ojb8nx-MuiTableCell-root {\r\n            line-height: 1px;\r\n        }\r\n\r\n        .css-8g76k9>:not(style) {\r\n            width: unset !important;\r\n        }\r\n\r\n        .css-11bf4st-MuiTableCell-root {\r\n            padding: 3px;\r\n        }\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
