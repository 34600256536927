import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MemberUrgentReferralForm from "./MemberUrgentReferralForm";
import MemberNonUrgentReferralForm from "./MemberNonUrgentReferralForm";
import MemberCriticalReferralForm from "./MemberCriticalReferralForm";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
  "& .MuiPaper-root": {
    width: "80%", // Change this to the desired width
    maxWidth: "none", // Prevents the dialog from shrinking
  },
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "var(--unselectedColor)", // Unchecked color
  "&.Mui-checked": {
    color: "var(--selectedColor)", // Checked color
  },
}));

export default function Referral({ open, onClose }) {
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        sx={{
          backgroundColor: "var(--mainTheme900)",
          color: "white",
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "600",
        }}
        onClose={onClose}
      >
        Case Management Referral Form
        <Typography
          sx={{
            fontSize: "20px",
            textAlign: "left",
            marginTop: "28px",
          }}
          gutterBottom
        >
          Please check which Category the referral fits in.
        </Typography>
        <FormControl sx={{ display: "flex" }} component="fieldset">
          <RadioGroup
            row
            sx={{ display: "flex", gap: "100px" }}
            value={selectedValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="URGENT"
              control={<CustomRadio />}
              label="URGENT"
            />
            <FormControlLabel
              value="NON-URGENT"
              control={<CustomRadio />}
              label="NON-URGENT"
            />
            <FormControlLabel
              value="Critical Incident Report"
              control={<CustomRadio />}
              label="Critical Incident Report"
            />
          </RadioGroup>
        </FormControl>
      </BootstrapDialogTitle>
      <DialogContent>
        {selectedValue === "URGENT" && <MemberUrgentReferralForm />}
        {selectedValue === "NON-URGENT" && <MemberNonUrgentReferralForm />}
        {selectedValue === "Critical Incident Report" && (
          <MemberCriticalReferralForm />
        )}
      </DialogContent>
      {/* <DialogActions>
        <Button
          sx={{
        backgroundColor: "var(--mainTheme900)",
        color: "white",
        width: "100px",
        '&:hover': {
          backgroundColor: "var(--mainTheme500)", 
        },
      }}
      //  autoFocus
        onClick={onClose}>
          Submit
        </Button>
        <Button 
        sx={{
          backgroundColor: "var(--mainTheme900)",
          color: "white",
          width: "100px",
          '&:hover': {
            backgroundColor: "var(--mainTheme500)",
          },
        }}
        // autoFocus
         onClick={onClose}>
          Cancel
        </Button>
      </DialogActions> */}
    </BootstrapDialog>
  );
}
