const json2023 = {
  sectionId: 9,
  sectionName: "Allergies/Medication",
  isEditable: false,
  questions: [
    {
      id: 6,
      name: "allergy_allergies",
      type: "options_tree",
      label: "35. Allergies",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 11,
              name: "allergy_allergiesDatatable",
              label: "Allergies",
              editToggle: false,
              type: "datatable",
              tablelist: [
                [
                  {
                    id: 1,
                    label: "Allergies",
                    name: "HRAForm_Allergies",
                    columns: [
                      {
                        label: "Substance",
                        type: "text",
                      },
                      {
                        label: "Reaction",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  true,
                ],
                [
                  {
                    id: 2,
                    label: "BMI Index Chart",
                    name: "HRAForm_BMI_Index_Chart",
                    columns: [
                      {
                        label: "BMI",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 3,
                    label: "Counter Medications",
                    name: "HRAForm_Counter_Medications",
                    columns: [
                      {
                        label: "Date",
                        type: "date",
                      },
                      {
                        label: "Description",
                        type: "text",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 4,
                    label: "Medical History Specialists",
                    name: "HRAForm_Medical_History_Speciality",
                    columns: [
                      {
                        label: "Member Specialist",
                        type: "select",
                      },
                      {
                        label: "Speciality",
                        type: "text",
                      },
                      {
                        label: "For",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 5,
                    label: "Medications",
                    name: "HRAForm_Medications",
                    columns: [
                      {
                        label: "Diagnoses",
                        type: "text",
                      },
                      {
                        label: "label Name",
                        type: "select",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "select",
                      },
                      {
                        label: "Prescribing Physician",
                        type: "text",
                      },
                      {
                        label: "Status",
                        type: "togglebutton",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 6,
                    label: "Mini Cog Versions",
                    name: "HRAForm_Minicog_Version",
                    columns: [
                      {
                        label: "Version 1",
                        type: "text",
                      },
                      {
                        label: "Version 2",
                        type: "text",
                      },
                      {
                        label: "Version 3",
                        type: "text",
                      },
                      {
                        label: "Version 4",
                        type: "text",
                      },
                      {
                        label: "Version 5",
                        type: "text",
                      },
                      {
                        label: "Version 6",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 7,
                    label: "Preventive Care",
                    name: "HRAForm_Preventive_Care",
                    columns: [
                      {
                        label: "Screen",
                        type: "text",
                      },
                      {
                        label: "Answer",
                        type: "select",
                      },
                      {
                        label: "Date",
                        type: "text",
                      },
                      {
                        label: "Method",
                        type: "text",
                      },
                      {
                        label: "Recommendation",
                        type: "checkbox",
                      },
                      {
                        label: "Education Completed",
                        type: "checkbox",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 8,
                    label: "Previously Documented Conditions",
                    name: "HRAForm_Previously_Documented_Conditions",
                    columns: [
                      {
                        label: "Diagnosis",
                        type: "text",
                      },
                      {
                        label: "Prior HCC",
                        type: "text",
                      },
                      {
                        label: "Diagnosis Code",
                        type: "text",
                      },
                      {
                        label: "Date of Service",
                        type: "date",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 9,
                    label: "Prior Assessments",
                    name: "HRAForm_Prior_Assessments",
                    columns: [
                      {
                        label: "Date Time",
                        type: "date",
                      },
                      {
                        label: "Document",
                        type: "button",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 10,
                    label: "Stages Of Kidney Disease",
                    name: "HRAForm_Stage_Of_Kidney_Disease",
                    columns: [
                      {
                        label: "Stages",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 11,
                    label: "Alcohol Usage",
                    name: "HRAForm_Alcohol_Usage",
                    columns: [
                      {
                        label: "How many drinks",
                        type: "select",
                      },
                      {
                        label: "How often",
                        type: "select",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 12,
                    name: "Family History",
                    columns: [
                      {
                        label: "Family Member",
                        type: "select",
                      },
                      {
                        label: "Medical Condition",
                        type: "text",
                      },
                      {
                        label: "Cause of Death",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
              ],
              xs: 12,
              required: "no",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 1,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 1,
      editToggle: false,
    },
    {
      id: 7,
      name: "medications",
      type: "label",
      label: "Medications",
      editToggle: false,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 2,
    },
    {
      id: 11,
      name: "allergy_medicDatatable",
      label: "Medications",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 3,
    },
    {
      id: 6,
      name: "allergy_counterMedic",
      type: "options_tree",
      label: "36. Over the Counter Medications / Supplements",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 11,
              name: "allergy_counterMedicDatatable",
              label: "Data Table",
              editToggle: false,
              type: "datatable",
              tablelist: [
                [
                  {
                    id: 1,
                    label: "Allergies",
                    name: "HRAForm_Allergies",
                    columns: [
                      {
                        label: "Substance",
                        type: "text",
                      },
                      {
                        label: "Reaction",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 2,
                    label: "BMI Index Chart",
                    name: "HRAForm_BMI_Index_Chart",
                    columns: [
                      {
                        label: "BMI",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 3,
                    label: "Counter Medications",
                    name: "HRAForm_Counter_Medications",
                    columns: [
                      {
                        label: "Date",
                        type: "date",
                      },
                      {
                        label: "Description",
                        type: "text",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  true,
                ],
                [
                  {
                    id: 4,
                    label: "Medical History Specialists",
                    name: "HRAForm_Medical_History_Speciality",
                    columns: [
                      {
                        label: "Member Specialist",
                        type: "select",
                      },
                      {
                        label: "Speciality",
                        type: "text",
                      },
                      {
                        label: "For",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 5,
                    label: "Medications",
                    name: "HRAForm_Medications",
                    columns: [
                      {
                        label: "Diagnoses",
                        type: "text",
                      },
                      {
                        label: "label Name",
                        type: "select",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "select",
                      },
                      {
                        label: "Prescribing Physician",
                        type: "text",
                      },
                      {
                        label: "Status",
                        type: "togglebutton",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 6,
                    label: "Mini Cog Versions",
                    name: "HRAForm_Minicog_Version",
                    columns: [
                      {
                        label: "Version 1",
                        type: "text",
                      },
                      {
                        label: "Version 2",
                        type: "text",
                      },
                      {
                        label: "Version 3",
                        type: "text",
                      },
                      {
                        label: "Version 4",
                        type: "text",
                      },
                      {
                        label: "Version 5",
                        type: "text",
                      },
                      {
                        label: "Version 6",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 7,
                    label: "Preventive Care",
                    name: "HRAForm_Preventive_Care",
                    columns: [
                      {
                        label: "Screen",
                        type: "text",
                      },
                      {
                        label: "Answer",
                        type: "select",
                      },
                      {
                        label: "Date",
                        type: "text",
                      },
                      {
                        label: "Method",
                        type: "text",
                      },
                      {
                        label: "Recommendation",
                        type: "checkbox",
                      },
                      {
                        label: "Education Completed",
                        type: "checkbox",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 8,
                    label: "Previously Documented Conditions",
                    name: "HRAForm_Previously_Documented_Conditions",
                    columns: [
                      {
                        label: "Diagnosis",
                        type: "text",
                      },
                      {
                        label: "Prior HCC",
                        type: "text",
                      },
                      {
                        label: "Diagnosis Code",
                        type: "text",
                      },
                      {
                        label: "Date of Service",
                        type: "date",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 9,
                    label: "Prior Assessments",
                    name: "HRAForm_Prior_Assessments",
                    columns: [
                      {
                        label: "Date Time",
                        type: "date",
                      },
                      {
                        label: "Document",
                        type: "button",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 10,
                    label: "Stages Of Kidney Disease",
                    name: "HRAForm_Stage_Of_Kidney_Disease",
                    columns: [
                      {
                        label: "Stages",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 11,
                    label: "Alcohol Usage",
                    name: "HRAForm_Alcohol_Usage",
                    columns: [
                      {
                        label: "How many drinks",
                        type: "select",
                      },
                      {
                        label: "How often",
                        type: "select",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 12,
                    name: "Family History",
                    columns: [
                      {
                        label: "Family Member",
                        type: "select",
                      },
                      {
                        label: "Medical Condition",
                        type: "text",
                      },
                      {
                        label: "Cause of Death",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 13,
                    label: "Depression Severity",
                    name: "Depression_Severity",
                    columns: [
                      {
                        label: "Score",
                        type: "number",
                      },
                      {
                        label: "Depression Severity",
                        type: "checkbox",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
              ],
              xs: 12,
              required: "no",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 1,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
          depth: 2,
        },
      ],
      questionId: 2,
      editToggle: false,
      depth: 1,
    },

    {
      id: 6,
      name: "allergy_longTermUsageNone",
      type: "options_tree",
      label: "Long Term Use of:",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "allergy_longTermUsageNoneSub",
              type: "options_tree",
              label: "Describe",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: false,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "ASA",
                  value: "asa",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Steroids",
                  value: "steroids",
                  type: "toggle_button",
                  depth: 1,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Insulin",
                  value: "insulin",
                  type: "toggle_button",
                  depth: 1,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "allergy_longTermUsageNoneSubAntiCoagSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 3,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Coumadin",
                          value: "coumadin",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Thrombin Inhibitors(Paradaxa)",
                          value: "thrombin",
                          type: "toggle_button",
                          depth: 3,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Plavix",
                          value: "plavix",
                          type: "toggle_button",
                          depth: 3,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Factor Xa Inhibitors(Xarelto,Eliquis)",
                          value: "factorXa",
                          type: "toggle_button",
                          depth: 3,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 4,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          depth: 3,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 2,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Anticoagulants",
                  value: "anticoagulants",
                  type: "toggle_button",
                  depth: 1,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Statins",
                  value: "statins",
                  type: "toggle_button",
                  depth: 1,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Biphosphonate",
                  value: "biphosphonate",
                  type: "toggle_button",
                  depth: 1,
                },
                // {
                //   subQuestionId: 7,
                //   hasSubQuestions: false,
                //   subQuestions: [],
                //   editToggle: false,
                //   isCommentEnabled: false,
                //   isConfigured: true,
                //   isModified: false,
                //   label: "None",
                //   value: "none",
                //   type: "toggle_button",
                //   depth: 1,
                // },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          depth: 1,
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 4,
      editToggle: false,
    },
    {
      id: 7,
      name: "medication_compliance_and_knowledge_of_use_and_disease",
      type: "label",
      label: "Medication Compliance and Knowledge of Use and Disease",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 5,
    },
    {
      id: 6,
      name: "allergy_medicSubTakeMedic",
      type: "options_tree",
      label: "1. Do you ever forget to take your medicine?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 6,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubAttenMedic",
      type: "options_tree",
      label: "2. Do you sometimes not pay enough attention to your medication?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 7,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubLongTerm",
      type: "options_tree",
      label:
        "3. Do you know the Long Term benefit of taking your medicine as told to you by the doctor or pharmacist?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 8,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubStopMedic",
      type: "options_tree",
      label:
        "4. When you feel better do you sometimes stop taking your medicine?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 9,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubFeelWorse",
      type: "options_tree",
      label:
        "5. Sometimes if you feel worse when you take your medicine do you stop taking it?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 10,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubForgotRefill",
      type: "options_tree",
      label: "6. Do you sometimes forget to refill your prescription on time?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 11,
      editToggle: false,
    },
    {
      id: 10,
      name: "allergies_Recommendations",
      label: "Recommendations",
      placeholder: "",
      value: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        [
          "Discuss options with your Doctor and/or pharmacist to improve medication adherance",
          "medicAdherenceAllergy",
        ],
        [
          "Discuss medication side effects with your Doctor",
          "sideEffectsAllergy",
        ],
        ["Other", "otherAllergy"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 12,
    },

    {
      id: 10,
      name: "horizontal_line",
      label: "Horizontal Line",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "line",
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: false,
      isModified: false,
    },

    {
      id: 10,
      name: "educatedOnImportance",
      label: "",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        [
          "Educated on importance of medication compliance, member verbalizes understanding",
          "educateImportance",
        ],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 6,
    },
  ],
};

const json2024 = {
  sectionId: 9,
  sectionName: "Allergies/Medication",
  isEditable: false,
  questions: [
    {
      id: 6,
      name: "allergy_allergies",
      type: "options_tree",
      label: "35. Allergies",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 11,
              name: "allergy_allergiesDatatable",
              label: "Allergies",
              editToggle: false,
              type: "datatable",
              tablelist: [
                [
                  {
                    id: 1,
                    label: "Allergies",
                    name: "HRAForm_Allergies",
                    columns: [
                      {
                        label: "Substance",
                        type: "text",
                      },
                      {
                        label: "Reaction",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  true,
                ],
                [
                  {
                    id: 2,
                    label: "BMI Index Chart",
                    name: "HRAForm_BMI_Index_Chart",
                    columns: [
                      {
                        label: "BMI",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 3,
                    label: "Counter Medications",
                    name: "HRAForm_Counter_Medications",
                    columns: [
                      {
                        label: "Date",
                        type: "date",
                      },
                      {
                        label: "Description",
                        type: "text",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 4,
                    label: "Medical History Specialists",
                    name: "HRAForm_Medical_History_Speciality",
                    columns: [
                      {
                        label: "Member Specialist",
                        type: "select",
                      },
                      {
                        label: "Speciality",
                        type: "text",
                      },
                      {
                        label: "For",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 5,
                    label: "Medications",
                    name: "HRAForm_Medications",
                    columns: [
                      {
                        label: "Diagnoses",
                        type: "text",
                      },
                      {
                        label: "label Name",
                        type: "select",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "select",
                      },
                      {
                        label: "Prescribing Physician",
                        type: "text",
                      },
                      {
                        label: "Status",
                        type: "togglebutton",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 6,
                    label: "Mini Cog Versions",
                    name: "HRAForm_Minicog_Version",
                    columns: [
                      {
                        label: "Version 1",
                        type: "text",
                      },
                      {
                        label: "Version 2",
                        type: "text",
                      },
                      {
                        label: "Version 3",
                        type: "text",
                      },
                      {
                        label: "Version 4",
                        type: "text",
                      },
                      {
                        label: "Version 5",
                        type: "text",
                      },
                      {
                        label: "Version 6",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 7,
                    label: "Preventive Care",
                    name: "HRAForm_Preventive_Care",
                    columns: [
                      {
                        label: "Screen",
                        type: "text",
                      },
                      {
                        label: "Answer",
                        type: "select",
                      },
                      {
                        label: "Date",
                        type: "text",
                      },
                      {
                        label: "Method",
                        type: "text",
                      },
                      {
                        label: "Recommendation",
                        type: "checkbox",
                      },
                      {
                        label: "Education Completed",
                        type: "checkbox",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 8,
                    label: "Previously Documented Conditions",
                    name: "HRAForm_Previously_Documented_Conditions",
                    columns: [
                      {
                        label: "Diagnosis",
                        type: "text",
                      },
                      {
                        label: "Prior HCC",
                        type: "text",
                      },
                      {
                        label: "Diagnosis Code",
                        type: "text",
                      },
                      {
                        label: "Date of Service",
                        type: "date",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 9,
                    label: "Prior Assessments",
                    name: "HRAForm_Prior_Assessments",
                    columns: [
                      {
                        label: "Date Time",
                        type: "date",
                      },
                      {
                        label: "Document",
                        type: "button",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 10,
                    label: "Stages Of Kidney Disease",
                    name: "HRAForm_Stage_Of_Kidney_Disease",
                    columns: [
                      {
                        label: "Stages",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 11,
                    label: "Alcohol Usage",
                    name: "HRAForm_Alcohol_Usage",
                    columns: [
                      {
                        label: "How many drinks",
                        type: "select",
                      },
                      {
                        label: "How often",
                        type: "select",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 12,
                    name: "Family History",
                    columns: [
                      {
                        label: "Family Member",
                        type: "select",
                      },
                      {
                        label: "Medical Condition",
                        type: "text",
                      },
                      {
                        label: "Cause of Death",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
              ],
              xs: 12,
              required: "no",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 1,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 1,
      editToggle: false,
    },
    {
      id: 7,
      name: "medications",
      type: "label",
      label: "Medications",
      editToggle: false,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 2,
    },
    {
      id: 11,
      name: "allergy_medicDatatable",
      label: "Medications",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "HRAForm_BMI_Index_Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          true,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stages Of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
      ],
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 3,
    },
    {
      id: 6,
      name: "allergy_counterMedic",
      type: "options_tree",
      label: "36. Over the Counter Medications / Supplements",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 11,
              name: "allergy_counterMedicDatatable",
              label: "Data Table",
              editToggle: false,
              type: "datatable",
              tablelist: [
                [
                  {
                    id: 1,
                    label: "Allergies",
                    name: "HRAForm_Allergies",
                    columns: [
                      {
                        label: "Substance",
                        type: "text",
                      },
                      {
                        label: "Reaction",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 2,
                    label: "BMI Index Chart",
                    name: "HRAForm_BMI_Index_Chart",
                    columns: [
                      {
                        label: "BMI",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 3,
                    label: "Counter Medications",
                    name: "HRAForm_Counter_Medications",
                    columns: [
                      {
                        label: "Date",
                        type: "date",
                      },
                      {
                        label: "Description",
                        type: "text",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  true,
                ],
                [
                  {
                    id: 4,
                    label: "Medical History Specialists",
                    name: "HRAForm_Medical_History_Speciality",
                    columns: [
                      {
                        label: "Member Specialist",
                        type: "select",
                      },
                      {
                        label: "Speciality",
                        type: "text",
                      },
                      {
                        label: "For",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 5,
                    label: "Medications",
                    name: "HRAForm_Medications",
                    columns: [
                      {
                        label: "Diagnoses",
                        type: "text",
                      },
                      {
                        label: "label Name",
                        type: "select",
                      },
                      {
                        label: "Dose Per Units",
                        type: "text",
                      },
                      {
                        label: "Route",
                        type: "select",
                      },
                      {
                        label: "Frequency",
                        type: "select",
                      },
                      {
                        label: "Prescribing Physician",
                        type: "text",
                      },
                      {
                        label: "Status",
                        type: "togglebutton",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 6,
                    label: "Mini Cog Versions",
                    name: "HRAForm_Minicog_Version",
                    columns: [
                      {
                        label: "Version 1",
                        type: "text",
                      },
                      {
                        label: "Version 2",
                        type: "text",
                      },
                      {
                        label: "Version 3",
                        type: "text",
                      },
                      {
                        label: "Version 4",
                        type: "text",
                      },
                      {
                        label: "Version 5",
                        type: "text",
                      },
                      {
                        label: "Version 6",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 7,
                    label: "Preventive Care",
                    name: "HRAForm_Preventive_Care",
                    columns: [
                      {
                        label: "Screen",
                        type: "text",
                      },
                      {
                        label: "Answer",
                        type: "select",
                      },
                      {
                        label: "Date",
                        type: "text",
                      },
                      {
                        label: "Method",
                        type: "text",
                      },
                      {
                        label: "Recommendation",
                        type: "checkbox",
                      },
                      {
                        label: "Education Completed",
                        type: "checkbox",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 8,
                    label: "Previously Documented Conditions",
                    name: "HRAForm_Previously_Documented_Conditions",
                    columns: [
                      {
                        label: "Diagnosis",
                        type: "text",
                      },
                      {
                        label: "Prior HCC",
                        type: "text",
                      },
                      {
                        label: "Diagnosis Code",
                        type: "text",
                      },
                      {
                        label: "Date of Service",
                        type: "date",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 9,
                    label: "Prior Assessments",
                    name: "HRAForm_Prior_Assessments",
                    columns: [
                      {
                        label: "Date Time",
                        type: "date",
                      },
                      {
                        label: "Document",
                        type: "button",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 10,
                    label: "Stages Of Kidney Disease",
                    name: "HRAForm_Stage_Of_Kidney_Disease",
                    columns: [
                      {
                        label: "Stages",
                        type: "text",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 11,
                    label: "Alcohol Usage",
                    name: "HRAForm_Alcohol_Usage",
                    columns: [
                      {
                        label: "How many drinks",
                        type: "select",
                      },
                      {
                        label: "How often",
                        type: "select",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
                [
                  {
                    id: 12,
                    name: "Family History",
                    columns: [
                      {
                        label: "Family Member",
                        type: "select",
                      },
                      {
                        label: "Medical Condition",
                        type: "text",
                      },
                      {
                        label: "Cause of Death",
                        type: "text",
                      },
                    ],
                    isReadOnly: false,
                  },
                  false,
                ],
                [
                  {
                    id: 13,
                    label: "Depression Severity",
                    name: "Depression_Severity",
                    columns: [
                      {
                        label: "Score",
                        type: "number",
                      },
                      {
                        label: "Depression Severity",
                        type: "checkbox",
                      },
                    ],
                    isReadOnly: true,
                  },
                  false,
                ],
              ],
              xs: 12,
              required: "no",
              isCommentEnabled: false,
              isConfigured: true,
              isModified: true,
              subQuestionId: 1,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
          depth: 2,
        },
      ],
      questionId: 4,
      editToggle: false,
      depth: 1,
    },

    {
      id: 6,
      name: "allergy_longTermUsageNone",
      type: "options_tree",
      label: "Long Term Use of:",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "allergy_longTermUsageNoneSub",
              type: "options_tree",
              label: "Describe",
              isMulti: true,
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: false,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 1,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "ASA",
                  value: "asa",
                  isConfigured: true,
                  type: "toggle_button",
                },
                // {
                //   subQuestionId: 2,
                //   hasSubQuestions: false,
                //   subQuestions: [],
                //   editToggle: false,
                //   isCommentEnabled: false,
                //   isConfigured: true,
                //   isModified: false,
                //   label: "Steroids",
                //   value: "steroids",
                //   type: "toggle_button",
                //   depth: 1,
                // },
                {
                  subQuestionId: 2,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "allergy_longTermUsageNoneSubSteroidsSubType",
                      type: "options_tree",
                      label: "Steroids Type",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 3,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Systemic",
                          value: "systemic",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Inhaled",
                          value: "inhaled",
                          type: "toggle_button",
                          depth: 3,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 2,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Steroids",
                  value: "steroids",
                  type: "toggle_button",
                  depth: 1,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Insulin",
                  value: "insulin",
                  type: "toggle_button",
                  depth: 1,
                },
                {
                  subQuestionId: 4,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "allergy_longTermUsageNoneSubAntiCoagSubDesc",
                      type: "options_tree",
                      label: "Describe",
                      fullWidth: true,
                      required: true,
                      xs: 12,
                      isCommentEnabled: true,
                      isConfigured: true,
                      isModified: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          depth: 3,
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          label: "Coumadin",
                          value: "coumadin",
                          isConfigured: true,
                          type: "toggle_button",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Thrombin Inhibitors(Paradaxa)",
                          value: "thrombin",
                          type: "toggle_button",
                          depth: 3,
                        },
                        {
                          subQuestionId: 3,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Plavix",
                          value: "plavix",
                          type: "toggle_button",
                          depth: 3,
                        },
                        {
                          subQuestionId: 4,
                          hasSubQuestions: false,
                          subQuestions: [],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Factor Xa Inhibitors(Xarelto,Eliquis)",
                          value: "factorXa",
                          type: "toggle_button",
                          depth: 3,
                        },
                        {
                          subQuestionId: 5,
                          hasSubQuestions: true,
                          subQuestions: [
                            {
                              id: 1,
                              name: "allergy_longTermUsageNoneSubAntiCoagSubDescOtherSubDesc",
                              type: "text",
                              label: "Describe",
                              editToggle: false,
                              xs: 4,
                              required: "no",
                              placeholder: "",
                              defaultValue: "",
                              isCommentEnabled: false,
                              isConfigured: true,
                              isModified: true,
                              subQuestionId: 1,
                              depth: 4,
                            },
                          ],
                          editToggle: false,
                          isCommentEnabled: false,
                          isConfigured: true,
                          isModified: false,
                          label: "Other",
                          value: "other",
                          type: "toggle_button",
                          depth: 3,
                        },
                      ],
                      subQuestionId: 1,
                      editToggle: false,
                      depth: 2,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Anticoagulants",
                  value: "anticoagulants",
                  type: "toggle_button",
                  depth: 1,
                },
                {
                  subQuestionId: 5,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Statins",
                  value: "statins",
                  type: "toggle_button",
                  depth: 1,
                },
                {
                  subQuestionId: 6,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Biphosphonate",
                  value: "biphosphonate",
                  type: "toggle_button",
                  depth: 1,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "NSAID's (Non-steroidal anti inflammatory)",
                  value: "steroidalAntiInf",
                  type: "toggle_button",
                  depth: 1,
                },
                {
                  subQuestionId: 7,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Antiplatelet",
                  value: "antiplatelet",
                  type: "toggle_button",
                  depth: 1,
                },

                {
                  subQuestionId: 7,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 1,
                      name: "allergy_longTermUsageNoneSubOtherSubAnswer",
                      type: "text",
                      label: "Describe",
                      editToggle: false,
                      xs: 4,
                      required: "no",
                      placeholder: "",
                      defaultValue: "",
                      isCommentEnabled: false,
                      isConfigured: true,
                      isModified: true,
                      subQuestionId: 1,
                      depth: 3,
                    },
                  ],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Other",
                  value: "other",
                  type: "toggle_button",
                  depth: 2,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          depth: 1,
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          depth: 1,
        },
      ],
      questionId: 5,
      editToggle: false,
    },
    {
      id: 7,
      name: "medication_compliance_and_knowledge_of_use_and_disease",
      type: "label",
      label: "Medication Compliance and Knowledge of Use and Disease",
      editToggle: false,
      xs: 12,
      required: "no",
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 5,
    },
    {
      id: 6,
      name: "allergy_enoughMoney",
      type: "options_tree",
      label: "1. Do you have enough money to buy your medicine?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 6,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubTakeMedic",
      type: "options_tree",
      label: "2. Do you ever forget to take your medicine?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 7,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubAttenMedic",
      type: "options_tree",
      label: "3. Do you sometimes not pay enough attention to your medication?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 8,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubLongTerm",
      type: "options_tree",
      label:
        "4. Do you know the Long Term benefit of taking your medicine as told to you by the doctor or pharmacist?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 9,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubStopMedic",
      type: "options_tree",
      label:
        "5. When you feel better do you sometimes stop taking your medicine?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 10,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubFeelWorse",
      type: "options_tree",
      label:
        "6. Sometimes if you feel worse when you take your medicine do you stop taking it?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 11,
      editToggle: false,
    },
    {
      id: 6,
      name: "allergy_medicSubForgotRefill",
      type: "options_tree",
      label: "7. Do you sometimes forget to refill your prescription on time?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 1,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 12,
      editToggle: false,
    },
    {
      id: 10,
      name: "allergies_Recommendations2",
      label: "Recommendations",
      placeholder: "",
      value: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        [
          "Discuss options with your Doctor and/or pharmacist to improve medication adherance",
          "medicAdherenceAllergy",
        ],
        [
          "Discuss medication side effects with your Doctor",
          "sideEffectsAllergy",
        ],
        [
          "Case management referral for followup",
          "caseManagRefFollowupAllergy",
        ],
        [
          "Followup with Clinician/Specialists",
          "followupWithClinicianSpecialistsAllergy",
        ],
        [
          "Member to use Durable Medical Equipment",
          "memberDurableMedicalEquipmentAllergy",
        ],
        ["Other", "otherAllergy2"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 23,
    },

    {
      id: 10,
      name: "horizontal_line",
      label: "Horizontal Line",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "line",
      xs: 12,
      required: "no",
      isCommentEnabled: false,
      isConfigured: false,
      isModified: false,
    },

    {
      id: 10,
      name: "educatedOnImportance",
      label: "",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        [
          "Educated on importance of medication compliance, member verbalizes understanding",
          "educateImportance",
        ],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 24,
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
