const json2023 = {
  sectionId: 16,
  sectionName: "Home Safety & Personal Goals",
  isEditable: false,
  questions: [
    {
      id: 6,
      name: "home_fallen",
      type: "options_tree",
      label: "In the Past year, how many times have you Fallen?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
          name: "none",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "home_fallenOnceSubWryFall",
              type: "options_tree",
              label:
                "Do you worry about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "home_fallenOnceSubWrisFall",
              type: "options_tree",
              label:
                "Worries about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "home_fallenOnceSubFrac",
              type: "options_tree",
              label: "Did you have a fracture in past 6 months?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "home_fallenOnceSubFracSubByFall",
                      type: "options_tree",
                      label: "Was it due to fall?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "home_fallenOnceSubFracSubOstMed",
                      type: "options_tree",
                      label: "Are You on Osteoporosis med?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 3,
            },
          ],
          isCommentEnabled: false,
          label: "Once",
          value: "once",
          type: "toggle_button",
          name: "once",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "home_fallenTwiceSubWryFall",
              type: "options_tree",
              label:
                "Do you worry about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "home_fallenTwiceSubWrisFall",
              type: "options_tree",
              label:
                "Worries about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "home_fallenTwiceSubFrac",
              type: "options_tree",
              label: "Did you have a fracture in past 6 months?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "home_fallenTwiceSubFracSubByFall",
                      type: "options_tree",
                      label: "Was it due to fall?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "home_fallenTwiceSubFracSubOstMed",
                      type: "options_tree",
                      label: "Are You on Osteoporosis med?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 3,
            },
          ],
          isCommentEnabled: false,
          label: "Twice",
          value: "twice",
          type: "toggle_button",
          name: "twice",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "home_fallenThreeSubWryFall",
              type: "options_tree",
              label:
                "Do you worry about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "home_fallenThreeSubWrisFall",
              type: "options_tree",
              label:
                "Worries about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "home_fallenThreeSubFrac",
              type: "options_tree",
              label: "Did you have a fracture in past 6 months?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "home_fallenThreeSubFracSubByFall",
                      type: "options_tree",
                      label: "Was it due to fall?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "home_fallenThreeSubFracSubOstMed",
                      type: "options_tree",
                      label: "Are You on Osteoporosis med?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 3,
            },
          ],
          isCommentEnabled: false,
          label: "Three Times",
          value: "threeTimes",
          type: "toggle_button",
          name: "threeTimes",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "home_fallenMoreThreeSubWryFall",
              type: "options_tree",
              label:
                "Do you worry about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "home_fallenMoreThreeSubWrisFall",
              type: "options_tree",
              label:
                "Worries about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 2,
            },
            {
              id: 6,
              name: "home_fallenMoreThreeSubFrac",
              type: "options_tree",
              label: "Did you have a fracture in past 6 months?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "home_fallenMoreThreeSubFracSubByFall",
                      type: "options_tree",
                      label: "Was it due to fall?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "home_fallenMoreThreeSubFracSubOstMed",
                      type: "options_tree",
                      label: "Are You on Osteoporosis med?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 3,
            },
          ],
          isCommentEnabled: false,
          label: "More than Three times",
          value: "moreThanThreeTimes",
          type: "toggle_button",
          name: "moreThanThreeTimes",
        },
      ],
      questionId: 1,
    },
    {
      id: 7,
      name: "homeSafety",
      type: "label",
      label: "Home Safety",
      xs: 12,
      isCommentEnabled: false,
      questionId: 2,
    },
    {
      id: 6,
      name: "home_obstInHouse",
      type: "options_tree",
      label:
        "a. Do you have obstacles in the house, loose small rugs or objects on the floor that could cause tripping?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 3,
    },
    {
      id: 6,
      name: "home_elecCords",
      type: "options_tree",
      label:
        "b. Do you have electrical cords running across floors, in doorways or under a rugs?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 4,
    },
    {
      id: 6,
      name: "home_noMats",
      type: "options_tree",
      label: "c. Do you have no slip mats on the shower floor or bath tub?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 5,
    },
    {
      id: 6,
      name: "home_adeLight",
      type: "options_tree",
      label: "d. Do have adequate lighting in hallways and on the stairs?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 6,
    },
    {
      id: 6,
      name: "home_handRail",
      type: "options_tree",
      label: "e. Do you have handrails on staircases?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 7,
    },
    {
      id: 6,
      name: "home_hotWater",
      type: "options_tree",
      label: "f. Is your hot water heater set for a maximum of 120 degrees?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 8,
    },
    {
      id: 6,
      name: "home_smokeDet",
      type: "options_tree",
      label:
        "g. Do you have smoke detectors on each level of the house and in all sleeping a rooms?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 9,
    },
    {
      id: 6,
      name: "home_co2Det",
      type: "options_tree",
      label:
        "h. Do you have carbon Monoxide detectors on each level of the house?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 10,
    },
    {
      id: 6,
      name: "home_escRoute",
      type: "options_tree",
      label: "i. Have used established an escape route in the event of fire?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 11,
    },
    {
      id: 1,
      name: "home_wishToChange",
      type: "text",
      label:
        "Are there things about yourself you wish you could change or improve?",
      xs: 12,
      isCommentEnabled: true,
      questionId: 12,
    },
    {
      id: 1,
      name: "home_qualityOfLife",
      type: "text",
      label:
        "Is there anything that you could do to improve your quality of life?",
      xs: 12,
      isCommentEnabled: true,
      questionId: 13,
    },
    {
      id: 6,
      name: "home_abusedBySomeone",
      type: "options_tree",
      label: "Have you ever physically or felt emotionally abused by someone?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 14,
    },
    {
      id: 6,
      name: "home_feelingLikeHarming",
      type: "options_tree",
      label: "Feeling like harming others or yourself?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "home_feelingLikeHarmingSubAnsWho",
              type: "text",
              label: "Who do you feel like harming?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 1,
              name: "home_feelingLikeHarmingSubAnsAt",
              type: "text",
              label: "Do you feel like this at this moment?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 2,
            },
            {
              id: 1,
              name: "home_feelingLikeHarmingSubAnsSafe",
              type: "text",
              label: "Are you in a safe place?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 3,
            },
            {
              id: 1,
              name: "home_feelingLikeHarmingSubAnsAssist",
              type: "text",
              label: "Would you like me to assist you to call 911?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 4,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 15,
    },
    {
      id: 6,
      name: "home_afraidOfAnyOne",
      type: "options_tree",
      label: "Are you afraid of anyone or is anyone hurting you?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "home_afraidOfAnyOneSubAnsWho",
              type: "text",
              label: "Who are you afraid of? Are you afraid at this moment?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 1,
              name: "home_afraidOfAnyOneSubAnsAt",
              type: "text",
              label: "Who is hurting you?Are you being hurt at this moment?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 2,
            },
            {
              id: 1,
              name: "home_afraidOfAnyOneSubAnsSafe",
              type: "text",
              label: "Are you in a safe place?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 3,
            },
            {
              id: 1,
              name: "home_afraidOfAnyOneSubAnsAssist",
              type: "text",
              label: "Would you like me to assist you to call 911?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 4,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 16,
    },
  ],
};

const json2024 = {
  sectionId: 16,
  sectionName: "Home Safety & Personal Goals",
  isEditable: false,
  questions: [
    {
      id: 6,
      name: "home_fallen",
      type: "options_tree",
      label: "In the Past year, how many times have you Fallen?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "None",
          value: "none",
          type: "toggle_button",
          name: "none",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "home_fallenOnceSubWryFall",
              type: "options_tree",
              label:
                "Do you worry about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "home_fallenOnceSubFrac",
              type: "options_tree",
              label: "Did you have a fracture in past 6 months?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "home_fallenOnceSubFracSubByFall",
                      type: "options_tree",
                      label: "Was it due to fall?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "home_fallenOnceSubFracSubOstMed",
                      type: "options_tree",
                      label: "Are You on Osteoporosis med?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Once",
          value: "once",
          type: "toggle_button",
          name: "once",
        },
        {
          subQuestionId: 3,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "home_fallenTwiceSubWryFall",
              type: "options_tree",
              label:
                "Do you worry about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "home_fallenTwiceSubFrac",
              type: "options_tree",
              label: "Did you have a fracture in past 6 months?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "home_fallenTwiceSubFracSubByFall",
                      type: "options_tree",
                      label: "Was it due to fall?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "home_fallenTwiceSubFracSubOstMed",
                      type: "options_tree",
                      label: "Are You on Osteoporosis med?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Twice",
          value: "twice",
          type: "toggle_button",
          name: "twice",
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "home_fallenThreeSubWryFall",
              type: "options_tree",
              label:
                "Do you worry about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "home_fallenThreeSubFrac",
              type: "options_tree",
              label: "Did you have a fracture in past 6 months?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "home_fallenThreeSubFracSubByFall",
                      type: "options_tree",
                      label: "Was it due to fall?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "home_fallenThreeSubFracSubOstMed",
                      type: "options_tree",
                      label: "Are You on Osteoporosis med?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "Three Times",
          value: "threeTimes",
          type: "toggle_button",
          name: "threeTimes",
        },
        {
          subQuestionId: 5,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "home_fallenMoreThreeSubWryFall",
              type: "options_tree",
              label:
                "Do you worry about falling or feeling unsteady when standing or walking?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 1,
            },
            {
              id: 6,
              name: "home_fallenMoreThreeSubFrac",
              type: "options_tree",
              label: "Did you have a fracture in past 6 months?",
              xs: 12,
              isCommentEnabled: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  subQuestionId: 1,
                  hasSubQuestions: true,
                  subQuestions: [
                    {
                      id: 6,
                      name: "home_fallenMoreThreeSubFracSubByFall",
                      type: "options_tree",
                      label: "Was it due to fall?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 1,
                    },
                    {
                      id: 6,
                      name: "home_fallenMoreThreeSubFracSubOstMed",
                      type: "options_tree",
                      label: "Are You on Osteoporosis med?",
                      xs: 12,
                      isCommentEnabled: true,
                      hasSubQuestions: true,
                      subQuestions: [
                        {
                          subQuestionId: 1,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "Yes",
                          value: "yes",
                          type: "toggle_button",
                          name: "yes",
                        },
                        {
                          subQuestionId: 2,
                          hasSubQuestions: false,
                          subQuestions: [],
                          isCommentEnabled: false,
                          label: "No",
                          value: "no",
                          type: "toggle_button",
                          name: "no",
                        },
                      ],
                      subQuestionId: 2,
                    },
                  ],
                  isCommentEnabled: false,
                  label: "Yes",
                  value: "yes",
                  type: "toggle_button",
                  name: "yes",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  isCommentEnabled: false,
                  label: "No",
                  value: "no",
                  type: "toggle_button",
                  name: "no",
                },
              ],
              subQuestionId: 2,
            },
          ],
          isCommentEnabled: false,
          label: "More than Three times",
          value: "moreThanThreeTimes",
          type: "toggle_button",
          name: "moreThanThreeTimes",
        },
      ],
      questionId: 1,
    },
    {
      id: 7,
      name: "home_timedUpGo",
      type: "label",
      label: "Timed Up & Go (TUG)",
      xs: 12,
      isCommentEnabled: false,
      questionId: 2,
    },
    {
      id: 10,
      name: "home_timedUpGoNotApplicable",
      label: "",
      value: "",
      type: "checkbox",
      options: [["Not Applicable", "notApplicable"]],
      xs: 12,
      isCommentEnabled: true,
      questionId: 3,
    },
    {
      id: 10,
      name: "horizontal_line",
      label: "Horizontal Line",
      type: "line",
      xs: 12,
      isCommentEnabled: false,
      questionId: 4,
    },
    {
      id: 7,
      name: "home_directions",
      type: "label",
      label: "Directions:",
      xs: 12,
      isCommentEnabled: true,
      questionId: 5,
    },
    {
      id: 11,
      name: "home_timedUpGo",
      label: "",
      type: "datatable",
      tableName: "home_timedUpGo",
      xs: 9,
      isCommentEnabled: false,
      questionId: 6,
    },
    {
      id: 7,
      name: "home_membersScoreSeconds",
      type: "label",
      label:
        "Members who score ≥12 seconds to complete the TUG is at risk for falling",
      xs: 12,
      isCommentEnabled: false,
      questionId: 8,
    },
    {
      id: 6,
      name: "home_memberGait",
      type: "options_tree",
      label: "Member Gait:",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Normal",
          value: "normal",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "home_memberGaitAbnormalSubDesc",
              value: "",
              type: "text",
              label: "Describe",
              xs: 12,
              isCommentEnabled: false,
            },
          ],
          isCommentEnabled: false,
          label: "Abnormal",
          value: "abnormal",
          type: "toggle_button",
        },
      ],
      questionId: 9,
    },
    {
      id: 10,
      name: "home_fallRisk",
      label: "",
      value: "",
      type: "checkbox",
      options: [["Fall risk discussed with member/caregiver", "fallRisk"]],
      xs: 12,
      isCommentEnabled: true,
      questionId: 10,
    },
    {
      id: 10,
      name: "home_planFurther",
      label: "",
      value: "",
      type: "checkbox",
      options: [
        [
          "Plan: Further education and evaluation ( ie Physical Therapy, Refer to evidence-based exercise or fall prevention program)",
          "planFurther",
        ],
      ],
      xs: 12,
      isCommentEnabled: true,
      questionId: 11,
    },
    {
      id: 7,
      name: "homeSafety",
      type: "label",
      label: "Home Safety",
      xs: 12,
      isCommentEnabled: false,
      questionId: 12,
    },
    {
      id: 6,
      name: "home_obstInHouse",
      type: "options_tree",
      label:
        "a. Do you have obstacles in the house, loose small rugs or objects on the floor that could cause tripping?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 13,
    },
    {
      id: 6,
      name: "home_elecCords",
      type: "options_tree",
      label:
        "b. Do you have electrical cords running across floors, in doorways or under a rugs?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 14,
    },
    {
      id: 6,
      name: "home_noMats",
      type: "options_tree",
      label: "c. Do you have no slip mats on the shower floor or bath tub?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 15,
    },
    {
      id: 6,
      name: "home_adeLight",
      type: "options_tree",
      label: "d. Do have adequate lighting in hallways and on the stairs?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 16,
    },
    {
      id: 6,
      name: "home_handRail",
      type: "options_tree",
      label: "e. Do you have handrails on staircases?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 17,
    },
    {
      id: 6,
      name: "home_hotWater",
      type: "options_tree",
      label: "f. Is your hot water heater set for a maximum of 120 degrees?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 18,
    },
    {
      id: 6,
      name: "home_smokeDet",
      type: "options_tree",
      label:
        "g. Do you have smoke detectors on each level of the house and in all bedrooms?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 19,
    },
    {
      id: 6,
      name: "home_co2Det",
      type: "options_tree",
      label:
        "h. Do you have carbon Monoxide detectors on each level of the house?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 20,
    },
    {
      id: 6,
      name: "home_escRoute",
      type: "options_tree",
      label: "i. Have you established an escape route in the event of a fire?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 21,
    },
    {
      id: 6,
      name: "home_eventOfEmergency",
      type: "options_tree",
      label:
        "j. In the event of an emergency or natural disaster do you and / or your caregiver have an emergency plan in place",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
          name: "yes",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
          name: "no",
        },
      ],
      questionId: 22,
    },
    {
      id: 10,
      name: "home_memberEducated",
      label: "",
      value: "",
      type: "checkbox",
      options: [["Member educated on home safety", "memberEducated"]],
      xs: 12,
      isCommentEnabled: true,
      questionId: 23,
    },
    {
      id: 1,
      name: "home_improveLifeQuality",
      type: "text",
      label:
        "What is something you can work on to improve your quality of life?",
      xs: 8,
      isCommentEnabled: true,
      questionId: 24,
    },
    {
      id: 5,
      name: "home_improveLifeQualitySelect",
      label: "",
      type: "select",
      options: [
        ["I want to work on it", "1"],
        ["I am already working on it but need some help", "2"],
        ["I am already working on it and don't need any help", "3"],
        ["I am not interested at this time", "4"],
      ],
      xs: 8,
      isCommentEnabled: true,
      questionId: 25,
    },
    {
      questionId: 26,
      label: "Are there things preventing you from making changes?",
      value: "",
      type: "options_tree",
      xs: 12,
      isMulti: true,
      subQuestions: [
        {
          subQuestionId: 1,
          label: "Education Needed",
          type: "toggle_button",
          value: "educationNeeded",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 2,
          label: "Social Support",
          type: "toggle_button",
          value: "socialSupport",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 3,
          label: "Environmental Factors",
          type: "toggle_button",
          value: "environmentalFactors",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 4,
          label: "Stress",
          type: "toggle_button",
          value: "stress",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 5,
          label: "Self Confidence",
          type: "toggle_button",
          value: "selfConfidence",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 6,
          label: "Other",
          type: "toggle_button",
          value: "other",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 7,
          label: "None",
          type: "toggle_button",
          value: "none",
          hasSubQuestions: false,
          subQuestions: [{}],
        },
        {
          subQuestionId: 8,
          label: "Lack of Motivation",
          type: "toggle_button",
          value: "lackOfMotivation",
          hasSubQuestions: true,
          subQuestions: [{}],
        },
        {
          subQuestionId: 9,
          label: "Not a Priority at this time",
          type: "toggle_button",
          value: "notPriority",
          hasSubQuestions: true,
          subQuestions: [{}],
        },
        {
          subQuestionId: 10,
          label: "Member Nonverbal",
          type: "toggle_button",
          value: "memberNonverbal",
          hasSubQuestions: true,
          subQuestions: [{}],
        },
        {
          subQuestionId: 11,
          label: "Member refused to answer",
          type: "toggle_button",
          value: "memberRefused",
          hasSubQuestions: true,
          subQuestions: [{}],
        },
      ],
      isCommentEnabled: true,
      name: "home_preventing",
    },
    {
      id: 6,
      name: "home_abusedBySomeone",
      type: "options_tree",
      label: "Have you ever physically or felt emotionally abused by someone?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 27,
    },
    {
      id: 6,
      name: "home_feelingLikeHarming",
      type: "options_tree",
      label: "Feeling like harming others or yourself?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "home_feelingLikeHarmingSubAnsWho",
              type: "text",
              label: "Who do you feel like harming?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 1,
              name: "home_feelingLikeHarmingSubAnsAt",
              type: "text",
              label: "Do you feel like this at this moment?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 2,
            },
            {
              id: 1,
              name: "home_feelingLikeHarmingSubAnsSafe",
              type: "text",
              label: "Are you in a safe place?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 3,
            },
            {
              id: 1,
              name: "home_feelingLikeHarmingSubAnsAssist",
              type: "text",
              label: "Would you like me to assist you to call 911?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 4,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 28,
    },
    {
      id: 6,
      name: "home_afraidOfAnyOne",
      type: "options_tree",
      label: "Are you afraid of anyone or is anyone hurting you?",
      xs: 12,
      isCommentEnabled: true,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 1,
              name: "home_afraidOfAnyOneSubAnsWho",
              type: "text",
              label: "Who are you afraid of? Are you afraid at this moment?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 1,
            },
            {
              id: 1,
              name: "home_afraidOfAnyOneSubAnsAt",
              type: "text",
              label: "Who is hurting you?Are you being hurt at this moment?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 2,
            },
            {
              id: 1,
              name: "home_afraidOfAnyOneSubAnsSafe",
              type: "text",
              label: "Are you in a safe place?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 3,
            },
            {
              id: 1,
              name: "home_afraidOfAnyOneSubAnsAssist",
              type: "text",
              label: "Would you like me to assist you to call 911?",
              xs: 12,
              isCommentEnabled: false,
              subQuestionId: 4,
            },
          ],
          isCommentEnabled: false,
          label: "Yes",
          value: "yes",
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          isCommentEnabled: false,
          label: "No",
          value: "no",
          type: "toggle_button",
        },
      ],
      questionId: 29,
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
