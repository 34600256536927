import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Typography, Box, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { postApi, getApi } from "../../../../utils/axiosApiWrapper";
import dayjs from "dayjs";
import ReviewReferralForm from "./ReviewReferralForm";

const MemberUrgentReferralForm = () => {
  const CustomRadio = styled(Radio)(({ theme }) => ({
    color: "var(--mainTheme900)", // Unchecked color
    "&.Mui-checked": {
      color: "var(--mainTheme900)", // Checked color
    },
  }));

  const [memberCommunity, setMemberCommunity] = useState({
    MemberCommunity: false,
  });

  const [diseaseCategories, setDiseaseCategories] = useState({
    CHF: false,
    Diabetes: false,
    HTN: false,
    COPD: false,
    Asthma: false,
    Other: false,
    Behavioral: false,
    Sdoh: false,
  });

  const [diseaseComment, setDiseaseComment] = useState("");
  const [name, setName] = useState("");
  const [dob, setDob] = useState(null);
  const [idNumber, setIdNumber] = useState("");
  const [telephoneNumber, setTelephoneNumber] = useState("");
  const [reportDate, setReportDate] = useState(null);
  const [providerName, setProviderName] = useState("");
  const [providerTelephoneNumber, setProviderTelephoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [providerAgency, setProviderAgency] = useState("");
  const [donContacted, setDonContacted] = useState("");
  const [apsContacted, setApsContacted] = useState("");
  const [cpsContacted, setCpsContacted] = useState("");
  const [followUpPlans, setFollowUpPlans] = useState("");
  const [openReviewDialog, setOpenReviewDialog] = useState(false);

  const handleOpenReviewDialog = () => {
    setOpenReviewDialog(true);
  };

  const assessmentId = sessionStorage.getItem("assessmentId");

  const handleDateChange = (date) => {
    setDob(date);
  };
  const handleReportDateChange = (date) => {
    setReportDate(date);
  };

  // urgent: {
  //   alliantOrBcbs: "1",
  //   diseaseCategory: {
  //     chf: "1",
  //     diabetes: "1",
  //     htn: "1",
  //     copd: "1",
  //     asthma: "1",
  //     other: "1",
  //     comment: "text",
  //   },
  //   memberTelephone: "text",
  //   dateOfReport: "2015-03-26T10:58:51",
  //   provider: "text",
  //   pTelephone: "text",
  //   pEmail: "text",
  //   pAgency: "text",
  //   pDirector: "2",
  //   aps: "2",
  //   cps: "2",
  //   describe: "text",
  // },

  const SaveUrgentReferralFormData = async () => {
    try {
      const payload = {
        id: assessmentId,
        urgent: {
          alliantOrBcbs:
            memberCommunity?.MemberCommunity == true
              ? "1"
              : memberCommunity?.MemberCommunity == false
              ? "2"
              : "",
          diseaseCategory: {
            chf:
              diseaseCategories?.CHF == true
                ? "1"
                : diseaseCategories?.CHF == false
                ? "2"
                : "",
            diabetes:
              diseaseCategories?.Diabetes == true
                ? "1"
                : diseaseCategories?.Diabetes == false
                ? "2"
                : "",
            htn:
              diseaseCategories?.HTN == true
                ? "1"
                : diseaseCategories?.HTN == false
                ? "2"
                : "",
            copd:
              diseaseCategories?.COPD == true
                ? "1"
                : diseaseCategories?.COPD == false
                ? "2"
                : "",
            asthma:
              diseaseCategories?.Asthma == true
                ? "1"
                : diseaseCategories?.Asthma == false
                ? "2"
                : "",
            other:
              diseaseCategories?.Other == true
                ? "1"
                : diseaseCategories?.Other == false
                ? "2"
                : "",
            bahavioural:
              diseaseCategories?.Behavioral == true
                ? "1"
                : diseaseCategories?.Behavioral == false
                ? "2"
                : "",
            sdoh:
              diseaseCategories?.Sdoh == true
                ? "1"
                : diseaseCategories?.Sdoh == false
                ? "2"
                : "",
            comment: diseaseComment,
          },
          //name:name,
          memberTelephone: telephoneNumber, //"text",
          dateOfReport: reportDate, //"2015-03-26T10:58:51",
          provider: providerName,
          pTelephone: providerTelephoneNumber,
          pEmail: email,
          pAgency: providerAgency,
          pDirector:
            donContacted == "yes" ? "1" : donContacted == "no" ? "2" : "",
          aps: apsContacted == "yes" ? "1" : apsContacted == "no" ? "2" : "",
          cps: cpsContacted == "yes" ? "1" : cpsContacted == "no" ? "2" : "",
          describe: followUpPlans,
        },
      };
      console.log(payload);
      const response = await postApi("/referral-form/", payload);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeDonContacted = (e) => {
    setDonContacted(e?.target?.value);
  };

  const handleCloseReviewDialog = () => {
    setOpenReviewDialog(false);
  };

  const handleChangeApsContacted = (e) => {
    setApsContacted(e?.target?.value);
  };
  const handleChangeCpsContacted = (e) => {
    setCpsContacted(e?.target?.value);
  };

  const fetchUrgentData = async () => {
    try {
      const response = await getApi(`/referral-form/?id=${assessmentId}`);

      const data = response?.data?.data?.[0];

      // if (data) {
      setName(sessionStorage.getItem("Name"));
      setDob(sessionStorage.getItem("dob"));
      //setDob(data.dob || null);
      setIdNumber(sessionStorage.getItem("memberId"));
      setMemberCommunity({ MemberCommunity: data.alliantOrBcbs === "1" });
      setProviderName(data?.provider || "");
      setDiseaseCategories({
        CHF: data.diseaseCategory.chf === "1",
        Diabetes: data.diseaseCategory.diabetes === "1",
        HTN: data.diseaseCategory.htn === "1",
        COPD: data.diseaseCategory.copd === "1",
        Asthma: data.diseaseCategory.asthma === "1",
        Other: data.diseaseCategory.other === "1",
        Behavioral: data.urgent.diseaseCategory.bahavioural === "1",
        Sdoh: data.urgent.diseaseCategory.sdoh === "1",
      });

      setDiseaseComment(data.diseaseCategory.comment || "");
      //setName(data.name || "");

      //setIdNumber(data.idNumber || "");
      setTelephoneNumber(data.memberTelephone || "");
      setReportDate(data.dateOfReport || null);

      setProviderTelephoneNumber(data.pTelephone || "");
      setEmail(data.pEmail || "");
      setProviderAgency(data.pAgency || "");
      setDonContacted(data.pDirector === "1" ? "yes" : "no");
      setApsContacted(data.aps === "1" ? "yes" : "no");
      setCpsContacted(data.cps === "1" ? "yes" : "no");

      setFollowUpPlans(data.describe || "");
      // }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  console.log("Rendering with providerName:", providerName);

  useEffect(() => {
    // if (open) {
    fetchUrgentData();
    // }
  }, []);

  useEffect(() => {
    // if (open) {
    fetchUrgentData();
    // }
  }, []);

  return (
    //1
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              if (e?.target.checked) {
                setMemberCommunity({
                  ...memberCommunity,
                  MemberCommunity: true,
                });
              } else {
                setMemberCommunity({
                  ...memberCommunity,
                  MemberCommunity: false,
                });
              }
            }}
            checked={memberCommunity.MemberCommunity}
          />
        }
        label="Alliant Health Plans - Member community resources left behind "
      />
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
        Disease Category:
      </Typography>
      <FormGroup>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setDiseaseCategories({ ...diseaseCategories, CHF: true });
                    } else {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        CHF: false,
                      });
                    }
                  }}
                  checked={diseaseCategories.CHF}
                />
              }
              label="CHF"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        Diabetes: true,
                      });
                    } else {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        Diabetes: false,
                      });
                    }
                  }}
                  checked={diseaseCategories.Diabetes}
                />
              }
              label="Diabetes"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setDiseaseCategories({ ...diseaseCategories, HTN: true });
                    } else {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        HTN: false,
                      });
                    }
                  }}
                  checked={diseaseCategories.HTN}
                />
              }
              label="HTN"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        COPD: true,
                      });
                    } else {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        COPD: false,
                      });
                    }
                  }}
                  checked={diseaseCategories.COPD}
                />
              }
              label="COPD"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        Asthma: true,
                      });
                    } else {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        Asthma: false,
                      });
                    }
                  }}
                  checked={diseaseCategories.Asthma}
                />
              }
              label="Asthma"
            />
          </Grid>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        Other: true,
                      });
                    } else {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        Other: false,
                      });
                    }
                  }}
                  checked={diseaseCategories.Other}
                />
              }
              label="Other"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        Sdoh: true,
                      });
                    } else {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        Sdoh: false,
                      });
                    }
                  }}
                />
              }
              label="SDOH"
            />
          </Grid>

          <Grid item sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    if (e?.target.checked) {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        Behavioral: true,
                      });
                    } else {
                      setDiseaseCategories({
                        ...diseaseCategories,
                        Behavioral: false,
                      });
                    }
                  }}
                />
              }
              label="Behavioral Health"
            />
          </Grid>
        </Grid>
      </FormGroup>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          marginTop: "24px",
        }}
      >
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Comment:
        </Typography>
        <TextField
          label=""
          value={diseaseComment}
          onChange={(e) => {
            setDiseaseComment(e?.target?.value);
          }}
          style={{ border: "1px solid var(--mainTheme500)", width: "60%" }}
          variant="outlined"
        />
      </Box>
      {/* {diseaseCategories?.Other == true ? (
        <TextField
          value={diseaseOtherDescribe}
          onChange={(e) => {
            setDiseaseOtherDescribe(e?.target?.value);
          }}
          label="Describe"
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
        />
      ) : null} */}
      <Typography style={{ marginTop: "20px" }}>
        If this is an URGENT Referral request the Director of Nursing Services
        should be called and informed of the situation.
        <Box>
          <Typography
            variant="h5"
            style={{
              backgroundColor: "var(--mainTheme500)",
              color: "#fff",
              padding: "8px",
              borderRadius: "6px",
            }}
            marginTop={4}
          >
            Members Information
          </Typography>
        </Box>
      </Typography>
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Name:
        </Typography>
        <TextField
          label=""
          value={name}
          disabled
          onChange={(e) => {
            setName(e?.target?.value);
          }}
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px", width: "45vw" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Date of Birth:
        </Typography>
        <LocalizationProvider
          sx={{ border: "1px solid var(--mainTheme500)", width: "43vw" }}
          dateAdapter={AdapterDayjs}
        >
          <DemoContainer
            sx={{ border: "1px solid var(--mainTheme500)", width: "40%" }}
            components={["DatePicker"]}
          >
            <DatePicker
              value={dayjs(new Date(dob))}
              variant="outlined"
              disabled
              onChange={(date) => {
                handleDateChange(date);
              }}
              label=""
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          ID Number:
        </Typography>
        <TextField
          label=""
          type="number"
          disabled
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={idNumber}
          onChange={(e) => {
            setIdNumber(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Member Telephone number:
        </Typography>
        <TextField
          label=""
          type="number"
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={telephoneNumber}
          onChange={(e) => {
            setTelephoneNumber(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box>
        <Typography
          variant="h5"
          style={{
            backgroundColor: "var(--mainTheme500)",
            color: "#fff",
            padding: "8px",
            borderRadius: "6px",
          }}
          marginTop={4}
        >
          Contact Information
        </Typography>
      </Box>
      <Box style={{ paddingTop: "16px", width: "45vw" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Date of this report:
        </Typography>
        <LocalizationProvider
          sx={{ border: "1px solid var(--mainTheme500)", width: "43vw" }}
          dateAdapter={AdapterDayjs}
        >
          <DemoContainer
            sx={{ border: "1px solid var(--mainTheme500)", width: "40%" }}
            components={["DatePicker"]}
          >
            <DatePicker
              value={dayjs(new Date(reportDate))}
              variant="outlined"
              onChange={(date) => {
                handleReportDateChange(date);
              }}
              label=""
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Provider Name
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={providerName}
          onChange={(e) => {
            setProviderName(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Telephone number:
        </Typography>
        <TextField
          label=""
          type="number"
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={providerTelephoneNumber}
          onChange={(e) => {
            setProviderTelephoneNumber(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Email:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={email}
          onChange={(e) => {
            setEmail(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box style={{ paddingTop: "16px" }}>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Provider agency:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          value={providerAgency}
          onChange={(e) => {
            setProviderAgency(e?.target?.value);
          }}
        />
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            textAlign: "left",
            paddingTop: "16px",
            fontWeight: "bold",
          }}
          gutterBottom
        >
          Director of Nursing Services contacted:
        </Typography>
        <FormControl sx={{ display: "flex" }} component="fieldset">
          <RadioGroup
            value={donContacted}
            onChange={handleChangeDonContacted}
            row
            sx={{ display: "flex", gap: "100px" }}
          >
            <FormControlLabel
              value="yes"
              control={<CustomRadio />}
              label="Yes"
            />
            <FormControlLabel value="no" control={<CustomRadio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            textAlign: "left",
            paddingTop: "16px",
            fontWeight: "bold",
          }}
          gutterBottom
        >
          APS Contacted:
        </Typography>
        <FormControl sx={{ display: "flex" }} component="fieldset">
          <RadioGroup
            value={apsContacted}
            onChange={handleChangeApsContacted}
            row
            sx={{ display: "flex", gap: "100px" }}
          >
            <FormControlLabel
              value="yes"
              control={<CustomRadio />}
              label="Yes"
            />
            <FormControlLabel value="no" control={<CustomRadio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            textAlign: "left",
            paddingTop: "16px",
            fontWeight: "bold",
          }}
          gutterBottom
        >
          CPS Contacted:
        </Typography>
        <FormControl sx={{ display: "flex" }} component="fieldset">
          <RadioGroup
            value={cpsContacted}
            onChange={handleChangeCpsContacted}
            row
            sx={{ display: "flex", gap: "100px" }}
          >
            <FormControlLabel
              value="yes"
              control={<CustomRadio />}
              label="Yes"
            />
            <FormControlLabel value="no" control={<CustomRadio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      />

      <Box
        style={{ paddingY: "16px", fontWeight: "bold", marginBottom: "20px" }}
      >
        <Typography style={{ fontWeight: "bold" }}>
          Describe plans for provider agency follow-up:
        </Typography>
        <TextField
          label=""
          style={{ border: "1px solid var(--mainTheme500)", width: "45vw" }}
          variant="outlined"
          multiline
          value={followUpPlans}
          onChange={(e) => {
            setFollowUpPlans(e?.target?.value);
          }}
        />
      </Box>
      {/* <Divider
        sx={{
          marginTop: "32px",
          marginBottom: "16px",
          borderColor: "#0000004d",
          borderBottomWidth: "thin",
          width: "100%",
        }}
      /> */}

      <Box style={{ position: "fixed", bottom: "35px", right: "200px" }}>
        <Box display="flex">
          <Box margin={1}>
            <Button
              sx={{
                backgroundColor: "var(--mainTheme900)",
                color: "white",
                width: "100%",
                padding: "21px 16px",
                "&:hover": {
                  backgroundColor: "var(--mainTheme500)",
                },
              }}
              //  autoFocus
              onClick={handleOpenReviewDialog}
            >
              Review Referral
            </Button>
          </Box>
        </Box>
      </Box>
      <ReviewReferralForm
        open={openReviewDialog}
        onClose={handleCloseReviewDialog}
      />
    </Box>
  );
};
export default MemberUrgentReferralForm;
