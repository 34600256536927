// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 5px;
}
table .no-data {
  padding: 10px;
}
table .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active {
  background: var(--mainTheme350);
  color: var(--colorBlack);
  border-radius: 5px;
  padding: 1px 10px;
}
table .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active svg {
  fill: var(--colorBlack);
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/DataTable/DataTable.scss"],"names":[],"mappings":"AAEI;EACE,YAAA;AADN;AAKE;EACE,aAAA;AAHJ;AAQM;EACE,+BAAA;EACA,wBAAA;EACA,kBAAA;EACA,iBAAA;AANR;AAOQ;EACE,uBAAA;AALV","sourcesContent":["table {\r\n  .MuiInputBase-input {\r\n    &.MuiOutlinedInput-input {\r\n      padding: 5px;\r\n    }\r\n  }\r\n\r\n  .no-data {\r\n    padding: 10px;\r\n  }\r\n\r\n  .MuiButtonBase-root {\r\n    &.MuiTableSortLabel-root {\r\n      &.Mui-active {\r\n        background: var(--mainTheme350);\r\n        color: var(--colorBlack);\r\n        border-radius: 5px;\r\n        padding: 1px 10px;\r\n        svg {\r\n          fill: var(--colorBlack);\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
