// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-picker {
  position: relative;
}
.theme-picker .color-lens {
  background-color: var(--colorWhite);
  position: absolute;
  padding: 10px;
  top: 50px;
  left: -20px;
  border-radius: 5px;
  z-index: 10;
  display: flex;
  flex-direction: row;
}
.theme-picker .color-lens .card {
  display: flex;
  flex-direction: column;
  padding-left: 2px;
}
.theme-picker .color-lens .card .platte-color {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}`, "",{"version":3,"sources":["webpack://./src/components/ThemePlatte/theme.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,mCAAA;EACA,kBAAA;EACA,aAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AAER;AADQ;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;AAGZ;AAFY;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AAIhB","sourcesContent":[".theme-picker {\r\n    position: relative;\r\n    .color-lens {\r\n        background-color: var(--colorWhite);\r\n        position: absolute;\r\n        padding: 10px;\r\n        top: 50px;\r\n        left: -20px;\r\n        border-radius: 5px;\r\n        z-index: 10;\r\n        display: flex;\r\n        flex-direction: row;\r\n        .card {\r\n            display: flex;\r\n            flex-direction: column;\r\n            padding-left: 2px;\r\n            .platte-color {\r\n                width: 20px;\r\n                height: 20px;\r\n                margin-bottom: 2px;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
