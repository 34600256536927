import { FormControl, Button, Grid, Stack } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

import "./DynamicForms.css";

export const DFButton = ({ inputProps, handleButtonClick }) => {
  const { name, label } = inputProps;

  return (
    <Stack direction="row">
      <Grid item>
        <FormControl
          className="inputStyle"
          component="fieldset"
          variant="outlined"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            height: "100%",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={(e) => handleButtonClick(name)}
            variant="contained"
            sx={{
              width: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              BackgroundColor: "var(--mainTheme)",
              textAlign: "center",
              height: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {label}
          </Button>
        </FormControl>
      </Grid>
    </Stack>
  );
};

DFButton.propTypes = {
  inputProps: PropTypes.object.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
};
