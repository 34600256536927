// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-memberlist {
    width: 100%;
    margin: 5px 10px;
    padding: 0 10px;
}

.checkbox-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px; /* Adjust the width as needed */
    flex-shrink: 0;
}


.datatable-memberlist {
    margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminDashboard/MemberListPage/MemberListPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW,EAAE,+BAA+B;IAC5C,cAAc;AAClB;;;AAGA;IACI,gBAAgB;AACpB","sourcesContent":[".content-memberlist {\r\n    width: 100%;\r\n    margin: 5px 10px;\r\n    padding: 0 10px;\r\n}\r\n\r\n.checkbox-cell {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    width: 40px; /* Adjust the width as needed */\r\n    flex-shrink: 0;\r\n}\r\n\r\n\r\n.datatable-memberlist {\r\n    margin-top: 15px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
