import {
  Card,
  CardHeader,
  Grid,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tooltip,
} from "@mui/material";
import toast from "react-hot-toast";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CardSections,
  HeaderCard,
} from "../../../../components/Dashboard/DynamicFormMUI/customFields";

import { DragNDropContext } from "../../../../contexts/DragNDropContext";
import { useUserContext } from "../../../../contexts/UserContext";
import Labels from "../../../../resource_files/Labels.resx";
import { putApi, getApi } from "../../../../utils/axiosApiWrapper";
import Toaster from "../../../toaster/toaster";
import { getInputsData } from "./ScreenOnlyFormData";
import QueryForm from "../Fill_HRA/QueryForm";
import { getObjectData } from "../../../../utils/helpers.DynamicForm";
import Demographics from "./Sections/Demographics/Demographics";
import CovidScreening from "./Sections/CovidScreening/CovidScreening";
import Previously from "./Sections/previouslyDocumented/previouslyDocumented";
import Screenings from "./Sections/Screenings/Screenings";
import PatientSummary from "./Sections/PatientSummary/PatientSummary";
import { setScreeningsData } from "./Sections/Screenings/ScreeningsMapping";
import { setCovidScreeningData } from "./Sections/CovidScreening/covidScreeningMapping";
import { SaveOutlined } from "@mui/icons-material";
import { QueryFormIcon } from "../../../../assets/Icons/FillHra/QueryFormIcon";
import { styled } from "@mui/styles";

const sectionMap2023 = {
  1: ["demographics", "Demographics"],
  2: ["previously", "Previously Documented Conditions"],
  3: ["covid", "Covid Screening"],
  4: ["screenings", "Screening Needed"],
  5: ["patient", "Patient Summary"],
};

const sectionMap2024 = {
  1: ["demographics", "Demographics"],
  2: ["previously", "Previously Documented Conditions"],
  3: ["covid", "Covid Screening"],
  4: ["screenings", "Screening Needed"],
  5: ["patient", "Patient Summary"],
};

const ScreeningOnly = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    saveSectionFormData(currSection + 1).then((resp) => {
      const statusCode = resp?.status;
      if (statusCode === 200) {
        const data = resp?.data || {};
        if (data && Object.keys(data).length > 0) {
          const toastMsg = data.message;
          toast.remove();
          if (toastMsg === "Assessment updated successfully.") {
            toast.success(toastMsg, {});
          } else {
            toast.error(toastMsg, {});
          }
        }
      }
    });
    setOpen(true);
  };

  const [sections, setSections] = useState([]);
  // const [currSection, setCurrSection] = useState(0);
  const [prevSelectedSection, setPrevSelectedSection] = useState(-1);
  // const sectionIdRef = useRef(0);
  // let sections = [];
  // let currSection = 0;
  const [isShowSection, setIsShowSection] = useState({});
  // const [toasterMessage, setToasterMessage] = useState("");
  // const [toasterSeverity, setToasterSeverity] = useState(Labels.SUCCESS);
  // const [toasterOpen, setToasterOpen] = useState(false);
  const sectionRefs = sections.map(() => React.createRef());
  const [isLoading, setIsLoading] = useState(false);

  const {
    user,
    storedPlan,
    storedYear,

    autoSaveCovidScreeningData,
    setAutoSaveCovidScreeningData,

    autoSaveScreeningNeeded,
    setAutoSaveScreeningNeeded,

    autoSavePatientData,
    setAutoSavePatientData,

    autoSaveDemographicsData,
    setAutoSaveDemographicsData,
    autoSavePrevDocData,
    setAutoSavePrevDocData,
    currSection,
    setCurrSection,
    toasterMessage,
    setToasterMessage,
    toasterSeverity,
    setToasterSeverity,
    toasterOpen,
    setToasterOpen,
    sideBarSectionDisable,
  } = useUserContext();
  const { roleId } = user;
  const { getAssessmentData, _memberIdForQueryForm, getAssessment } =
    useUserContext();
  const [formData, setFormData] = useState({});

  const location = useLocation();

  const assessmentId = location?.state?.assessmentId
    ? location?.state?.assessmentId
    : sessionStorage.getItem("assessmentId");
  const tabId = location?.state?.tabId
    ? location?.state?.tabId
    : sessionStorage.getItem("tabId");

  const hraStatus = location?.state?.hraStatus
    ? location?.state?.hraStatus
    : sessionStorage.getItem("hraStatus");
  const patientName = location?.state?.patientName
    ? location?.state?.patientName
    : sessionStorage.getItem("patientName");
  const clientPlan = localStorage.getItem("clientPlan") || storedPlan;
  const clientYear = localStorage.getItem("clientYear") || storedYear;
  const onCloseToaster = () => {
    setToasterOpen(false);
    setToasterSeverity(Labels.SUCCESS);
    setToasterMessage("");
    setToasterMessage("");
  };

  const updateHraStatusToInProgress = async (cancelComment) => {
    const requestBody = {
      data: {
        _id: _memberIdForQueryForm,
        hraStatus: {
          hraStatus: 2,
          subStatus: "",
          cancelStatus: "",
        },
      },
    };
    try {
      const response = await putApi("/hra-status/updateHraStatus", requestBody);

      if (response.data.code === 200) {
        // setSignSubmitDialog(false);
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        toast.remove();
        toast.success("Assessment Updated Successfully", {});
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage(response.data.message);
        // fetchMemberData(storedPlan, storedYear);
      } else {
        return;
      }
    } catch (error) {
      return;
    }

    // setIsCancelAppointmentAlertOpen(false);
    // } else {
    //   console.error("Selected HRA status not found in data array");
    // }
  };

  const handleCardClick = (index) => {
    setIsLoading(true);
    setCurrSection(index);
    setPrevSelectedSection(currSection);
    // sectionIdRef.current = index;
    const sectionRef = sectionRefs[index];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 1500);
    }
  };

  // useEffect(() => {
  //   console.log("prevSelectedSection", prevSelectedSection);
  //   console.log("currSection", currSection);
  //   saveSectionFormData(prevSelectedSection + 1);
  // }, [currSection]);

  useEffect(() => {
    const getData = async () => {
      const data = await getInputsData(clientYear);
      setSections(data);
    };
    getData();
  }, []);

  const showQueryFormBtn = sessionStorage.getItem("showQueryFormBtn");

  const covidScreeningRef = useRef();
  // const { ref: activitiesInViewRef, inView: activitiesInView } = useInView({
  //   ...inViewOptions,
  // });

  const demographicsRef = useRef();
  // const { ref: selfInViewRef, inView: selfInView } = useInView({
  //   ...inViewOptions,
  // });

  const screeningsRef = useRef();
  const previouslyRef = useRef();
  // const { ref: patientInViewRef, inView: patientInView } = useInView({
  //   ...inViewOptions,
  // });
  const patientSummaryRef = useRef();

  const handlePatientSave = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });
      let tempData = patientSummaryRef?.current?.getPayloadData();
      if (!Boolean(tempData)) {
        tempData = autoSavePatientData;
      }
      const patientData = {};

      const visitType = {
        isVisitType: tempData?.visitTypeSaveBtn
          ? tempData?.visitTypeSaveBtn
          : "",
        consentedToVideo:
          tempData?.visitTypeSaveBtn == 2
            ? tempData?.consentedToVideoSaveBtn
              ? tempData?.consentedToVideoSaveBtn
              : ""
            : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = tempData?.assessorsCommentsSaveBtn
        ? tempData?.assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = tempData?.memberAcknowledgementSaveBtn
        ? tempData?.memberAcknowledgementSaveBtn
        : "";

      patientData.acceptDisclosure = tempData?.acceptDisclosureSaveBtn
        ? tempData?.acceptDisclosureSaveBtn
        : "";
      patientData.evaluationDate = tempData?.evaluationDate;
      patientData.examFinishDate = tempData?.examFinishDateSaveBtn
        ? tempData?.examFinishDateSaveBtn
        : tempData?.examFinishDate
        ? tempData?.examFinishDate
        : "";

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature
              ? providerSignature
              : tempData?.providerSignAllDashboard
              ? tempData?.providerSignAllDashboard
              : "",
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response?.data?.code === 406 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
        //toast.success(response?.data?.message, {});
      }
      if (response.status == 404 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (
        response.status === 200 &&
        response?.data?.message == "Assessment updated successfully."
      ) {
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        toast.success("Assessment Updated Successfully", {});
        const roleId = sessionStorage.getItem("roleId");
      } else {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Error saving data");
      }
    } catch (error) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Error saving data");
    }
  };

  const handlePatientSave2024 = async (providerSignature) => {
    try {
      const formDataCamelCase = {};
      Object.keys(formData).forEach((key) => {
        const camelCaseKey = key.replace(/_./g, (x) => x[1].toUpperCase());
        formDataCamelCase[camelCaseKey] = formData[key];
      });
      let tempData = patientSummaryRef?.current?.getPayloadData();
      if (!Boolean(tempData)) {
        tempData = autoSavePatientData;
      }
      const patientData = {};
      const visitType = {
        isVisitType: tempData?.visitTypeSaveBtn
          ? tempData?.visitTypeSaveBtn
          : "",
      };

      patientData.visitType = visitType;
      patientData.assessorsComments = tempData?.assessorsCommentsSaveBtn
        ? tempData?.assessorsCommentsSaveBtn
        : "";
      patientData.memberAcknowledgement = tempData?.memberAcknowledgementSaveBtn
        ? tempData?.memberAcknowledgementSaveBtn
        : "";

      patientData.acceptDisclosure = tempData?.acceptDisclosureSaveBtn
        ? tempData?.acceptDisclosureSaveBtn
        : "";

      patientData.memberConsentedToCompleteVirtualVisit =
        tempData?.memberConsentedToCompleteVirtualVisitSaveBtn
          ? tempData?.memberConsentedToCompleteVirtualVisitSaveBtn
          : "";

      patientData.membersIdentityConfirmedWithID =
        tempData?.membersIdentityConfirmedWithIDSaveBtn
          ? tempData?.membersIdentityConfirmedWithIDSaveBtn
          : "";

      patientData.comment = tempData?.commentSaveBtn
        ? tempData?.commentSaveBtn
        : "";

      patientData.evaluationDate = tempData?.evaluationDate;
      patientData.examFinishDate = tempData?.examFinishDateSaveBtn
        ? tempData?.examFinishDateSaveBtn
        : tempData?.examFinishDate
        ? tempData?.examFinishDate
        : "";

      const payload = {
        id: assessmentId,
        year: clientYear,
        data: {
          name: "Patient Summary",
          data: {
            ...patientData,
            providerSignature: providerSignature
              ? providerSignature
              : tempData?.providerSignAllDashboard
              ? tempData?.providerSignAllDashboard
              : "",
          },
        },
      };
      if (tabId == 1) {
        updateHraStatusToInProgress();
      }
      const response = await putApi("/hra/assessment", payload);
      if (response?.data?.code === 406 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (response.status == 404 || response.status == 401) {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage(response?.data?.message);
      }
      if (
        response.status === 200 &&
        response?.data?.message == "Assessment updated successfully."
      ) {
        // setToasterOpen(true);
        // setToasterSeverity(Labels.SUCCESS);
        // setToasterMessage("Assessment Updated Successfully");
        toast.success("Assessment Updated Successfully", {});
        const roleId = sessionStorage.getItem("roleId");
      } else {
        setToasterOpen(true);
        setToasterSeverity(Labels.ERROR);
        setToasterMessage("Error saving data");
      }
    } catch (error) {
      setToasterOpen(true);
      setToasterSeverity(Labels.ERROR);
      setToasterMessage("Error saving datass");
    }
  };

  const handleSave = () => {
    if (clientYear === "2024") {
      handlePatientSave2024();
    } else {
      handlePatientSave();
    }
  };
  useEffect(() => {
    const getData = async () => {
      const secondApiResponse = await getApi(
        `/hra/get-assessment?id=${assessmentId}`,
      );
      getAssessment(secondApiResponse);
      getSectionDbData(currSection + 1);
      //saveSectionFormData(prevSelectedSection + 1);
      if (prevSelectedSection + 1 != 5 && prevSelectedSection >= 0) {
        saveSectionFormData(prevSelectedSection + 1).then((resp) => {
          const statusCode = resp?.status;
          if (statusCode === 200) {
            const data = resp?.data || {};
            if (data && Object.keys(data).length > 0) {
              const toastMsg = data.message;
              toast.remove();
              if (toastMsg === "Assessment updated successfully.") {
                toast.success(toastMsg, {});
              } else {
                toast.error(toastMsg, {});
              }
            }
          }
        });
      } else if (prevSelectedSection + 1 == 5) {
        // handlePatientSave();
        handleSave();
      }
    };
    getData();
  }, [currSection]);

  const getSectionDbData = async (sectionId) => {
    const assessmentDataFromAPI = await getAssessmentData();
    let sectionDbData = {};

    let sectionMap = {};
    if (clientYear === "2023") {
      sectionMap = { ...sectionMap2023 };
    } else if (clientYear === "2024") {
      sectionMap = { ...sectionMap2024 };
    }

    let sectionData = sectionMap[sectionId];
    let sectionKey = sectionData[0];
    let tempDbData = getObjectData(
      assessmentDataFromAPI,
      `data.data.${sectionKey}.data`,
      {},
    );

    sectionDbData = {
      ...tempDbData,
    };

    return sectionDbData;
  };
  useEffect(() => {
    getSectionDbData(currSection + 1);
  }, [currSection]);

  const saveSectionFormData = async (sectionId) => {
    try {
      let payload = {};
      let assessId = assessmentId;

      let sectionMap = {};
      if (clientYear === "2023") {
        sectionMap = { ...sectionMap2023 };
      } else if (clientYear === "2024") {
        sectionMap = { ...sectionMap2024 };
      }

      let sectionData = sectionMap[sectionId];
      let sectionName = sectionData[1];
      let tempData = {};
      if (sectionId === 1) {
        tempData = demographicsRef?.current?.getPayloadData(); //getSectionFormData(sectionData);
        if (!Boolean(tempData)) {
          tempData = autoSaveDemographicsData;
        }
      } else if (sectionId === 2) {
        tempData = previouslyRef?.current?.getPayloadData();

        if (!Boolean(tempData)) {
          tempData = autoSavePrevDocData; //setAllergiesData(autoSaveAllergiesData, clientYear);
        }
        if (clientYear === "2023") {
          delete tempData.suspectCodes;
        }

        const view =
          tempData?.viewSubAnswers?.length > 0
            ? "1"
            : "2" || tempData?.suspectCodes?.length > 0
            ? "1"
            : "2";
        tempData = {
          ...tempData,
          view,
        };
        payload = {
          id: assessId,
          year: clientYear,
          data: {
            name: sectionName,
            data: {
              ...tempData,
            },
          },
        };
      } else if (sectionId === 3) {
        tempData = covidScreeningRef?.current?.getPayloadData();

        if (!Boolean(tempData)) {
          tempData = setCovidScreeningData(
            autoSaveCovidScreeningData,
            clientYear,
          );
        }
      } else if (sectionId === 4) {
        tempData = screeningsRef?.current?.getPayloadData();
        if (!Boolean(tempData)) {
          tempData = setScreeningsData(autoSaveScreeningNeeded, clientYear);
        }
      } else if (sectionId === 5) {
        handleSave();
      }

      payload = {
        id: assessId,
        year: clientYear,
        data: {
          name: sectionName,
          data: {
            ...tempData,
          },
        },
      };

      if (sectionId !== 5) {
        const response = await putApi("/hra/assessment", payload);

        if (tabId == 1) {
          updateHraStatusToInProgress();
        }
        return response;
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    const id = setInterval(() => {
      saveSectionFormData(currSection + 1).then((resp) => {
        const statusCode = resp?.status;
        if (statusCode === 200) {
          const data = resp?.data || {};
          if (data && Object.keys(data).length > 0) {
            const toastMsg = data.message;
            toast.remove();
            if (toastMsg === "Assessment updated successfully.") {
              toast.success(toastMsg, {});
            } else {
              toast.error(toastMsg, {});
            }
          }
        }
      });
    }, 60000);
    return () => {
      clearInterval(id);
    };
  }, [currSection]);

  const userRoleId = sessionStorage.getItem("roleId");
  const isAdm = userRoleId === "1" || userRoleId === "2";
  const isClinician = userRoleId === "5";
  const isSaveBtn =
    hraStatus === "In Progress" ||
    hraStatus === "Scheduled" ||
    hraStatus === "Unscheduled";
  const isQfBtn =
    hraStatus === "Clinician Review - QA" ||
    hraStatus === "Clinician Review - Coding" ||
    hraStatus === "Submitted - Ready for Coding" ||
    hraStatus === "Submitted - Ready for QA";

  // Function to handle saving data
  const handleSaveData = async (sectionId) => {
    try {
      const response = await saveSectionFormData(sectionId);
      //Fix for HRA-1553
      if (response) {
        const { status, data = {} } = response;
        if (status === 200) {
          const toastMsg = data.message || "Unknown error occurred";
          if (toastMsg === "Assessment updated successfully.") {
            toast.success(toastMsg);
          } else {
            toast.error(toastMsg);
          }
        } else {
          toast.error("Failed to save data. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  // Function to call saving data function
  const handleSaveAndClickOpen = async () => {
    const currentSection = sections[currSection];
    await handleSaveData(currentSection.sectionId);
  };

  //Handle Open Query Form
  const handleOpenQueryForm = () => setOpen(true);

  //Managing State for open & close speed dial
  const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
  const handleOpenSpeedDial = () => setOpenSpeedDial(true);
  const handleCloseSpeedDial = () => setOpenSpeedDial(false);

  //Speed dial Options
  const actions = [
    {
      icon: <SaveOutlined />,
      name: "Save Form Data",
      handler: handleSaveAndClickOpen,
      visibility: (isAdm && isSaveBtn) || (isClinician && tabId !== 3),
    },
    {
      icon: <QueryFormIcon />,
      name: "Open Query Form",
      handler: handleOpenQueryForm,
      visibility: showQueryFormBtn === "true" || (isAdm && isQfBtn),
    },
  ];

  //Custom style for Speed Dial
  const StyledSpeedDial = styled(SpeedDial)({
    position: "fixed",
    bottom: 15,
    right: 16,
    "& .MuiSpeedDial-fab": {
      backgroundColor: `var(--mainTheme900)`,
      "&:hover": {
        backgroundColor: `var(--mainTheme900)`,
      },
    },
  });

  //Filter Visible action alone
  const visibleActions = actions.filter((action) => action.visibility);

  const actionLength = visibleActions.length ? visibleActions.length : 0;

  return (
    <>
      {actionLength === 1 && (
        <Tooltip title={visibleActions[0].name} arrow>
          <StyledSpeedDial
            ariaLabel={visibleActions[0].name}
            icon={visibleActions[0].icon}
            onClick={visibleActions[0].handler}
            transitionDuration={0}
          />
        </Tooltip>
      )}
      {actionLength === 2 && (
        <StyledSpeedDial
          ariaLabel="Save and Open Query Form"
          icon={<SpeedDialIcon />}
          onClose={handleCloseSpeedDial}
          onOpen={handleOpenSpeedDial}
          open={openSpeedDial}
          transitionDuration={0}
        >
          {visibleActions.map((action) =>
            action.visibility ? (
              <SpeedDialAction
                sx={{
                  bgcolor: `var(--mainTheme900)`,
                  color: "white",
                  "&:hover": {
                    bgcolor: `var(--mainTheme900)`,
                  },
                }}
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.handler}
              />
            ) : (
              <></>
            ),
          )}
        </StyledSpeedDial>
      )}
      <QueryForm
        open={open}
        setOpen={setOpen}
        memberId={_memberIdForQueryForm}
        formData={formData}
        _patientName={patientName}
      />
      <Grid item xs={10} marginTop="80px" marginBottom={1}>
        <HeaderCard formData={formData} getSectionDbData={getSectionDbData} />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        style={{ fontFamily: "Inter !important" }}
        className={"fillHraForm"}
      >
        <Grid item xs={2} style={{ padding: "3px 7px" }}>
          <CardSections
            //routedFrom="FillHra"
            screenOnlyRoute="ScreenOnly"
            sections={sections}
            currSection={currSection}
            // currSection={sectionIdRef.current}
            handleCardClick={handleCardClick}
            saveSectionFormData={saveSectionFormData}
            sideBarSectionDisable={sideBarSectionDisable}
          />
        </Grid>
        <Grid
          item
          xs={10}
          style={{ padding: "2px", textAlign: "center !important" }}
        >
          <Card
            style={{
              height: "auto",
              minHeight: "76vh",
              maxHeight: "450px",
              overflowY: "auto",
              marginLeft: "10px",
            }}
          >
            {sections.map((section, index) => {
              return (
                <div key={index} id={section.sectionId}>
                  <div ref={sectionRefs[index]} className="section">
                    <span
                      style={{
                        width: "100% !important",
                        margin: "0 !important",
                        padding: "0 !important",
                      }}
                    >
                      <CardHeader
                        title={section.sectionName}
                        action={
                          <a
                            style={{
                              textDecoration: "none",

                              color: "rgba(0, 0, 0, 0.87)",
                            }}
                            sx={{
                              textDecoration: "none",
                              color: "var(--mainTheme)",
                            }}
                            href={`#${section.sectionId}`}
                          >
                            <div
                              style={{
                                width: "100% !important",
                                margin: "0 !important",
                                padding: "0 !important",
                              }}
                            >
                              <IconButton
                                style={{
                                  float: "right",
                                  color: "#FFFFFF",
                                }}
                                aria-label="Section minimise"
                                size="small"
                                onClick={() => {
                                  //   if (isShowSection[index] !== undefined) {
                                  //     setIsShowSection({
                                  //       ...isShowSection,
                                  //       [index]: !isShowSection[index],
                                  //     });
                                  //   } else {
                                  //     setIsShowSection({
                                  //       ...isShowSection,
                                  //       [index]: false,
                                  //     });
                                  //   }
                                  // }}

                                  // if (currSection != index) {
                                  //   handleCardClick(index);
                                  // } else {
                                  //   handleCardClick(0);
                                  // }

                                  // if (section?.sectionId !== currSection) {
                                  if (index !== currSection) {
                                    //  setCurrSection(section?.sectionId);
                                    setCurrSection(section?.sectionId - 1);
                                    setPrevSelectedSection(currSection);
                                  } else {
                                    setCurrSection(section?.sectionId);
                                    setPrevSelectedSection(currSection);
                                    //setCurrentSectionId(undefined);
                                    //setCurrSection(undefined);
                                  }
                                }}
                              >
                                {currSection == index ? (
                                  <KeyboardControlKeyIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                            </div>
                          </a>
                        }
                        sx={{
                          backgroundColor: `var(--mainTheme)`,
                          color: "white",
                          padding: "15px",
                          textAlign: "center !important",
                          textTransform: "uppercase",
                          fontWeight: 700,
                        }}
                        titleTypographyProps={{ variant: "outline" }}
                      />
                    </span>
                    <div
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <Suspense fallback={<Loader />}> */}

                      <Grid
                        container
                        spacing={2}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {isShowSection[index] === true ||
                        isShowSection[index] === undefined ? (
                          (() => {
                            if (
                              section.sectionId === 1 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                <Demographics
                                  currSection={1}
                                  getSectionDbData={getSectionDbData}
                                  ref={demographicsRef}
                                  setAutoSaveDemographicsData={
                                    setAutoSaveDemographicsData
                                  }
                                />
                              );
                            } else if (
                              section.sectionId === 2 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                <Previously
                                  currSection={2}
                                  getSectionDbData={getSectionDbData}
                                  ref={previouslyRef}
                                  setAutoSavePrevDocData={
                                    setAutoSavePrevDocData
                                  }
                                />
                              );
                            } else if (
                              section.sectionId === 3 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={covidInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <CovidScreening
                                    currSection={3}
                                    getSectionDbData={getSectionDbData}
                                    ref={covidScreeningRef}
                                    setAutoSaveCovidScreeningData={
                                      setAutoSaveCovidScreeningData
                                    }
                                    // inView={covidInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 4 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                // <Suspense fallback={<Loader />}>
                                <div
                                  // ref={screeningsInViewRef}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Screenings
                                    currSection={4}
                                    getSectionDbData={getSectionDbData}
                                    ref={screeningsRef}
                                    setAutoSaveScreeningNeeded={
                                      setAutoSaveScreeningNeeded
                                    }
                                    // inView={screeningsInView}
                                  />
                                </div>
                                // </Suspense>
                              );
                            } else if (
                              section.sectionId === 5 &&
                              currSection + 1 == section.sectionId
                            ) {
                              return (
                                <PatientSummary
                                  currSection={5}
                                  getSectionDbData={getSectionDbData}
                                  ref={patientSummaryRef}
                                  setAutoSavePatientData={
                                    setAutoSavePatientData
                                  }
                                />
                              );
                            }
                          })()
                        ) : (
                          <></>
                        )}
                      </Grid>
                      {/* </Suspense> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </Card>
        </Grid>
      </Grid>
      <Toaster
        open={toasterOpen}
        onClose={onCloseToaster}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
};

export default ScreeningOnly;
