const json2023 = {
  sectionId: 12,
  sectionName: "Vital Signs",
  isEditable: false,
  isCommentEnabled: true,
  questions: [
    {
      id: 7,
      name: "vital_vitalSigns",
      value: "vitalSigns",
      type: "label",
      label: "Vital Signs",
      editToggle: false,
      xs: 12,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 1,
      depth: 1,
    },
    {
      id: 2,
      name: "vital_diastolicbp",
      value: "diastolicbp",
      type: "number",
      label: "Blood pressure (mmHG)",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 2,
      depth: 1,
    },
    {
      id: 2,
      name: "vital_systolicbp",
      value: "systolicbp",
      type: "number",
      label: "Blood pressure (mmHG)",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 3,
    },
    {
      id: 2,
      name: "vital_pulse",
      value: "pulse",
      type: "number",
      label: "Pulse (bpm)",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 4,
    },
    {
      id: 2,
      name: "vital_respiratoryRate",
      value: "respiratoryRate",
      type: "number",
      label: "Respiratory Rate",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 5,
    },
    {
      id: 2,
      name: "vital_temp",
      value: "temp",
      type: "number",
      label: "Temp",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 6,
    },
    {
      id: 2,
      name: "vital_pulseOximetry",
      value: "pulseOximetry",
      type: "number",
      label: "Pulse Oximetry",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 7,
    },
    {
      id: 2,
      name: "vital_painScale",
      value: "painScale",
      type: "number",
      label: "Pain Scale/10",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 8,
    },
    {
      id: 2,
      name: "vital_bmiLabel",
      value: "bmi",
      type: "label",
      label: "BMI",
      editToggle: false,
      xs: 12,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 9,
    },

    {
      id: 2,
      name: "vital_feet",
      value: "feet",
      type: "number",
      label: "Patient's Height (Feet)",
      editToggle: false,
      xs: 1.9,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 10,
    },
    {
      id: 2,
      name: "vital_inch",
      value: "inch",
      type: "number",
      label: "Patient's Height (Inch)",
      editToggle: false,
      xs: 1.9,
      mt: 22,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 11,
    },
    {
      id: 2,
      name: "vital_patientsWeight",
      value: "patientsWeight",
      type: "number",
      label: "Patient's Weight (lbs)",
      editToggle: false,
      xs: 1.9,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 12,
    },

    {
      id: 2,
      name: "vital_bmii",
      value: "bmi",
      type: "number",
      label: "BMI",
      editToggle: false,
      xs: 1.5,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 13,
      disabled: true,
    },

    // {
    //   id: 3,
    //   name: "",
    //   value: "",
    //   type: "label",
    //   label: "",
    //   editToggle: false,
    //   xs: 1,
    //   required: false,
    //   placeholder: "",
    //   defaultValue: "",
    //   isCommentEnabled: false,
    //   isConfigured: true,
    //   isModified: false,
    //   questionId: 14,
    //   disabled: true,
    // },

    {
      id: 11,
      name: "dataTable",
      label: "Data Table",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart",
            name: "BMI Index Chart",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          true,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stage of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 13,
            label: "Depression Severity",
            name: "Depression_Severity",
            columns: [
              {
                label: "Score",
                type: "number",
              },
              {
                label: "Depression Severity",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
      ],
      xs: 6,
      required: "no",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 15,
    },
    {
      id: 6,
      name: "vital_bmi",
      type: "options_tree",
      label: "",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Obesity",
          value: "obesity",
          name: "obesity",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Moderate Obesity",
          value: "moderateObesity",
          name: "moderateObesity",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Morbid Obesity",
          value: "morbidObesity",
          name: "morbidObesity",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 4,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "vital_bmiMalnutritionSubDescribe",
              value: "describe",
              type: "options_tree",
              label: "Describe",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Active",
                  value: "active",
                  name: "active",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "History of",
                  value: "historyOf",
                  name: "historyOf",
                  type: "toggle_button",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Rule out",
                  value: "ruleOut",
                  name: "ruleOut",
                  type: "toggle_button",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 6,
              name: "vital_bmiMalnutritionSubMalnutrition",
              value: "malnutrition",
              type: "options_tree",
              label: "Malnutrition",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  label: "Yes",
                  value: "yes",
                  name: "yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  name: "no",
                  type: "toggle_button",
                  depth: 4,
                },
              ],
              subQuestionId: 2,
              depth: 3,
              editToggle: false,
            },
            {
              id: 6,
              name: "vital_bmiMalnutritionSubSupportedBy",
              value: "supportedBy",
              type: "options_tree",
              label: "Supported by",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  label: "Albumin <3.5g/dl",
                  value: "albumin",
                  name: "albumin",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Muscle wasting",
                  value: "muscleWaiting",
                  name: "muscleWaiting",
                  type: "toggle_button",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isConfigured: true,
                  isModified: false,
                  label: "History of severe weight loss",
                  value: "historyOfSevereWtLoss",
                  name: "historyOfSevereWtLoss",
                  type: "toggle_button",
                  depth: 4,
                },
              ],
              subQuestionId: 3,
              depth: 3,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Malnutrition",
          value: "malnutrition",
          name: "malnutrition",
          type: "toggle_button",
          depth: 2,
        },
      ],
      questionId: 16,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "vital_specialDiet",
      value: "specialDiet",
      type: "options_tree",
      label: "Are you on a special diet?",
      isExclusive: false,
      fullWidth: true,
      required: true,
      isMulti: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Heart Healthy Diet",
          value: "heartHealthyDiet",
          name: "heartHealthyDiet",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Diabetic Diet",
          value: "diabeticDiet",
          name: "diabeticDiet",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Renal Diet",
          value: "renalDiet",
          name: "renalDiet",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Vegetarian",
          value: "vegetarian",
          name: "vegetarian",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Vegan",
          value: "vegan",
          name: "vegan",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Gluten Free",
          value: "glutenFree",
          name: "glutenFree",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Keto",
          value: "keto",
          name: "keto",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 8,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Pescatarian",
          value: "pescatarian",
          name: "pescatarian",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 9,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "vital_specialDietSubOtherSubDesc",
              value: "describe",
              type: "text",
              placeholder: "",
              label: "Describe",
              variant: "outlined",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: false,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Other",
          value: "other",
          name: "vital_specialDietSubOther",
          type: "toggle_button",
          depth: 2,
        },
      ],
      questionId: 17,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "vital_lostWeight",
      type: "options_tree",
      label: "Have you lost weight in the past 6 months?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "None",
          value: "none",
          name: "none",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "5lbs",
          value: "fiveLbs",
          name: "fiveLbs",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "10lbs",
          value: "tenLbs",
          name: "tenLbs",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "15lbs",
          value: "fifteenLbs",
          name: "fifteenLbs",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "More than 15 lbs",
          value: "moreThanFifteenLbs",
          name: "moreThanFifteenLbs",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "10% of your weight (calculated by assessor)",
          value: "tenPercentYourWeight",
          name: "tenPercentYourWeight",
          type: "toggle_button",
          depth: 2,
        },
      ],
      questionId: 18,
      editToggle: false,
      depth: 1,
    },
    {
      id: 10,
      name: "vital_Recommendations",
      value: "nutritionManagement",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Nutrition/weight management", "nutritionManagement"],
        ["Other", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 19,
    },
    // {
    //   id: 10,
    //   name: "other",
    //   value: "other",
    //   placeholder: "",
    //   editToggle: false,
    //   defaultValue: "",
    //   type: "checkbox",
    //   options: [["Other", "other"]],
    //   xs: 12,
    //   required: false,
    //   isCommentEnabled: true,
    //   isConfigured: true,
    //   isModified: false,
    //   questionId: 15,
    // },
  ],
};

const json2024 = {
  sectionId: 12,
  sectionName: "Vital Signs",
  isEditable: false,
  isCommentEnabled: true,
  questions: [
    {
      id: 7,
      name: "vital_vitalSigns",
      value: "vitalSigns",
      type: "label",
      label: "Vital Signs",
      editToggle: false,
      xs: 12,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 1,
      depth: 1,
    },
    {
      questionId: 2,
      name: "vital_reported",
      label: "",
      required: true,
      type: "radio",
      options: [
        ["Self Reported", "1"],
        ["Actual", "2"],
      ],
      xs: 4,
      isCommentEnabled: false,
      isModified: false,
      depth: 1,
    },
    {
      id: 2,
      name: "vital_diastolicbp",
      value: "diastolicbp",
      type: "number",
      label: "Blood pressure (mmHG)",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 3,
      depth: 1,
    },
    {
      id: 2,
      name: "vital_systolicbp",
      value: "systolicbp",
      type: "number",
      label: "Blood pressure (mmHG)",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 4,
    },
    {
      id: 2,
      name: "vital_pulse",
      value: "pulse",
      type: "number",
      label: "Pulse (bpm)",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 5,
    },
    {
      id: 2,
      name: "vital_respiratoryRate",
      value: "respiratoryRate",
      type: "number",
      label: "Respiratory Rate",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 6,
    },
    {
      id: 2,
      name: "vital_temp",
      value: "temp",
      type: "number",
      label: "Temp",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 7,
    },
    {
      id: 2,
      name: "vital_pulseOximetry",
      value: "pulseOximetry",
      type: "number",
      label: "Pulse Oximetry",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 8,
    },
    {
      id: 2,
      name: "vital_painScale",
      value: "painScale",
      type: "number",
      label: "Pain Scale/10",
      editToggle: false,
      xs: 4,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 9,
    },
    {
      id: 2,
      name: "vital_bmiLabel",
      value: "bmi",
      type: "label",
      label: "BMI",
      editToggle: false,
      xs: 12,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 10,
    },

    {
      id: 2,
      name: "vital_feet",
      value: "feet",
      type: "number",
      label: "Patient's Height (Feet)",
      editToggle: false,
      xs: 1.9,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 11,
    },
    {
      id: 2,
      name: "vital_inch",
      value: "inch",
      type: "number",
      label: "Patient's Height (Inch)",
      editToggle: false,
      xs: 1.9,
      mt: 22,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 12,
    },
    {
      id: 2,
      name: "vital_patientsWeight",
      value: "patientsWeight",
      type: "number",
      label: "Patient's Weight (lbs)",
      editToggle: false,
      xs: 1.9,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 13,
    },

    {
      id: 2,
      name: "vital_bmii",
      value: "bmi",
      type: "number",
      label: "BMI",
      editToggle: false,
      xs: 1.5,
      required: false,
      placeholder: "",
      defaultValue: "",
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 14,
      disabled: true,
    },

    // {
    //   id: 3,
    //   name: "",
    //   value: "",
    //   type: "label",
    //   label: "",
    //   editToggle: false,
    //   xs: 1,
    //   required: false,
    //   placeholder: "",
    //   defaultValue: "",
    //   isCommentEnabled: false,
    //   isConfigured: true,
    //   isModified: false,
    //   questionId: 14,
    //   disabled: true,
    // },

    {
      id: 11,
      name: "dataTable",
      label: "Data Table",
      editToggle: false,
      type: "datatable",
      tablelist: [
        [
          {
            id: 1,
            label: "Allergies",
            name: "HRAForm_Allergies",
            columns: [
              {
                label: "Substance",
                type: "text",
              },
              {
                label: "Reaction",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 2,
            label: "BMI Index Chart 2024",
            name: "BMI Index Chart 2024",
            columns: [
              {
                label: "BMI",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          true,
        ],
        [
          {
            id: 3,
            label: "Counter Medications",
            name: "HRAForm_Counter_Medications",
            columns: [
              {
                label: "Date",
                type: "date",
              },
              {
                label: "Description",
                type: "text",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 4,
            label: "Medical History Specialists",
            name: "HRAForm_Medical_History_Speciality",
            columns: [
              {
                label: "Member Specialist",
                type: "select",
              },
              {
                label: "Speciality",
                type: "text",
              },
              {
                label: "For",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 5,
            label: "Medications",
            name: "HRAForm_Medications",
            columns: [
              {
                label: "Diagnoses",
                type: "text",
              },
              {
                label: "label Name",
                type: "select",
              },
              {
                label: "Dose Per Units",
                type: "text",
              },
              {
                label: "Route",
                type: "select",
              },
              {
                label: "Frequency",
                type: "select",
              },
              {
                label: "Prescribing Physician",
                type: "text",
              },
              {
                label: "Status",
                type: "togglebutton",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 6,
            label: "Mini Cog Versions",
            name: "HRAForm_Minicog_Version",
            columns: [
              {
                label: "Version 1",
                type: "text",
              },
              {
                label: "Version 2",
                type: "text",
              },
              {
                label: "Version 3",
                type: "text",
              },
              {
                label: "Version 4",
                type: "text",
              },
              {
                label: "Version 5",
                type: "text",
              },
              {
                label: "Version 6",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 7,
            label: "Preventive Care",
            name: "HRAForm_Preventive_Care",
            columns: [
              {
                label: "Screen",
                type: "text",
              },
              {
                label: "Answer",
                type: "select",
              },
              {
                label: "Date",
                type: "text",
              },
              {
                label: "Method",
                type: "text",
              },
              {
                label: "Recommendation",
                type: "checkbox",
              },
              {
                label: "Education Completed",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 8,
            label: "Previously Documented Conditions",
            name: "HRAForm_Previously_Documented_Conditions",
            columns: [
              {
                label: "Diagnosis",
                type: "text",
              },
              {
                label: "Prior HCC",
                type: "text",
              },
              {
                label: "Diagnosis Code",
                type: "text",
              },
              {
                label: "Date of Service",
                type: "date",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 9,
            label: "Prior Assessments",
            name: "HRAForm_Prior_Assessments",
            columns: [
              {
                label: "Date Time",
                type: "date",
              },
              {
                label: "Document",
                type: "button",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 10,
            label: "Stage of Kidney Disease",
            name: "HRAForm_Stage_Of_Kidney_Disease",
            columns: [
              {
                label: "Stages",
                type: "text",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 11,
            label: "Alcohol Usage",
            name: "HRAForm_Alcohol_Usage",
            columns: [
              {
                label: "How many drinks",
                type: "select",
              },
              {
                label: "How often",
                type: "select",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
        [
          {
            id: 12,
            name: "Family History",
            columns: [
              {
                label: "Family Member",
                type: "select",
              },
              {
                label: "Medical Condition",
                type: "text",
              },
              {
                label: "Cause of Death",
                type: "text",
              },
            ],
            isReadOnly: false,
          },
          false,
        ],
        [
          {
            id: 13,
            label: "Depression Severity",
            name: "Depression_Severity",
            columns: [
              {
                label: "Score",
                type: "number",
              },
              {
                label: "Depression Severity",
                type: "checkbox",
              },
            ],
            isReadOnly: true,
          },
          false,
        ],
      ],
      xs: 6,
      required: "no",
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      questionId: 15,
    },
    {
      id: 6,
      name: "vital_bmi",
      type: "options_tree",
      label: "",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: false,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          subQuestionId: 1,
          hasSubQuestions: true,
          subQuestions: [
            {
              id: 6,
              name: "vital_bmiMalnutritionSubDescribe",
              value: "describe",
              type: "options_tree",
              label: "Describe",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  label: "Active",
                  value: "active",
                  name: "active",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "History of",
                  value: "historyOf",
                  name: "historyOf",
                  type: "toggle_button",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isCommentEnabled: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Rule out",
                  value: "ruleOut",
                  name: "ruleOut",
                  type: "toggle_button",
                  depth: 4,
                },
              ],
              subQuestionId: 1,
              editToggle: false,
              depth: 3,
            },
            {
              id: 6,
              name: "vital_bmiMalnutritionSubMalnutrition",
              value: "malnutrition",
              type: "options_tree",
              label: "Malnutrition",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  label: "Yes",
                  value: "yes",
                  name: "Yes",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isConfigured: true,
                  isModified: false,
                  label: "No",
                  value: "no",
                  name: "No",
                  type: "toggle_button",
                  depth: 4,
                },
              ],
              subQuestionId: 2,
              depth: 3,
              editToggle: false,
            },
            {
              id: 6,
              name: "vital_bmiMalnutritionSubSupportedBy",
              value: "supportedBy",
              type: "options_tree",
              label: "Supported by",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: true,
              isConfigured: true,
              isModified: true,
              hasSubQuestions: true,
              subQuestions: [
                {
                  depth: 4,
                  subQuestionId: 1,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  label: "Albumin <3.5g/dl",
                  value: "albumin",
                  name: "albumin",
                  isConfigured: true,
                  type: "toggle_button",
                },
                {
                  subQuestionId: 2,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isConfigured: true,
                  isModified: false,
                  label: "Muscle wasting",
                  value: "muscleWaiting",
                  name: "muscleWaiting",
                  type: "toggle_button",
                  depth: 4,
                },
                {
                  subQuestionId: 3,
                  hasSubQuestions: false,
                  subQuestions: [],
                  editToggle: false,
                  isConfigured: true,
                  isModified: false,
                  label: "History of severe weight loss",
                  value: "historyOfSevereWtLoss",
                  name: "historyOfSevereWtLoss",
                  type: "toggle_button",
                  depth: 4,
                },
              ],
              subQuestionId: 3,
              depth: 3,
              editToggle: false,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Malnutrition",
          value: "malnutrition",
          name: "malnutrition",
          type: "toggle_button",
          depth: 2,
        },
        {
          depth: 2,
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Underweight",
          value: "underWeight",
          name: "underWeight",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          depth: 2,
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Normal Weight",
          value: "normalWeight",
          name: "normalWeight",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          depth: 2,
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Overweight",
          value: "overWeight",
          name: "overWeight",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          depth: 2,
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Obesity",
          value: "obesity",
          name: "obesity",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Moderate Obesity",
          value: "moderateObesity",
          name: "moderateObesity",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Morbid Obesity",
          value: "morbidObesity",
          name: "morbidObesity",
          type: "toggle_button",
          depth: 2,
        },
      ],
      questionId: 16,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "vital_specialDiet",
      value: "specialDiet",
      type: "options_tree",
      label: "Are you on a special diet?",
      isExclusive: false,
      fullWidth: true,
      required: true,
      isMulti: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Heart Healthy Diet",
          value: "heartHealthyDiet",
          name: "heartHealthyDiet",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Diabetic Diet",
          value: "diabeticDiet",
          name: "diabeticDiet",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Renal Diet",
          value: "renalDiet",
          name: "renalDiet",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Vegetarian",
          value: "vegetarian",
          name: "vegetarian",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Vegan",
          value: "vegan",
          name: "vegan",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Gluten Free",
          value: "glutenFree",
          name: "glutenFree",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Keto",
          value: "keto",
          name: "keto",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 8,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Pescatarian",
          value: "pescatarian",
          name: "pescatarian",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 9,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Tube Feeding",
          value: "tubeFeeding",
          name: "tubeFeeding",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 10,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Regular Diet",
          value: "regularDiet",
          name: "regularDiet",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 11,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "None",
          value: "none",
          name: "none",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 9,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "vital_specialDietSubOtherSubDesc",
              value: "describe",
              type: "text",
              placeholder: "",
              label: "Describe",
              variant: "outlined",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: false,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Other",
          value: "other",
          name: "vital_specialDietSubOther",
          type: "toggle_button",
          depth: 2,
        },
      ],
      questionId: 17,
      editToggle: false,
      depth: 1,
    },
    {
      id: 6,
      name: "vital_lostWeight",
      type: "options_tree",
      label: "Have you lost weight in the past 6 months?",
      fullWidth: true,
      required: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "None",
          value: "none",
          name: "none",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "5lbs",
          value: "fiveLbs",
          name: "fiveLbs",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "10lbs",
          value: "tenLbs",
          name: "tenLbs",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "15lbs",
          value: "fifteenLbs",
          name: "fifteenLbs",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "More than 15 lbs",
          value: "moreThanFifteenLbs",
          name: "moreThanFifteenLbs",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "10% of your weight (calculated by assessor)",
          value: "tenPercentYourWeight",
          name: "tenPercentYourWeight",
          type: "toggle_button",
          depth: 2,
        },
      ],
      questionId: 18,
      editToggle: false,
      depth: 1,
    },
    {
      questionId: 19,
      name: "vital_physicalActivityLevel",
      value: "physicalActivityLevel",
      label:
        "Select the statement that best describes your current level of physical activity.",
      type: "select",
      options: [
        [
          "I don’t exercise or walk regularly now, and I don’t plan to start in the near future.",
          "1",
        ],
        [
          "I don’t exercise or walk regularly now, but I've been thinking about starting.",
          "2",
        ],
        [
          "At least 30 minutes on some days, but fewer than 5 days a week.",
          "3",
        ],
        ["At least 30 minutes 5 or more days a week.", "4"],
        ["N/A", "5"],
      ],
      xs: 12,
      required: true,
      isCommentEnabled: true,
      isModified: false,
      depth: 1,
    },
    {
      questionId: 20,
      name: "vital_nutritionWeightManagementInterest",
      label: "Are you interested in nutrition and/ or weight management?",
      type: "select",
      value: "nutritionWeightManagementInterest",
      options: [
        ["I want to work on it.", "1"],
        ["I am already working on it but need some help.", "2"],
        ["I am already working on it and dont't need any help.", "3"],
        ["I am not interested at this time.", "4"],
        ["Member nonverbal or unable to answer.", "5"],
        ["Member refused to answer.", "6"],
      ],
      xs: 12,
      required: true,
      isCommentEnabled: true,
      isModified: false,
      depth: 1,
    },
    {
      id: 6,
      name: "vital_preventingChanges",
      value: "barriersToChange",
      type: "options_tree",
      label: "Are there things preventing you from making changes?",
      isExclusive: false,
      fullWidth: true,
      required: true,
      isMulti: true,
      xs: 12,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      hasSubQuestions: true,
      subQuestions: [
        {
          depth: 2,
          subQuestionId: 1,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          label: "Education Needed",
          value: "educationNeeded",
          name: "educationNeeded",
          isConfigured: true,
          type: "toggle_button",
        },
        {
          subQuestionId: 2,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Social Support",
          value: "socialSupport",
          name: "socialSupport",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 3,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Environmental Factors",
          value: "environmentalFactors",
          name: "environmentalFactors",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 4,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Stress",
          value: "stress",
          name: "stress",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 5,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Self Confidence",
          value: "selfConfidence",
          name: "selfConfidence",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 6,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Lack of Motivation",
          value: "lackOfMotivation",
          name: "lackOfMotivation",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 7,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Not a priority at this time",
          value: "notPriority",
          name: "notPriority",
          type: "toggle_button",
          depth: 2,
        },

        {
          subQuestionId: 8,
          hasSubQuestions: true,
          subQuestions: [
            {
              subQuestionId: 1,
              name: "vital_preventingChangesSubOtherSubDesc",
              value: "describe",
              type: "text",
              placeholder: "",
              label: "Describe",
              variant: "outlined",
              fullWidth: true,
              required: true,
              xs: 12,
              isCommentEnabled: false,
              isConfigured: true,
              isModified: false,
              depth: 3,
            },
          ],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "Other",
          value: "other",
          name: "vital_preventingChangesSubOther",
          type: "toggle_button",
          depth: 2,
        },
        {
          subQuestionId: 9,
          hasSubQuestions: false,
          subQuestions: [],
          editToggle: false,
          isCommentEnabled: false,
          isConfigured: true,
          isModified: false,
          label: "None",
          value: "none",
          name: "none",
          type: "toggle_button",
          depth: 2,
        },
      ],
      questionId: 21,
      editToggle: false,
      depth: 1,
    },
    {
      id: 22,
      name: "vital_Recommendations",
      value: "nutritionManagement",
      label: "Recommendations",
      placeholder: "",
      editToggle: false,
      defaultValue: "",
      type: "checkbox",
      options: [
        ["Nutrition/weight management", "nutritionManagement"],
        ["Case management referral for follow up", "caseManagementReferral"],
        [
          "Follow up with Clinician/Specialist",
          "followUpWithClinicianSpecialist",
        ],
        ["Member to use Durable Medical Equipment (DME)", "memberToUseDME"],
        ["Other", "other"],
      ],
      xs: 12,
      required: false,
      isCommentEnabled: true,
      isConfigured: true,
      isModified: false,
      questionId: 22,
    },
  ],
};
export const getInputsData = (client = "VPHP", year = "2023") => {
  let formLayoutData = {};
  if (year === "2023") {
    formLayoutData = {
      ...formLayoutData,
      ...json2023,
    };
  }
  if (year === "2024") {
    formLayoutData = {
      ...formLayoutData,
      ...json2024,
    };
  }

  return formLayoutData;
};
