// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form1 {
  display: flex;
  margin-top: 5px;
  align-items: center;
}

.filter-label {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filterInput {
  width: 280px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid rgba(17, 62, 91, 0.4);
  background: #fff;
}

.dialog-class {
  height: 400px;
  width: 1000px;
}

.dropdownMemberListDialog {
  border-radius: 1px;
  border: 1px solid var(--mainTheme);
  background: #fff;
  width: 248px;
  height: 50px;
  margin: 10px;
}

.dialogBtn {
  width: 131px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--mainTheme);
}

.dialogBtn:hover {
  background-color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dialog/Dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gCAAgC;EAChC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,uCAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".form1 {\r\n  display: flex;\r\n  margin-top: 5px;\r\n  align-items: center;\r\n}\r\n\r\n.filter-label {\r\n  color: #000;\r\n  font-family: \"Inter\", sans-serif;\r\n  font-size: 14px;\r\n  font-style: normal;\r\n  font-weight: 400;\r\n  line-height: normal;\r\n}\r\n\r\n.filterInput {\r\n  width: 280px;\r\n  height: 36px;\r\n  border-radius: 4px;\r\n  border: 1px solid rgba(17, 62, 91, 0.4);\r\n  background: #fff;\r\n}\r\n\r\n.dialog-class {\r\n  height: 400px;\r\n  width: 1000px;\r\n}\r\n\r\n.dropdownMemberListDialog {\r\n  border-radius: 1px;\r\n  border: 1px solid var(--mainTheme);\r\n  background: #fff;\r\n  width: 248px;\r\n  height: 50px;\r\n  margin: 10px;\r\n}\r\n\r\n.dialogBtn {\r\n  width: 131px;\r\n  height: 32px;\r\n  flex-shrink: 0;\r\n  border-radius: 20px;\r\n  background: var(--mainTheme);\r\n}\r\n\r\n.dialogBtn:hover {\r\n  background-color: red;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
