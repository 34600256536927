import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import { SelectDropdown } from "../../components/select/select";
import { Zoom } from "@mui/material";
import { QaFilterDialogs } from "../../components/Dashboard/Dialog/Dialog";
import AlertContext from "../../contexts/AlertContext";
import "./qalist.css";
import { useContext, useState, useEffect } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { styled } from "@mui/material/styles";
import React from "react";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Toaster from "../../components/toaster/toaster";
const useStyles = makeStyles((theme) => ({
  verticalDivider: {
    height: "100%",

    margin: 1,
    width: "5px",
    backgroundColor: "#1976d2",
  },

  card: {
    paddingX: "20px",
    textAlign: "center",
    width: "100%",
  },
  root: {
    border: "1px solid",
    padding: "10px",
    boxShadow: "5px 10px red",
  },
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 33,
  height: 20,
  padding: 0,
  display: "flex",
  marginLeft: 28,
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark" ? "#177ddc" : "var(--mainTheme)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 15,
    height: 16,
    marginLeft: 2,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const QAHeader = ({
  onDropdownChange,
  hraStatusOptions,
  onSearch,
  visitTypeOptions,
  clinicianData,
  selectedCheckboxes,
  onUnassignedBtnChange,
}) => {
  const { clientData, handleBadgeClick } = useUserContext();
  const [open, setOpen] = useState(false);
  const roleId = sessionStorage.getItem("roleId");
  const memberListOptions = [
    "Ready For QA",
    "Clinician Review",
    //"Referral"
  ];

  const filterDropdown = ["First name", "Identification#", "Last name"];
  const alertContext = useContext(AlertContext);
  const [selectedDropdownValue, setSelectedDropdownValue] =
    useState("Ready For QA");
  const [selectedFilter, setSelectedFilter] = useState(filterDropdown[0]);
  const [isFirstNameSelected, setIsFirstNameSelected] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [unassignedChecked, setUnassignedChecked] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [toasterSeverity, setToasterSeverity] = useState("");
  const [toasterOpen, setToasterOpen] = useState(false);
  const handleDropdownChange = (event) => {
    const newValue = event.target.value;
    setSelectedDropdownValue(newValue);
    onDropdownChange(newValue);
  };

  const toggleOpen = () => {
    alertContext.setFilterDialog(true);
  };
  const storedPlan = localStorage.getItem("clientPlan");
  const storedYear = localStorage.getItem("clientYear");

  const storedYearList = JSON.parse(
    localStorage.getItem("extractedYearOptions"),
  );

  useEffect(() => {
    setSelectedFilter("Identification#");
    onSearch("Identification#", "");
  }, []);

  const handleSearch = (e) => {
    if (e.key == "Enter") {
      if (searchTerm.length > 2) {
        if (selectedFilter === "First name") {
          onSearch(selectedFilter, searchTerm);
        } else if (selectedFilter === "Last name") {
          onSearch(selectedFilter, searchTerm);
        } else if (selectedFilter === "Identification#") {
          onSearch(selectedFilter, searchTerm);
        }
      } else {
        setToasterOpen(true);
        setToasterSeverity("error");
        setToasterMessage("Minimum 3 characters required to search!");
      }
    }
  };

  const handleResetSearch = async () => {
    //setLoading(true);
    setSelectedFilter(filterDropdown[0]);
    setSearchTerm("");
    onSearch(selectedFilter, "");
  };

  const handleFilterDropdownChange = (event) => {
    const { value } = event.target;
    setSelectedFilter(value);
    setIsFirstNameSelected(value === "First name");
  };
  const handleUnassignedSwitchChange = () => {
    const newUnassignedCheckedState = !unassignedChecked;
    setUnassignedChecked(newUnassignedCheckedState);
    if (newUnassignedCheckedState == true) {
      onUnassignedBtnChange("Unassigned");
    } else {
      onUnassignedBtnChange("NotUnassigned");
    }
  };

  return (
    <>
      <div className="member-list">
        <QaFilterDialogs
          hraStatusOptions={hraStatusOptions}
          visitTypeOptions={visitTypeOptions}
          clinicianData={clinicianData}
        />
        <div className="member-list-left">
          {(clientData?.isAllSelected === true ||
            localStorage.getItem("isAllSelected") === "true") &&
          (clientData?.plan !== null || storedPlan) &&
          (clientData?.year !== null || storedYearList) ? (
            <Tooltip
              TransitionComponent={Zoom}
              title={`Selected: ${clientData?.plan || storedPlan}-${
                clientData?.year || storedYear
              }`}
              placement="top"
              arrow
              enterDelay={150}
              leaveDelay={100}
            >
              <Box
                className="filterBoxML"
                onClick={handleBadgeClick}
                style={{ borderRadius: "50px" }}
              >
                <p>{`${clientData?.plan || storedPlan}-${
                  clientData?.year || storedYear
                }`}</p>
              </Box>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>
        <div className="member-list-right">
          <Box className="filterBoxFilterML" onClick={toggleOpen}>
            <p>Filter</p>
            <FilterAltIcon sx={{ height: "26px", width: "26px" }} />
          </Box>
          <SelectDropdown
            sx={{
              borderRadius: 20,
              width: "154px",
              height: "40px",
              fontSize: "16px",
              fontWeight: 500,
              marginRight: 1,
              marginLeft: 2,
              "&:hover": {
                backgroundColor: "var(--mainTheme)",
              },
            }}
            title="Identification"
            options={filterDropdown}
            className="dropdownMemberList"
            valueClass="dropdownValueMemberList"
            onChange={handleFilterDropdownChange}
          />
          <Box
            className="searchBoxML"
            sx={{ background: "var(--mainTheme200)" }}
          >
            <TextField
              onKeyDown={(e) => handleSearch(e)}
              placeholder="Search"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm && (
                      <CancelOutlinedIcon
                        style={{ marginRight: "5px", cursor: "pointer" }}
                        onClick={handleResetSearch}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
              variant="standard"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
        </div>
      </div>

      {(clientData?.isAllSelected === true ||
        localStorage.getItem("isAllSelected") === "true") &&
      (clientData?.plan !== null || storedPlan) &&
      (clientData?.year !== null || storedYearList) ? (
        <div
          style={{
            marginTop: "22px",
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              // width: "100%",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tabs
              className="custom-tabs"
              value={selectedDropdownValue}
              onChange={(e, newValue) =>
                handleDropdownChange({ target: { value: newValue } })
              }
              aria-label="wrapped label tabs example"
            >
              {memberListOptions.map((option, index) => (
                <Tab key={index} value={option} label={option} />
              ))}
            </Tabs>
          </Box>
          {roleId == 6 && selectedDropdownValue == "Ready For QA" ? (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginLeft={1}
            >
              <AntSwitch
                defaultChecked={unassignedChecked}
                onChange={handleUnassignedSwitchChange}
                inputProps={{ "aria-label": "ant design" }}
              />

              <Typography style={{ marginLeft: "5px" }}>UnAssigned</Typography>
            </Stack>
          ) : null}
        </div>
      ) : (
        <></>
      )}
      <Toaster
        open={toasterOpen}
        onClose={() => setToasterOpen(false)}
        severity={toasterSeverity}
        message={toasterMessage}
      />
    </>
  );
};

export default QAHeader;
