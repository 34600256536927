// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-dashboard {
    width: 100%;
}

.content-dashboard .css-12usxvt-MuiTableCell-root{
    line-height: 15px !important;
}

.datatable-scheduler {
    margin-top: 23px;
}

.datatable-scheduler .css-75kgeb-MuiTableCell-root{
    padding: 0px !important;
    font-family: sans-serif;
    border-bottom: unset !important;
}

.datatable-scheduler .css-1l9nj0y-MuiPaper-root-MuiTableContainer-root{
    box-shadow: 0px 2px 9px 4px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
}

.datatable-scheduler .css-75kgeb-MuiTableCell-root.MuiTableCell-body{
    font-size: 13px;
    text-align: -webkit-center;
}

.datatable-scheduler .css-ojb8nx-MuiTableCell-root{
    line-height: 1px;
}

.content-dashboard .css-1l9nj0y-MuiPaper-root-MuiTableContainer-root{
    max-height: 502px !important;
}

.datatable-scheduler .css-8g76k9>:not(style){
    width: unset !important;
}


.datatable-scheduler .css-11bf4st-MuiTableCell-root{
    padding: 3px;
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminDashboard/Scheduler/Scheduler.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,uBAAuB;IACvB,+BAA+B;AACnC;;AAEA;IACI,+GAA+G;AACnH;;AAEA;IACI,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,uBAAuB;AAC3B;;;AAGA;IACI,YAAY;AAChB","sourcesContent":[".content-dashboard {\r\n    width: 100%;\r\n}\r\n\r\n.content-dashboard .css-12usxvt-MuiTableCell-root{\r\n    line-height: 15px !important;\r\n}\r\n\r\n.datatable-scheduler {\r\n    margin-top: 23px;\r\n}\r\n\r\n.datatable-scheduler .css-75kgeb-MuiTableCell-root{\r\n    padding: 0px !important;\r\n    font-family: sans-serif;\r\n    border-bottom: unset !important;\r\n}\r\n\r\n.datatable-scheduler .css-1l9nj0y-MuiPaper-root-MuiTableContainer-root{\r\n    box-shadow: 0px 2px 9px 4px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);\r\n}\r\n\r\n.datatable-scheduler .css-75kgeb-MuiTableCell-root.MuiTableCell-body{\r\n    font-size: 13px;\r\n    text-align: -webkit-center;\r\n}\r\n\r\n.datatable-scheduler .css-ojb8nx-MuiTableCell-root{\r\n    line-height: 1px;\r\n}\r\n\r\n.content-dashboard .css-1l9nj0y-MuiPaper-root-MuiTableContainer-root{\r\n    max-height: 502px !important;\r\n}\r\n\r\n.datatable-scheduler .css-8g76k9>:not(style){\r\n    width: unset !important;\r\n}\r\n\r\n\r\n.datatable-scheduler .css-11bf4st-MuiTableCell-root{\r\n    padding: 3px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
