import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import MemberUrgentReferralForm from "./MemberUrgentReferralForm";
import MemberNonUrgentReferralForm from "./MemberNonUrgentReferralForm";
import MemberCriticalReferralForm from "./MemberCriticalReferralForm";
import { Box, Divider, TextField } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  // '& .MuiDialogActions-root': {
  //   padding: theme.spacing(1),
  // },
  "& .MuiPaper-root": {
    width: "80%", // Change this to the desired width
    maxWidth: "none", // Prevents the dialog from shrinking
  },
}));

const BootstrapDialogTitle = ({ children, onClose, ...other }) => {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ReviewReferralForm({
  open,
  onClose,
  patientId,
  assignedClinician,
  patientName,
  qaName,
}) {
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        sx={{
          backgroundColor: "var(--mainTheme900)",
          color: "white",
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "600",
        }}
        onClose={onClose}
      >
        Review Referral Form
      </BootstrapDialogTitle>
      <DialogContent>
        {selectedValue === "URGENT" && <MemberUrgentReferralForm />}
        {selectedValue === "NON-URGENT" && <MemberNonUrgentReferralForm />}
        {selectedValue === "Critical Incident Report" && (
          <MemberCriticalReferralForm />
        )}
      </DialogContent>
      <Box style={{ padding: "20px" }}>
        <Box>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Typography>PATIENT ID: {patientId}</Typography>
              <Typography>PATIENT NAME: {patientName} </Typography>
            </Box>
            <Box style={{ marginRight: "26%" }}>
              <Typography>CLINICIAN: {assignedClinician}</Typography>
              <Typography>QA ANALYST: {qaName}</Typography>
            </Box>
          </Box>
        </Box>

        <Divider
          sx={{
            marginTop: "32px",
            marginBottom: "16px",
            borderColor: "#0000004d",
            borderBottomWidth: "thin",
            width: "100%",
          }}
        />
        <Typography>Clinical Indication of the query:</Typography>
        <Box
          style={{ border: "1px solid black", height: "16vw", width: "75vw" }}
        >
          <Typography>From: </Typography>
          <Typography>Date: </Typography>
          <Typography>Select alliant checkbox: </Typography>
        </Box>

        <Typography style={{ marginTop: "20px" }}>
          Brief statement of needed query:
        </Typography>
        <Box style={{ border: "1px solid black", width: "75vw" }}>
          <TextField
            style={{ width: "100%", height: "200px", overflow: "scroll" }}
            multiline
            placeholder="Enter Your Response Here..."
          ></TextField>
        </Box>
      </Box>
      <DialogActions style={{ width: "100%", height: "48px" }}>
        <Button
          sx={{
            backgroundColor: "var(--mainTheme900)",
            color: "white",
            width: "100px",
            padding: "21px 11px",
            "&:hover": {
              backgroundColor: "var(--mainTheme500)",
            },
          }}
          //  autoFocus
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: "var(--mainTheme900)",
            color: "white",
            width: "100px",
            padding: "21px 11px",

            "&:hover": {
              backgroundColor: "var(--mainTheme500)",
            },
          }}
          // autoFocus
          onClick={onClose}
        >
          Re-Submit
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
